import { clsx } from 'clsx'
import { CheckIcon } from './icons'

export function ProgressStepIndicator({
  stepNumber,
  stepCount
}: {
  stepNumber: number
  stepCount: number
}) {
  return (
    <nav aria-label="Progress">
      <ol role="list" className="flex items-center">
        {Array.from({ length: stepCount }, (_, i) => i + 1).map((step) => {
          let status: 'complete' | 'current' | 'upcoming'
          if (step < stepNumber) {
            status = 'complete'
          } else if (step === stepNumber) {
            status = 'current'
          } else {
            status = 'upcoming'
          }

          return (
            <li key={step} className={clsx(step !== stepCount ? 'pr-8 sm:pr-20' : '', 'relative')}>
              {status === 'complete' ? (
                <>
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-blue-600" />
                  </div>
                  <a
                    href="#"
                    className="relative flex h-4 w-4 items-center justify-center rounded-full bg-blue-600 hover:bg-blue-900"
                  >
                    <CheckIcon className="size-2.5 text-white" />
                  </a>
                </>
              ) : status === 'current' ? (
                <>
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div className="relative flex h-4 w-4 items-center justify-center rounded-full border-2 border-blue-600 bg-white">
                    <span aria-hidden="true" className="h-1.5 w-1.5 rounded-full bg-blue-600" />
                  </div>
                </>
              ) : (
                <>
                  <div aria-hidden="true" className="absolute inset-0 flex items-center">
                    <div className="h-0.5 w-full bg-gray-200" />
                  </div>
                  <div className="group relative flex h-4 w-4 items-center justify-center rounded-full border-2 border-gray-300 bg-white hover:border-gray-400">
                    <span
                      aria-hidden="true"
                      className="h-1.5 w-1.5 rounded-full bg-transparent group-hover:bg-gray-300"
                    />
                  </div>
                </>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}
