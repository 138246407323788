import { ReactNode, useEffect, useMemo, useState } from 'react'
import { DocumentRenderMode, DocumentRenderModeProvider } from './primitive-elements'
import { renderToPDF } from './render-to-pdf'

type Props = {
  mode: DocumentRenderMode
  header?: ReactNode
  children: ReactNode
}
export function PDFElementDocViewer({ mode, header, children }: Props) {
  return (
    <DocumentRenderModeProvider value={mode}>
      {mode == 'pdf' ? (
        <PDFDocPDFViewer>{children}</PDFDocPDFViewer>
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            background: '#cbccd4',
            padding: '24px 0',
            overflow: 'scroll',
            position: 'relative'
          }}
        >
          {header}
          {children}
        </div>
      )}
    </DocumentRenderModeProvider>
  )
}

function PDFDocPDFViewer({ children }: { children: ReactNode }) {
  const [dataUrl, setDataUrl] = useState<string | undefined>()

  const dataUrlPromise = useMemo(async () => {
    const doc = await renderToPDF(children)
    return doc.saveAsBase64({ dataUri: true })
  }, [children])

  useEffect(() => {
    dataUrlPromise.then((url) => setDataUrl(url))
  }, [dataUrlPromise])

  if (!dataUrl) return <></>

  return (
    <iframe
      src={dataUrl}
      style={{
        width: '100%',
        height: '100%',
        padding: 0,
        margin: 0,
        border: 'none'
      }}
    />
  )
}
