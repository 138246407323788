type Props = {
  className?: string
}
export function AddNoteIcon({ className }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M14.7503 0C16.4833 0 17.8995 1.35645 17.9951 3.06558L18.0003 3.25V9.02188C17.5371 8.72531 17.0338 8.48588 16.5003 8.31353V3.25C16.5003 2.33183 15.7932 1.57881 14.8938 1.5058L14.7503 1.5H3.25029C2.65011 1.5 2.1205 1.80213 1.80525 2.2626C1.56429 2.61456 1.50049 3 1.50049 3.06558C1.00018 3.06558 0.265342 3.06555 0 3.06555C0.000183105 3 0.114317 2.34088 0.224012 2.06188C0.677297 0.908987 1.77025 0.0776222 3.06586 0.00514483L3.25029 0H14.7503ZM8.31368 16.5C8.48602 17.0335 8.72544 17.5368 9.022 18H3.25029C1.51725 18 0.10104 16.6435 0.00543029 14.9344L0.000285454 14.75L0 3.06555C0.500164 3.06555 1.00033 3.06555 1.50049 3.06558L1.50029 14.75C1.50029 15.6682 2.2074 16.4212 3.10676 16.4942L3.25029 16.5H8.31368ZM8.73081 11.5031C9.01758 10.9521 9.38097 10.4474 9.80683 10.0031H5.74804L5.64627 10.0099C5.2802 10.0596 4.99804 10.3734 4.99804 10.7531C4.99804 11.1673 5.33383 11.5031 5.74804 11.5031H8.73081ZM12.2525 6.49623C12.6667 6.49623 13.0025 6.83201 13.0025 7.24623C13.0025 7.62592 12.7204 7.93972 12.3543 7.98938L12.2525 7.99623H5.74804C5.33383 7.99623 4.99804 7.66044 4.99804 7.24623C4.99804 6.86653 5.2802 6.55274 5.64627 6.50307L5.74804 6.49623H12.2525ZM20.0002 14.5C20.0002 11.4624 17.5378 9 14.5002 9C11.4626 9 9.00018 11.4624 9.00018 14.5C9.00018 17.5376 11.4626 20 14.5002 20C17.5378 20 20.0002 17.5376 20.0002 14.5ZM15.0008 15L15.0013 17.5035C15.0013 17.7797 14.7774 18.0035 14.5013 18.0035C14.2251 18.0035 14.0013 17.7797 14.0013 17.5035L14.0008 15H11.4958C11.2199 15 10.9963 14.7762 10.9963 14.5C10.9963 14.2239 11.2199 14 11.4958 14H14.0006L14.0002 11.4993C14.0002 11.2231 14.224 10.9993 14.5002 10.9993C14.7763 10.9993 15.0002 11.2231 15.0002 11.4993L15.0006 14H17.4968C17.7726 14 17.9963 14.2239 17.9963 14.5C17.9963 14.7762 17.7726 15 17.4968 15H15.0008Z"
        fill="currentColor"
      />
    </svg>
  )
}
