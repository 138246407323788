import { clsx } from 'clsx'
import { ReactNode } from 'react'

export type TagChipColor = 'gray' | 'green' | 'yellow' | 'blue' | 'red' | 'orange' | 'purple'

export function TagChip({
  children,
  color,
  tip,
  onClick,
  className
}: {
  children: ReactNode
  color: TagChipColor
  tip?: string
  onClick?: () => void
  className?: string
}) {
  return (
    <div
      className={clsx(
        'h-4 cursor-pointer rounded-sm px-1.5 text-center text-xs',
        color == 'gray' && 'bg-stone-200 text-gray-800',
        color == 'green' && 'bg-green-600 text-white',
        color == 'yellow' && 'bg-yellow-400 text-gray-900',
        color == 'blue' && 'bg-blue-200 text-blue-600',
        color == 'red' && 'bg-red-200 text-red-600',
        color == 'orange' && 'bg-orange-400 text-orange-900',
        color == 'purple' && 'bg-purple-200 text-purple-600',
        className
      )}
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
      }}
      title={tip}
    >
      {children}
    </div>
  )
}
