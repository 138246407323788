import { isEmpty } from '@st/util/json-value'

type QueryParams = Record<string, string>

type LocationPath = {
  pathname: string
  searchParams: Record<string, string>
}

export function parsePath(path: string): LocationPath {
  const url = new URL('https://example.com' + path)
  const pathname = url.pathname

  const searchParams = Array.from(url.searchParams).reduce(
    (acc, [key, value]) => {
      acc[key] = value
      return acc
    },
    {} as Record<string, string>
  )

  return { pathname, searchParams }
}

export function getQueryParams(url: string): QueryParams {
  const parts = url.split('?')
  if (parts.length == 1) {
    return {}
  }

  const urlSearchParams = new URLSearchParams(parts[1])
  const queryParams: QueryParams = {}

  for (var [name, value] of Array.from(urlSearchParams.entries())) {
    queryParams[name] = value
  }

  return queryParams
}

export function addQueryParams(
  url: string,
  queryParams: { [name: string]: string | undefined | null }
): string {
  const parts = url.split('?')
  const searchParams = new URLSearchParams(parts[1])
  for (var [name, value] of Object.entries(queryParams)) {
    if (isEmpty(value)) continue
    searchParams.append(name, value!)
  }
  const search = searchParams.toString()
  return search == '' ? parts[0] : parts[0] + '?' + search
}

export function removeQueryParams(url: string, paramsToRemove: string[]) {
  const parts = url.split('?')
  const searchParams = new URLSearchParams(parts[1])

  for (const param of paramsToRemove) {
    searchParams.delete(param)
  }

  const search = searchParams.toString()
  return search == '' ? parts[0] : parts[0] + '?' + search
}

export function hostFor(href: string): string {
  return new URL(href).host
}

/**
 * Strip out unnecessary parts of a url such, www, https, and trailing slashes
 * that make a url less human friendly.
 *
 * These will be added in by a browser anyway when pasting into the address bar,
 * so they are unecessary to show when displaying to a user.
 *
 * @param href
 */
export function humanFriendlyUrl(href: string): string {
  const { host, pathname, search, protocol } = new URL(href)
  return (
    protocol +
    '//' +
    (host.startsWith('www.') ? host.substring('www.'.length) : host) +
    (pathname == '/' ? '' : pathname) +
    search
  )
}

type RewriteOptions = {
  pathname?: string
  host?: string
}

export function rewrite(originalUrl: string, options: RewriteOptions): string {
  const url = new URL(originalUrl)

  if (options.pathname) {
    url.pathname = options.pathname
  }

  if (options.host) {
    url.host = options.host
  }

  return url.toString()
}

/**
 * For certain situations, such as a url in an email for an image,
 * URL paths cannot contain certain characters like spaces.
 *
 * But file names can contain spaces!

 * This causes email clients to auto-encode the invalid characters.
 * For example, GMail will replace " " with "+" which would result in broken links because,
 * in our app it would be "%20" instead
 *
 * By encoding the path segments on our end, we can avoid delegating this to the email providers
 * who do crazy shit like replace " " with "+" among the other crazy shit they do.
 * 
 *
 * For example:
 * https://app.stanfordtax.com/storage/organizations/abc123/public/FB HEADER.png
 *
 * After:
 * https://app.stanfordtax.com/storage/organizations/abc123/public/FB%2520HEADER.png
 *
 *
 * @param url
 */
export function autoEncodePathSegments(url: string): string {
  // calling toString() invokes the auto-encoding as we'd want it
  return new URL(url).toString()
}
