import { many, map, opt, Parser, seq } from './tspc'

export function join<T, S>(
  parser: Parser<T, S>,
  separator: Parser<T, unknown>
): Parser<T, S[]> {
  const separatorAndToken = map(seq(separator, parser), (el) => el[1])
  const rest = opt(many(separatorAndToken))
  return map(seq(parser, rest), (el) => {
    return el[1] ? [el[0], ...el[1]] : [el[0]]
  })
}

/**
 * Makes sure that the {@link text} is fully described by the {@link parser}
 */
export function matches<T extends string>(
  parser: Parser<T, unknown>,
  text: T
): boolean {
  const res = parser(text, 0)
  return res.success && res.position == text.length
}
