import { cn } from '@util/cn'
import { ReactElement } from 'react'

type ButtonType = 'large' | 'small'

type Props = {
  value: boolean | undefined
  onChange?: (newValue: boolean | undefined) => void
  type?: ButtonType
}
export function YesNo({ value, onChange, type = 'large' }: Props) {
  const iconSize = type == 'large' ? 74 : 24
  return (
    <div className={cn('flex flex-row justify-center', type == 'large' ? 'gap-5' : null)}>
      <IconButton
        selected={value === true}
        label="Yes"
        onClick={() => onChange?.(true)}
        type={type}
      >
        <YesIcon size={iconSize} selected={value === true} />
      </IconButton>
      <IconButton
        selected={value === false}
        label="No"
        onClick={() => onChange?.(false)}
        type={type}
      >
        <NoIcon size={iconSize} selected={value === false} />
      </IconButton>
    </div>
  )
}

type IconButtonProps = {
  children: ReactElement
  onClick: () => void
  label: string
  selected: boolean
  type: ButtonType
}
function IconButton({ onClick, children, selected, label, type }: IconButtonProps) {
  return (
    <button
      className={cn(
        'icon-button',
        `icon-button--${type}`,
        selected ? 'icon-button--selected' : null
      )}
      onClick={() => onClick()}
    >
      {children}
      <span className="text-base">{label}</span>
    </button>
  )
}

function YesIcon({ size = 74, selected }: { size?: number; selected: boolean }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {selected ? (
        <circle cx="37" cy="37" r="36" stroke="white" strokeWidth="2" />
      ) : (
        <>
          <ellipse cx="35.7348" cy="35.1486" rx="22.1606" ry="22.1606" fill="white" />
          <circle cx="36" cy="36" r="36" fill="#5ABE8E" />
        </>
      )}
      <path
        d="M29.1582 36.5968C28.6317 35.9571 27.6864 35.8654 27.0468 36.3918C26.4071 36.9183 26.3154 37.8636 26.8418 38.5032L29.1582 36.5968ZM49.2964 26.7545C49.7131 26.0385 49.4705 25.1203 48.7545 24.7036C48.0385 24.2869 47.1203 24.5295 46.7036 25.2455L49.2964 26.7545ZM34.8644 45.8902L33.7062 46.8435L34.8644 45.8902ZM36.5008 45.7578L37.7972 46.5123L36.5008 45.7578ZM26.8418 38.5032L33.7062 46.8435L36.0226 44.937L29.1582 36.5968L26.8418 38.5032ZM37.7972 46.5123L49.2964 26.7545L46.7036 25.2455L35.2044 45.0032L37.7972 46.5123ZM33.7062 46.8435C34.813 48.1882 36.9211 48.0175 37.7972 46.5123L35.2044 45.0032C35.3796 44.7022 35.8012 44.6681 36.0226 44.937L33.7062 46.8435Z"
        fill="white"
      />
    </svg>
  )
}

function NoIcon({ size = 74, selected }: { size?: number; selected: boolean }) {
  if (selected) {
    return (
      <svg
        width={size}
        height={size}
        viewBox="0 0 74 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="37" cy="37" r="36" stroke="white" strokeWidth="2" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.8388 26.0398C46.4246 25.4541 47.3743 25.4541 47.9601 26.0398C48.5459 26.6256 48.5459 27.5754 47.9601 28.1612L39.1213 37L47.9602 45.8388C48.546 46.4246 48.546 47.3744 47.9602 47.9602C47.3744 48.5459 46.4246 48.5459 45.8389 47.9602L37 39.1213L28.1611 47.9601C27.5754 48.5459 26.6256 48.5459 26.0398 47.9601C25.454 47.3744 25.454 46.4246 26.0398 45.8388L34.8787 37L26.0399 28.1612C25.4541 27.5754 25.4541 26.6256 26.0399 26.0398C26.6257 25.4541 27.5754 25.4541 28.1612 26.0398L37 34.8787L45.8388 26.0398Z"
          fill="white"
        />
      </svg>
    )
  }
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 74 74"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="36" cy="36" r="36" fill="#D0474B" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.8388 25.0398C45.4246 24.4541 46.3743 24.4541 46.9601 25.0398C47.5459 25.6256 47.5459 26.5754 46.9601 27.1612L38.1213 36L46.9602 44.8388C47.546 45.4246 47.546 46.3744 46.9602 46.9602C46.3744 47.5459 45.4246 47.5459 44.8389 46.9602L36 38.1213L27.1611 46.9601C26.5754 47.5459 25.6256 47.5459 25.0398 46.9601C24.454 46.3744 24.454 45.4246 25.0398 44.8388L33.8787 36L25.0399 27.1612C24.4541 26.5754 24.4541 25.6256 25.0399 25.0398C25.6257 24.4541 26.5754 24.4541 27.1612 25.0398L36 33.8787L44.8388 25.0398Z"
        fill="white"
      />
    </svg>
  )
}
