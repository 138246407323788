import { removeQueryParams } from '@st/util/url'

/**
 * The destination to go to after a successful login
 *
 * When a user logs in with a magic link, we want to stripe out the email link params
 * for security reasons and so the url doesn't have a lot of junk on it.
 *
 * @param currentUrl
 * @returns
 */
export function removeLoginQueryParams(location: string): string {
  return removeQueryParams(location, ['magic-link-token'])
}
