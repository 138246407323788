import { cn } from '@util/cn'
import { CSSProperties, ReactNode } from 'react'
import { SetAppRoute } from './routing/routes'
import { useLink } from './use-link'

type Props = {
  href: SetAppRoute | string
  style?: CSSProperties
  className?: string
  children: ReactNode
  onClick?: () => void
  target?: string
}

export function Link({ href, className, children, style, onClick, target }: Props) {
  const { href: aHref, onClick: handleClick, isActive } = useLink({ href, target, onClick })

  return (
    <a className={className} href={aHref} target={target} onClick={handleClick} style={style}>
      {children}
    </a>
  )
}
