import type { Core } from '@pdftron/webviewer'
import { XFile, fetchFile, toXFile } from '@st/util/xfile'
import { asyncMap } from '@st/util/async'
import { Result, err, ok, splitResults } from '@st/util/result'
import { loadPDFTronCore } from '../pdftron'
import { Size } from '@st/util/geom'

type InvalidPDFError = 'passwordRequired' | 'unknown'

export type InvalidPDF = { file: XFile; error: InvalidPDFError; message?: string }
export type PDFError = {
  type: 'invalid'
  invalidDocuments: InvalidPDF[]
}

const A4_WIDTH = 595.276

type DocResult = {
  file: XFile
  doc: Core.Document
}

export async function concatPDFs(sourceFiles: XFile[]): Promise<Result<XFile, PDFError>> {
  const core = await loadPDFTronCore()
  const docResults = await asyncMap(
    sourceFiles,
    async (xfile): Promise<Result<DocResult, InvalidPDF>> => {
      const data = await fetchFile(xfile).then((f) => f.blob())

      try {
        const doc = await core.createDocument(data, { extension: 'pdf' })
        doc.setFilename(xfile.name)
        const finalDoc = await removePDFSecurityIfPresent(doc)

        return ok({ file: xfile, doc: finalDoc })
      } catch (e) {
        if (typeof e == 'string') {
          if (e.includes('requires a password')) {
            return err({ file: xfile, error: 'passwordRequired' })
          }
        }
        return err({ file: xfile, error: 'unknown' })
      }
    }
  )

  const { succeeded, failed } = splitResults(docResults)

  if (failed.length > 0) {
    return err({ type: 'invalid', invalidDocuments: failed })
  }

  const [master, ...remaining] = succeeded

  const invalidPdfs: InvalidPDF[] = []

  for (const result of remaining) {
    try {
      await master.doc.insertPages(result.doc)
    } catch (e) {
      invalidPdfs.push({
        file: result.file,
        error: 'unknown',
        message: e instanceof Error ? e.message : undefined
      })
    }
  }

  if (invalidPdfs.length > 0) {
    return err({ type: 'invalid', invalidDocuments: invalidPdfs })
  }

  await ensureScaleToMaxDimensions(master.doc, {
    width: A4_WIDTH,
    // we are OK with any height
    height: Number.MAX_SAFE_INTEGER
  })

  const combinedDocumentData = await master.doc.getFileData()
  const file = new File([new Blob([combinedDocumentData])], 'combined.pdf', {
    type: 'application/pdf'
  })

  return toXFile(file, { protocol: 'blob' }).then(ok)
}

/**
 * Ensures all pages in a PDF document are scaled to fit within maximum dimensions
 * @param doc The PDFTron Document to process
 * @param size The maximum dimensions to scale to, with width and height properties
 */

async function ensureScaleToMaxDimensions(doc: Core.Document, size: Size) {
  const maxWidth = size.width
  const maxHeight = size.height

  const core = await loadPDFTronCore()
  const pdfDoc = await doc.getPDFDoc()
  const pageCount = await pdfDoc.getPageCount()

  for (let i = 1; i <= pageCount; i++) {
    const page = await pdfDoc.getPage(i)
    const mediaBox = await page.getMediaBox()
    const width = await mediaBox.width()
    const height = await mediaBox.height()

    const scaleX = maxWidth / width
    const scaleY = maxHeight / height

    const scale = Math.min(scaleX, scaleY)

    if (scale < 1) {
      // Scale the page content
      await page.scale(scale)

      // Adjust the page boxes to match the new dimensions
      const newWidth = width * scale
      const newHeight = height * scale
      const newMediaBox = await core.PDFNet.Rect.init(0, 0, newWidth, newHeight)
      await page.setMediaBox(newMediaBox)
      await page.setCropBox(newMediaBox)
    }
  }
}

async function removePDFSecurityIfPresent(doc: Core.Document): Promise<Core.Document> {
  const core = await loadPDFTronCore()

  const pdfDoc = await doc.getPDFDoc()
  if (await pdfDoc.isEncrypted()) {
    await pdfDoc.removeSecurity()

    const decryptedData = await pdfDoc.saveMemoryBuffer(
      core.PDFNet.SDFDoc.SaveOptions.e_linearized |
        core.PDFNet.SDFDoc.SaveOptions.e_compatibility |
        core.PDFNet.SDFDoc.SaveOptions.e_remove_unused
    )

    const decryptedDoc = await core.createDocument(decryptedData, {
      extension: 'pdf',
      filename: doc.getFilename()
    })

    return decryptedDoc
  } else {
    return doc
  }
}
