import { useMemo } from 'react'

export function TimeLabel({ dateTime, className }: { dateTime: string; className?: string }) {
  const formattedTime = useMemo(() => formatISO8601DateTime(dateTime), [dateTime])
  return (
    <time className={className} dateTime={dateTime}>
      {formattedTime}
    </time>
  )
}

function formatISO8601DateTime(time: string): string {
  return parseISO8601Time(time).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })

  /**
   * Parse an ISO8601 timestamp that's cross-browser compatible.
   */
  function parseISO8601Time(iso8601string: string): Date {
    // Safari doesn't accept ISO8601 strings if the date and time are separated by a space
    // It must contain a T
    // See more: https://stackoverflow.com/a/60391306
    return new Date(iso8601string.replace(/\s+/g, 'T'))
  }
}
