import { CompareFn, sort } from './sort'
export { asc } from './sort'

/**
 * Get an element at a given {@link index} for an {@link array}
 * If the index is out of range, return undefined
 *
 * @param array
 * @param index
 * @returns
 */
export function at<T>(array: T[], index: number): T | undefined {
  return index < 0 || index >= array.length ? undefined : array[index]
}

export type Predicate<T> = (value: T) => boolean

export function maybe<T>(value: T | undefined | null): [T] | [] {
  if (!value) return []
  return [value]
}

/**
 * Find the last element in array array which matches a given condition
 *
 * @param array
 * @param condition
 * @returns
 */
export function findLast<T>(array: T[], condition: Predicate<T>): T | undefined {
  for (var i = array.length - 1; i >= 0; i--) {
    if (condition(array[i]) === true) {
      return array[i]
    }
  }
  return undefined
}

/**
 * Generates an array of numbers within a specified range.
 * Includes the start ane end numbers.
 *
 * Keep in mind: the length of the array will be (end - start + 1)
 *
 * @param start The starting value of the range. This number is included in the returned array.
 * @param end The ending value of the range. This number is included in the returned array.
 * @returns An array of numbers starting from `start` and ending at `end`. Each element in the array is a consecutive number from the start value, incrementing by 1 until the end value is reached.
 *
 * @example
 * // For generating a range from 1 to 5
 * const numbers = range(1, 5);
 * console.log(numbers); // Output: [1, 2, 3, 4, 5]
 *
 * @example
 * // For generating a range from -2 to 2
 * const numbers = range(-2, 2);
 * console.log(numbers); // Output: [-2, -1, 0, 1, 2]
 */
export function range(start: number, end: number): number[] {
  if (start > end) {
    return []
  }
  return Array(end - start + 1)
    .fill(0)
    .map((_, index) => start + index)
}
export function updateAt<T>(array: T[], index: number, fn: (el: T) => T): T[] {
  return array.map((el, i) => {
    if (i == index) return fn(el)
    return el
  })
}

/**
 * Given an {@link array}, return a map of arrays where the elements are
 * grouped by {@link keyOf}
 */
export function groupBy<T>(array: T[], keyOf: (el: T) => string): Record<string, T[]> {
  const map: Record<string, T[]> = {}
  for (var el of array) {
    let key = keyOf(el)
    if (map[key]) {
      map[key].push(el)
    } else {
      map[key] = [el]
    }
  }
  return map
}

export type PaginatedPage<T> = {
  number: number
  items: T[]
  isFirst: boolean
  isLast: boolean
}

export function paginate<T>(array: T[], size: number): Array<PaginatedPage<T>> {
  if (size <= 0) {
    throw new Error('Chunk size must be positive')
  }

  const pages: Array<PaginatedPage<T>> = []

  if (array.length == 0) {
    return [{ number: 1, items: [], isFirst: true, isLast: true }]
  }

  for (let i = 0; i < array.length; i += size) {
    pages.push({
      number: i + 1,
      isFirst: false,
      isLast: false,
      items: array.slice(i, i + size)
    })
  }

  pages[0].isFirst = true
  pages[pages.length - 1].isLast = true

  return pages
}

/**
 * Given an {@link array}, return a map where the the key
 * is the {@link keyOf} property of the value
 *
 * If there are duplicate elements with the same key, the last element be used
 */
export function keyBy<T>(array: T[], keyOf: (el: T) => string): Record<string, T> {
  const map: Record<string, T> = {}
  for (var el of array) map[keyOf(el)] = el
  return map
}

/**
 * Given an {@link array}, return a map where the the key
 * is the {@link entryOf} property of the value
 *
 * If there are duplicate elements with the same key, the last element be used
 */
export function objectBy<A, K extends string | number | symbol, V>(
  array: A[],
  entryOf: (el: A) => [K, V]
): Record<K, V> {
  const map: Record<string | number | symbol, V> = {}
  for (var el of array) {
    const [k, v] = entryOf(el)
    map[k] = v
  }
  return map
}

/**
 * Return a new array with the duplicates from {@link array} removed
 */
export function unique<T>(array: T[]): T[] {
  return Array.from(new Set(array))
}

/**
 * Given an {@link array}, return a map where the the key
 * is the {@link uniqueValueOf} property of the value
 *
 * If there are duplicate elements with the same key, the last element be used
 */
export function uniqueBy<T>(array: T[], uniqueValueOf: (el: T) => string | number): T[] {
  const seen = new Set()
  const uniqueValues: T[] = []
  for (const el of array) {
    const uniqueValue = uniqueValueOf(el)
    if (seen.has(uniqueValue)) {
      continue
    }
    seen.add(uniqueValue)
    uniqueValues.push(el)
  }
  return uniqueValues
}

class Pipe<T> {
  constructor(v: T) {
    this._v = v
  }

  private _v: T

  value(): T {
    return this._v
  }

  log(): Pipe<T> {
    console.log(this._v)
    return this
  }

  map<E, X>(this: Pipe<E[]>, mapFn: (v: E, index: number) => X): Pipe<X[]> {
    return new Pipe(this._v.map(mapFn))
  }

  flatMap<E, X>(this: Pipe<E[]>, mapFn: (v: E) => X[]): Pipe<X[]> {
    return new Pipe(this._v.flatMap(mapFn))
  }

  filter<E>(this: Pipe<E[]>, filterFn: (v: E) => boolean): Pipe<E[]> {
    return new Pipe(this._v.filter(filterFn))
  }

  mapRecord<E, X>(this: Pipe<Record<string, E>>, func: (el: E, key: string) => X): Pipe<X[]> {
    return new Pipe(Object.entries<E>(this._v).map(([key, el]) => func(el, key)))
  }

  sort<E>(this: Pipe<E[]>, compareFn: CompareFn<E>): Pipe<E[]> {
    return new Pipe(sort(this._v, compareFn))
  }

  groupBy<T>(this: Pipe<T[]>, keyOf: (el: T) => string): Pipe<Record<string, T[]>> {
    return new Pipe(groupBy(this._v, keyOf))
  }

  transform<X>(fn: (v: T) => X): Pipe<X> {
    return new Pipe(fn(this._v))
  }
}

export function pipe<T>(value: T): Pipe<T> {
  return new Pipe(value)
}
