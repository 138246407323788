export type Cast<Out> = (input: unknown) => Out

export const toStr: Cast<string> = (input) => (typeof input === 'string' ? input : '')

export const toStrArray: Cast<string[]> = (input) =>
  Array.isArray(input) && input.every((i) => typeof i === 'string') ? input : []

export const toMaybeStr: Cast<string | undefined> = (input) =>
  typeof input === 'string' ? input : undefined

export const toBool: Cast<boolean> = (input) => (typeof input === 'boolean' ? input : false)

export const toMaybeBool: Cast<boolean | undefined> = (input) =>
  typeof input === 'boolean' ? input : undefined

export const toInt: Cast<number> = (input) =>
  typeof input === 'number' && Number.isInteger(input) && !isNaN(input) ? input : 0

export interface DataType<T, S = unknown> {
  valid: (value: S) => boolean
  cast: (value: S) => T
}
