import { DependencyList, useCallback, useEffect, useRef } from 'react'

/**
 * useDebouncedCallback
 * Returns a debounced version of the provided callback function.
 *
 * @param {Function} callback - The callback function to debounce.
 * @param {number} delay - The delay in milliseconds to debounce.
 * @param {Array} deps - The dependencies for the callback.
 * @returns {Function} - The debounced callback function.
 */
export function useDebouncedCallback<T extends unknown[]>(
  callback: (...args: T) => void,
  delay: number,
  deps: DependencyList = []
) {
  type ScheduledCallback = { timer: ReturnType<typeof setTimeout>; args: T }
  const timerRef = useRef<ScheduledCallback | null>(null)

  const debouncedCallback = useCallback(
    (...args: T) => {
      if (timerRef.current) {
        clearTimeout(timerRef.current.timer)
        timerRef.current = null
      }

      timerRef.current = {
        args: args,
        timer: setTimeout(() => {
          if (timerRef.current) {
            callback(...timerRef.current.args)
          }
          timerRef.current = null
        }, delay)
      }
    },
    [callback, delay, ...deps]
  )

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current.timer)
        // execute to ensure the callback is executed with the latest args
        callback(...timerRef.current.args)
        timerRef.current = null
      }
    }
  }, [])

  return debouncedCallback
}
