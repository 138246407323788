import { SVGProps } from 'react'

export function DotsThree(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      fill="currentColor"
      height="16"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.25 8C9.25 8.19778 9.19135 8.39112 9.08147 8.55557C8.97159 8.72002 8.81541 8.84819 8.63268 8.92388C8.44996 8.99957 8.24889 9.01937 8.05491 8.98079C7.86093 8.9422 7.68275 8.84696 7.54289 8.70711C7.40304 8.56725 7.3078 8.38907 7.26922 8.19509C7.23063 8.00111 7.25043 7.80004 7.32612 7.61732C7.40181 7.43459 7.52998 7.27841 7.69443 7.16853C7.85888 7.05865 8.05222 7 8.25 7C8.51522 7 8.76957 7.10536 8.95711 7.29289C9.14464 7.48043 9.25 7.73478 9.25 8ZM4 7C3.80222 7 3.60888 7.05865 3.44443 7.16853C3.27998 7.27841 3.15181 7.43459 3.07612 7.61732C3.00043 7.80004 2.98063 8.00111 3.01922 8.19509C3.0578 8.38907 3.15304 8.56725 3.29289 8.70711C3.43275 8.84696 3.61093 8.9422 3.80491 8.98079C3.99889 9.01937 4.19996 8.99957 4.38268 8.92388C4.56541 8.84819 4.72159 8.72002 4.83147 8.55557C4.94135 8.39112 5 8.19778 5 8C5 7.73478 4.89464 7.48043 4.70711 7.29289C4.51957 7.10536 4.26522 7 4 7ZM12.5 7C12.3022 7 12.1089 7.05865 11.9444 7.16853C11.78 7.27841 11.6518 7.43459 11.5761 7.61732C11.5004 7.80004 11.4806 8.00111 11.5192 8.19509C11.5578 8.38907 11.653 8.56725 11.7929 8.70711C11.9327 8.84696 12.1109 8.9422 12.3049 8.98079C12.4989 9.01937 12.7 8.99957 12.8827 8.92388C13.0654 8.84819 13.2216 8.72002 13.3315 8.55557C13.4414 8.39112 13.5 8.19778 13.5 8C13.5 7.73478 13.3946 7.48043 13.2071 7.29289C13.0196 7.10536 12.7652 7 12.5 7Z" />
    </svg>
  )
}
