/**
 * A value with only primitive data that can be serialized to a JSON string
 */
export type JSONValue = JsonPrimitive | JsonMap | JSONArray | null | undefined

export type JsonPrimitive = string | number | boolean

export type JSONArray = JSONValue[]

export interface JsonMap {
  [key: string]: JSONValue
}

/**
 * Type guard to determine if a JSONValue is an array.
 * @param value The value to check.
 */
export function isArray(value: JSONValue): value is JSONArray {
  return Array.isArray(value)
}

/**
 * Type guard to determine if a JSONValue is a map (object).
 * @param value The value to check.
 */
export function isMap(value: JSONValue): value is JsonMap {
  return typeof value === 'object' && value !== null && !Array.isArray(value)
}

/**
 * Type guard to determine if a JSONValue is a primitive.
 * @param value The value to check.
 */
export function isPrimitive(value: JSONValue): value is JsonPrimitive {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null
  )
}

export function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false
    }

    for (var i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false
      }
    }

    return true
  } else if (
    a !== null &&
    typeof a === 'object' &&
    !Array.isArray(a) &&
    b !== null &&
    typeof b == 'object' &&
    !Array.isArray(b)
  ) {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)

    if (aKeys.length !== bKeys.length) {
      return false
    }

    for (var k of aKeys) {
      if (!deepEqual(a[k], b[k])) {
        return false
      }
    }
    return true
  }

  return false
}

export function clone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj))
}

export function isEmpty<T extends JSONValue | null>(obj: T): boolean {
  if (obj === undefined || obj === null) {
    return true
  } else if (Array.isArray(obj)) {
    return obj.length == 0
  } else if (typeof obj == 'string') {
    return obj.length == 0
  } else if (typeof obj == 'object') {
    return Object.keys(obj).length == 0
  }
  return false
}

export function isNotEmpty<T>(obj: T | null | undefined): obj is T {
  return !isEmpty(obj as JSONValue)
}

export function filterEntries(map: JsonMap, cond: (v: JSONValue, key: string) => boolean): JsonMap {
  const filteredMap: JsonMap = {}
  for (const [k, v] of Object.entries(map)) {
    if (cond(v, k)) filteredMap[k] = v
  }
  return filteredMap
}

type PathSegment = string | number
export function atPath(obj: JsonMap | JSONValue, pathSegments: PathSegment[]): JSONValue | null {
  let cur: JSONValue = obj
  for (let s of pathSegments) {
    if (!cur) return null
    if (Array.isArray(cur)) {
      if (Number.isNaN(s)) return null
      const arrIndex = typeof s === 'number' ? s : parseInt(s)
      cur = cur[arrIndex]
    } else if (typeof cur == 'object') {
      cur = cur[s]
    }
  }
  return cur
}

type WalkCallback = (value: JSONValue, path: string) => void

export function walkJSON(obj: JSONValue, callback: WalkCallback, currentPath: string = ''): void {
  callback(obj, currentPath)

  if (isArray(obj)) {
    obj.forEach((item, index) => {
      const newPath = currentPath ? `${currentPath}.${index}` : `${index}`
      walkJSON(item, callback, newPath)
    })
  } else if (isMap(obj)) {
    Object.entries(obj).forEach(([key, value]) => {
      const newPath = currentPath ? `${currentPath}.${key}` : key
      walkJSON(value, callback, newPath)
    })
  }
}

export type JSONPathEntry = { path: string; value: JSONValue }
export function toPathEntries(value: JSONValue): JSONPathEntry[] {
  const pathEntries: JSONPathEntry[] = []
  walkJSON(value, (v, path) => {
    if (isPrimitive(v)) {
      pathEntries.push({ path, value: v })
    }
  })
  return pathEntries
}
