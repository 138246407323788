import { WizardTip } from '@features/ui-components/wizard-tip'
import { TipConfig } from '@st/folder/ui-config'
import { isEmpty } from '@st/util/json-value'

type Props = {
  tip?: TipConfig | null
  prevValue?: string
}
/**
 * A group of tips that text inputs will show when relevant:
 * - A tip giving a hint about the field
 * - A tip with the value of a previous year
 */
export function InputTips({ tip, prevValue }: Props) {
  return (
    <>
      {tip == null ? undefined : <WizardTip tip={tip} />}
      {isEmpty(prevValue) ? undefined : (
        <WizardTip tip={{ title: 'Previous year', body: prevValue! }}>2021</WizardTip>
      )}
    </>
  )
}
