const Minus: IconComponent = (props) => (
  <svg
    fill="currentColor"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M14 10.75C14 10.8494 13.9605 10.9448 13.8901 11.0151C13.8198 11.0855 13.7244 11.125 13.625 11.125H7.62498C7.52552 11.125 7.43014 11.0855 7.35981 11.0151C7.28949 10.9448 7.24998 10.8494 7.24998 10.75C7.24998 10.6505 7.28949 10.5551 7.35981 10.4848C7.43014 10.4145 7.52552 10.375 7.62498 10.375H13.625C13.7244 10.375 13.8198 10.4145 13.8901 10.4848C13.9605 10.5551 14 10.6505 14 10.75ZM21.3903 21.5153C21.3555 21.5502 21.3141 21.5778 21.2686 21.5967C21.2231 21.6156 21.1743 21.6253 21.125 21.6253C21.0757 21.6253 21.0269 21.6156 20.9814 21.5967C20.9359 21.5778 20.8945 21.5502 20.8597 21.5153L15.919 16.5747C14.3958 17.9594 12.3914 18.6934 10.3341 18.6199C8.27687 18.5464 6.32994 17.6713 4.90937 16.1814C3.4888 14.6915 2.70726 12.7052 2.73176 10.6467C2.75626 8.58832 3.58485 6.62111 5.04048 5.16548C6.49611 3.70985 8.46332 2.88126 10.5217 2.85676C12.5802 2.83226 14.5665 3.6138 16.0564 5.03437C17.5463 6.45494 18.4214 8.40187 18.4949 10.4591C18.5684 12.5164 17.8344 14.5208 16.4497 16.044L21.3903 20.9847C21.4252 21.0195 21.4528 21.0609 21.4717 21.1064C21.4906 21.1519 21.5003 21.2007 21.5003 21.25C21.5003 21.2993 21.4906 21.3481 21.4717 21.3936C21.4528 21.4391 21.4252 21.4805 21.3903 21.5153ZM10.625 17.875C12.0342 17.875 13.4117 17.4571 14.5834 16.6742C15.7551 15.8913 16.6683 14.7785 17.2076 13.4766C17.7469 12.1747 17.888 10.7421 17.6131 9.35996C17.3382 7.97785 16.6596 6.70829 15.6631 5.71184C14.6667 4.7154 13.3971 4.0368 12.015 3.76189C10.6329 3.48697 9.20028 3.62806 7.89836 4.16734C6.59644 4.70661 5.48366 5.61984 4.70076 6.79154C3.91785 7.96324 3.49998 9.34079 3.49998 10.75C3.50197 12.639 4.25327 14.4502 5.58904 15.7859C6.9248 17.1217 8.73592 17.873 10.625 17.875Z" />
  </svg>
)

export { Minus }
