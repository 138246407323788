import { envVar } from '@env'
import { Script } from '@st/react-util/script'
import { AnalyticsEvent } from '../analytics-events'
import { AnalyticsProvider, EventData, serviceCall } from '../core'

export const GoogleAnalyticsService: AnalyticsProvider = {
  Script: () => {
    const googleAnalyticsId = envVar('NEXT_PUBLIC_GOOGLE_ANALYTICS_ID')
    if (!googleAnalyticsId) return <></>
    return <GoogleAnalyticsScript googleAnalyticsId={googleAnalyticsId} />
  },
  track(event: AnalyticsEvent, data?: EventData) {
    serviceCall('gtag', 'event', event, data)

    switch (event) {
      case 'signed_up':
        serviceCall('gtag', 'event', 'conversion', {
          send_to: 'AW-10829380399/LJv9COvSzY0DEK_27Kso',
          transaction_id: ''
        })
        break
      case 'purchase':
        serviceCall('gtag', 'event', 'conversion', {
          send_to: 'AW-10829380399/YcX9COmN1oYYEK_27Kso',
          transaction_id: ''
        })
        break
    }
  },
  trackLocationChanged(location: string) {
    serviceCall('gtag', 'page_view', {
      page_title: document.title,
      page_path: location
    })
  }
}

function GoogleAnalyticsScript({ googleAnalyticsId }: { googleAnalyticsId: string }) {
  return (
    <>
      <Script
        id="google-analytics-boot"
        src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
        async={true}
      />

      <Script id="google-analytics" async={true}>
        {`
          window.dataLayer = window.dataLayer || [];

          function gtag() { window.dataLayer.push(arguments); }

          gtag('js', new Date());
          gtag('config', '${googleAnalyticsId}', {
            send_page_view: false
          });
          gtag('config', 'AW-10829380399');
        `}
      </Script>
    </>
  )
}
