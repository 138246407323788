import { ReactNode, createContext, useContext } from 'react'
import { StoreContext } from './process'

const StoreDepsContext = createContext<StoreContext>({})

type Props<S, E> = {
  children: ReactNode
  deps: StoreContext
}
export function StoreDepsProvider<S, E>({ children, deps }: Props<S, E>) {
  return (
    <StoreDepsContext.Provider value={deps}>
      {children}
    </StoreDepsContext.Provider>
  )
}

export function useStoreContext(): StoreContext {
  return useContext(StoreDepsContext)
}
