import { clientAppBaseUrl } from '@domain/config'
import {
  Base64Param,
  BooleanParam,
  GetRouteMatchType,
  GetSetRouteMatchType,
  route,
  RouteMap,
  StringParam
} from '@st/util/routing'
import { SchemaIdParam } from './params'

export const rootRoute = route('/', '/', {})

export const organizationFolder = route(
  'organization_folder',
  '/:organizationSlug/workpapers/:folderId',
  {
    organizationSlug: StringParam,
    folderId: StringParam
  }
)

export const organizationSubmissionLegacyRoute = route(
  'organization_submission_legacy',
  '/:organizationSlug/submissions/:folderId',
  {
    organizationSlug: StringParam,
    folderId: StringParam
  }
)

export const debugRoute = route('debug', '/debug', {})

export const organizationFolderDebug = route(
  'organization_folder_debug',
  '/:organizationSlug/workpapers/:folderId/debug',
  {
    organizationSlug: StringParam,
    folderId: StringParam
  }
)

export const organizationPreviewQuestionnaire = route(
  'organization_preview_questionnaire',
  '/:organizationSlug/workpapers/:folderId/preview-questionnnaire',
  {
    organizationSlug: StringParam,
    folderId: StringParam
  }
)

export const previewFolderRoute = route(
  'preview_folder',
  '/preview/:folderConfigId/:documentId?&:logo?&:company?',
  {
    organizationSlug: StringParam,
    folderConfigId: StringParam,
    documentId: StringParam,
    logo: StringParam,
    company: StringParam
  }
)

export const organizationSelectStackRoute = route(
  'organization_select_stack',
  '/:organizationSlug/stack',
  {
    organizationSlug: StringParam
  }
)

export const clientFolderRoute = route('client_folder', '/:organizationSlug/folders/:folderId', {
  organizationSlug: StringParam,
  folderId: StringParam
})

export const folderListRoute = route('folder_list', '/:organizationSlug/workpapers', {
  organizationSlug: StringParam
})

export const authorizeZapierRoute = route(
  'authorize_zapier',
  '/authorize/zapier&:client_id&:state&:redirect_uri',
  {
    client_id: StringParam,
    state: StringParam,
    redirect_uri: StringParam
  }
)

export const submissionListLegacyRoute = route(
  'submission_list_legacy_route',
  '/:organizationSlug/submissions',
  {
    organizationSlug: StringParam,
    folderId: StringParam
  }
)

export const createImportRoute = route('create_import', '/:organizationSlug/import&:preview?', {
  organizationSlug: StringParam,
  preview: BooleanParam
})

export const runImportRoute = route('run_import', '/:organizationSlug/import/:importId', {
  organizationSlug: StringParam,
  importId: StringParam
})

export const manageOrganizationTagsRoute = route(
  'manage_organization_tags',
  '/:organizationSlug/settings/tags',
  {
    organizationSlug: StringParam
  }
)

export const manageOrganizationIntegrationsRoute = route(
  'manage_organization_integrations',
  '/:organizationSlug/settings/integrations',
  {
    organizationSlug: StringParam
  }
)

export const manageOrganizationMessageTemplatesRoute = route(
  'manage_organization_message_templates',
  '/:organizationSlug/settings/templates',
  {
    organizationSlug: StringParam
  }
)

export const manageOrganizationPlanRoute = route(
  'manage_organization_plan',
  '/:organizationSlug/settings/plan',
  {
    organizationSlug: StringParam
  }
)

export const brandingRoute = route('branding', '/:organizationSlug/settings/branding', {
  organizationSlug: StringParam
})

export const teamRoute = route('team', '/:organizationSlug/settings/team', {
  organizationSlug: StringParam
})

export const teamInvitation = route('team_invitation', '/:organizationSlug/invitation/:email', {
  organizationSlug: StringParam,
  email: Base64Param
})

export const loggedInPricing = route('logged_in_pricing', '/:organizationSlug/pricing', {
  organizationSlug: StringParam
})

export const loggedOutPricing = route('logged_out_pricing', '/pricing')

export const updatesRoute = route('updates', '/updates')

export const loginRoute = route('login', '/login')

export const signUpRoute = route(
  'sign_up',
  '/sign-up&:email?&:organizationName?&:phoneNumber?&:trial?',
  {
    email: StringParam,
    organizationName: StringParam,
    phoneNumber: StringParam,
    trial: BooleanParam
  }
)

export const clientFindOrCreateFolderRoute = route(
  'find_or_create_folder',
  '/:organizationSlug/questionnaire/:schemaId&:email?',
  {
    email: StringParam,
    organizationSlug: StringParam,
    schemaId: SchemaIdParam
  }
)

export const securityRoute = route('security', '/security')

const routesMap = {
  rootRoute,
  loginRoute,
  signUpRoute,
  authorizeZapierRoute,
  loggedOutPricing,
  securityRoute,
  debugRoute,
  previewFolderRoute,
  organizationPreviewQuestionnaire,
  organizationFolderDebug,
  organizationFolder,
  folderListRoute,
  manageOrganizationTagsRoute,
  manageOrganizationMessageTemplatesRoute,
  manageOrganizationPlanRoute,
  manageOrganizationIntegrationsRoute,
  brandingRoute,
  organizationSelectStackRoute,
  teamRoute,
  teamInvitation,
  loggedInPricing,
  runImportRoute,
  createImportRoute,
  clientFindOrCreateFolderRoute,
  clientFolderRoute,
  organizationSubmissionLegacyRoute,
  submissionListLegacyRoute
}
export const routes = new RouteMap(routesMap)

export type AppRoute = GetRouteMatchType<typeof routesMap>
export type SetAppRoute = GetSetRouteMatchType<typeof routesMap>

type ClientFolderShareableLink = {
  organizationSlug: string
  folderId: string
}
export function clientFolderShareableLink(link: ClientFolderShareableLink): string {
  return (
    clientAppBaseUrl() +
    clientFolderRoute({
      organizationSlug: link.organizationSlug,
      folderId: link.folderId
    })
  )
}

type MagicLink = {
  organizationSlug: string
  schemaId: string
}
export function clientMagicLink(link: MagicLink): string {
  return (
    clientAppBaseUrl() +
    clientFindOrCreateFolderRoute({
      organizationSlug: link.organizationSlug,
      schemaId: link.schemaId
    })
  )
}
