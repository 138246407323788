import { isEmpty } from '@st/util/json-value'
import { MaskType, maskText } from '@st/util/mask'
import { useId } from '@util/react'
import { clsx } from 'clsx'
import { ChangeEvent, FocusEvent, ReactNode, useState } from 'react'

export type InputType = 'text' | 'date' | 'number'

type Props = {
  value: string
  type?: InputType
  className?: string
  rows?: number
  readOnly?: boolean
  autoFocus?: boolean
  autoFill?: boolean
  format?: (value: string) => string
  mask?: boolean
  isMultiline?: boolean
  label?: string
  onChange?: (value: string) => void
  onBlur?: () => void
  onClick?: () => void
  placeholder?: string
  notice?: ReactNode
  tip?: ReactNode
}
export function TextField({
  className,
  rows = 5,
  type = 'text',
  format,
  mask,
  value,
  label,
  tip,
  isMultiline,
  onChange,
  onBlur,
  placeholder,
  onClick,
  readOnly,
  autoFocus,
  notice
}: Props) {
  const id = useId()

  const [focused, setFocused] = useState(false)
  const shouldMask = mask == true && !focused
  const displayValue = shouldMask ? maskText(value, MaskType.alphanumeric, '●') : value

  function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    onChange?.(event.target.value)
  }

  function handleOnFocus(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setFocused(true)
  }

  function handleOnBlur(event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
    // If an input format such as PhoneNumber of SocialSecurityNumber is specified,
    // we would want to format it so it looks nice when the user focuses out
    if (format) {
      const value = format(event.target.value)
      onChange?.(value)
    }

    setFocused(false)

    onBlur?.()
  }

  return (
    <div className={clsx('flex flex-col gap-1', className)} onClick={onClick}>
      {isEmpty(label) ? null : (
        <label className="flex items-center gap-1" htmlFor={id}>
          {label}
          {tip ? <>&nbsp;</> : null}
          {tip}
        </label>
      )}
      {isMultiline ? (
        <textarea
          id={id}
          className={clsx(
            'w-full resize-none rounded-lg border border-gray-200 px-4 py-5 text-gray-900 ring-1 ring-inset ring-gray-200',
            className
          )}
          rows={rows}
          placeholder={placeholder}
          value={displayValue}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          readOnly={readOnly}
          autoFocus={autoFocus}
          autoComplete="off"
        />
      ) : (
        <input
          className={clsx(
            'h-16 w-full rounded-lg border border-gray-200 px-4 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-200',
            className
          )}
          id={id}
          type={type}
          placeholder={placeholder}
          value={displayValue}
          onChange={handleChange}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          readOnly={readOnly}
          autoFocus={autoFocus}
          autoComplete="off"
        />
      )}
      {notice}
    </div>
  )
}
