import { Base } from '../base'
import { Directory, XFile, fetchFile } from './core'

export type HDirectory = Directory
export type Hashed<T extends XFile> = T & { sha1: string; size: number }

export function isHashed<T extends XFile>(entry: XFile): entry is Hashed<T> {
  return 'sha1' in entry && entry.sha1 != undefined
}

export async function hashFile<T extends XFile>(dataFile: XFile): Promise<Hashed<T>> {
  // we don't recompute data file values
  if (isHashed(dataFile)) {
    return dataFile as Hashed<T>
  }

  const fileData = await fetchFile(dataFile).then((r) => r.arrayBuffer())

  return {
    ...dataFile,
    sha1: await computeFileHash(fileData),
    size: fileData.byteLength
  } as Hashed<T>
}

export function computeFileHash(data: ArrayBuffer) {
  // seems like a bug in nodejs
  const finalData = typeof Buffer !== 'undefined' ? Buffer.from(data) : data

  return crypto.subtle.digest('SHA-1', finalData).then((buf) => {
    // Convert ArrayBuffer to hex string without using Base
    return Array.from(new Uint8Array(buf))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('')
  })
}
