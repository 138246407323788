import { sort } from '../sort'

export type UnpackOpts = {
  shouldIgnore: (filename: string) => boolean
}

export type FileEntry = {
  path: string
  file: File
}

export async function unpackUpload(
  dt: File[] | FileList | DataTransfer,
  opts: Partial<UnpackOpts> = {}
): Promise<FileEntry[]> {
  const finalOpts: UnpackOpts = {
    shouldIgnore: opts.shouldIgnore ?? defaultIgnore
  }

  const { shouldIgnore } = finalOpts

  const allEntries: FileEntry[] = []

  if (dt instanceof DataTransfer) {
    for (const item of dt.items) {
      // If we synthetically create a DataTransfer object with a file
      // (when manually picking a file with an <input /> element)
      // Then for some reason entry is null
      // So we prefer using the more standard item.getAsFile() API
      // To be on the safe side, we only use the specialized webkitGetAsEntry for folders

      const entry = item.webkitGetAsEntry()
      const file = item.getAsFile()

      if (entry && isDirectoryEntry(entry)) {
        if (shouldIgnore(entry.name)) {
          continue
        }

        const subEntries = await readFSEntries(entry, '', finalOpts)
        subEntries.forEach((entry) => allEntries.push(entry))
      } else if (file) {
        if (shouldIgnore(file.name)) {
          continue
        }

        allEntries.push({ path: file.name, file })
      } else {
        console.warn('No entry for', item, file)
      }
    }
  } else {
    for (const file of dt) {
      // File[] or FileList[] so just a flat list of files
      if (shouldIgnore(file.name)) {
        continue
      }

      allEntries.push({ path: file.name, file })
    }
  }

  sort(allEntries, (a, b) => a.path.localeCompare(b.path))

  return allEntries
}

function readFSEntries(
  entry: FileSystemEntry,
  dirname: string,
  opts: UnpackOpts
): Promise<FileEntry[]> {
  function fileSystemEntryToFile(entry: FileSystemFileEntry): Promise<File> {
    return new Promise<File>((resolve, reject) => entry.file(resolve, reject))
  }

  if (isFileEntry(entry)) {
    return fileSystemEntryToFile(entry).then((file) => [
      { path: joinPath(dirname, file.name), file }
    ])
  } else if (isDirectoryEntry(entry)) {
    return readFSDirectoryEntries(entry, opts.shouldIgnore)
      .then((fsEntries) => {
        return Promise.all(
          fsEntries.map((fsEntry) => readFSEntries(fsEntry, joinPath(dirname, entry.name), opts))
        )
      })
      .then((entries) => {
        return entries.flat()
      })
  }

  throw 'Unsupported entry type'
}

// The dirReader may not return all of the entries in a single request
// So we need to iterate below and keep fetching the items until we have them all
// This may only be necessary if a folder with many files has been uploaded
function readFSDirectoryEntries(
  entry: FileSystemDirectoryEntry,
  shouldIgnore: (name: string) => boolean
): Promise<FileSystemEntry[]> {
  return new Promise((resolve, reject) => {
    let entries: FileSystemEntry[] = []

    const dirReader = entry.createReader()
    function readEntriesBatch() {
      dirReader.readEntries((batch) => {
        if (!batch || batch.length === 0) {
          resolve(entries)
        } else {
          entries = entries.concat(
            // filters out all ignorable entries
            batch.filter((el) => !shouldIgnore(el.name))
          )
          readEntriesBatch()
        }
      }, reject)
    }

    readEntriesBatch()
  })
}

function isFileEntry(entry: FileSystemEntry): entry is FileSystemFileEntry {
  return entry.isFile
}

function isDirectoryEntry(entry: FileSystemEntry): entry is FileSystemDirectoryEntry {
  return entry.isDirectory
}

function defaultIgnore(name: string) {
  return name == '.git' || name == '.DS_Store'
}

function joinPath(a: string, b: string) {
  if (a == '') return b
  return `${a}/${b}`
}
