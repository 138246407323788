import {
  documentUploadsModule,
  selUploadsOfDocumentType,
  uploadDocument
} from '@features/document-uploads'
import {
  selFolderDocumentsOfType,
  stFolderModule
} from '@features/st-folder-viewer/st-folder-module'
import { stQuestionnaireModule } from '@features/st-questionnaire/st-questionnaire-module'
import { FilesInputConfig } from '@st/folder/ui-config'
import { useProcess, useProcessState } from '@st/redux'
import { STDocument } from '@st/sdk'
import {
  DocumentDropZone,
  DropHere,
  FileUploadItem,
  Modal,
  QuestionnaireFile,
  QuestionnaireFilesList
} from '@st/theme'
import { cuid } from '@st/util/cuid'
import { Progress } from '@st/util/progress'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { ConfirmDeleteDocumentDialog } from './confirm-delete-document-dialog'

type Props = FilesInputConfig & {
  className?: string
  readonly?: boolean
}

type WizardDocumentAction = { type: 'confirmDelete'; doc: STDocument }

export function WizardFilesInput({ documentType, readonly = false }: Props) {
  const stQuestionnaire = useProcess(stQuestionnaireModule)
  const documentUploads = useProcess(documentUploadsModule)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)

  const uploads = useProcessState(documentUploadsModule, (s) =>
    selUploadsOfDocumentType(s, documentType!.id)
  )
  const documents = useProcessState(stFolderModule, (s) =>
    selFolderDocumentsOfType(s, documentType!.id)
  )

  const [action, setAction] = useState<WizardDocumentAction | undefined>(undefined)

  function onDropFiles(dt: DataTransfer) {
    for (const file of dt.files) {
      documentUploads
        .send(
          uploadDocument({
            uploadId: cuid(),
            file,
            folderId: folderId,
            documentTypeId: documentType?.id
          })
        )
        .then((documentId) => {
          stQuestionnaire.send({ type: 'addToChecklistStack', docId: documentId })
        })
    }
  }

  return (
    <div className="flex flex-col gap-2">
      <DocumentDropZone onDrop={onDropFiles}>
        <DropHere caption="PDF, PNG, JPG, CSV, XLSX, DOCX supported" />
      </DocumentDropZone>

      {uploads.length > 0 && (
        <div className="flex flex-col gap-1">
          {uploads.map((u) => {
            return (
              <FileUploadItem key={u.id} status="uploading" value={Progress.toNumber(u.progress)}>
                {u.filename}
              </FileUploadItem>
            )
          })}
        </div>
      )}

      <QuestionnaireFilesList>
        {documents.map((doc) => {
          return (
            <QuestionnaireFile
              key={doc.id}
              onClickDelete={() => {
                setAction({ type: 'confirmDelete', doc })
              }}
              onClickPreview={() => {
                window.open(doc.urls.sourceInline, '_blank')
              }}
            >
              {doc.originalName ?? doc.name}
            </QuestionnaireFile>
          )
        })}
      </QuestionnaireFilesList>

      {match(action)
        .with({ type: 'confirmDelete' }, ({ doc }) => (
          <Modal isOpen={true}>
            <ConfirmDeleteDocumentDialog
              doc={doc}
              onConfirm={() => {
                stQuestionnaire.send({ type: 'deleteDocument', docId: doc.id })
                setAction(undefined)
              }}
              onCancel={() => setAction(undefined)}
            />
          </Modal>
        ))
        .otherwise(() => null)}
    </div>
  )
}
