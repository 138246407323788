import { User } from '@features/auth/auth-client-adapter'
import { Organization } from '@st/sdk'
import { FC } from 'react'
import { AnalyticsEvent } from './analytics-events'

export type UserProperties = Record<string, string | number | boolean>
export type EventData = Record<string, string | number | boolean>
export type TrackOptions = {
  groups?: Record<string, string>
}

export type AuthContext = {
  getToken: () => Promise<string | undefined>
}

export interface AnalyticsProvider {
  Script: FC
  trackLocationChanged?(location: string): void

  track?: (event: AnalyticsEvent, data: EventData, options: TrackOptions) => void

  trackUserIdentified?(user: User, authContext: AuthContext): void
  trackUserAnonymous?(): void

  setUserProperties?(properties: UserProperties): void
  setOrganization?(properties: Organization): void

  captureException?(exception: any): void

  /**
   * Set entities that are related to this user (for example org or company)
   *
   * @param groupName
   * @param groupId
   * @param groupProperties
   */
  setGroup?(groupName: string, groupId: string, groupProperties?: Record<string, string>): void
}

export interface AnalyticsClient {
  Script: FC
  trackLocationChanged(location: string): void

  track: (event: AnalyticsEvent, data?: EventData, options?: TrackOptions) => void

  trackUserIdentified(user: User, authContext: AuthContext): void
  trackUserAnonymous(): void

  setUserProperties(properties: UserProperties): void

  setOrganization(organization: Organization): void

  captureException(exception: any): void

  /**
   * Set entities that are related to this user (for example org or company)
   *
   * @param groupName
   * @param groupId
   * @param groupProperties
   */
  setGroup(groupName: string, groupId: string, groupProperties?: Record<string, string>): void
}

export function serviceCall(name: string, ...args: any[]) {
  function getByPath(obj: any, path: string) {
    var thisContext = window
    var segments = path.split('.')
    for (var s of segments) {
      thisContext = obj
      obj = obj[s]
      if (!obj) {
        return [undefined, undefined]
      }
    }
    return [obj, thisContext]
  }

  const [func, context] = getByPath(window, name)
  if (typeof func == 'function') {
    // console.log('serviceCall', name, ...args)
    func.apply(context, args)
  }
}
