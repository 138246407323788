import { ReactNode } from 'react'
import { ElNode, toElem } from './reconciler'

type Props = { [key: string]: any }
type TreeNode = { type: string; children: TreeNode[] | string } & Props

export function toTreeNode(element: ReactNode) {
  return elNodeToTreeNode(toElem(element))
}

function elNodeToTreeNode(el: ElNode): TreeNode {
  const { children, props } = el

  const node: TreeNode = {
    ...props,
    type: props.tagName as string,
    children:
      typeof children == 'string' ? children : children.map(elNodeToTreeNode)
  }

  delete node.tagName

  return node
}
