import { Anchor, Popover } from '@st/react-util/popover'
import { cn } from '@util/cn'
import { ReactNode, useState } from 'react'
/**
 * A hover element with a (?) icon for showing updates.
 *
 * When when hovering over this element, a tooltip with the content of {@link props}
 * will be shown.
 */
type Props = {
  children: ReactNode
  tip?: ReactNode
}
export function Tooltip({ tip, children }: Props) {
  const [visible, setVisible] = useState(false)

  return (
    <Popover
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
      popupAnchor={Anchor.bottomCenter}
      childAnchor={Anchor.topCenter}
      popupOffset={{ dx: 0, dy: 0 }}
      popup={visible && tip ? tip : null}
    >
      {children}
    </Popover>
  )
}

export function TooltipIcon({ children }: { children: string }) {
  return (
    <div className={cn('tooltip-icon', children.length > 1 ? 'tooltip-icon--wide' : null)}>
      {children}
    </div>
  )
}

type CalloutProps = {
  position?: 'above' | 'below'
  title?: string | null
  children?: ReactNode | null
}
export function Callout({ position = 'above', title, children }: CalloutProps) {
  return (
    <span className={`callout callout--${position} text-left`}>
      <span className="callout__arrow" />
      {title ? (
        <h3 className="callout__title font-16" style={{ color: '#333333' }}>
          {title}
        </h3>
      ) : null}
      {children ? (
        <span
          className="callout__body whitespace-pre-line text-base font-normal"
          style={{ color: '#000000' }}
        >
          {children}
        </span>
      ) : null}
    </span>
  )
}

type TipProps = {
  children: ReactNode
}
export function Tip({ children }: TipProps) {
  return <div className="tip color-grey">{children}</div>
}
