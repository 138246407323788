import { validateSize } from '../geom'
import { HiddenInput, Renderer } from './interface'

export const hiddenInputRenderer: Renderer<HiddenInput> = {
  getSize(props, constraints, context) {
    return { width: 0, height: 0 }
  },
  render(props, origin, constraints, context) {
    return {
      type: 'hidden_input',
      name: props.name,
      value: props.value
    }
  }
}
