import type { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { CreateFolderJob, CreateFoldersFromImportJobResult, STFolderImportState } from '@st/sdk'
import { setToggle } from '@st/util/array-set'
import { match } from 'ts-pattern'

export type STRunFolderImportState =
  | STRunFolderImportLoadingState
  | STRunFolderImportSelectFoldersState
  | STRunFolderImportRunningState
  | STRunFolderImportCompletedState

type STRunFolderImportLoadingState = {
  step: 'loading'
  importId: string
  selectedJobIds: string[]
  importState?: STFolderImportState
}

type STRunFolderImportSelectFoldersState = {
  step: 'selectFolders'
  importId: string
  selectedJobIds: string[]
  importState: STFolderImportState
}

type STRunFolderImportRunningState = {
  step: 'running'
  importId: string
  selectedJobIds: string[]
  importState: STFolderImportState
}

type STRunFolderImportCompletedState = {
  step: 'completed'
  importId: string
  selectedJobIds: string[]
  importState: STFolderImportState
  createFoldersResult: CreateFoldersFromImportJobResult
}

export type STRunFolderImportMessage =
  | { type: 'importStateUpdated' }
  | { type: 'toggleJobSelected'; jobId: string }
  | { type: 'toggleSelectAll' }
  | { type: 'runImport' }
  | SDKResponse

type STRunFolderImportSend = {
  sdk: SDKMessage
}

type STRunFolderImportInit = {
  importId: string
}

export const stRunFolderImportModule = defineModule<
  STRunFolderImportState,
  STRunFolderImportMessage,
  STRunFolderImportInit,
  STRunFolderImportSend
>({
  name: 'stRunFolderImport',
  init: ({ importId }) => {
    return [
      { step: 'loading', importId: importId, selectedJobIds: [], importStateVsn: -1 },
      {
        sdk: {
          type: 'request',
          request: { type: 'organizations/getFolderImportState', folderImportJobId: importId }
        }
      }
    ]
  },
  handle: (state, message) => {
    return match({ state, message })
      .with(
        {
          message: { type: 'response', operation: { type: 'organizations/getFolderImportState' } }
        },
        ({ state, message }) => {
          return {
            step: 'selectFolders',
            importId: state.importId,
            importState: message.operation.response,
            selectedJobIds: selAvailableJobIds(message.operation.response.createFolderJobs)
          } satisfies STRunFolderImportSelectFoldersState
        }
      )
      .with(
        { state: { step: 'selectFolders' }, message: { type: 'toggleJobSelected' } },
        ({ state, message }) => ({
          ...state,
          selectedJobIds: setToggle(state.selectedJobIds, message.jobId)
        })
      )
      .with(
        { state: { step: 'selectFolders' }, message: { type: 'toggleSelectAll' } },
        ({ state }) => {
          const allSelected = selAllJobsSelected(state)
          const selectedJobIds = allSelected
            ? []
            : selAvailableJobIds(state.importState.createFolderJobs)
          return { ...state, selectedJobIds }
        }
      )
      .with({ state: { step: 'selectFolders' }, message: { type: 'runImport' } }, ({ state }) => {
        return [
          {
            step: 'running',
            importId: state.importId,
            importState: state.importState,
            selectedJobIds: state.selectedJobIds
          },
          {
            sdk: {
              type: 'request',
              delay: 6000,
              // delay: 1,
              request: {
                type: 'folders/createFoldersFromImportJob',
                folderImportJobId: state.importId,
                createFolderJobIds: state.selectedJobIds
              }
            }
          }
        ] satisfies [STRunFolderImportRunningState, { sdk: SDKMessage }]
      })
      .with(
        {
          state: { step: 'running' },
          message: { type: 'response', operation: { type: 'folders/createFoldersFromImportJob' } }
        },
        ({ state, message }) => {
          return {
            ...state,
            createFoldersResult: message.operation.response,
            step: 'completed'
          } satisfies STRunFolderImportCompletedState
        }
      )
      .otherwise(() => state)
  }
})

export function selSelectedJobIds(state: STRunFolderImportState) {
  return new Set(state.selectedJobIds)
}

export function selAllJobsSelected(state: STRunFolderImportState) {
  return (
    state.selectedJobIds.length ===
    selAvailableJobIds(state.importState?.createFolderJobs ?? []).length
  )
}

export function selAvailableJobIds(jobs: CreateFolderJob[]) {
  return jobs.filter((job) => authorizeCreateFolder(job).ok == true).map((job) => job.id)
}

export type CreateFolderAuthorization = { ok: true } | { ok: false; error: string }

export function authorizeCreateFolder(job: CreateFolderJob): CreateFolderAuthorization {
  if (job.existingFolder) {
    return { ok: false, error: 'This client has already been imported' }
  }
  return { ok: true }
}
