import { asyncMap } from '@st/util/async'
import { Result, err, ok, splitResults } from '../result'
import { parseISO8601Time } from '../time'
import {
  XFile,
  Directory,
  FetchFile,
  fetchFile,
  iterateFiles
} from '@st/util/xfile'

type ZipEntry = { name: string; input: Blob; lastModified?: Date }
type ZipError = { type: 'fetchFailed'; files: XFile[] }

export async function zip(
  directory: Directory,
  fetch: FetchFile = fetchFile
): Promise<Result<Blob, ZipError>> {
  type FetchError = { type: 'fetchFailed'; file: XFile }

  const { downloadZip } = await import('client-zip')

  const entryResults = await asyncMap(
    iterateFiles(directory),
    async (cur): Promise<Result<ZipEntry, FetchError>> => {
      try {
        const response = await fetch(cur.value)
        if (!response.ok) {
          return err({ type: 'fetchFailed', file: cur.value })
        }

        const blob = await response.blob()
        return ok({
          name: cur.path,
          input: blob,
          lastModified: cur.value.lastModified
            ? parseISO8601Time(cur.value.lastModified)
            : undefined
        })
      } catch (e) {
        return err({ type: 'fetchFailed', file: cur.value })
      }
    }
  )

  const { succeeded, failed } = splitResults(entryResults)

  if (failed.length > 0) {
    return err({ type: 'fetchFailed', files: failed.map((e) => e.file) })
  }

  return downloadZip(succeeded).blob().then(ok)
}
