import { envVar } from '@env'
import { User } from '@features/auth/auth-client-adapter'
import { createSTClientSDK, Organization } from '@st/sdk'
import { Script } from '@st/react-util/script'
import { AnalyticsProvider, serviceCall, UserProperties } from '../core'

const INTERCOM_CHAT_BLACKLIST = [
  '/preview-questionnaire',
  '/workpapers/', // /workpapers/$folderId - we need trailing slash for that
  '/import'
]

function shouldHideIntercomChat(location: string) {
  return INTERCOM_CHAT_BLACKLIST.some((path) => location.includes(path))
}

interface IntercomAnalyticsProvider extends AnalyticsProvider {
  setChatVisible(visible: boolean | 'auto'): void
}

export const IntercomAnalyticsService: IntercomAnalyticsProvider = {
  Script: () => {
    const intercomAppId = envVar('NEXT_PUBLIC_INTERCOM_APP_ID')
    if (!intercomAppId) return <></>
    return <IntercomScript intercomAppId={intercomAppId} />
  },
  setChatVisible: (visible: boolean | 'auto') => {
    const hidden = visible == 'auto' ? shouldHideIntercomChat(window.location.pathname) : !visible
    serviceCall('Intercom', 'update', {
      hide_default_launcher: hidden
    })
  },
  trackLocationChanged(location: string) {
    serviceCall('Intercom', 'update', {
      hide_default_launcher: shouldHideIntercomChat(location)
    })
  },
  async trackUserIdentified(user: User, { getToken }) {
    const intercomAppId = envVar('NEXT_PUBLIC_INTERCOM_APP_ID')
    if (!intercomAppId) return

    const sdk = createSTClientSDK({
      baseUrl: process.env.NEXT_PUBLIC_API_V2_ENDPOINT!,
      getToken: getToken
    })

    const resp = await sdk.send({ type: 'accounts/getIntercomIdentity' })

    serviceCall('Intercom', 'update', {
      user_id: user?.id,
      user_hash: resp.hash,
      name: user.name,
      email: user?.email
    })
  },
  trackUserAnonymous() {},
  setUserProperties(properties: UserProperties) {
    serviceCall('Intercom', 'update', properties)
  },
  setOrganization(organization: Organization) {
    serviceCall('Intercom', 'update', {
      company: {
        id: organization.id,
        name: organization.name,
        plan: undefined,
        slug: organization.slug
      }
    })
  }
}

export function showIntercomChat() {
  serviceCall('Intercom', 'showNewMessage')
}

function IntercomScript({ intercomAppId }: { intercomAppId: string }) {
  return (
    <>
      <Script id="intercom" async={true}>
        {`
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${intercomAppId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
        `}
      </Script>
      <Script id="intercom-boot" async={true}>
        {`
        window.Intercom("boot", {
          app_id: "${intercomAppId}"
        });
        `}
      </Script>
    </>
  )
}
