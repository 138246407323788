import { BulletedTextConfig } from '@st/folder/ui-config'
import { SimpleHtmlMarkdown } from '@util/simple-html-markdown'

export function BulletedText({ content }: BulletedTextConfig) {
  return (
    <span className="flex w-full flex-col gap-3">
      <Line />
      <SimpleHtmlMarkdown content={content} />
    </span>
  )
}

const Line = () => (
  <svg width="12" height="1" viewBox="0 0 12 1" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="12" y1="0.5" x2="-4.37114e-08" y2="0.499999" stroke="#AAAAAA" />
  </svg>
)
