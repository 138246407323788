import { envVar } from '@env'
import { User } from '@features/auth/auth-client-adapter'
import posthog from 'posthog-js'
import { useEffect } from 'react'
import { AnalyticsProvider } from '../core'

export const PosthogAnalyticsService: AnalyticsProvider = {
  Script: () => {
    const posthogApiKey = envVar('NEXT_PUBLIC_POSTHOG_API_KEY')!
    const posthogHost = envVar('NEXT_PUBLIC_POSTHOG_API_HOST')!
    if (!posthogApiKey || !posthogHost) return <></>
    return <PosthogAnalyticsScript posthogApiKey={posthogApiKey} posthogHost={posthogHost} />
  },
  track(event, data, options) {
    const groups = options.groups ?? {}
    withGroups(groups, () => {
      posthog.capture(event, data, {})
    })
  },
  trackLocationChanged(location: string) {
    posthog.capture('$pageview')
  },
  trackUserIdentified(user: User) {
    posthog.identify(user.id, {
      email: user.email,
      name: user.name
    })
  },
  trackUserAnonymous() {
    posthog.reset()
  },
  setUserProperties(properties: Record<string, string>) {
    const distinctId = posthog.get_distinct_id()
    if (!distinctId) return
    posthog.identify(distinctId, properties)
  },
  setGroup(groupName: string, groupId: string, groupProperties?: Record<string, string>) {
    posthog.group(groupName, groupId, groupProperties)
  }
}

function PosthogAnalyticsScript({
  posthogApiKey,
  posthogHost
}: {
  posthogApiKey: string
  posthogHost: string
}) {
  useEffect(() => {
    posthog.init(posthogApiKey, { api_host: posthogHost })
  }, [])

  return <></>
}

/**
 * Run callback with the groups set. After the callback has been executed,
 * the groups will be reset to their original state.
 *
 * @param groups
 * @param callback
 * @returns
 */
function withGroups(groups: Record<string, string>, callback: () => void) {
  const groupEntries = Object.entries(groups)

  // No need for the shennanigans below if groups is empty
  if (groupEntries.length == 0) {
    callback()
    return
  }

  // store existing groups to restore later
  const priorGroups = posthog.getGroups()

  for (const [groupType, groupKey] of groupEntries) {
    posthog.group(groupType, groupKey)
  }

  callback()

  posthog.resetGroups()
  // restore all prior groups
  posthog.register({ $groups: { ...priorGroups } })
}
