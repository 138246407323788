import { Base64 } from '../base'

export interface Param<T> {
  decode: (s: string) => T
  encode: (x: T) => string
}

export function TypedParam<T extends string>(): Param<T> {
  return {
    decode: (s) => s as T,
    encode: (s) => s
  }
}

export const StringParam = TypedParam<string>()

export const IntParam: Param<number> = {
  decode: (s) => parseInt(s),
  encode: (x) => x.toString()
}

export const BooleanParam: Param<boolean> = {
  decode: (s) => s === 'true',
  encode: (b) => b.toString()
}

export const Base64Param: Param<string> = {
  encode: Base64.encodeURL,
  decode: Base64.decodeURL
}

export const RawParam = TypedParam<string>()
