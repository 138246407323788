import { useFileStorageAdapter } from '@features/file-storage/file-storage-provider'
import { Logo } from '@theme'
import clsx from 'clsx'

type Props = {
  size?: number
  src: string
  className?: string
}
export function OrganizationLogo({ src, className, size = 80 }: Props) {
  const { resolvePublicURL } = useFileStorageAdapter()

  return (
    <img
      src={resolvePublicURL(src)}
      className={clsx('object-contain', className)}
      style={{ maxWidth: `${size * 2}px`, height: `${size}px` }}
    />
  )
}
