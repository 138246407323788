import { routes } from '@features/routing'
import { useDerivedState } from '@st/react-util/use-derived-state'
import { useFlashState } from '@st/react-util/use-flash-state'
import { useProcess, useProcessState } from '@st/redux'
import {
  Button,
  Dialog,
  DialogButtons,
  EmailsChipInput,
  EyeIcon,
  LinkIcon,
  TextArea,
  Tip
} from '@st/theme'
import { copyToClipboard } from '@util/clipboard'
import { match } from 'ts-pattern'
import {
  saveFolder,
  selCanSendQuestionnaire,
  sendQuestionnaireEmail,
  STSendQuestionnaireMessage,
  stSendQuestionnaireModule,
  STSendQuestionnaireState
} from './st-send-questionnaire-module'

export function STSendQuestionnaireDialog({
  folderId,
  onClose
}: {
  folderId: string
  onClose: () => void
}) {
  const stSendQuestionnaire = useProcess(stSendQuestionnaireModule, {
    folderId: folderId,
    templateType: 'folder_invite'
  })
  const state = useProcessState(stSendQuestionnaireModule)

  if (!state.sendState) {
    return null
  }

  return (
    <STSendQuestionnaireDialogView
      state={state}
      send={stSendQuestionnaire.send}
      onClickSendQuestionnaire={async (message) => {
        await stSendQuestionnaire.send(sendQuestionnaireEmail({ message: message }))
        onClose()
      }}
      onSaveFolder={(message) => {
        stSendQuestionnaire.send(saveFolder({ message: message }))
      }}
      onClose={onClose}
    />
  )
}

function STSendQuestionnaireDialogView({
  state,
  onClickSendQuestionnaire,
  onSaveFolder,
  onClose,
  send
}: {
  state: STSendQuestionnaireState
  send: (message: STSendQuestionnaireMessage) => void
  onClickSendQuestionnaire: (message: string) => void
  onSaveFolder: (message: string) => void
  onClose: () => void
}) {
  const [message, setMessage] = useDerivedState(state.sendState?.message ?? '')

  return (
    <Dialog
      title="Send questionnaire"
      className="w-full max-w-2xl"
      buttons={
        <DialogButtons
          leftButton={
            <CopyQuestionnaireLink
              url={state.sendState?.questionnaireUrl ?? ''}
              onCopied={() => onSaveFolder(message)}
            />
          }
        >
          <Button
            variant="subtle"
            onClick={() => {
              onSaveFolder(message)
              onClose()
            }}
          >
            Close
          </Button>

          <Tip title="Preview questionnaire">
            <Button
              variant="default"
              leadingIcon={<EyeIcon className="h-4 w-4" />}
              onClick={() => {
                window.open(
                  routes.toPath({
                    name: 'organization_preview_questionnaire',
                    folderId: state.folderId,
                    organizationSlug: state.sendState?.organizationSlug
                  })
                )
              }}
            >
              Preview questionnaire
            </Button>
          </Tip>

          <Button
            variant="primary"
            disabled={!selCanSendQuestionnaire(state)}
            onClick={() => onClickSendQuestionnaire(message)}
          >
            {match(state.status)
              .with('sending', () => 'Sending')
              .with('sent', () => 'Sent')
              .otherwise(() => 'Send')}
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        <label className="font-semibold">To:</label>
        <EmailsChipInput
          className="w-full"
          emails={state.emails}
          inputValue={state.inputValue}
          onInputChange={(v) => send({ type: 'typeEmail', email: v })}
          selectedEmail={state.selectedEmail}
          onSelectedEmailChange={(v) => send({ type: 'selectEmail', email: v })}
          onAdd={(email) => send({ type: 'addEmail', email })}
          onDelete={(email) => send({ type: 'removeEmail', email })}
        />
      </div>
      <TextArea
        className="mt-2 min-h-96"
        placeholder="Send a message"
        value={message}
        onChange={(value) => setMessage(value)}
      />
    </Dialog>
  )
}

function CopyQuestionnaireLink({ url, onCopied }: { url: string; onCopied: () => void }) {
  const [copied, setCopied] = useFlashState(false, 1500)

  return (
    <Tip title="Only the emails you add above will have access to enter the questionnaire.">
      <Button
        variant="default"
        leadingIcon={<LinkIcon className="h-4 w-4" />}
        onClick={() => {
          copyToClipboard(url)
          setCopied(true)
          onCopied()
        }}
      >
        {copied ? 'Copied link' : 'Copy shareable link'}
      </Button>
    </Tip>
  )
}
