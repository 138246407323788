import { useId } from '@util/react'
import {
  ComponentType,
  createContext,
  Dispatch,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useReducer
} from 'react'

type ModalProps<Props, Response> = Props & {
  onClose?: (res: Response) => void
}

type ModalHook<Props, Response> = (props: Props) => Promise<Response>

type VisibleModal = {
  id: string
  Component: FC
}

type ModalSurfaceState = {
  modals: VisibleModal[]
}

type ModalSurfaceAction =
  | { type: 'SHOW_MODAL'; modal: VisibleModal }
  | { type: 'HIDE_MODAL'; id: string }

function modalSurfaceReducer(
  state: ModalSurfaceState,
  action: ModalSurfaceAction
): ModalSurfaceState {
  switch (action.type) {
    case 'SHOW_MODAL': {
      return { ...state, modals: [...state.modals, action.modal] }
    }
    case 'HIDE_MODAL': {
      return {
        ...state,
        modals: state.modals.filter((el) => el.id != action.id)
      }
    }
  }
}

const ModalSurfaceContext = createContext<
  Dispatch<ModalSurfaceAction> | undefined
>(undefined)

export function ModalSurface({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(modalSurfaceReducer, { modals: [] })
  return (
    <ModalSurfaceContext.Provider value={dispatch}>
      {children}

      {state.modals.map(({ Component, id }) => (
        <Component key={id} />
      ))}
    </ModalSurfaceContext.Provider>
  )
}

export function useModal<Props, Response>(
  Component: ComponentType<ModalProps<Props, Response>>
): ModalHook<Props, Response> {
  const dispatch = useContext(ModalSurfaceContext)
  const modalId = useId('modal-')

  const show = useCallback(
    (props: Props) => {
      return new Promise<Response>((resolve) => {
        function ModalComponent() {
          return (
            <Component
              {...props}
              onClose={(v) => {
                resolve(v)
                dispatch!({ type: 'HIDE_MODAL', id: modalId })
              }}
            />
          )
        }

        if (!dispatch) {
          throw Error(
            'You must have an ancestor ModalSurface component for rendering the modals'
          )
        }

        dispatch({
          type: 'SHOW_MODAL',
          modal: {
            id: modalId,
            Component: ModalComponent
          }
        })
      })
    },
    [dispatch, modalId]
  )

  return show
}
