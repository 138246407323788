import { clsx } from 'clsx'

type Props = {
  className?: string
}

export function Separator({ className }: Props) {
  return (
    <div
      className={clsx('mx-2 h-full w-px self-stretch bg-gray-200', className)}
      aria-hidden="true"
    />
  )
}
