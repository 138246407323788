import { unique } from './array'

/**
 * A set that is really just an array underneath.
 * This is meant to be used instead of an actual Set object to keep things simple
 */
export type ArraySet<T> = T[]

export function setContains<T>(set: ArraySet<T>, value: T): boolean {
  return set.includes(value)
}

export function setAdd<T>(set: ArraySet<T>, value: T): ArraySet<T> {
  if (setContains(set, value)) return set
  return [...set, value].sort()
}

export function setRemove<T>(set: ArraySet<T>, value: T): ArraySet<T> {
  if (!setContains(set, value)) return set
  return set.filter((v) => v != value).sort()
}

export function setToggle<T>(set: ArraySet<T>, value: T): ArraySet<T> {
  return setContains(set, value) ? setRemove(set, value) : setAdd(set, value)
}

type SetDiff<T> = {
  add: Array<T>
  remove: Array<T>
}
export function setDiff<T>(opts: { old: ArraySet<T>; new: ArraySet<T> }): SetDiff<T> {
  const diff: SetDiff<T> = { add: [], remove: [] }
  // is in new set, but not in old set
  for (const newValue of opts.new) {
    if (!setContains(opts.old, newValue)) {
      diff.add.push(newValue)
    }
  }
  // is in old set but not in new set
  for (const oldValue of opts.old) {
    if (!setContains(opts.new, oldValue)) {
      diff.remove.push(oldValue)
    }
  }
  return diff
}
