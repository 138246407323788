import { ReactNode, useCallback, DragEvent } from 'react'
import {
  useFloating,
  useInteractions,
  useRole,
  useDismiss,
  FloatingOverlay,
  FloatingFocusManager,
  useId
} from '@floating-ui/react'

interface ModalProps {
  children: ReactNode
  isOpen: boolean
  onClose?: () => void
  className?: string
}

export function Modal({ isOpen, children, onClose, className }: ModalProps) {
  // Prevent file drag/drop events from elements underneath
  const preventDrag = useCallback((e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  // Set up floating UI
  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (!open && onClose) onClose()
    }
  })

  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown'
  })
  const role = useRole(context, { role: 'dialog' })

  const { getFloatingProps } = useInteractions([dismiss, role])

  const labelId = useId()
  const descriptionId = useId()

  if (!isOpen) {
    return null
  }

  return (
    <FloatingOverlay
      lockScroll
      className={`fixed inset-0 z-10 bg-stone-500 bg-opacity-75 ${className}`}
    >
      <div
        className="h-svh w-svw"
        onDragEnter={preventDrag}
        onDragLeave={preventDrag}
        onDragOver={preventDrag}
        onDrop={preventDrag}
      >
        <FloatingFocusManager context={context}>
          <div
            ref={refs.setFloating}
            aria-labelledby={labelId}
            aria-describedby={descriptionId}
            {...getFloatingProps({
              className: 'flex h-svh w-svw flex-col items-center justify-center'
            })}
          >
            {children}
          </div>
        </FloatingFocusManager>
      </div>
    </FloatingOverlay>
  )
}
