import { clsx } from 'clsx'
import { FocusEvent, forwardRef, KeyboardEvent, ReactNode, Ref, useEffect, useRef } from 'react'
import { CloseIcon } from './icons'
import { isValidEmail } from '@st/util/email'

type EmailInputProps = {
  value: string
  onChange: (value: string) => void
  onAdd: (email: string) => void
  onFocusPrevious: () => void
  onFocus?: () => void
  onBlur?: () => void
}
const EmailInput = forwardRef(
  (
    { value, onChange, onAdd, onFocusPrevious, onFocus, onBlur }: EmailInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Enter' || e.key === ',') {
        e.preventDefault()
        e.stopPropagation()

        const v = value.trim()

        if (v.length == 0) {
          return
        }

        if (isValidEmail(v)) {
          onAdd(v)
        }
      } else if (e.key === 'Backspace' && value === '') {
        e.preventDefault()
        e.stopPropagation()

        onFocusPrevious()
      }
    }

    function handleBlur(e: FocusEvent<HTMLInputElement>) {
      const v = value.trim()
      if (v.length > 0 && isValidEmail(v)) {
        onAdd(v)
      } else {
        onChange('')
      }

      onBlur?.()
    }

    return (
      <input
        ref={ref}
        type="text"
        className="h-6 border-0 px-0 py-0 text-base outline-none focus:ring-0"
        autoFocus={true}
        onFocus={() => onFocus?.()}
        onBlur={handleBlur}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeyDown}
      />
    )
  }
)
EmailInput.displayName = 'EmailInput'

const EmailChip = forwardRef<
  HTMLButtonElement,
  {
    children?: ReactNode
    onDelete?: () => void
    onFocus?: () => void
    onBlur?: () => void
  }
>(({ children, onDelete, onFocus, onBlur }, ref) => {
  return (
    <button
      ref={ref}
      className="flex h-6 flex-row items-center rounded-full border border-gray-300 bg-stone-100 px-2 text-base text-blue-500"
      onFocus={onFocus}
      onBlur={onBlur}
    >
      {children}
      <div
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          onDelete?.()
        }}
        className="cursor-pointer"
      >
        <CloseIcon className="ml-0.5 mt-0.5 h-3 w-3 text-gray-800" />
      </div>
    </button>
  )
})
EmailChip.displayName = 'EmailChip'

type Props = {
  className?: string

  /**
   * The list of email chips to show
   */
  emails: string[]

  /**
   * The value of the free-form input that can be typed into
   */
  inputValue: string

  /**
   * When the free-form input changes
   */
  onInputChange: (value: string) => void

  /**
   * The currently selected email (the chip that will have an outline)
   */
  selectedEmail: string | undefined

  /**
   * The currently selected email changes
   */
  onSelectedEmailChange: (email: string | undefined) => void

  /**
   * Trying to add an email to the list of emails
   */
  onAdd: (email: string) => void

  /**
   * Trying to delete an email (removing a chip)
   */
  onDelete: (email: string) => void
}

/**
 * An email-inspired input for typing to add emails.
 * @param param0
 * @returns
 */
export function EmailsChipInput({
  className,
  emails,
  inputValue,
  onInputChange,
  selectedEmail,
  onSelectedEmailChange,
  onAdd,
  onDelete
}: Props) {
  const inputRef = useRef<HTMLInputElement>(null)
  const prevFocusRef = useRef<HTMLButtonElement>(null)
  const prevFocusIndex = selectedEmail ? emails.indexOf(selectedEmail) : emails.length - 1

  useEffect(() => {
    if (selectedEmail == undefined) {
      inputRef.current?.focus()
    }
  }, [selectedEmail == undefined])

  function handleKeyDown(e: KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Backspace' && selectedEmail) {
      e.preventDefault()
      e.stopPropagation()
      onDelete?.(selectedEmail)
    }
  }

  return (
    <div
      className={clsx(
        'flex flex-row flex-wrap gap-2 rounded-md border border-stone-300 px-2 py-1.5',
        className
      )}
      onKeyDown={handleKeyDown}
    >
      {emails.map((email, index) => {
        return (
          <EmailChip
            key={email}
            ref={index == prevFocusIndex ? prevFocusRef : undefined}
            onDelete={() => onDelete(email)}
            onFocus={() => onSelectedEmailChange(email)}
          >
            {email}
          </EmailChip>
        )
      })}

      <EmailInput
        ref={inputRef}
        value={inputValue}
        onChange={onInputChange}
        onAdd={onAdd}
        onFocus={() => onSelectedEmailChange(undefined)}
        onFocusPrevious={() => {
          if (prevFocusRef.current) {
            prevFocusRef.current.focus()
          }
        }}
      />
    </div>
  )
}
