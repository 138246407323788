import { Base } from '../base'
import { getOrThrow } from '../result'
import { InlineXFile, XFile, castFileProtocol, fetchFile } from './core'
import { computeFileHash } from './hash'
import { URLProtocol } from './url'

export * from './core'
export * from './hash'
export * from './url'

const DEFAULT_MIME_TYPE = 'application/octet-stream'

type ToXFileOpts<T extends URLProtocol> = {
  protocol?: T | 'inline'
  name?: string
  mimeType?: string
  hash?: boolean
}
export async function toXFile<T extends URLProtocol>(
  file: File | Response | Blob | ArrayBuffer | string,
  opts: ToXFileOpts<T> = { protocol: 'inline' }
): Promise<XFile | InlineXFile> {
  if (typeof file === 'string') {
    file = new Blob([file], { type: opts.mimeType })
  }

  const xfile = toInlineXFile(file, opts)
  if ((opts.hash && !xfile.sha1) || opts.protocol == 'content') {
    if (file instanceof ArrayBuffer) {
      xfile.sha1 = await computeFileHash(file)
    } else if (file instanceof Blob) {
      xfile.sha1 = await file.arrayBuffer().then(computeFileHash)
      xfile.size = file.size
    } else if (file instanceof Response) {
      const response = file
      const blob = await response.blob()

      // replace data because the readable stream will no longer be usable
      xfile.data = blob
      xfile.size = blob.size
      xfile.sha1 = await xfile.data.arrayBuffer().then(computeFileHash)
    }
  }
  const protocol = opts.protocol ?? 'inline'
  if (protocol == 'inline') {
    return xfile
  } else {
    return castFileProtocol(xfile, protocol, { fetch: fetchFile }).then(getOrThrow)
  }
}

function toInlineXFile<T extends URLProtocol>(
  file: File | Response | Blob | ArrayBuffer | string,
  opts: ToXFileOpts<T>
): InlineXFile {
  if (file instanceof ArrayBuffer) {
    if (!opts.name) {
      throw new Error('name is needed')
    }
    return {
      type: 'file',
      name: opts.name,
      uri: 'inline:',
      size: file.byteLength,
      mimeType: opts.mimeType ?? DEFAULT_MIME_TYPE,
      data: file
    }
  } else if (file instanceof Blob) {
    return {
      name: 'name' in file ? file.name : opts.name!,
      type: 'file',
      mimeType: file.type,
      size: file.size,
      uri: 'inline:',
      data: file
    }
  } else if (file instanceof Response) {
    const response = file
    const contentLength = response.headers.get('Content-Length')
    const sha1 = response.headers.get('x-amz-meta-sha1')

    return {
      name: opts.name!,
      type: 'file',
      mimeType: response.headers.get('Content-Type') ?? DEFAULT_MIME_TYPE,
      size: contentLength ? Number(contentLength) : undefined,
      uri: 'inline:',
      data: response.body!,
      sha1: sha1 || undefined
    }
  }
  throw new Error('Unsupported source')
}
