import { cn } from '@util/cn'
import { CSSProperties, ReactNode } from 'react'

export type ButtonGroupLayout = 'row' | 'column'

type Props = {
  layout?: ButtonGroupLayout
  style?: CSSProperties
  className?: string
  children: ReactNode
}

export function ButtonGroup({ children, className, style, layout = 'row' }: Props) {
  return (
    <div className={cn('flex flex-wrap justify-center gap-5', className, layout)} style={style}>
      {children}
    </div>
  )
}
