import { useProcess, useProcessState } from '@st/redux'
import { selDocumentTypeOptions, stFolderModule } from './st-folder-module'
import { Button, Dialog, DialogButtons, Select } from '@st/theme'
import { STDocument } from '@st/sdk'
import { useState } from 'react'
import { useAppDeps } from '@features/app-deps-provider'
import { platformModule } from '@features/st-pdf-viewer/platform-module'

export function STMoveDocumentDialog({
  folderId,
  doc,
  onClose
}: {
  folderId: string
  doc: STDocument
  onClose: () => void
}) {
  const { sdk } = useAppDeps()

  const platform = useProcess(platformModule)

  const documentTypes = useProcessState(stFolderModule, selDocumentTypeOptions)
  const [destinationDocumentTypeId, setDestinationDocumentTypeId] = useState<string | undefined>(
    doc.documentTypeId
  )
  const [isSaving, setSaving] = useState(false)

  return (
    <Dialog
      title="Move document"
      subtitle={`Where would you like to move ${doc.name}?`}
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={
              destinationDocumentTypeId == undefined ||
              destinationDocumentTypeId == doc.documentTypeId ||
              isSaving
            }
            onClick={async () => {
              setSaving(true)

              const documentType = documentTypes.find((dt) => dt.id == destinationDocumentTypeId)
              await sdk.send({
                type: 'folders/moveDocument',
                folderId,
                documentId: doc.id,
                documentTypeId: destinationDocumentTypeId!
              })
              platform.send({
                type: 'showSnackbar',
                message: `${doc.name} moved to ${documentType!.name}`
              })
              setSaving(false)
              onClose?.()
            }}
          >
            {isSaving ? 'Moving' : 'Move'}
          </Button>
        </DialogButtons>
      }
    >
      <Select
        options={documentTypes}
        buildValue={(dt) => dt.id}
        buildLabel={(dt) => dt.name}
        value={destinationDocumentTypeId}
        onChange={setDestinationDocumentTypeId}
      />
    </Dialog>
  )
}
