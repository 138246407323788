import { isMap, JsonMap, JSONValue } from '@st/util/json-value'
import { join } from '@st/util/parse'
import { map, or, regexp, seq, token } from '@st/util/tspc'

export type Expression =
  | { op: LogicalOperator; expressions: Expression[] }
  | { op: BinaryOperator; leftKey: string; rightValue: string }
  | { op: UrnaryOperator; inputKey: string }

type LogicalOperator = 'AND' | 'OR'
type BinaryOperator = '=' | '!=' | '>' | '<' | ' CONTAINS ' | ' DOES NOT CONTAIN '
type UrnaryOperator = ' IS NOT EMPTY' | ' IS EMPTY'

const inputKeyToken = regexp(/[a-z0-9_.*]+/)
const valueToken = regexp(/\S+/)

const binaryOperatorToken = or<string, BinaryOperator>(
  token('='),
  token('!='),
  token('>'),
  token('<'),
  token(' CONTAINS '),
  token(' DOES NOT CONTAIN ')
)

const binaryExprToken = map(
  seq(inputKeyToken, binaryOperatorToken, valueToken),
  (v): Expression => ({ op: v[1], leftKey: v[0], rightValue: v[2] })
)

const urnaryOperatorToken = or<string, UrnaryOperator>(token(' IS NOT EMPTY'), token(' IS EMPTY'))
const urnaryExprToken = map(
  seq(inputKeyToken, urnaryOperatorToken),
  (v): Expression => ({ op: v[1], inputKey: v[0] })
)

const primitiveExprToken = or(urnaryExprToken, binaryExprToken)

const andExprToken = map(
  join(primitiveExprToken, token(' AND ')),
  (expressions): Expression =>
    expressions.length == 1 ? expressions[0] : { op: 'AND', expressions }
)

const fullExprToken = map(
  join(andExprToken, token(' OR ')),
  (expressions): Expression =>
    expressions.length == 1 ? expressions[0] : { op: 'OR', expressions }
)

type ExprEvalContext = {
  resolveValue: (path: string) => JSONValue
}

/**
 * Given a set of {@link inputs} filled in by the user,
 * return whether or not the filter {@link condition} applies.
 *
 * Used in the questionnaire to conditionally show/hide pages.
 * Take a look at the showWhen in a sample UI config to see how it's used.
 *
 * Some examples of filters:
 *  basic_info.number_of_dependents>0
 *  self_employment.list_of_expenses CONTAINS home_office
 *
 * Take a look at the tests for the exact behavior of the filters.
 *
 * If {@link condition} is omitted, we would do nothing.
 * This is interpreted as no filtering rules are applied.
 */
export function matchesCondition(condition: string | undefined, context: ExprEvalContext) {
  if (!condition) return true

  const conditionExpr = parseExpression(condition)
  if (!conditionExpr) return false

  return evalExpression(conditionExpr, context)
}

export function parseExpression(expr: string): Expression | undefined {
  if (expr.length == 0) return undefined
  const cleanedExpr = expr.replace(/\s*(\r?\n)+\s*/g, ' ')
  const res = fullExprToken(cleanedExpr, 0)
  return res.success ? res.value : undefined
}

function evalExpression(expr: Expression, context: ExprEvalContext): boolean {
  switch (expr.op) {
    // logical operators
    case 'AND':
      return expr.expressions.every((expr) => evalExpression(expr, context))
    case 'OR':
      return expr.expressions.some((expr) => evalExpression(expr, context))
    // urnary operators
    case ' IS EMPTY':
      return isValueEmpty(context.resolveValue(expr.inputKey))
    case ' IS NOT EMPTY':
      return !isValueEmpty(context.resolveValue(expr.inputKey))
    // binary operators
    case '=':
    case '!=':
    case '>':
    case '<':
    case ' CONTAINS ':
    case ' DOES NOT CONTAIN ':
      const leftValue = context.resolveValue(expr.leftKey)
      switch (expr.op) {
        case '=':
          return stringRepesentation(leftValue) == expr.rightValue
        case '!=':
          return stringRepesentation(leftValue) != expr.rightValue
        case '>':
          return typeof leftValue == 'number' && leftValue! > parseInt(expr.rightValue)
        case '<':
          return typeof leftValue == 'number' && leftValue! < parseInt(expr.rightValue)
        case ' CONTAINS ':
          return contains(leftValue, expr.rightValue)
        case ' DOES NOT CONTAIN ':
          return !contains(leftValue, expr.rightValue)
      }
  }
}

function contains(leftValue: JSONValue, rightValue: JSONValue) {
  if (Array.isArray(leftValue)) {
    return leftValue.includes(rightValue)
  } else if (typeof leftValue == 'string' && typeof rightValue == 'string') {
    return (
      leftValue.length > 1 &&
      rightValue.length > 1 &&
      leftValue.toLowerCase().includes(rightValue.toLowerCase())
    )
  }
  return false
}

/**
 * We have our own custom empty implementation because, for example,
 * with globs, of data such as medical_expenses.* which return an object,
 * we want to consider an object of empty values as empty.
 *
 * @param value
 * @returns
 */
function isValueEmpty(value: JSONValue): boolean {
  if (isPrimitiveValueEmpty(value)) {
    return true
  } else if (Array.isArray(value)) {
    return value.length == 0
  } else if (isMap(value)) {
    return isMapEmpty(value)
  }
  return false
}

function isPrimitiveValueEmpty(value: JSONValue) {
  return value === '' || value === 0 || value === null || value === undefined
}

// if all values in an object are empty, we will condisider the entire object empty for purposes
// of these conditional expressions
function isMapEmpty(map: JsonMap) {
  for (let k in map) {
    if (!isPrimitiveValueEmpty(map[k])) return false
  }
  return true
}

export function resolveValue(path: string, inputs: JsonMap) {
  // imperative optimization for simple globs ending in .*
  if (path.endsWith('.*')) {
    const map: JsonMap = {}
    // if a path, for example, is rental_property.0.address.*, we remove the * to get the prefix
    const prefix = path.substring(0, path.length - 1)
    for (const k in inputs) {
      if (k.startsWith(prefix) && !isPrimitiveValueEmpty(inputs[k])) {
        const subpath = k.substring(prefix.length)
        map[subpath] = inputs[k]
      }
    }
    return map
  }

  return inputs[path]
}

function stringRepesentation(value: JSONValue) {
  if (value === undefined || value === null) {
    return 'null'
  }
  return value.toString()
}
