import { SetAppRoute } from '@features/routing'
import { useLink } from '@features/use-link'
import { SideNavLink } from '@st/theme'
import { ReactNode } from 'react'
import { STAppHeaderNav } from './st-app-header-nav'

export function OrganizationSettingsScaffold({
  title,
  children
}: {
  title: string
  children?: ReactNode
}) {
  return (
    <div style={{ minWidth: '1200px' }} className="flex flex-col">
      <STAppHeaderNav />

      <div className="px-16 py-8">
        <h1 className="mb-8 text-2xl">{title}</h1>
        <div className="grid grid-cols-[200px_auto] gap-4">
          <SettingsSubnav />
          <div>{children}</div>
        </div>
      </div>
    </div>
  )
}

function SettingsSubnav() {
  return (
    <div className="flex flex-col">
      <AppSideNavLink href={{ name: 'team' }}>Team</AppSideNavLink>
      <AppSideNavLink href={{ name: 'branding' }}>Branding</AppSideNavLink>
      {/* <AppSideNavLink href={{ name: 'team' }}>Templates</AppSideNavLink> */}
      <AppSideNavLink href={{ name: 'manage_organization_tags' }}>Tags</AppSideNavLink>
      <AppSideNavLink href={{ name: 'manage_organization_message_templates' }}>
        Templates
      </AppSideNavLink>
      <AppSideNavLink href={{ name: 'manage_organization_plan' }}>Plan</AppSideNavLink>
      {/* <AppSideNavLink href={{ name: 'manage_organization_integrations' }}>
        Integrations
      </AppSideNavLink> */}
    </div>
  )
}

function AppSideNavLink(props: {
  href: SetAppRoute | string
  children: ReactNode
  onClick?: () => void
}) {
  const { href, onClick, isActive } = useLink({ href: props.href })

  return (
    <SideNavLink href={href} onClick={onClick} active={isActive}>
      {props.children}
    </SideNavLink>
  )
}
