import { clsx } from 'clsx'
import { ReactNode } from 'react'

export function PostItNote({
  children,
  className,
  signature,
  variant = 'normal'
}: {
  children: ReactNode
  variant?: 'normal' | 'success'
  className?: string
  signature?: ReactNode
}) {
  return (
    <blockquote
      className={clsx(
        'rounded-md text-base',
        variant == 'normal' && 'bg-yellow-100',
        variant == 'success' && 'bg-green-300',
        className
      )}
    >
      <div className="relative flex flex-col px-12 py-6">
        <svg
          viewBox="0 0 12 11"
          fill="none"
          className={clsx(
            'absolute start-4 top-6 h-4 w-4',
            variant == 'normal' && 'text-yellow-500',
            variant == 'success' && 'text-green-800'
          )}
        >
          <path
            d="M7 8.5C7 9.88071 8.11929 11 9.5 11C10.8807 11 12 9.88071 12 8.5C12 7.11929 10.8807 6 9.5 6C9.01272 6 8.558 6.13941 8.17355 6.38052C8.36908 5.26347 8.71392 4.36448 9.12561 3.61596C9.77495 2.43533 10.6021 1.60503 11.3536 0.853554C11.5488 0.658292 11.5488 0.341709 11.3536 0.146446C11.1583 -0.0488157 10.8417 -0.0488157 10.6464 0.146446L10.6345 0.158345C9.88816 0.904718 8.97121 1.82165 8.24939 3.13404C7.51918 4.4617 7 6.17024 7 8.5ZM1.17355 6.38052C1.558 6.13941 2.01272 6 2.5 6C3.88071 6 5 7.11929 5 8.5C5 9.88071 3.88071 11 2.5 11C1.11929 11 0 9.88071 0 8.5C0 6.17024 0.519179 4.4617 1.24939 3.13404C1.97121 1.82165 2.88816 0.904718 3.63455 0.158345L3.64645 0.146446C3.84171 -0.0488157 4.15829 -0.0488157 4.35355 0.146446C4.54882 0.341709 4.54882 0.658292 4.35355 0.853554C3.60208 1.60503 2.77495 2.43533 2.12561 3.61596C1.71392 4.36448 1.36908 5.26347 1.17355 6.38052Z"
            fill="currentColor"
          />
        </svg>

        <div className="whitespace-pre-wrap text-base">{children}</div>

        <div className="mt-4 text-base font-bold">-{signature}</div>
      </div>
    </blockquote>
  )
}
