import { JSONArray, JSONValue, JsonMap, JsonPrimitive, isMap } from '@st/util/json-value'
import {
  CSSProperties,
  MouseEventHandler,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react'

type Theme = { dark: string; medium: string; light: string }

const FONT: CSSProperties = {
  fontFamily: 'verdana,arial,helvetica,sans-serif',
  fontSize: 'xx-small'
}

const BLUE: Theme = { dark: '#0000cc', medium: '#4444cc', light: '#ccddff' }
const GREEN: Theme = { dark: '#006600', medium: '#009900', light: '#ccffcc' }

type Filter = (path: string[], value: JSONValue) => boolean

type DumpJsonState = {
  filterChild: Filter
}
const DumpJSONContext = createContext<DumpJsonState>({
  filterChild: () => true
})

function useDumpJSON() {
  return useContext(DumpJSONContext)
}

type Props = {
  value: JSONValue
}
export function DumpJSON({ value }: Props) {
  const [query, setQuery] = useState('')

  const filterChild: Filter = useCallback(
    (path: string[], value: JSONValue) => {
      if (query.length == 0) return true
      if (path.length == 0) return true

      const parts = query.split(' ')
      return path.some((key) => parts.every((p) => key.includes(p)))
    },
    [query]
  )

  return (
    <DumpJSONContext.Provider value={{ filterChild }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <input
          type="text"
          style={{ outline: 'none' }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Search"
        />

        <DumpJSONValue value={value} path={[]} />
      </div>
    </DumpJSONContext.Provider>
  )
}

type DumpJSONValueProps = { value: JSONValue; path: string[] }
function DumpJSONValue({ value, path = [] }: DumpJSONValueProps) {
  if (typeof value === 'object' && value != null) {
    return <DumpJSONObject value={value} path={path} />
  }
  return <DumpJSONPrimitive value={value} path={path} />
}

function DumpJSONPrimitive({ value }: { value: JsonPrimitive | null | undefined; path: string[] }) {
  return <span>{value?.toString()}</span>
}

function DumpJSONObject({ value, path }: { value: JsonMap | JSONArray; path: string[] }) {
  const { filterChild } = useDumpJSON()
  const [expanded, setExpanded] = useState(true)
  const theme = isMap(value) ? BLUE : GREEN

  let entries = Object.entries(value).filter(([key, value]) => {
    const subpath = [...path, key]
    return filterChild(subpath, value)
  })

  if (!Array.isArray(value)) {
    // we want object keys to be sorted
    entries = entries.sort((a, b) => a[0].localeCompare(b[0]))
  }

  return (
    <table style={{ ...FONT, background: theme.dark }}>
      <tbody>
        <tr style={{ position: 'relative' }}>
          <th
            colSpan={2}
            onClick={() => setExpanded(!expanded)}
            style={{
              textAlign: 'left',
              padding: 5,
              color: 'white',
              background: theme.medium,
              position: 'sticky',
              top: 0
            }}
          >
            {path.at(-1) ?? '.'}
          </th>
        </tr>
        {expanded
          ? entries.map(([key, v]) => {
              const subpath = [...path, key]
              return (
                <EntryRow key={key} path={subpath} value={v} color={theme.light}>
                  <DumpJSONValue value={v} path={subpath} />
                </EntryRow>
              )
            })
          : null}
      </tbody>
    </table>
  )
}

type EntryRowProps = {
  path: string[]
  value: JSONValue
  color: string
  children: ReactNode
}
function EntryRow({ path, value, color, children }: EntryRowProps) {
  return (
    <tr>
      <td style={{ padding: 3, background: color }} onClick={handleCopy(() => path.join('.'))}>
        {path.at(-1)}
      </td>
      <td
        style={{ padding: 3, background: '#fff' }}
        onClick={handleCopy(() => JSON.stringify(value))}
      >
        {children}
      </td>
    </tr>
  )
}

function handleCopy(text: () => string): MouseEventHandler {
  return (e) => {
    e.preventDefault()
    e.stopPropagation()
    const value = text()
    console.log(value)
    console.log(shortenPath(value))
    navigator.clipboard.writeText(shortenPath(value))
  }
}

function shortenPath(longPath: string) {
  return longPath
    .replace(/^data./, '')
    .replace(/\.data/, '')
    .replace(/SDSX\./, '')
    .replace(/\.blocks/, '')
    .replace(/\.record\.xtra\.rows/, '.')
    .replace(/\.contents/, '.')
    .replace(/\.values/, '.')
    .replace(/\.v\.data\.value/, '')
    .replace(/\.\./g, '.')
}
