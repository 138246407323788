/**
 * Return just the filename given the full path of the file
 * @param path
 */
export function basename(path: string) {
  const lastIndex = path.lastIndexOf('/')
  return lastIndex == -1 ? path : path.substring(lastIndex + 1)
}

export function dirname(path: string): string {
  const lastIndex = path.lastIndexOf('/')
  return lastIndex == -1 ? path : path.substring(0, lastIndex)
}

/**
 * Return the file extension, not including the "." of a file
 * Normalized to lowercase to avoid bugs when checking for particular extensions
 *
 * @param filename
 * @returns
 */
export function extname(filename: string): string | undefined {
  const extIndex = filename.lastIndexOf('.')
  return extIndex == -1
    ? undefined
    : filename.substring(extIndex + 1).toLowerCase()
}

/**
 * If we want a simple filename representation that is compatible for web urls,
 * Mac, and Windows, the easiest solution is to sanitize the filename and remove incompatible characters
 *
 * @param filename
 * @returns
 */
export function sanitizeFilename(filename: string): string {
  const VALID_CHARS_REGEX = /[A-Za-z0-9\-._~!$'()*,; ]+/g
  // Extract all matches of valid characters and join them back into a string
  // This removes any characters not included in the regex pattern
  const sanitized = (filename.match(VALID_CHARS_REGEX) || []).join('')
  return sanitized.replace(/\s+/, ' ').trim()
}

/**
 *
 * @param filename
 */
export function numberFile(filename: string, n: number): string {
  const suffix = ` (${n})`

  const ext = extname(filename)
  return ext
    ? `${dropFileExtension(filename)}${suffix}.${ext}`
    : `${filename}${suffix}`
}

export function getNextAvailableFileName(
  desiredFileName: string,
  fileNameExists: (name: string) => boolean
): string {
  let curFileName = desiredFileName
  let n = 1

  while (fileNameExists(curFileName)) {
    if (n === 1000) {
      throw `Possible infinite loop for generating a filename for ${desiredFileName}`
    }

    curFileName = numberFile(desiredFileName, n++)
  }

  return curFileName
}

/**
 * Joins path segments for file storage
 */
export function joinPath(...segments: string[]): string {
  return segments.join('/')
}

export function dropFileExtension(filename: string): string {
  const ext = extname(filename)
  if (!ext) return filename // nothing to drop bc no file extension
  return filename.substring(0, filename.length - 1 - ext.length)
}

export function ensureFileExtension(filename: string, ext: string): string {
  if (filename.endsWith(`.${ext}`)) {
    return filename
  }
  return `${filename}.${ext}`
}
