import { JSONValue, JsonMap, isArray, isMap, isPrimitive } from './json-value'

export type TruncateOpts = {
  maxStringLength: number
  maxKeys: number
  maxArrayLength: number
}

export function truncate(value: JSONValue, opts: TruncateOpts): JSONValue {
  // Directly use the provided options, which are now all required
  const { maxStringLength, maxKeys, maxArrayLength } = opts

  if (typeof value === 'string') {
    if (value.length > maxStringLength) {
      return `${value.slice(0, maxStringLength)}<${value.length - maxStringLength}} chars>`
    } else {
      return value
    }
  } else if (isArray(value)) {
    if (value.length > maxArrayLength) {
      return [
        ...value.slice(0, maxArrayLength).map((el) => truncate(el, opts)),
        `<${value.length - maxArrayLength} els>`
      ]
    } else {
      return value.map((el) => truncate(el, opts))
    }
  } else if (isMap(value)) {
    const keys = Object.keys(value)
    const truncatedObject: JsonMap = {}

    keys.slice(0, maxKeys).forEach((key) => {
      truncatedObject[key] = truncate(value[key], opts)
    })

    if (keys.length > maxKeys) {
      const itemsTruncated = keys.length - maxKeys
      truncatedObject[`<${itemsTruncated} keys>`] = `<${itemsTruncated}>`
    }

    return truncatedObject
  }

  return value
}
