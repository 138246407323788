import React from 'react'
import ReactDOM from 'react-dom/client'
import '@styles/main.scss'
import '@themestyle'
import './styles/globals.css'

import { App } from './app'
import { initSentry } from './init-sentry'

initSentry()

ReactDOM.createRoot(document.getElementById('root')!).render(
  // react strict mode is disabled because it is finnicky with the PDFTron/Apryse viewer
  // it makes debugging harder becdause of the create/dispose/create lifecycle of the controller
  // <React.StrictMode>
  <App />
  // </React.StrictMode>
)
