import { Wizard, WizardIcons } from '@st/theme'
import { useState } from 'react'
import { STQuestionnaireDownloadCSVPage } from './st-questionnaire-download-csv-page'
import { STQuestionnairesBulkEmailPage } from './st-questionnaires-bulk-email-page'

export function STQuestionnairesSendPage({
  folderIds,
  onClose,
  onClickEnrollInReminders
}: {
  folderIds: string[]
  onClose: () => void
  onClickEnrollInReminders: (folderIds: string[]) => void
}) {
  // const [selectedOption, setSelectedOption] = useState<
  //   'stanfordtax' | 'email' | 'clientPortal' | undefined
  // >(undefined)

  return (
    <STQuestionnairesBulkEmailPage
      folderIds={folderIds}
      onClose={onClose}
      // onClickBack={onClose}
      onClickEnrollInReminders={onClickEnrollInReminders}
    />
  )
  // if (selectedOption == 'email') {
  //   return (
  //     <STQuestionnaireDownloadCSVPage
  //       folderIds={folderIds}
  //       onClose={onClose}
  //       onClickBack={() => setSelectedOption(undefined)}
  //     />
  //   )
  // } else if (selectedOption == 'stanfordtax') {
  //   return (
  //     <STQuestionnairesBulkEmailPage
  //       folderIds={folderIds}
  //       onClose={onClose}
  //       onClickBack={() => setSelectedOption(undefined)}
  //       onClickEnrollInReminders={onClickEnrollInReminders}
  //     />
  //   )
  // }

  // return (
  //   <Wizard.Scaffold breadcrumb="Import" onClose={onClose}>
  //     <Wizard.Main title="How would you like to send the questionnaires?">
  //       <Wizard.OptionsColumn>
  //         <Wizard.LargeOptionButton
  //           icon={WizardIcons.ReturningClientsIcon}
  //           caption="Just click a button and StanfordTax will send the email on your behalf - whitelabeled with your firm name and logo."
  //           onClick={() => setSelectedOption('stanfordtax')}
  //         >
  //           Via StanfordTax <RecommendedBadge />
  //         </Wizard.LargeOptionButton>

  //         <Wizard.LargeOptionButton
  //           icon={WizardIcons.ReturningClientsIcon}
  //           caption="We'll walk you through the steps of sending the questionnaire links in bulk through your own email."
  //           onClick={() => setSelectedOption('email')}
  //         >
  //           Via my email
  //         </Wizard.LargeOptionButton>

  //         {/* <Wizard.LargeOptionButton
  //           icon={WizardIcons.NewClientsIcon}
  //           caption="We'll give you a CSV with all of the questionnaire links so that you can copy and paste the links into your portal."
  //         >
  //           Via my client portal
  //         </Wizard.LargeOptionButton> */}
  //       </Wizard.OptionsColumn>
  //     </Wizard.Main>
  //   </Wizard.Scaffold>
  // )
}

function RecommendedBadge() {
  return (
    <div className="rounded-md bg-yellow-400 px-2 py-0.5 text-xs font-semibold uppercase text-yellow-800">
      Recommended
    </div>
  )
}
