import { ButtonHTMLAttributes } from 'react'
import { clsx } from 'clsx'

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isSelected?: boolean
}

export function ToggleButton({
  children,
  className,
  disabled = false,
  isSelected = false,
  ...props
}: ButtonProps) {
  return (
    <button
      className={clsx(
        'rounded p-1.5 outline-none',
        {
          'bg-transparent text-gray-700 hover:bg-stone-100 active:bg-stone-200': !isSelected,
          'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-800': isSelected
        },
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}
