// ImportInstructionsConfig.ts

import { ImportInstructions } from './common'

export const TAX_APP_IMPORT_INSTRUCTIONS: ImportInstructions[] = [
  {
    appId: 'axcess',
    estimatedTime: '5 minutes',
    steps: [
      {
        caption:
          "Open up Axcess 2023 (the prior year tax software) and select the clients you'd like to add to StanfordTax.",
        image: 'axcess/2024/axcess-instructions-1.png'
      },
      {
        caption:
          'Click “Utilities” and select “Transfer to Prosystem fx.” This does not require you to have ProSystem fx as you will not actually be transferring any data to ProSystem fx. (note: if the button is disabled, it is because you must have the “Return transfer” functional right permission assigned to use the Transfer to ProSystem fx feature.)',
        image: 'axcess/2024/axcess-instructions-2.png'
      },
      {
        caption:
          'Enter an “Office group name,” select an “Export file location” - and then click “Transfer” (note: we recommend creating a folder on your Desktop called StanfordTax and exporting to that folder)',
        image: 'axcess/2024/axcess-instructions-3.png'
      },
      {
        caption:
          'Wait for the process to complete (note: Axcess is preparing an export file at this step, and is not actually transferring returns to Prosystem fx)',
        image: 'axcess/2024/axcess-instructions-4.png'
      },
      {
        caption:
          "Once the process is complete you will see the name of the export file (all clients will be exported into the same .dat file). This is the file you'll be uploading to StanfordTax, so make note of the name.",
        image: 'axcess/2024/axcess-instructions-5.png'
      },
      {
        caption: 'Find the export file on your computer, and upload it below.'
      }
    ]
  },
  {
    appId: 'drake',
    estimatedTime: '5 minutes',
    steps: [
      {
        caption:
          'Open up Drake 2023 (the prior year tax software) and go to Tools > File Maintenance > Backup.',
        image: 'drake/drake-instructions-1.png'
      },
      {
        caption:
          'Select where you\'d like the backup to be stored on your computer, select "Selective Backup", and click "Backup."',
        image: 'drake/drake-instructions-2.png'
      },
      {
        caption:
          'Select "Customize Backup" at the top and "Tax Returns" on the left side panel. Then select the clients you\'d like to include in the backup, and click "Backup."',
        image: 'drake/drake-instructions-3.png'
      },
      {
        caption: 'After you click "Backup", Drake will generate your backup.',
        image: 'drake/drake-instructions-4.png'
      },
      {
        caption:
          'Locate the backup file on your computer (the file name will look something like D22_DB_05-26-23_15-51.7z) and upload it below.'
      }
    ]
  },
  {
    appId: 'lacerte',
    estimatedTime: '5 minutes',
    steps: [
      {
        caption:
          "Open up Lacerte 2023 (the prior year tax software) and select the clients you'd like to add to StanfordTax. Then select 'Backup' within the Client menu.",
        image: 'lacerte/lacerte-instructions-1.png'
      },
      {
        caption: 'Select where you\'d like the backup to be stored, and click "Ok"',
        image: 'lacerte/lacerte-instructions-2.png'
      },
      {
        caption: 'Drag and drop the entire DETAIL folder.'
      }
    ]
  },
  {
    appId: 'proseries',
    estimatedTime: '5 minutes',
    steps: [
      {
        caption:
          'Open up Proseries 2023 (the prior year tax software) and go to "HomeBase." Select the clients you\'d like to add to StanfordTax. You can select multiple clients at once by holding the shift key.',
        image: 'proseries/proseries-instructions-1.png'
      },
      {
        caption: 'Go to File > Client File Maintenance > Copy/Backup.',
        image: 'proseries/proseries-instructions-2.png'
      },
      {
        caption:
          'Select where you\'d like the backup to be stored on your computer, and click "Backup Clients."',
        image: 'proseries/proseries-instructions-3.png'
      },
      {
        caption:
          'Locate the folder where you chose to save the backup. The folder should contain a .23i and a .23i.cache file for each client. Drag & drop the entire folder below.'
      }
    ]
  },
  {
    appId: 'prosystem',
    estimatedTime: '5 minutes',
    steps: [
      {
        caption: 'In Office Manager, select "Backup Client Data"',
        image: 'prosystem/prosystem-instructions-1.png'
      },
      {
        caption:
          'Select "All Clients" and click "OK" (note: you\'ll be able to select specific clients in the next step).',
        image: 'prosystem/prosystem-instructions-2.png'
      },
      {
        caption:
          'Select the clients you\'d like to add to StanfordTax, and click "Add." You can also click "Add All" to add all clients.',
        image: 'prosystem/prosystem-instructions-3.png'
      },
      {
        caption: 'Click "Select Drive"',
        image: 'prosystem/prosystem-instructions-4.png'
      },
      {
        caption:
          'Select where you\'d like the backup file to be stored on your computer, and then click "OK" (note: you might have to double-click the directory name before clicking "OK")',
        image: 'prosystem/prosystem-instructions-5.png'
      },
      {
        caption:
          'If you have previous backups stored on your computer for the same clients, you\'ll see a popup like the one below. Select "Overwrite All Files."',
        image: 'prosystem/prosystem-instructions-6.png'
      },
      {
        caption:
          'In addition to the backup file, Prosystem generates a PDF report of the backup. Choose any name you\'d like for the report, and click "Save."',
        image: 'prosystem/prosystem-instructions-7.png'
      },
      {
        caption:
          'Locate the folder where you chose to save the backup. The folder should contain a .ZIP for each client, and a CLNTBKUP.001 file. Drag & drop the entire folder below.'
      }
    ]
  },
  {
    appId: 'ultratax',
    estimatedTime: '5 minutes',
    steps: [
      {
        caption:
          'Open up UltraTax 2023 (the prior year tax software) and select "Backup" within the File menu.',
        image: 'ultratax/ultratax-instructions-1.png'
      },
      {
        caption:
          'Select the clients you\'d like to add to StanfordTax, and click "Select." You can also click "Select All" if you\'d like to add all clients.',
        image: 'ultratax/ultratax-instructions-2.png'
      },
      {
        caption: "Select where you'd like the backup to be stored.",
        image: 'ultratax/ultratax-instructions-3.png'
      },
      {
        caption: 'Uncheck the "Encrypt client backup" checkbox.',
        image: 'ultratax/ultratax-instructions-4.png'
      },
      {
        caption: 'Click "Backup"',
        image: 'ultratax/ultratax-instructions-5.png'
      },
      {
        caption: 'Click "Done"',
        image: 'ultratax/ultratax-instructions-6.png'
      },
      {
        caption:
          'Locate the folder where you chose to save the backup. The folder should contain within it a folder for each client, and a CltBKLst.WT3 file. Drag & drop the entire folder below (with the CltBKLst.WT3 file).'
      }
    ]
  },
  {
    appId: 'proconnect',
    estimatedTime: '3 minutes (once you receive the Lacerte Data File)',
    steps: [
      {
        caption:
          "Our integration with ProConnect uses what's called a “Lacerte Data File.” To get this file, contact ProConnect support by requesting a phone callback through their help portal (https://ito.intuit.com/app/protax/conversation) or by calling them directly at 1-800-200-7599. They will prepare the Lacerte file for you and send you a link to download the file. Note: they will tell you that it takes 4-6 weeks to prepare the file, but most StanfordTax users receive it within one week.",
        image: 'proconnect/pro-connect-call-icon.png'
      },
      {
        caption: 'Upload the Lacerte Data File below'
      }
    ]
  }
]
