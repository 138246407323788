import { envVar } from '@env'
import { useAuthState, useSetPath } from '@features/app-hooks'
import { authModule, logout } from '@features/auth/auth-module'
import { Link } from '@features/link'
import { useProcess } from '@st/redux'
import { cn } from '@util/cn'

export function LoginLogoutLink({ className }: { className?: string }) {
  const { status } = useAuthState()

  if (status == 'loggedIn') {
    return <LogoutLink className={className} />
  }

  return (
    <Link className="active" href={{ name: 'login' }}>
      Log In
    </Link>
  )
}

function LogoutLink({ className }: { className?: string }) {
  const auth = useProcess(authModule)
  const setLocation = useSetPath()

  async function onClickLogout() {
    await auth.send(logout(undefined))
    if (envVar('NEXT_PUBLIC_HOMEPAGE_URL')) {
      window.location.href = envVar('NEXT_PUBLIC_HOMEPAGE_URL')!
    } else {
      setLocation('/')
    }
  }

  return (
    <a
      className={cn('logout-button', className)}
      style={{ cursor: 'pointer' }}
      onClick={onClickLogout}
    >
      Log out
    </a>
  )
}
