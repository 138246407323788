import { ReactNode } from 'react'

export function FullPageCard(props: { children?: ReactNode; footer?: ReactNode }) {
  return (
    <div className="flex h-screen w-screen flex-col justify-center bg-gray-100 py-12">
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="mx-auto mt-10 max-h-[600px] w-full max-w-[480px]">
          {props.children && (
            <div className="mx-4 my-8 min-h-0 overflow-y-auto bg-white px-6 py-12 shadow-md sm:rounded-lg sm:px-12">
              {props.children}
            </div>
          )}

          {props.footer ? <div className="mt-10">{props.footer}</div> : null}
        </div>
      </div>
    </div>
  )
}
