import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole
} from '@floating-ui/react'
import { clsx } from 'clsx'
import { ReactNode, createContext, useContext, useState } from 'react'

import { DotsThreeIcon } from '@st/theme/src/icons/16x16'

type ContextMenuProps = { children: ReactNode }

const MenuContext = createContext({ closeMenu: () => {} })

export function ContextMenu({ children }: ContextMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    middleware: [offset({ crossAxis: -8 }), flip(), shift()],
    whileElementsMounted: autoUpdate
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'menu' })

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps({
          'aria-label': 'Menu',
          'className': clsx(
            isOpen ? 'visible' : 'invisible',
            'ml-auto h-8 w-6 outline-none group-hover:visible'
          )
        })}
      >
        <DotsThreeIcon aria-hidden="true" role="img" />
      </button>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps({
              className:
                'flex flex-col gap-y-0.5 rounded border border-solid border-stone-300 bg-white py-2 shadow outline-none'
            })}
          >
            <MenuContext.Provider value={{ closeMenu: () => setIsOpen(false) }}>
              {children}
            </MenuContext.Provider>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

export function PrimaryContextMenu({ children }: ContextMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom-start',
    middleware: [offset({ crossAxis: -8 }), flip(), shift()],
    whileElementsMounted: autoUpdate
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'menu' })

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss, role])

  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps({
          'aria-label': 'Menu',
          'className': clsx('h-8 w-6 outline-none')
        })}
      >
        <DotsThreeIcon role="img" />
      </button>
      {isOpen && (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps({
              className:
                'flex flex-col gap-y-0.5 rounded border border-solid border-stone-300 bg-white py-2 shadow outline-none'
            })}
          >
            <MenuContext.Provider value={{ closeMenu: () => setIsOpen(false) }}>
              {children}
            </MenuContext.Provider>
          </div>
        </FloatingPortal>
      )}
    </>
  )
}

type ContextMenuItemProps = {
  icon?: ReactNode
  children: ReactNode
  onClick?: () => void
}

export function ContextMenuItem({ icon, children, onClick }: ContextMenuItemProps) {
  const { closeMenu } = useContext(MenuContext)

  return (
    <div
      role="menuitem"
      onClick={(e) => {
        e.stopPropagation()
        onClick?.()
        closeMenu()
      }}
      className={clsx(
        'flex min-w-12 cursor-pointer items-center gap-2 px-2 pb-[3px] pt-0.5 text-sm text-[#2B2C38] outline-none',
        'hover:border-l-[1px] hover:border-solid hover:border-[#11153666] hover:bg-[#9091A233] hover:pl-[7px]'
      )}
    >
      {icon && <div className="flex w-4 flex-row items-center justify-center">{icon}</div>}
      {children}
    </div>
  )
}
