import { Anchor, Offset, Rect, anchored } from '@st/util/geom'
import { MouseEvent, ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react'
export { Anchor } from '@st/util/geom'

type Props = {
  children: ReactNode
  popup?: ReactNode
  popupAnchor?: Anchor
  childAnchor?: Anchor
  popupOffset?: Partial<Offset>
  onClickBarrier?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}
export function Popover({
  children,
  popup,
  popupAnchor = Anchor.topRight,
  popupOffset,
  childAnchor = Anchor.bottomRight,
  onClickBarrier,
  onMouseEnter,
  onMouseLeave
}: Props) {
  const [popoverPosition, setPopoverPosition] = useState<{
    left: number
    top: number
  }>({ left: 0, top: 0 })

  const childRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const isOpen = popup != undefined

  function handleClickBarrier(e: MouseEvent) {
    e.stopPropagation()
    e.preventDefault()
    onClickBarrier?.()
  }

  function updatePositions() {
    if (!childRef.current || !popoverRef.current) {
      return
    }

    const childRect = getElRect(childRef.current)
    const popoverRect = getElRect(popoverRef.current)

    const desiredPopoverRect = anchored({
      follower: popoverRect,
      followerAnchor: popupAnchor,
      followerOffset: popupOffset,
      target: childRect,
      targetAnchor: childAnchor
    })

    setPopoverPosition({
      left: desiredPopoverRect.x,
      // top-left of screen is (0, 0), going right is +x, going down is -y
      // we're basically in the bottom right quadrant of an x,y chart
      top: desiredPopoverRect.y
    })
  }

  useEffect(() => {
    if (isOpen) {
      updatePositions()
    }
  }, [isOpen, popupAnchor, childAnchor, childRef.current, popoverRef.current])

  return (
    <>
      <div
        className="popover__child"
        ref={childRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {children}
      </div>

      {popup ? (
        <div
          className="popover__element"
          ref={popoverRef}
          style={{
            position: 'fixed',
            zIndex: 5000,
            left: popoverPosition.left,
            top: popoverPosition.top
          }}
        >
          {popup}
        </div>
      ) : null}

      {popup && onClickBarrier ? (
        <div
          className="popover__barrier"
          style={{
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            position: 'fixed',
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, 0)'
          }}
          onClick={handleClickBarrier}
        />
      ) : null}
    </>
  )
}

function getElRect(el: HTMLDivElement): Rect {
  const rect = el.getBoundingClientRect()
  return { x: rect.x, y: rect.y, width: rect.width, height: rect.height }
}
