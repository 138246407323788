import { ReduxStore } from '@st/redux'
import { authModule } from './auth/auth-module'

import * as Sentry from '@sentry/browser'
import { JsonMap } from '@st/util/json-value'
import { truncate } from '@st/util/truncate'
import { routeModule } from './route-module'

export const appStore = new ReduxStore({
  name: 'st',
  modules: [routeModule, authModule],
  onEvent: (event, context) => {
    Sentry.addBreadcrumb({
      type: 'info',
      level: 'info',
      category: 'redux',
      message: event.type,
      data: truncate(event as JsonMap, {
        maxArrayLength: 10,
        maxKeys: 10,
        maxStringLength: 100
      }) as any
    })
  }
})
