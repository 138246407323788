import { FormationDocument } from '@features/formation-viewer/formation-document'
import { FormConfig } from '@st/folder/ui-config'
import { PDFElementDocViewer, ReadWriteProvider } from '@st/pdf'
import { getFormConfig } from '@st/tax-folder'
import { toStr } from '@st/util/cast'
import { parseMimeType } from '@util/mime'
import { usePromise } from '@util/promise'
import {
  useFieldDecoration,
  useInputsWrite,
  useRead,
  useReadFieldComment,
  useReadInputs
} from './st-folder-hooks'

type Props = {
  mimeType: string
}
export function STFormationViewer({ mimeType }: Props) {
  const mime = parseMimeType(mimeType)
  const structureId = toStr(mime?.parameters.structureId)

  const configResp = usePromise<FormConfig>(() => {
    return getFormConfig(structureId).then((f) => {
      return f!
    })
  }, [structureId])

  const formConfig = configResp.status == 'fulfilled' ? configResp.value : undefined

  if (!formConfig) {
    return null
  }

  return <FormationViewerInner formConfig={formConfig} />
}

function FormationViewerInner({ formConfig }: { formConfig: FormConfig }) {
  return (
    <ReadWriteProvider
      useRead={useRead}
      useReadInputs={useReadInputs}
      useFieldDecoration={useFieldDecoration}
      useWrite={useInputsWrite}
      useReadFieldComment={useReadFieldComment}
    >
      <PDFElementDocViewer mode="html">
        <FormationDocument config={formConfig!} />
      </PDFElementDocViewer>
    </ReadWriteProvider>
  )
}
