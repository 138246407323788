import { offset, useFloating } from '@floating-ui/react-dom'
import { clsx } from 'clsx'
import { ReactNode, useState } from 'react'
import { Tip } from './Tip'

export const Checklist = {
  Table({ children }: { children: ReactNode }) {
    return (
      <table className="w-full table-fixed divide-y divide-gray-300 overflow-hidden font-sans">
        <thead>
          <tr>
            <th
              scope="col"
              className="w-8 py-3.5 pl-4 text-left text-xs font-thin uppercase text-gray-500"
            ></th>
            <th
              scope="col"
              className="w-52 py-3.5 pr-3 text-left text-xs font-thin uppercase text-gray-500"
            >
              Name
            </th>
            <th
              scope="col"
              className="w-64 py-3.5 text-left text-xs font-thin uppercase text-gray-500"
            >
              Note
            </th>
            <th
              scope="col"
              className="w-48 py-3.5 text-left text-xs font-thin uppercase text-gray-500"
            >
              Link
            </th>
            <th
              scope="col"
              className="w-20 py-3.5 pl-4 pr-3 text-left text-xs font-thin uppercase text-gray-500"
            ></th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">{children}</tbody>
      </table>
    )
  },

  Row({ children, checked }: { children: ReactNode; checked?: boolean }) {
    return (
      <tr
        className={clsx(
          'group h-9',
          !checked && 'hover:bg-stone-100',
          checked && 'bg-blue-100 hover:bg-blue-200'
        )}
      >
        {children}
      </tr>
    )
  },

  CheckboxCell(props: { checked: boolean; onChange: (newValue: boolean) => void }) {
    return (
      <td className="relative whitespace-nowrap px-2 py-2 align-middle text-sm text-gray-500">
        <input
          type="checkbox"
          className="border-gray-300 px-2"
          checked={props.checked}
          onChange={(e) => props.onChange(e.target.checked)}
        />
      </td>
    )
  },

  MainCell({ children }: { children: ReactNode }) {
    return (
      <td className="whitespace-nowrap py-2 pl-0 pr-3 text-base font-semibold text-gray-900">
        <div className="flex flex-row items-center gap-2">{children}</div>
      </td>
    )
  },

  NoteCell({ children }: { children: ReactNode }) {
    return (
      <td className="whitespace-nowrap py-2 pl-0 pr-3 text-base text-gray-700">
        <div className="truncate">{children}</div>
      </td>
    )
  },

  LinkCell({ children }: { children?: ReactNode }) {
    return (
      <td className="gap-2 whitespace-nowrap py-2 text-sm text-gray-500">
        <div className="flex flex-row items-center gap-2">{children}</div>
      </td>
    )
  },

  ActionGroup({ children }: { children: ReactNode }) {
    return (
      <td className="gap-2 whitespace-nowrap px-3 py-2 text-sm text-gray-500">
        <div className="invisible flex flex-row items-center gap-2 group-hover:visible">
          {children}
        </div>
      </td>
    )
  },

  Button({ children, tip, onClick }: { children?: ReactNode; tip?: string; onClick: () => void }) {
    return (
      <Tip title={tip}>
        <button
          className="relative h-6 max-h-[40px] w-6 max-w-[40px] select-none rounded-lg text-center align-middle font-sans text-xs font-medium uppercase text-gray-900 transition-all hover:bg-gray-900/10 active:bg-gray-900/20 disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          type="button"
          onClick={onClick}
        >
          <span className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
            {children}
          </span>
        </button>
      </Tip>
    )
  },

  Footer(props: { children: ReactNode }) {
    return (
      <tr className="h-9">
        <td colSpan={5}>{props.children}</td>
      </tr>
    )
  }
}
