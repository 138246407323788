import { useProcess, useProcessState } from '@st/redux'
import { Button, Dialog, DialogButtons, TagChip } from '@st/theme'
import { SortDirection, SortState } from '@st/util/sort'
import { formatISO8601DateTime } from '@st/util/time'
import { downloadWorkpaper, downloadZip } from './st-download-document-handler'
import {
  DocumentItem,
  DownloadError,
  DownloadListSortColumn,
  selDownloadableDocumentList,
  STDownloadFolderMessage,
  stDownloadFolderModule,
  STDownloadFolderState
} from './st-download-folder-module'
import { andWords, inflect } from '@st/util/inflect'
import clsx from 'clsx'

export function STDownloadFolderDialog({
  folderId,
  onClose
}: {
  folderId: string
  onClose: () => void
}) {
  const stDownloadFolder = useProcess(stDownloadFolderModule, { folderId: folderId })
  const state = useProcessState(stDownloadFolderModule)

  return (
    <STDownloadFolderDialogView
      state={state}
      send={stDownloadFolder.send}
      onClose={onClose}
      onClickDownloadWorkpaper={() => stDownloadFolder.send(downloadWorkpaper(undefined))}
      onClickDownloadZip={() => stDownloadFolder.send(downloadZip(undefined))}
    />
  )
}

export function STDownloadFolderDialogView({
  state,
  send,
  onClose,
  onClickDownloadZip,
  onClickDownloadWorkpaper
}: {
  state: STDownloadFolderState
  send: (message: STDownloadFolderMessage) => void
  onClose: () => void
  onClickDownloadZip: () => void
  onClickDownloadWorkpaper: () => void
}) {
  const items = selDownloadableDocumentList(state)

  const selectedDocumentIds = state.selectedDocumentIds
  const allSelected = state.selectedDocumentIds.length == state.downloadState?.documents.length

  return (
    <Dialog
      title="Download workpaper"
      className="sm:max-w-6xl"
      subtitle={
        state.lastErrors ? (
          <div className="text-red-500">
            Failed to download {inflect(state.lastErrors.length, 'document')}. The failed documents
            automatically deselected.
          </div>
        ) : undefined
      }
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={state.download != undefined}
            onClick={onClickDownloadZip}
          >
            {state.download?.format == 'zip' ? 'Generating zip' : 'Download zip'}
          </Button>
          <Button
            variant="primary"
            disabled={state.download != undefined}
            onClick={onClickDownloadWorkpaper}
          >
            {state.download?.format == 'pdf' ? 'Generating workpaper' : 'Download workpaper'}
          </Button>
        </DialogButtons>
      }
    >
      <FileDownloadsTable
        sortState={state.sortState}
        items={items}
        selectedDocumentIds={selectedDocumentIds}
        allSelected={allSelected}
        errors={state.lastErrors}
        send={send}
      />
    </Dialog>
  )
}

function FileDownloadsTable({
  sortState,
  items,
  selectedDocumentIds,
  allSelected,
  errors,
  send
}: {
  sortState: SortState<DownloadListSortColumn> | undefined
  items: DocumentItem[]
  selectedDocumentIds: string[]
  allSelected: boolean
  errors: DownloadError[] | undefined
  send: (message: STDownloadFolderMessage) => void
}) {
  return (
    <table className="w-full table-fixed divide-y divide-gray-300">
      <thead>
        <tr>
          <th className="w-8">
            <input
              type="checkbox"
              className="h-3 w-3"
              checked={allSelected}
              onChange={() => send({ type: 'toggleSelectAll' })}
            />
          </th>
          <th
            className="cursor-pointer px-2 py-2 text-left text-xs font-normal uppercase text-gray-500"
            onClick={() => send({ type: 'toggleSort', column: 'name' })}
          >
            File name {sortState?.column == 'name' && <SortArrow direction={sortState.direction} />}
          </th>
          <th className="w-32 py-2 text-left text-xs font-normal uppercase text-gray-500">
            Category
          </th>
          <th
            className="w-52 cursor-pointer py-2 text-left text-xs font-normal uppercase text-gray-500"
            onClick={() => send({ type: 'toggleSort', column: 'uploadedAt' })}
          >
            Uploaded at
            {sortState?.column == 'uploadedAt' && <SortArrow direction={sortState.direction} />}
          </th>
          <th
            className="w-32 cursor-pointer py-2 text-left text-xs font-normal uppercase text-gray-500"
            onClick={() => send({ type: 'toggleSort', column: 'exportedAt' })}
          >
            Status{' '}
            {sortState?.column == 'exportedAt' && <SortArrow direction={sortState.direction} />}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 overflow-y-auto">
        {items.map((item) => {
          return (
            <tr key={item.document.id}>
              <td className="text-center">
                <input
                  type="checkbox"
                  className="h-3 w-3"
                  checked={selectedDocumentIds.includes(item.document.id)}
                  onChange={() => send({ type: 'toggleSelection', documentId: item.document.id })}
                />
              </td>
              <td className="text-nowrap px-2 py-2 text-base text-gray-900">
                <div
                  className={clsx('grow overflow-hidden text-ellipsis text-nowrap', {
                    'text-red-500': errors?.find((e) => e.documentId == item.document.id)
                  })}
                >
                  {item.document.name}
                </div>
              </td>
              <td className="py-2 text-base text-gray-700">{item.documentType.name}</td>
              <td className="py-2 text-base text-gray-700">
                {item.document.uploadedAt && formatISO8601DateTime(item.document.uploadedAt)}
              </td>
              <td>
                <div className="flex flex-row">
                  {item.document.exportedAt ? <TagChip color="gray">Downloaded</TagChip> : null}
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function SortArrow({ direction }: { direction: SortDirection }) {
  return <span className="ml-1 text-xs text-gray-500">{direction == 'asc' ? '▲' : '▼'}</span>
}
