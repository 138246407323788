import { useEffect } from 'react'

export type Keybindings = {
  [keys: string]: () => void
}

type Shortcut = {
  matches: (event: KeyboardEvent) => boolean
  handler: () => void
}

function createShortcut(keys: string, handler: () => void): Shortcut {
  const MOD = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'Meta' : 'Control'

  const [key, ...modifiers] = keys
    .split('+')
    .reverse()
    .map((mod) => mod.replace('$mod', MOD))

  return {
    matches: (event) => {
      return (
        modifiers.every((mod) => event.getModifierState(mod)) &&
        (event.key === key || event.code === key)
      )
    },
    handler: handler
  }
}

export function registerShortcuts(
  target: Window | HTMLElement,
  keyBindings: Keybindings
): () => void {
  const shortcuts = Object.entries(keyBindings).map(([keys, handler]) =>
    createShortcut(keys, handler)
  )
  function keybindingsHandler(event: Event) {
    if (!(event instanceof KeyboardEvent)) {
      return
    }

    const matchingShortcut = shortcuts.find((s) => s.matches(event))
    if (matchingShortcut) {
      matchingShortcut.handler()
    }
  }

  target.addEventListener('keydown', keybindingsHandler)

  return () => {
    return target.removeEventListener('keydown', keybindingsHandler)
  }
}

export function useKeyboardShortcuts(keyBindings: Keybindings) {
  useEffect(() => registerShortcuts(window, keyBindings), [])
}
