import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { GetOrCreateFolderResponse } from '@st/sdk'
import { buildSchemaId, CURRENT_TAX_YEAR, RETURN_TYPES, ReturnType, TaxYear } from '@st/tax-folder'
import { isValidEmail } from '@st/util/email'
import { isNotEmpty } from '@st/util/json-value'

export type STFolderCreateOneByOneState = {
  status: 'idle' | 'running'
  organizationId: string
  form: {
    year: TaxYear
    returnType: ReturnType
    firstName: string
    lastName: string
    corporationName: string
    email: string
  }
  confirmDialogVisible: boolean
  lastResponse: GetOrCreateFolderResponse | undefined
}

export type STFolderCreateOneByOneMessage =
  | { type: 'setField'; field: keyof STFolderCreateOneByOneState['form']; value: any }
  | { type: 'addClient'; confirmOnRelatedFolders: boolean }
  | { type: 'closeConfirmDialog' }
  | SDKResponse

type STFolderCreateOneByoneSend = {
  sdk: SDKMessage
}

type STFolderCreateOneByOneInit = {
  organizationId: string
}

export const stFolderCreateOneByOneModule = defineModule<
  STFolderCreateOneByOneState,
  STFolderCreateOneByOneMessage,
  STFolderCreateOneByOneInit,
  STFolderCreateOneByoneSend
>({
  name: 'stFolderCreateOneByOne',
  init: ({ organizationId }) => ({
    status: 'idle',
    form: {
      year: CURRENT_TAX_YEAR,
      returnType: RETURN_TYPES[0],
      firstName: '',
      lastName: '',
      corporationName: '',
      email: ''
    },
    confirmDialogVisible: false,
    lastResponse: undefined,
    organizationId
  }),
  handle: (state, message) => {
    switch (message.type) {
      case 'setField':
        return { ...state, form: { ...state.form, [message.field]: message.value } }
      case 'addClient':
        return [
          { ...state, status: 'running', lastResponse: undefined },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/getOrCreateFolder',
                organizationId: state.organizationId,
                schemaId: buildSchemaId(state.form.year, state.form.returnType),
                email: state.form.email,
                firstName: state.form.firstName,
                lastName: state.form.lastName,
                corporationName: state.form.corporationName,
                confirmOnRelatedFolders: message.confirmOnRelatedFolders
              }
            }
          }
        ]
      case 'closeConfirmDialog':
        return { ...state, confirmDialogVisible: false }
      case 'response':
        if (message.operation.type === 'folders/getOrCreateFolder') {
          const response = message.operation.response
          switch (response.status) {
            case 'created':
              return {
                ...state,
                status: 'idle',
                confirmDialogVisible: false,
                form: {
                  ...state.form,
                  email: '',
                  firstName: '',
                  lastName: '',
                  corporationName: ''
                },
                lastResponse: response
              }
            case 'needs_confirmation':
              // next click will add it
              return {
                ...state,
                status: 'idle',
                confirmDialogVisible: true,
                lastResponse: response
              }
          }
        }
        return state
      default:
        return state
    }
  }
})

export function selRelatedFolders(state: STFolderCreateOneByOneState) {
  return state.lastResponse?.relatedFolders ?? []
}

export function selCanAddClient(state: STFolderCreateOneByOneState) {
  return (
    state.status == 'idle' &&
    isValidEmail(state.form.email) &&
    isNotEmpty(state.form.firstName) &&
    isNotEmpty(state.form.lastName)
  )
}
