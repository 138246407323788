import { HeaderNavigation } from '@theme'
import { LoginLogoutLink } from './login-logout-link'

export function OrganizationLoggedOutHeader() {
  const homeHref = useMarketingPageHref()
  return (
    <HeaderNavigation backHref={homeHref}>
      <LoginLogoutLink />
    </HeaderNavigation>
  )
}

/**
 * Get the link to the marketing home page
 * If the app is hosted at app.<something>.com, the marketing home page is <something>.com
 */
function useMarketingPageHref() {
  const url = new URL(window.location.href)
  return url.origin.replace('//app.', '//')
}
