export type Progress = {
  value: number
  max: number
}

export const Progress = {
  zero: { value: 0, max: 0 } satisfies Progress,
  sum: (progresses: Progress[]): Progress => {
    return progresses.reduce(
      (acc, cur) => {
        return { value: acc.value + cur.value, max: acc.max + cur.max }
      },
      { max: 0, value: 0 }
    )
  },
  toNumber(progress: Progress): number {
    if (progress.max === 0) return 0
    return progress.value / progress.max
  },
  toString(progress: Progress): string {
    const num = Progress.toNumber(progress)
    return (num * 100).toFixed(0).toString() + '%'
  }
}
