export function CheckCircle(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#a)">
        <circle cx="8" cy="8" r="8" fill="currentColor" />
        <path
          fill="#fff"
          d="M11.866 5.791 6.89 10.867a.436.436 0 0 1-.623 0L4.133 8.692A.463.463 0 0 1 4 8.367a.477.477 0 0 1 .128-.328.444.444 0 0 1 .313-.137.43.43 0 0 1 .315.132l1.822 1.857 4.666-4.759a.436.436 0 0 1 .724.157.477.477 0 0 1-.102.502Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
