import { Cache } from './interface'

type CachedRepoOpts<K, V> = {
  cache: Cache<V>
  fetch: (key: K) => Promise<V>
  cacheKey: (key: K) => string
}

/**
 * A class that memoizes function calls with input {@link K} and output {@link V}
 * The first time something is retrieved, it'll be fetched using the {@link CachedRepoOpts.fetch}
 */
export class CachedRepo<K, V> {
  constructor({ cache, fetch, cacheKey }: CachedRepoOpts<K, V>) {
    this.cache = cache
    this.fetch = fetch
    this.cacheKey = cacheKey
  }

  private cache: Cache<V>
  private fetch: (key: K) => Promise<V>
  private cacheKey: (key: K) => string

  private promiseCache: Record<string, Promise<V>> = {}

  get = async (key: K): Promise<V> => {
    const cKey = this.cacheKey(key)

    const value = await this.cache.get(cKey)
    if (value !== undefined) return value

    if (cKey in this.promiseCache) {
      return this.promiseCache[cKey]
    }

    const promise = this.fetch(key)
    this.promiseCache[cKey] = promise

    promise
      .then((v) => this.cache.set(cKey, v))
      .finally(() => delete this.promiseCache[cKey])

    return promise
  }
}
