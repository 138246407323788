import { useProcess } from '@st/redux/src/process-hooks'

import { annotationLayerModule, AnnotationMessage } from '@features/st-annotation-layer'
import { ProcessSend, useProcessState } from '@st/redux'
import { DocumentField } from '@st/sdk'
import { DocumentFieldCard } from '@st/theme'
import { asc, sort } from '@st/util/sort'
import { stFolderModule } from './st-folder-module'

export function STAnnotationsPanel() {
  const annotationLayer = useProcess(annotationLayerModule)
  const annotationType = useProcessState(annotationLayerModule, (s) => s.sidebarType)
  const selectedAnnotId = useProcessState(annotationLayerModule, (s) => s.activeAnnotId)
  const selectedDocumentFields = useProcessState(stFolderModule, (s) => {
    const selectedDocId = s.selectedDocId
    const selectedDoc = s.folderState?.documents.find((d) => d.id == selectedDocId)
    return sort(
      selectedDoc?.fields ?? [],
      asc((a) => a.bounds?.y ?? Number.MAX_SAFE_INTEGER)
    )
  })

  switch (annotationType) {
    case 'field':
      return (
        <AnnotationFieldsPanel
          fields={selectedDocumentFields}
          selectedAnnotId={selectedAnnotId}
          send={annotationLayer.send}
        />
      )
    default:
      return null
  }
}

function AnnotationFieldsPanel(props: {
  fields: DocumentField[]
  selectedAnnotId: string | undefined
  send: ProcessSend<AnnotationMessage>
}) {
  return (
    <div className="flex flex-col divide-y divide-gray-200 overflow-x-hidden overflow-y-scroll border-l border-stone-400">
      {props.fields.map((field) => {
        return (
          <DocumentFieldCard
            key={field.key}
            title={field.key}
            body={field.value as any}
            selected={props.selectedAnnotId == field.key}
            onClick={() => {
              if (!field.bounds) {
                return
              }
              props.send({
                type: 'selectAnnot',
                annotId: field.key,
                scrollIntoView: true
              })
            }}
          />
        )
      })}
    </div>
  )
}
