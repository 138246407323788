export function InlineColorPicker({
  options,
  value,
  onChange
}: {
  options: string[]
  value: string
  onChange: (v: string) => void
}) {
  return (
    <div className="flex flex-row gap-3">
      {options.map((opt) => {
        return (
          <div
            key={opt}
            className="cursor-pointer"
            style={{
              background: opt,
              width: 24,
              height: 24,
              borderRadius: '50%',
              border: opt == value ? `2px solid white` : '2px solid white',
              boxShadow: opt == value ? `0 0 0 2px black` : 'none'
            }}
            onClick={() => onChange(opt)}
          />
        )
      })}
    </div>
  )
}
