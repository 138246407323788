const PencilSimple: IconComponent = (props) => (
  <svg
    fill="currentColor"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M10.6552 3.43921L8.56079 1.34436C8.49114 1.2747 8.40845 1.21944 8.31745 1.18174C8.22644 1.14404 8.1289 1.12463 8.03039 1.12463C7.93189 1.12463 7.83435 1.14404 7.74334 1.18174C7.65234 1.21944 7.56965 1.2747 7.5 1.34436L1.71985 7.12499C1.6499 7.19438 1.59444 7.27698 1.5567 7.368C1.51896 7.45901 1.49969 7.55662 1.5 7.65514V9.74999C1.5 9.9489 1.57902 10.1397 1.71967 10.2803C1.86033 10.421 2.05109 10.5 2.25 10.5H4.34485C4.44338 10.5003 4.54098 10.481 4.632 10.4433C4.72301 10.4055 4.80561 10.3501 4.875 10.2801L10.6552 4.49999C10.7248 4.43034 10.7801 4.34766 10.8178 4.25665C10.8555 4.16565 10.8749 4.0681 10.8749 3.9696C10.8749 3.87109 10.8555 3.77355 10.8178 3.68255C10.7801 3.59154 10.7248 3.50885 10.6552 3.43921ZM4.34485 9.74999H2.25V7.65514L6.375 3.53015L8.46985 5.62499L4.34485 9.74999ZM9 5.09436L6.90516 2.99999L8.03016 1.87499L10.125 3.96936L9 5.09436Z" />
  </svg>
)

export { PencilSimple }
