import { useAuthState } from '@features/app-hooks'
import { ReactNode } from 'react'

/**
 * A top app-level component that redirects to a login page if the user is not authenticated.
 * If the user is authenticated, the {@link props} are rendered as normal
 */
type Props = {
  /**
   * The screen to show when the user needs to login
   */
  loginScreen: ReactNode

  /**
   * The element to show when the auth state is unknown and we are in a loading state
   * Defaults to a blank screen
   */
  loadingScreen?: ReactNode

  children: ReactNode
}
export function RequireLogin({ loadingScreen, loginScreen, children }: Props) {
  const { status } = useAuthState()

  switch (status) {
    case 'loggedIn':
      return <>{children}</>
    case 'loggedOut':
    case 'loggingIn':
    case 'signingUp':
    case 'error':
      return <>{loginScreen}</>
    case 'unknown':
      return <>{loadingScreen ?? loginScreen}</>
    default:
      return null
  }
}
