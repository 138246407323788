import { useAppDeps } from '@features/app-deps-provider'
import { assetURL } from '@features/app-hooks'
import { useOrganization } from '@features/organizations'
import { ProcessSend, useProcess, useProcessState } from '@st/redux'
import { FolderImportJob } from '@st/sdk'
import { TAX_APP_IMPORT_INSTRUCTIONS, TAX_APPS_IMPORT_OPTIONS } from '@st/tax-folder'
import {
  Button,
  DocumentDropZone,
  DropHere,
  DropProgress,
  ProgressStepIndicator,
  Wizard,
  WizardIcons
} from '@st/theme'
import { match } from 'ts-pattern'
import {
  createAndUploadFolderImport,
  CreateFolderImportMode,
  stCreateFolderImportModule,
  STFolderImportMessage,
  STFolderImportState,
  STFolderImportTaxAppInstructions,
  STFolderImportUploadFile
} from './st-create-folder-import-module'
import { STFolderCreateOneByOnePage } from './st-folder-create-one-by-one-page'
import { STQuestionnaireMagicLinksPage } from './st-questionnaire-magic-links-page'

type Props = {
  mode: CreateFolderImportMode
  onClose: () => void
  onImportSubmitted: (folderImport: FolderImportJob) => void
}
/**
 * Import is run in 2 steps:
 * 1. Create the import (including creating an import with a unique id and uploading a source backup file)
 * 2. Create folders from the import
 *
 * This page is responsible for step 1.
 */
export function STCreateFolderImportPage({ mode, onClose, onImportSubmitted }: Props) {
  const folderImport = useProcess(stCreateFolderImportModule, { mode })
  const state = useProcessState(stCreateFolderImportModule)
  const organization = useOrganization()

  switch (state.step) {
    case 'newOrReturning':
      return (
        <STFolderImportSelectNewOrReturning
          state={state}
          send={folderImport.send}
          onClose={onClose}
        />
      )
    case 'taxAppSelector':
      return (
        <STFolderImportTaxAppSelector state={state} send={folderImport.send} onClose={onClose} />
      )
    case 'newClientsImportMethodSelector':
      return (
        <SelectAddNewClientsMethod
          state={state}
          send={folderImport.send}
          onBack={() => {
            folderImport.send({ type: 'selectNewOrReturning' })
          }}
          onClose={onClose}
        />
      )
    case 'taxAppInstructions':
      return (
        <TaxAppUploadBackupFileInstructionsPage
          state={state}
          send={folderImport.send}
          onClose={onClose}
        />
      )
    case 'uploadImportFile':
    case 'preparingFolderImportFile':
    case 'uploadingFolderImportFile':
      return (
        <UploadFolderImport
          state={state}
          send={folderImport.send}
          onImportSubmitted={onImportSubmitted}
          onClose={onClose}
        />
      )
    case 'copyMagicLink':
      return (
        <STQuestionnaireMagicLinksPage
          organization={organization}
          onBack={() => folderImport.send({ type: 'selectNewClients' })}
          onClose={onClose}
        />
      )
    case 'createOneByOne':
      return (
        <STFolderCreateOneByOnePage
          onBack={() => folderImport.send({ type: 'selectNewClients' })}
          onClose={onClose}
        />
      )
  }
}

type PageProps = {
  state: STFolderImportState
  send: (message: STFolderImportMessage) => void
  onBack?: () => void
  onClose?: () => void
}
function STFolderImportSelectNewOrReturning({ state, send, onClose }: PageProps) {
  return (
    <Wizard.Scaffold breadcrumb="Add clients" onClose={onClose}>
      <Wizard.Main
        title={
          state.mode == 'preview'
            ? 'Do you want to preview a questionnaire for a new client or returning client?'
            : 'Do you want to add new clients or returning clients?'
        }
        subtitle={
          state.mode == 'preview'
            ? "You can preview questionnaires for as many clients as you'd like for free. Your clients won't receive anything unless you expicitly send them the questionnaire."
            : "You can add as many clients as you'd like for free. You'll only have to pay for clients who use the questionnaire."
        }
      >
        <Wizard.OptionsColumn>
          <Wizard.LargeOptionButton
            icon={WizardIcons.ReturningClientsIcon}
            caption="Select this option if the clients you want to add are in your tax software."
            onClick={() => send({ type: 'selectReturningClients' })}
          >
            Returning clients
          </Wizard.LargeOptionButton>

          <Wizard.LargeOptionButton
            icon={WizardIcons.NewClientsIcon}
            caption="Select this option if the clients you want to add are NOT in your tax software."
            onClick={() => send({ type: 'selectNewClients' })}
          >
            New clients
          </Wizard.LargeOptionButton>
        </Wizard.OptionsColumn>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function STFolderImportTaxAppSelector({ send, onClose }: PageProps) {
  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={() => send({ type: 'selectNewOrReturning' })}>
            Back
          </Button>
        </Wizard.BottomNav>
      }
      onClose={onClose}
    >
      <Wizard.Main
        title="Select your tax software"
        subtitle="We use prior year tax return data to generate personalized questionnaires for each client."
      >
        <Wizard.OptionsGrid>
          {TAX_APPS_IMPORT_OPTIONS.map((app) => {
            return (
              <Wizard.OptionButton
                key={app.id}
                icon={<img className="size-14" src={`/logos/${app.id}.png`} />}
                onClick={() => send({ type: 'selectedTaxApp', taxApp: app })}
              >
                {app.name}
              </Wizard.OptionButton>
            )
          })}

          <Wizard.OptionButton
            icon={<EllipsisCircleIcon className="size-14" />}
            onClick={() => send({ type: 'selectOtherSoftware' })}
          >
            Other software
          </Wizard.OptionButton>
        </Wizard.OptionsGrid>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function EllipsisCircleIcon({ className }: { className: string }) {
  return (
    <svg className={className} fill="none">
      <rect width="48" height="48" fill="#EEF1FF" rx="24" />
      <path
        fill="#536CF1"
        d="M24 20.553a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm-7.5-4.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Zm15-4.5a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 4.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3Z"
      />
    </svg>
  )
}

function SelectAddNewClientsMethod({ send, onClose, onBack }: PageProps) {
  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onBack}>
            Back
          </Button>
        </Wizard.BottomNav>
      }
      onClose={onClose}
    >
      <Wizard.Main title="How would you like to add the clients?">
        <Wizard.OptionsColumn>
          {/* <Wizard.LargeOptionButton
            icon={WizardIcons.ReturningClientsIcon}
            caption="Select this option if you want to add many new clients at once and would like the option to personalize their checklists & questionnaires before sending it to them."
            onClick={() => send({ type: 'selectBulkCSV' })}
          >
            In Bulk via CSV
          </Wizard.LargeOptionButton> */}

          <Wizard.LargeOptionButton
            icon={WizardIcons.OneByOneIcon}
            caption="Select this option if you want to add a single client and would would like the option to personalize their checklist & questionnaire before sending it to them."
            onClick={() => send({ type: 'selectOneByOne' })}
          >
            One by one
          </Wizard.LargeOptionButton>

          <Wizard.LargeOptionButton
            icon={WizardIcons.MagicLinkIcon}
            caption="Select this option if you want to send the same link to all new clients and are not looking personalize their checklists & questionnaires."
            onClick={() => send({ type: 'selectMagicLink' })}
          >
            Magic link
          </Wizard.LargeOptionButton>
        </Wizard.OptionsColumn>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function TaxAppUploadBackupFileInstructionsPage({
  state,
  send,
  onClose
}: {
  state: STFolderImportTaxAppInstructions
  send: ProcessSend<STFolderImportMessage>
  onClose: () => void
}) {
  const instructions = TAX_APP_IMPORT_INSTRUCTIONS.find((el) => el.appId == state.taxApp.id)
  const step = instructions?.steps[state.currentPage - 1]

  if (!instructions || state.taxApp.integration == 'coming_soon') {
    return (
      <Wizard.Scaffold breadcrumb="Add clients" onClose={onClose}>
        <Wizard.Main>
          <div className="flex h-full flex-col items-center justify-center gap-4 text-center">
            <img className="h-14 w-14" src={`/logos/${state.taxApp.id}.png`} />
            The integration for {state.taxApp.name} is coming soon. <br /> Stay tuned!
          </div>
        </Wizard.Main>
      </Wizard.Scaffold>
    )
  }

  const stepNumber = state.currentPage
  const stepCount = instructions.steps.length

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      bottomNav={
        <Wizard.BottomNav>
          <Button
            size="l"
            variant="default"
            disabled={stepNumber == 0}
            onClick={() => send({ type: 'prevInstruction' })}
          >
            Back
          </Button>
          <Button
            size="l"
            variant="primary"
            disabled={stepNumber == stepCount}
            onClick={() => send({ type: 'nextInstruction' })}
          >
            Next
          </Button>
        </Wizard.BottomNav>
      }
      onClose={onClose}
    >
      <Wizard.Main
        variant="wide"
        progress={
          <ProgressStepIndicator
            stepNumber={state.currentPage}
            stepCount={instructions.steps.length}
          />
        }
        title={`Add clients from ${state.taxApp.name}`}
        subtitle={step?.caption}
      >
        {step?.image && (
          <img className="w-[1000px] object-contain" src={assetURL(`instructions/${step.image}`)} />
        )}
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function UploadFolderImport({
  state,
  send,
  onClose,
  onImportSubmitted
}: {
  state: STFolderImportUploadFile
  send: ProcessSend<STFolderImportMessage>
  onClose: () => void
  onImportSubmitted: (folderImport: FolderImportJob) => void
}) {
  const organization = useOrganization()

  const instructions = TAX_APP_IMPORT_INSTRUCTIONS.find((el) => el.appId == state.taxApp.id)

  if (!instructions) {
    return null
  }

  const stepNumber = instructions.steps.length
  const stepCount = instructions.steps.length
  const step = instructions?.steps[stepNumber - 1]

  return (
    <Wizard.Scaffold
      breadcrumb="Add clients"
      bottomNav={
        <Wizard.BottomNav>
          <Button
            size="l"
            variant="default"
            disabled={stepNumber == 0}
            onClick={() => send({ type: 'prevInstruction' })}
          >
            Back
          </Button>
        </Wizard.BottomNav>
      }
      onClose={onClose}
    >
      <Wizard.Main
        progress={
          <ProgressStepIndicator stepNumber={stepNumber} stepCount={instructions.steps.length} />
        }
        title={`Add clients from ${state.taxApp.name}`}
        subtitle={step?.caption}
      >
        {stepNumber == stepCount && (
          <DocumentDropZone
            className="w-[600px]"
            onDrop={async (dataTransfer: DataTransfer) => {
              const submittedImport = await send(
                createAndUploadFolderImport({
                  dataTransfer: dataTransfer,
                  organizationId: organization.id,
                  sourceName: state.taxApp.id
                })
              )
              onImportSubmitted(submittedImport)
            }}
          >
            {match(state)
              .with({ step: 'uploadImportFile' }, () => (
                <DropHere caption="1040, 1120, 1120s, and 1065 supported">
                  Drop your backup here <a className="text-blue-500">or browse</a>
                </DropHere>
              ))
              .with({ step: 'preparingFolderImportFile' }, () => (
                <DropProgress progress={0} caption="Preparing upload..." />
              ))
              .with({ step: 'uploadingFolderImportFile' }, ({ progress }) => (
                <DropProgress
                  progress={progress.bytesTransferred / progress.totalBytes}
                  caption="Uploading..."
                />
              ))
              .exhaustive()}
          </DocumentDropZone>
        )}
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}
