import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { PencilIcon, TrashIcon } from './icons'

export function EditableTagOption({
  color,
  children,
  onClickEdit,
  onClickDelete
}: {
  color: string
  children?: ReactNode
  onClickEdit?: () => void
  onClickDelete?: () => void
}) {
  return (
    <div
      className={clsx(
        'group flex h-10 w-full flex-row items-center rounded-md px-4 py-2',
        `bg-${color}-300 text-${color}-800`
      )}
    >
      <div className="flex-1">{children}</div>

      <div className="invisible flex flex-row items-center justify-end gap-3 group-hover:visible">
        <button onClick={onClickDelete}>
          <TrashIcon className="h-4 w-4" />
        </button>
        <button onClick={onClickEdit}>
          <PencilIcon className="h-4 w-4" />
        </button>
      </div>
    </div>
  )
}

export function SelectableTagOption({
  color,
  selected,
  children,
  onClick
}: {
  color: string
  selected?: boolean
  children?: ReactNode
  onClick?: () => void
}) {
  return (
    <div
      className={clsx(
        'group flex h-10 w-full cursor-pointer flex-row items-center rounded-md px-4 py-2',
        `bg-${color}-300 text-${color}-800`
      )}
      onClick={onClick}
    >
      <div className="flex-1">{children}</div>

      <div className={selected ? 'visible' : 'invisible'}>
        <TagOptionCheckIcon className="h-4 w-4 text-black" />
      </div>
    </div>
  )
}

function TagOptionCheckIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
      />
    </svg>
  )
}
