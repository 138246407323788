export function TrashIcon(props: { className: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none">
      <path
        d="M13.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3A1.5 1.5 0 0 0 5 2.5V3H2.5a.5.5 0 1 0 0 1H3v9a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4h.5a.5.5 0 0 0 0-1M6 2.5l.5-.5h3l.5.5V3H6zM12 13H4V4h8zM7 6.5v4a.5.5 0 0 1-1 0v-4a.5.5 0 1 1 1 0m3 0v4a.5.5 0 0 1-1 0v-4a.5.5 0 1 1 1 0"
        fill="currentColor"
      />
    </svg>
  )
}
