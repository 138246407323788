export function ChecklistVisual({ className }: { className: string }) {
  return (
    <svg fill="none" viewBox="0 0 65 68" className={className}>
      <g clip-path="url(#a)">
        <g filter="url(#b)">
          <rect width="60" height="20" x="2.5" y="1" fill="#fff" rx="2" />
        </g>
        <rect width="36" height="4" x="22.5" y="6" fill="#E6E7EB" rx="2" />
        <rect width="24" height="4" x="22.5" y="12" fill="#E6E7EB" rx="2" />
        <g filter="url(#c)">
          <rect width="60" height="20" x="2.5" y="23" fill="#fff" rx="2" />
        </g>
        <path
          fill="#CBCCD4"
          d="M18.5 28v10a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1V28a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1"
        />
        <path
          fill="#fff"
          d="M15.4 31.1a.5.5 0 0 1 0 .8l-3.5 3.5a.5.5 0 0 1-.8 0l-1.5-1.5a.5.5 0 1 1 .8-.8l1.1 1.2 3.1-3.2a.5.5 0 0 1 .8 0"
        />
        <g filter="url(#d)">
          <rect width="60" height="20" x="2.5" y="45" fill="#fff" rx="2" />
        </g>
        <path
          fill="#CBCCD4"
          d="M18.5 6v10a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1"
        />
        <path
          fill="#fff"
          d="M15.4 9.1a.5.5 0 0 1 0 .8l-3.5 3.5a.5.5 0 0 1-.8 0l-1.5-1.5a.5.5 0 1 1 .8-.8l1.1 1.2L14.6 9a.5.5 0 0 1 .8 0"
        />
        <path
          fill="#CBCCD4"
          d="M18.5 50v10a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1V50a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1m-1 10V50h-10v10z"
        />
        <rect width="36" height="4" x="22.5" y="28" fill="#E6E7EB" rx="2" />
        <rect width="36" height="4" x="22.5" y="50" fill="#E6E7EB" rx="2" />
        <rect width="24" height="4" x="22.5" y="34" fill="#E6E7EB" rx="2" />
        <rect width="24" height="4" x="22.5" y="56" fill="#E6E7EB" rx="2" />
      </g>
      <defs>
        <filter
          id="b"
          width="64"
          height="24"
          x=".5"
          y="0"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1214_51649" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1214_51649" result="shape" />
        </filter>
        <filter
          id="c"
          width="64"
          height="24"
          x=".5"
          y="22"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1214_51649" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1214_51649" result="shape" />
        </filter>
        <filter
          id="d"
          width="64"
          height="24"
          x=".5"
          y="44"
          color-interpolation-filters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1214_51649" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_1214_51649" result="shape" />
        </filter>
        <clipPath id="a">
          <path fill="#fff" d="M.5 0h64v68H.5z" />
        </clipPath>
      </defs>
    </svg>
  )
}
