/**
 * Given an array of possible classes, return a concantenated string which
 * can be used as the className property for a React component.
 *
 * null and undefined classes are filtered out, which can be useful for conditionally applying classes
 *
 * @param classes
 * @returns The string which can be used for the class name
 */
export function cn(...classes: (string | null | undefined)[]): string {
  return classes.filter((classEntry) => classEntry).join(' ')
}
