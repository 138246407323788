export function CircleXIcon(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#a)">
        <circle cx="8" cy="8" r="8" fill="currentColor" />
        <path
          fill="#fff"
          d="M11.182 11.546a.375.375 0 0 1-.53 0L8 8.894l-2.652 2.652a.375.375 0 1 1-.53-.53L7.47 8.364 4.818 5.712a.375.375 0 1 1 .53-.53L8 7.834l2.652-2.652a.375.375 0 1 1 .53.53L8.53 8.364l2.652 2.652a.375.375 0 0 1 0 .53Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
