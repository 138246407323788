import { useAppDeps } from '@features/app-deps-provider'
import {
  addMemberToOrganization,
  removeMemberFromOrganization,
  resendOrganizationInviteToMember,
  stOrganizationModule
} from '@features/st-organizations/st-organization-module'
import { OrganizationSettingsScaffold } from '@features/st-organizations/st-organization-settings-scaffold'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { useProcess, useProcessState } from '@st/redux'
import { OrganizationMembership } from '@st/sdk'
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  Dialog,
  DialogButtons,
  Modal,
  PlusIcon,
  Table,
  TagChip,
  TextInput
} from '@st/theme'
import { isValidEmail } from '@st/util/email'
import { useState } from 'react'
import { match } from 'ts-pattern'

type OrganizationTeamAction =
  | { type: 'add' }
  | { type: 'confirmRemove'; membership: OrganizationMembership }
  | { type: 'resendInvite'; membership: OrganizationMembership }

export function OrganizationTeamPage() {
  const platform = useProcess(platformModule)
  const stOrganization = useProcess(stOrganizationModule)
  const memberships = useProcessState(stOrganizationModule, (s) => s.state?.memberships ?? [])

  const [action, setAction] = useState<OrganizationTeamAction | undefined>()

  async function onAction(action: OrganizationTeamAction) {
    switch (action.type) {
      case 'add':
      case 'confirmRemove':
        setAction(action)
        break
      case 'resendInvite':
        await stOrganization.send(
          resendOrganizationInviteToMember({ userId: action.membership.userId })
        )
        platform.send({
          type: 'showSnackbar',
          message: `Invite resent to ${action.membership.user.email}`
        })
        break
    }
  }

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Team</h2>

      <Table>
        <Table.Head>
          <Table.Th>Email</Table.Th>
          <Table.Th className="w-60">Role</Table.Th>
          <Table.Th className="w-32"></Table.Th>
        </Table.Head>
        <Table.Body>
          {memberships.map((membership) => (
            <Table.Row key={membership.userId}>
              <Table.Cell>{membership.user.email}</Table.Cell>
              <Table.Cell>
                <TagChip color="gray" className="w-20">
                  {membership.role!}
                </TagChip>
              </Table.Cell>
              <Table.Cell>
                <div className="flex flex-row items-center">
                  <ContextMenu>
                    <ContextMenuItem
                      onClick={() => onAction({ type: 'resendInvite', membership: membership })}
                    >
                      Resend invitation email
                    </ContextMenuItem>
                    <ContextMenuItem
                      onClick={() => onAction({ type: 'confirmRemove', membership: membership })}
                    >
                      Remove from team
                    </ContextMenuItem>
                  </ContextMenu>
                </div>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Button
        variant="primary"
        className="mt-12 self-end"
        leadingIcon={<PlusIcon className="h-4 w-4" />}
        onClick={() => setAction({ type: 'add' })}
      >
        Add team member
      </Button>

      {match(action)
        .with({ type: 'add' }, () => (
          <Modal isOpen={true}>
            <AddTeamMemberDialog onClose={() => setAction(undefined)} />
          </Modal>
        ))
        .with({ type: 'confirmRemove' }, ({ membership }) => {
          return (
            <Modal isOpen={true}>
              <RemoveTeamMemberDialog
                membership={membership}
                onClose={() => setAction(undefined)}
              />
            </Modal>
          )
        })
        .otherwise(() => null)}
    </OrganizationSettingsScaffold>
  )
}

function AddTeamMemberDialog({ onClose }: { onClose: () => void }) {
  const { sdk } = useAppDeps()

  const stOrganization = useProcess(stOrganizationModule)
  const platform = useProcess(platformModule)

  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)

  const [email, setEmail] = useState('')

  const [sending, setSending] = useState(false)

  async function onPressInvite() {
    setSending(true)
    const resp = await sdk.send({ type: 'accounts/getOrCreateUserByEmail', email })
    const user = resp.user
    await stOrganization.send(addMemberToOrganization({ userId: user.id }))
    await sdk.send({ type: 'organizations/sendTeamInviteEmail', userId: user.id, organizationId })
    setSending(false)

    platform.send({ type: 'showSnackbar', message: 'Sent invite to ' + email })

    onClose()
  }

  return (
    <Dialog
      title="Add team member"
      subtitle="Enter the email of the team member you would like to invite"
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={!isValidEmail(email) && !sending}
            onClick={onPressInvite}
          >
            {sending ? 'Inviting...' : 'Invite'}
          </Button>
        </DialogButtons>
      }
    >
      <TextInput
        className="w-full"
        placeholder="Email address"
        autoFocus={true}
        value={email}
        onChange={setEmail}
      />
    </Dialog>
  )
}

function RemoveTeamMemberDialog({
  membership,
  onClose
}: {
  membership: OrganizationMembership
  onClose: () => void
}) {
  const stOrganization = useProcess(stOrganizationModule)
  const platform = useProcess(platformModule)

  async function onClickRemove() {
    await stOrganization.send(removeMemberFromOrganization({ userId: membership.userId }))
    platform.send({
      type: 'showSnackbar',
      message: `Removed ${membership.user.email} from the organization`
    })
    onClose()
  }

  if (membership.role == 'owner') {
    return (
      <Dialog
        title="Unauthorized"
        subtitle="It is not possible to remove the owner of an organization"
        buttons={
          <DialogButtons>
            <Button variant="default" onClick={onClose}>
              Close
            </Button>
          </DialogButtons>
        }
      />
    )
  }

  return (
    <Dialog
      title="Remove from team"
      subtitle={`Please confirm that you would like to remove ${membership.user.email} from the organization`}
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClickRemove}>
            Remove
          </Button>
        </DialogButtons>
      }
    />
  )
}
