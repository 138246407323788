import { defineModule, defineTask } from '@st/redux'
import { STSDK } from '@st/sdk'
import { Progress } from '@st/util/progress'
import { asc, sort } from '@st/util/sort'
import { computeFileHash } from '@st/util/xfile'
import { create } from 'mutative'

type DocumentUploadsState = {
  uploads: Record<string, Upload>
}

export type DocumentUploadContext = {
  sdk: STSDK
}

export type Upload = {
  id: string
  progress: Progress
  startedAt: string
  status: 'running' | 'success' | 'failed'

  filesize: number
  filename: string

  folderId: string
  mimeType: string

  sha1: string | undefined
  documentId: string | undefined
  documentTypeId?: string | undefined

  meta: Record<string, string>
}

export type Message =
  | { type: 'uploadStarted'; upload: Upload }
  | { type: 'uploadPrepared'; upload: Upload }
  | { type: 'uploadProgressed'; uploadId: string; progress: Progress }
  | { type: 'uploadCompleted'; uploadId: string }
  | { type: 'uploadFailed'; uploadId: string }
  | { type: 'clearUpload'; uploadId: string }

export const documentUploadsModule = defineModule<DocumentUploadsState, Message>({
  name: 'documentUploads',
  init: () => {
    return { uploads: {} }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'uploadStarted':
        return create(state, (s) => {
          s.uploads[message.upload.id] = message.upload
        })
      case 'uploadPrepared':
        return create(state, (s) => {
          s.uploads[message.upload.id] = message.upload
        })
      case 'uploadProgressed':
        return create(state, (s) => {
          s.uploads[message.uploadId].progress = message.progress
        })
      case 'uploadCompleted':
        return create(state, (s) => {
          s.uploads[message.uploadId].status = 'success'
          delete s.uploads[message.uploadId]
        })
      case 'uploadFailed':
        return create(state, (s) => {
          s.uploads[message.uploadId].status = 'failed'
        })
      case 'clearUpload':
        return create(state, (s) => {
          delete s.uploads[message.uploadId]
        })
    }
  }
})

export function selUploads(uploadsState: DocumentUploadsState) {
  const uploads = Object.values(uploadsState.uploads)
  return sort(uploads, asc('startedAt'))
}

export function selUploadsOfDocumentType(
  uploadsState: DocumentUploadsState,
  documentTypeId: string
) {
  const uploads = Object.values(uploadsState.uploads)
  return sort(uploads, asc('startedAt')).filter((upload) => upload.documentTypeId == documentTypeId)
}

type UploadDocument = {
  uploadId: string
  file: File
  folderId: string
  documentTypeId?: string
  meta?: Record<string, string>
}

export const uploadDocument = defineTask(
  documentUploadsModule,
  async (
    { send },
    { uploadId, file, folderId, documentTypeId, meta = {} }: UploadDocument,
    { sdk }: DocumentUploadContext
  ) => {
    let upload: Upload = {
      id: uploadId,
      startedAt: new Date().toISOString(),
      filename: file.name,
      folderId: folderId,
      mimeType: file.type,
      filesize: file.size,
      status: 'running',
      progress: { value: 0, max: file.size },
      documentTypeId: documentTypeId,

      documentId: undefined,
      sha1: undefined,
      meta: meta
    }

    send({ type: 'uploadStarted', upload })

    const sha1 = await file.arrayBuffer().then(computeFileHash)

    const resp = await sdk.send({
      type: 'folders/generatePresignedDocumentUploadURL',
      filename: upload.filename,
      folderId: upload.folderId,
      sha1: sha1
    })

    upload = { ...upload, sha1: sha1, documentId: resp.documentId }
    send({ type: 'uploadPrepared', upload })

    var xhr = new XMLHttpRequest()
    xhr.open('PUT', resp.uploadUrl, true)

    for (const [key, value] of Object.entries(resp.uploadHeaders)) {
      xhr.setRequestHeader(key, value as string)
    }

    const uploadResp = await new Promise((resolve, reject) => {
      xhr.upload.onprogress = (event) => {
        send({
          type: 'uploadProgressed',
          uploadId: upload.id,
          progress: { value: event.loaded, max: event.total }
        })
      }

      xhr.onload = () => {
        if (xhr.status == 200) {
          resolve(undefined)
        } else {
          send({
            type: 'uploadFailed',
            uploadId: upload.id
          })
          reject()
        }
      }

      xhr.onerror = (e) => {
        console.error(e)
        send({
          type: 'uploadFailed',
          uploadId: upload.id
        })
        reject()
      }

      xhr.send(file)
    })

    await sdk.send({
      type: 'folders/finalizeDocumentUpload',
      documentId: resp.documentId,
      documentTypeId: documentTypeId,
      folderId: folderId
    })
    send({ type: 'uploadCompleted', uploadId: upload.id })

    return resp.documentId
  }
)
