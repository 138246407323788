import { clsx } from 'clsx'
import { ReactNode } from 'react'

function TableMain({ children, className }: { children: ReactNode; className?: string }) {
  return <table className="table-fixed divide-y divide-gray-300">{children}</table>
}

export const Table = Object.assign(TableMain, {
  Head: ({ children }: { children: ReactNode }) => {
    return (
      <thead>
        <tr className="h-9">{children}</tr>
      </thead>
    )
  },
  Th: ({ children, className }: { children?: ReactNode; className?: string }) => {
    return (
      <th className={clsx('px-4 text-left text-xs uppercase text-gray-600', className)}>
        {children}
      </th>
    )
  },
  Body: ({ children }: { children: ReactNode }) => {
    return <tbody className="divide-y divide-gray-200 bg-white">{children}</tbody>
  },
  Row: ({ children }: { children: ReactNode }) => {
    return <tr className="group h-12">{children}</tr>
  },
  Cell: ({ children, className }: { children?: ReactNode; className?: string }) => {
    return (
      <td className={clsx('whitespace-nowrap px-4 text-base text-gray-500', className)}>
        {children}
      </td>
    )
  }
})
