export function SendIconLarge(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none">
      <g clipPath="url(#clip0_1907_3885)">
        <path
          d="M14.9857 8.50114C14.9856 8.32336 14.9382 8.14881 14.8482 7.99547C14.7583 7.84213 14.6291 7.71554 14.4739 7.62875L14.4673 7.62212L3.41079 1.71336C3.23339 1.61533 3.03039 1.57353 2.8287 1.59348C2.627 1.61344 2.43613 1.69421 2.28138 1.8251C2.12663 1.95598 2.0153 2.1308 1.96215 2.32639C1.909 2.52197 1.91653 2.72909 1.98376 2.9203L3.90886 8.50114L1.98332 14.0771C1.91894 14.255 1.90683 14.4477 1.94841 14.6322C1.98999 14.8168 2.08354 14.9856 2.21799 15.1188C2.23832 15.1391 2.25953 15.1585 2.28163 15.1771C2.43589 15.3083 2.62657 15.3891 2.82808 15.4088C3.0296 15.4284 3.23231 15.386 3.40902 15.2872L14.4638 9.37663C14.466 9.37442 14.4682 9.37221 14.4704 9.37C14.6257 9.28395 14.7552 9.15811 14.8458 9.00542C14.9364 8.85272 14.9846 8.67867 14.9857 8.50114ZM2.9357 14.409L2.9273 14.413L2.9304 14.4099L4.79716 9.0023H9.0398C9.17015 8.99896 9.29404 8.94483 9.38505 8.85146C9.47606 8.75809 9.527 8.63286 9.527 8.50246C9.527 8.37207 9.47606 8.24684 9.38505 8.15347C9.29404 8.06009 9.17015 8.00597 9.0398 8.00263L4.79716 8.00263L2.92907 2.5937L2.92598 2.59061L2.93216 2.5968L13.9851 8.50114L2.9357 14.409Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1907_3885">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
