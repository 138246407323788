import { Cache, CacheOpts } from './interface'

type CacheEntry<T> = {
  value: T
  expiration?: number
}

// Having a global variable more closely mimics
// having a cache because of the shared state
// For example you can have multiple cache instances connected to a key-value store
const IN_MEMORY_CACHE_DATA: Record<
  string,
  Record<string, CacheEntry<unknown>>
> = {}

export class InMemoryCache<T> implements Cache<T> {
  constructor(private namespace: string) {
    if (!(this.namespace in IN_MEMORY_CACHE_DATA)) {
      IN_MEMORY_CACHE_DATA[this.namespace] = {}
    }
  }

  private map() {
    return IN_MEMORY_CACHE_DATA[this.namespace] as Record<string, CacheEntry<T>>
  }

  get(key: string): Promise<T | undefined> {
    const now = Date.now()

    const entry = this.map()[key]
    if (!entry) return Promise.resolve(undefined)

    if (entry.expiration && now > entry.expiration) {
      delete this.map()[key]
      return Promise.resolve(undefined)
    }

    return Promise.resolve(entry.value)
  }

  set(key: string, value: T, opts?: CacheOpts): Promise<void> {
    this.map()[key] = { value, expiration: opts?.expiration }
    return Promise.resolve()
  }

  delete(key: string): Promise<void> {
    delete this.map()[key]
    return Promise.resolve()
  }
}
