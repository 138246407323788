import { EventHandler, SyntheticEvent, useRef } from 'react'

/**
 * Return a new event handler that prevents the event from bubbling up
 *
 * @param handler
 * @returns
 */
export function handle<T>(
  handler?: EventHandler<SyntheticEvent<T>>
): EventHandler<SyntheticEvent<T>> {
  return function (e) {
    e.preventDefault()
    e.stopPropagation()

    handler?.(e)
  }
}

export function useId(prefix: string = '') {
  const { current: inputId } = useRef(
    prefix + (Math.random().toString(36) + '00000000000000000').slice(2, 7)
  )
  return inputId
}

export function hasClass(el: Element, className: string): boolean {
  if (typeof el.className.indexOf !== 'function') {
    // Workaround for issue similar to the one below:
    // If element no longer exists className may no longer exist
    // https://github.com/bvaughn/react-virtualized/issues/1203
    return false
  }
  return el.className.indexOf(className) != -1
}
