export function formatPercentage(value: number): string {
  // return (value * 100).toFixed(0).toString() + '%'
  return (value * 100).toFixed(0).toString()
}

export function formatMoney(cents: number): string {
  const dollars = cents / 100
  return `$${dollars
    .toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
    .replace(/\.00$/, '')}`
}
