export type RouteMatcher = (path: string) => [true, Params] | [false]
type Params = Record<string, string>

export function makeRouteMatcher(pattern: string): RouteMatcher {
  const { keys, regexp } = pathToRegexp(pattern)

  return function routeMatcher(path: string) {
    const out = regexp.exec(path)

    if (!out) return [false]

    const params: Record<string, string> = {}
    keys.forEach((key, i) => {
      params[key] = out[i + 1]
    })

    return [true, params]
  }
}

// escapes a regexp string (borrowed from path-to-regexp sources)
// https://github.com/pillarjs/path-to-regexp/blob/v3.0.0/index.js#L202
function escapeRegex(str: string) {
  return str.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1')
}

// returns a segment representation in RegExp based on flags
// adapted and simplified version from path-to-regexp sources
function regexForSegment(
  repeat: boolean,
  optional: boolean,
  hasPrefix: boolean
) {
  let capture = repeat ? '((?:[^\\/]+?)(?:\\/(?:[^\\/]+?))*)' : '([^\\/]+?)'
  if (optional && hasPrefix) capture = '(?:\\/' + capture + ')'
  return capture + (optional ? '?' : '')
}

type RouteRegExp = {
  keys: string[]
  regexp: RegExp
}

function pathToRegexp(pattern: string): RouteRegExp {
  const groupRx = /:([A-Za-z0-9_]+)([?+*]?)/g

  let match = null,
    lastIndex = 0,
    keys = [],
    result = ''

  while ((match = groupRx.exec(pattern)) !== null) {
    const [_, segment, mod] = match

    // :foo  [1]      (  )
    // :foo? [0 - 1]  ( o)
    // :foo+ [1 - ∞]  (r )
    // :foo* [0 - ∞]  (ro)
    const repeat = mod === '+' || mod === '*'
    const optional = mod === '?' || mod === '*'
    const prefix = optional && pattern[match.index - 1] === '/' ? 1 : 0

    const prev = pattern.substring(lastIndex, match.index - prefix)

    keys.push(segment)
    lastIndex = groupRx.lastIndex

    result += escapeRegex(prev) + regexForSegment(repeat, optional, prefix == 1)
  }

  result += escapeRegex(pattern.substring(lastIndex))
  return { keys, regexp: new RegExp('^' + result + '(?:\\/)?$', 'i') }
}
