import type { Core } from '@pdftron/webviewer'

export type PDFTronCore = typeof Core
export type PDFNetPage = Core.PDFNet.Page
export type DocumentViewerEvent = keyof typeof Core.DocumentViewer.Events
export type AnnotationManagerEvent = keyof typeof Core.AnnotationManager.Events
export type DocumentViewer = Core.DocumentViewer
export type PDFTronDocument = Core.Document
export type PDFTronBookmark = Core.PDFNet.Bookmark
export type CoreAnnotation = Core.Annotations.Annotation

declare global {
  interface Window {
    Core: typeof Core
  }
}

const PDF_TRON_LICENSE_KEY =
  'StanfordTax, Inc.:OEM:StanfordTax::B+:AMS(20260414):95F554106A96330BEABBC8B253084F01404F2D263E13F954964B4C5460AE02F431F5C7'

export async function loadPDFTronCore(): Promise<PDFTronCore> {
  await loadScript('/lib/pdftron/core/webviewer-core.min.js')
  await loadScript('/lib/pdftron/core/pdf/PDFNet.js')
  const core = window.Core

  core.setWorkerPath('/lib/pdftron/core')
  core.setPDFWorkerPath('/lib/pdftron/core/pdf')
  core.enableFullPDF()
  core.disableEmbeddedJavaScript()
  await core.PDFNet.initialize(PDF_TRON_LICENSE_KEY)

  return core
}

const LOADED_SCRIPTS: Record<string, Promise<void> | undefined> = {}
function loadScript(src: string): Promise<void> {
  // already loaded
  if (LOADED_SCRIPTS[src]) {
    return LOADED_SCRIPTS[src]!
  }

  LOADED_SCRIPTS[src] = new Promise(function (resolve, reject) {
    const script = document.createElement('script')
    script.src = src
    script.onload = function () {
      resolve()
    }
    script.onerror = function () {
      reject()
    }
    document.head.appendChild(script)
  })

  return LOADED_SCRIPTS[src]!
}
