import clsx from 'clsx'
import { EventHandler, HTMLAttributeAnchorTarget, ReactNode, SyntheticEvent } from 'react'
import { BrandLogo } from './BrandLogo'

export function HeaderNav(props: {
  children: ReactNode
  trailingMenu?: ReactNode
  onClickLogo?: () => void
}) {
  return (
    <nav className="flex h-14 flex-row items-stretch justify-between bg-gray-900 px-5 font-sans">
      <div className="flex flex-row">
        <div className="flex items-center">
          <BrandLogo variant="dark" onClick={props.onClickLogo} />
        </div>

        <div className="ml-6 flex items-stretch space-x-6">{props.children}</div>
      </div>
      {props.trailingMenu && <div className="flex flex-row items-center">{props.trailingMenu}</div>}
    </nav>
  )
}

export function HeadNavLink(props: {
  children: ReactNode
  active?: boolean
  href?: string
  target?: HTMLAttributeAnchorTarget
  onClick?: EventHandler<SyntheticEvent<unknown, Event>>
}) {
  return (
    <a
      href={props.href}
      onClick={props.onClick}
      target={props.target}
      className={clsx(
        'flex cursor-pointer items-center border-b-2 text-base',
        props.active && 'border-blue-400 text-blue-400',
        !props.active && 'border-transparent text-white'
      )}
    >
      {props.children}
    </a>
  )
}
