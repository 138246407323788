import { AppAnalytics, useAnalytics } from '@features/analytics'
import { AppDepsPlugins } from '@features/app-deps-create'
import { AppDepsProvider, useAppDeps } from '@features/app-deps-provider'
import { useAppDepsCreate, useRoute, useSetRoute } from '@features/app-hooks'
import { appStore } from '@features/app-store'
import { RequireLogin } from '@features/auth/require-login'
import { DebugPage } from '@features/dev/debug-page'
import { documentUploadsModule } from '@features/document-uploads'
import { ErrorReporting } from '@features/error-reporting'
import { OrganizationBrandingPage, OrganizationLoginPage } from '@features/organizations'
import { organizationModule } from '@features/organizations/organization-module'
import { routes } from '@features/routing'
import { sdkModule } from '@features/sdk-module'
import { STCreateFolderImportPage, STRunFolderImportPage } from '@features/st-folder-import'
import { STFolderListPage } from '@features/st-folder-list'
import { STFolderViewer } from '@features/st-folder-viewer/st-folder-viewer'
import { OrganizationPlansPage } from '@features/st-organizations'
import { STOrganizationMessageTemplatesPage } from '@features/st-organizations/st-organization-message-templates-page'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { STOrganizationPlanSettingsPage } from '@features/st-organizations/st-organization-plan-settings-page'
import {
  CURRENT_PROMOTION,
  OrganizationLoggedOutPlansPage
} from '@features/st-organizations/st-organization-plans-page'
import { OrganizationIntegrationsPage } from '@features/st-organizations/st-organizations-integrations-page'
import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { STQuestionnaire } from '@features/st-questionnaire'
import { DevModeProvider } from '@st/react-util/dev-mode'
import { StoreDepsProvider, StoreProvider, useProcess, useProcessState } from '@st/redux'
import { Organization } from '@st/sdk'
import { FullPageCard, SnackbarContainer, useShowSnackbar } from '@st/theme'
import { ReactNode, useEffect } from 'react'
import { match } from 'ts-pattern'
import { OrganizationFolderTagsSettingsPage } from '../st-organizations/st-organization-folder-tags-settings-page'
import { OrganizationSelectStackPage } from './pages/organization-select-stack'
import { OrganizationSignUpPage } from './pages/organization-sign-up-page'
import { OrganizationTeamInvitationPage } from './pages/organization-team-invitation-page'
import { OrganizationTeamPage } from './pages/organization-team-page'
import { PageNotFound } from './pages/page-not-found'
import { AuthorizeZapierPage } from '@features/st-organizations/st-organizations-authorize-zapier-page'
import { OrganizationHeader, StanfordTaxHeader } from './pages/organization-login-page'

/**
 * The app that accountants use to personalize their organization,
 * view the submissions of their clients, manage their billing, etc.
 */
export function OrganizationApp() {
  const appDeps = useAppDepsCreate()

  return (
    <StoreDepsProvider deps={appDeps}>
      <StoreProvider store={appStore}>
        <AppDepsProvider deps={appDeps}>
          <AppDepsPlugins />
          <AppAnalytics.Script />
          <ErrorReporting />

          <DevModeProvider value={localStorage.getItem('dev') == 'true'}>
            <SnackbarContainer>
              <OrganizationRoutes />
            </SnackbarContainer>
          </DevModeProvider>
        </AppDepsProvider>
      </StoreProvider>
    </StoreDepsProvider>
  )
}

function OrganizationRoutes() {
  const { sdk } = useAppDeps()
  const showSnackbar = useShowSnackbar()

  useProcess(platformModule, { showSnackbar })
  useProcess(sdkModule, { sdk })
  useProcess(documentUploadsModule)

  const setRoute = useSetRoute()
  const route = useRoute()

  switch (route?.name) {
    case '/':
    case 'login':
      return <OrganizationLoginPage header={<StanfordTaxHeader />} />
    case 'logged_out_pricing':
      return <OrganizationLoggedOutPlansPage promotion={CURRENT_PROMOTION} />
    case 'logged_in_pricing':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <OrganizationPlansPage promotion={CURRENT_PROMOTION} />
        </OrganizationContainerRequiresLogin>
      )
    case 'sign_up':
      return <OrganizationSignUpPage />
    case 'folder_list':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STFolderListPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'create_import':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STCreateFolderImportPage
            mode={route.preview ? 'preview' : 'normal'}
            onClose={() => setRoute({ name: 'folder_list' })}
            onImportSubmitted={(folderImport) => {
              setRoute({ name: 'run_import', importId: folderImport.id })
            }}
          />
        </OrganizationContainerRequiresLogin>
      )
    case 'run_import':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STRunFolderImportPage
            importId={route.importId}
            onClose={() => setRoute({ name: 'folder_list' })}
          />
        </OrganizationContainerRequiresLogin>
      )
    case 'branding':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <OrganizationBrandingPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'organization_select_stack':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <OrganizationSelectStackPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'team':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <OrganizationTeamPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'team_invitation':
      return (
        <OrganizationTeamInvitationPage
          organizationSlug={route.organizationSlug}
          email={route.email}
        />
      )
    case 'organization_folder':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STFolderViewer folderId={route.folderId} />
        </OrganizationContainerRequiresLogin>
      )
    case 'debug':
      return <DebugPage />
    case 'organization_folder_debug':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STFolderViewer folderId={route.folderId} debug={true} />
        </OrganizationContainerRequiresLogin>
      )
    case 'organization_preview_questionnaire':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STQuestionnaire preview={true} folderId={route.folderId} role="organization_member" />
        </OrganizationContainerRequiresLogin>
      )
    case 'manage_organization_tags':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <OrganizationFolderTagsSettingsPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'manage_organization_message_templates':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STOrganizationMessageTemplatesPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'manage_organization_plan':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <STOrganizationPlanSettingsPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'manage_organization_integrations':
      return (
        <OrganizationContainerRequiresLogin organizationSlug={route.organizationSlug}>
          <OrganizationIntegrationsPage />
        </OrganizationContainerRequiresLogin>
      )
    case 'authorize_zapier':
      return (
        <AuthorizeZapierPage
          clientId={route.client_id}
          state={route.state}
          redirectUri={route.redirect_uri}
        />
      )
    case 'organization_submission_legacy':
      return (
        <RedirectTo
          href={
            routes.toPath({
              name: 'organization_folder',
              organizationSlug: route.organizationSlug,
              folderId: route.folderId
            })!
          }
        />
      )
    case 'submission_list_legacy_route':
      return (
        <RedirectTo
          href={routes.toPath({ name: 'folder_list', organizationSlug: route.organizationSlug })!}
        />
      )
    default:
      return <PageNotFound />
  }
}

function OrganizationContainerRequiresLogin({
  children,
  organizationSlug
}: {
  children: ReactNode
  organizationSlug: string
}) {
  const analytics = useAnalytics()

  useProcess(organizationModule, { organizationSlug })
  const organization = useProcessState(organizationModule, (s) => s.organization)

  useEffect(() => {
    if (organization) analytics.setOrganization(organization)
  }, [analytics, organization])

  return (
    <RequireLogin
      loginScreen={
        <OrganizationLoginPage header={<OrganizationHeader organization={organization} />} />
      }
      loadingScreen={<></>}
    >
      {organization ? (
        <LoadOrganizationState organization={organization}>{children}</LoadOrganizationState>
      ) : null}
    </RequireLogin>
  )
}
function LoadOrganizationState({
  organization,
  children
}: {
  organization: Organization
  children: ReactNode
}) {
  useProcess(stOrganizationModule, { organizationId: organization.id })
  const state = useProcessState(stOrganizationModule)

  return match(state.status)
    .with('loading', () => null)
    .with('loaded', () => <>{children}</>)
    .with('unauthorized', () => (
      <FullPageCard>You are not authorized to access this organization.</FullPageCard>
    ))
    .exhaustive()
}

function RedirectTo({ href }: { href: string }) {
  useEffect(() => {
    window.location.href = href
  }, [href])
  return <></>
}
