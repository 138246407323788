import { OrganizationLogo } from '@features/organizations'
import { routes } from '@features/routing'
import { ProcessSend, useProcess, useProcessState } from '@st/redux'
import { Button, FullPageCard } from '@st/theme'
import { P, match } from 'ts-pattern'
import {
  OrganizationTeamInviteMessage,
  OrganizationTeamInviteState,
  organizationTeamInviteModule
} from './organization-team-invite-module'

export function OrganizationTeamInvitationPage({
  organizationSlug,
  email
}: {
  organizationSlug: string
  email: string
}) {
  const teamInvitation = useProcess(organizationTeamInviteModule, { organizationSlug, email })
  const state = useProcessState(organizationTeamInviteModule)

  return <OrganizationTeamInvitationInner state={state} send={teamInvitation.send} />
}

function OrganizationTeamInvitationInner({
  state,
  send
}: {
  state: OrganizationTeamInviteState
  send: ProcessSend<OrganizationTeamInviteMessage>
}) {
  const organization = state.organization

  function onClickSend() {
    send({
      type: 'sendSetPasswordEmail',
      continueUrl:
        window.location.origin +
        '/' +
        routes.toPath({
          name: 'folder_list',
          organizationSlug: organization!.slug!
        })
    })
  }

  return (
    <FullPageCard>
      {organization && (
        <div className="flex flex-col items-center gap-3">
          {organization.logo ? <OrganizationLogo src={organization.logo} size={80} /> : null}
          <h1 className="text-3xl">{organization.name}</h1>
        </div>
      )}

      <p className="mt-4 text-center text-sm">
        {match(state.status)
          .with('loading', () => '')
          .with(
            P.union('sendSetPasswordEmail', 'sendingSetPasswordEmail'),
            () =>
              `To finalize your account setup for ${
                organization!.name
              }, please click the button below to recieve a secure link in your email reset your password.`
          )
          .with(
            'sentSetPasswordEmail',
            () =>
              `You have been emailed a secure link to reset your password. Please use it to set your
              password and login to ${organization!.name}`
          )
          .exhaustive()}
      </p>

      {match(state.status)
        .with(P.union('loading', 'sendSetPasswordEmail'), () => (
          <Button
            variant="primary"
            className="mx-auto mt-4"
            disabled={state.status == 'loading'}
            onClick={onClickSend}
          >
            Send set password email
          </Button>
        ))
        .with('sendingSetPasswordEmail', () => (
          <Button variant="primary" className="mx-auto mt-4" disabled={true}>
            Sending email
          </Button>
        ))
        .with('sentSetPasswordEmail', () => <></>)
        .exhaustive()}
    </FullPageCard>
  )
}
