import { FC, ReactNode } from 'react'
import { CloseIcon } from './icons'

function Scaffold({
  breadcrumb,
  children,
  bottomNav,
  onClose
}: {
  breadcrumb?: string
  children?: ReactNode
  bottomNav?: ReactNode
  onClose?: () => void
}) {
  return (
    <div className="grid h-svh w-full grid-rows-[50px_auto_50px] overflow-hidden bg-gray-100">
      <div className="flex flex-row items-center justify-between border-b border-stone-200 bg-white px-5 py-3">
        <span className="text-lg font-bold text-gray-900">{breadcrumb}</span>

        <div className="flex flex-1 justify-end">
          <button onClick={onClose}>
            <CloseIcon className="h-4 w-4 text-black hover:text-red-500" />
          </button>
        </div>
      </div>

      {children}

      {bottomNav}
    </div>
  )
}

function OptionsGrid({ children }: { children: ReactNode }) {
  return (
    <div className="flex w-[650px] flex-row flex-wrap items-center justify-center gap-5 px-2 py-2">
      {children}
    </div>
  )
}

function OptionsColumn({ children }: { children: ReactNode }) {
  return <div className="flex w-[650px] flex-col flex-wrap gap-4 px-2 py-2">{children}</div>
}

function LargeOptionButton({
  icon: Icon,
  children,
  caption,
  onClick
}: {
  icon: FC<{ className: string }>
  children: ReactNode
  caption: ReactNode
  onClick?: () => void
}) {
  return (
    <button
      className="flex h-40 flex-row items-center rounded-md px-10 text-left text-lg text-gray-700 shadow-md"
      onClick={onClick}
    >
      <Icon className="mr-4 h-12 w-12 min-w-12" />
      <div className="flex flex-col items-start gap-y-2">
        <div className="flex flex-row items-center gap-2">{children}</div>
        <p className="text-sm text-gray-700">{caption}</p>
      </div>
    </button>
  )
}

function BottomNav({ children }: { children: ReactNode }) {
  return (
    <div className="flex flex-row items-center justify-between border-t border-stone-200 bg-white px-5 py-3">
      {children}
    </div>
  )
}

function OptionButton({
  icon,
  children,
  onClick
}: {
  icon?: ReactNode
  children: ReactNode
  onClick?: () => void
}) {
  return (
    <button
      className="flex h-32 w-48 flex-col items-center justify-center gap-2 rounded-md text-base font-semibold text-gray-700 shadow-md"
      onClick={onClick}
    >
      {icon}
      {children}
    </button>
  )
}

function Main({
  progress,
  title,
  subtitle,
  banner,
  children,
  variant = 'default'
}: {
  progress?: ReactNode
  title?: string
  subtitle?: string
  banner?: ReactNode
  children: ReactNode
  variant?: 'default' | 'wide'
}) {
  return (
    <main className="mx-auto mt-4 flex w-full flex-col items-center overflow-y-auto">
      {progress}
      {progress && <div className="mb-6" />}

      {banner && <div className="mb-8 flex w-full">{banner}</div>}

      {(title || subtitle) && (
        <div className="my-12 flex w-[600px] flex-col gap-y-3">
          {title && <h1 className="text-center text-3xl">{title}</h1>}
          {subtitle && <p className="text-center text-lg">{subtitle}</p>}
        </div>
      )}

      {children}
    </main>
  )
}

export const Wizard = {
  Scaffold,
  Main,
  OptionsGrid,
  OptionsColumn,
  OptionButton,
  LargeOptionButton,
  BottomNav
}
