export type Result<V, E> = OkResult<V> | ErrorResult<E>

export type OkResult<V> = { ok: true; value: V }
export type ErrorResult<E> = { ok: false; error: E }

export type AsyncResult<T, E = unknown> =
  | { status: 'pending' }
  | { status: 'fulfilled'; value: T }
  | { status: 'rejected'; error: E }

export function valueOrDefault<Type, Error>(
  result: AsyncResult<Type, Error>,
  defaultValue: Type
): Type {
  return result.status == 'fulfilled' ? result.value : defaultValue
}

type SplitResults<V, E> = {
  succeeded: V[]
  failed: E[]
}
export function splitResults<V, E>(
  results: Result<V, E>[]
): SplitResults<V, E> {
  const ret: SplitResults<V, E> = { succeeded: [], failed: [] }
  for (const r of results) {
    if (r.ok) ret.succeeded.push(r.value)
    else ret.failed.push(r.error)
  }
  return ret
}

export function isErr<E>(result: Result<unknown, E>): result is ErrorResult<E> {
  return !result.ok
}

export function isOk<V>(result: Result<V, unknown>): result is OkResult<V> {
  return result.ok
}

export function getOrThrow<V, E>(result: Result<V, E>): V {
  if (result.ok) {
    return result.value
  }
  throw result.error
}

export function ok<V>(value: V): OkResult<V> {
  return { ok: true, value }
}

export function err<E>(error: E): ErrorResult<E> {
  return { ok: false, error }
}
