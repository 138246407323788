import { useEffect } from 'react'

type Props = {
  children?: string
}
export function Title({ children }: Props) {
  useTitle(children)
  return null
}

export function useTitle(title: string | undefined) {
  useEffect(() => {
    const prevTitle = document.title
    if (title) {
      document.title = title
    }

    return () => {
      if (title) {
        document.title = prevTitle
      }
    }
  }, [title])
}
