import { AsyncResult } from '@st/util/result'
import { useEffect, useMemo, useState } from 'react'

export function delay<T = undefined>(value: T, ms: number): Promise<T> {
  return new Promise<T>((resolve) => setTimeout(() => resolve(value), ms))
}

export function usePromise<T>(
  promiseFn: () => Promise<T>,
  deps: ReadonlyArray<any>
): AsyncResult<T> {
  const [response, setResponse] = useState<AsyncResult<T>>({
    status: 'pending'
  })

  const promise = useMemo(() => {
    return promiseFn()
  }, [...deps])

  useEffect(() => {
    promise
      .then((value) => {
        setResponse({ status: 'fulfilled', value })
      })
      .catch((error) => {
        setResponse({ status: 'rejected', error })
      })
  }, [promise])

  return response
}
