export function EyeIcon(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M15.457 7.798c-.022-.05-.551-1.224-1.728-2.401C12.16 3.829 10.18 3 8 3s-4.16.829-5.729 2.397C1.094 6.574.562 7.75.543 7.797a.5.5 0 0 0 0 .407c.022.05.551 1.223 1.728 2.4C3.84 12.17 5.82 13 8 13s4.16-.829 5.729-2.396c1.177-1.177 1.706-2.35 1.728-2.4a.5.5 0 0 0 0-.407ZM8 12c-1.924 0-3.604-.7-4.996-2.078A8.342 8.342 0 0 1 1.562 8a8.334 8.334 0 0 1 1.442-1.922C4.396 4.7 6.076 4 8 4c1.924 0 3.604.7 4.996 2.078A8.34 8.34 0 0 1 14.44 8c-.451.841-2.414 4-6.441 4Zm0-7a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 5a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"
      />
    </svg>
  )
}
