import { QuestionTip } from '@st/theme'
import { cn } from '@util/cn'
import { useId } from '@util/react'
import { ChangeEvent, ReactNode } from 'react'
import { NoteTip } from './note-tip'
import { clsx } from 'clsx'

type Props = {
  children?: ReactNode
  tip?: { title?: string; body?: string } | null
  caption?: string
  noteTip?: string
  selected?: boolean
  onClick?: () => void
}
export function MultiSelectOption({ selected, children, caption, tip, noteTip, onClick }: Props) {
  const className = clsx(
    'multi-select-option',
    selected ? 'multi-select-option--selected' : null,
    selected ? 'dark-theme' : null,
    'flex flex-row items-center'
  )
  return (
    <div className={className} onClick={() => onClick?.()}>
      <label className="option-label font-16">{children}</label>
      {tip ? <span>&nbsp;</span> : null}
      {tip && <QuestionTip title={tip.title} body={tip.body} />}

      <span
        className={cn(
          'grow text-right text-base font-light',
          selected ? 'text-white' : 'color-grey'
        )}
      >
        {caption}
      </span>

      {noteTip ? <NoteTip translateX={20}>{noteTip}</NoteTip> : null}
    </div>
  )
}

type RadioSelectOptionProps = {
  label?: string
  tip?: { title?: string; body?: string } | null
  onSelect: () => void
  selected?: boolean
}
export function RadioSelectOption({ label, tip, selected, onSelect }: RadioSelectOptionProps) {
  const id = useId()

  function onChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      onSelect()
    }
  }

  const className = cn(
    'radio-select-option',
    selected ? 'radio-select-option--selected' : null,
    selected ? 'dark-theme' : null,
    'flex flex-row items-center'
  )

  return (
    <div className={className} onClick={() => onSelect()}>
      <input type="radio" checked={selected} id={id} onChange={onChange} />
      <label className="font-16 ml-2 flex flex-row items-center font-bold" htmlFor={id}>
        <span>{label}</span>
        {tip ? <span>&nbsp;</span> : null}
        {tip && <QuestionTip title={tip.title} body={tip.body} />}
      </label>
    </div>
  )
}
