import { StandardTableConfig } from '@st/folder/ui-config'
import { range } from '@st/util/array'
import { cn } from '@util/cn'
import { CellComponent } from './cell-component'

type Props = StandardTableConfig & {
  className?: string
}
export function StandardTable({ className, columns }: Props) {
  const rowCount = columns[0].components.length
  const colCount = columns.length

  return (
    <table className={cn('form-table-right-align', className)}>
      <thead>
        <tr className="text-base">
          {columns.map((column, index) => {
            return <th key={index}>{column.title}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {range(0, rowCount - 1).map((rowIndex) => (
          <tr key={rowIndex}>
            {range(0, colCount - 1).map((colIndex) => (
              <td key={colIndex}>
                <CellComponent config={columns[colIndex].components[rowIndex]} />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}
