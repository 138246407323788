import { ReactNode } from 'react'
import ReactReconciler, { HostConfig } from 'react-reconciler'

type Type = string
type Props = { [key: string]: any }

export type ElNode = {
  tagName: Type
  props: { [key: string]: unknown }
  children: ElNode[] | TextNode
}

// top-level container can only contain elements
type Container = ElNode & { children: ElNode[] }

type Instance = ElNode
type TextNode = string

type SuspenseInstance = unknown
type HydratableInstance = unknown
type PublicInstance = unknown
type HostContext = unknown
type UpdatePayload = unknown
type ChildSet = unknown
type TimeoutHandle = unknown
type NoTimeout = number

function createReconciler() {
  const hostConfig: HostConfig<
    Type,
    Props,
    Container,
    Instance,
    TextNode,
    SuspenseInstance,
    HydratableInstance,
    PublicInstance,
    HostContext,
    UpdatePayload,
    ChildSet,
    TimeoutHandle,
    NoTimeout
  > = {
    isPrimaryRenderer: false,

    supportsMutation: true,
    supportsPersistence: false,
    supportsHydration: false,

    scheduleTimeout: setTimeout,
    cancelTimeout: clearTimeout,
    noTimeout: -1,

    createInstance(tagName: Type, props: Props): Instance {
      props = { ...props }
      delete props.children
      return { tagName, props, children: [] }
    },

    createTextInstance: (text) => text,

    appendChildToContainer: addChild,
    appendInitialChild: addChild,
    appendChild: addChild,
    removeChildFromContainer: removeChild,

    prepareUpdate: () => null,

    getChildHostContext: (parentHostContext) => {},

    finalizeInitialChildren: () => false,
    shouldSetTextContent: () => false,
    getRootHostContext: () => {},

    getPublicInstance: (instance) => instance,
    prepareForCommit: () => null,
    resetAfterCommit: () => {},
    preparePortalMount: () => {},
    getCurrentEventPriority: () => 0,
    getInstanceFromNode: () => null,
    beforeActiveInstanceBlur: () => {},
    afterActiveInstanceBlur: () => {},
    prepareScopeUpdate: () => {},
    getInstanceFromScope: () => null,
    detachDeletedInstance: () => null,
    clearContainer: () => {}
  }

  return ReactReconciler(hostConfig)
}

export function toElem(element: ReactNode): ElNode {
  const reactElemReconciler = createReconciler()

  const container: Container = {
    tagName: 'el',
    props: { type: 'Root' },
    children: []
  }

  const root = reactElemReconciler.createContainer(
    container,
    0, // tag
    null, // hydrationCallbacks
    true, // isStrictMode
    false, // concurrentUpdatesByDefaultOverride
    '', // identifierPrefix
    () => null, // onRecoverableError
    null // transitionCallbacks
  )

  reactElemReconciler.updateContainer(element, root, null)

  return container.children[0]
}

function isTextNode(el: ElNode | TextNode): el is TextNode {
  return typeof el == 'string'
}

function isElement(el: ElNode | TextNode): el is ElNode {
  return typeof el != 'string'
}

function addChild(el: ElNode, child: ElNode | TextNode) {
  const { children } = el
  if (isTextNode(child)) {
    el.children = child
  } else {
    if (!Array.isArray(children))
      throw `Can not mix text nodes and normal nodes`
    children.push(child)
  }
}

function removeChild(el: ElNode, child: ElNode | TextNode) {
  const { children } = el

  if (!Array.isArray(children)) throw 'Children must be an array'
  if (!isElement(child)) throw 'Child must be an element'

  const index = children.indexOf(child)
  if (index != -1) children.splice(index, 1)
}
