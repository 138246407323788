export function PaperClipIcon(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 12 14" fill="none">
      <path
        d="M11.1 6.6a.5.5 0 0 1 0 .8l-5.1 5a3.5 3.5 0 1 1-5-4.9l6.2-6.3a2.5 2.5 0 0 1 3.6 3.6L4.6 11a1.5 1.5 0 1 1-2.2-2.2l5.2-5.3a.5.5 0 1 1 .8.8L3 9.6a.5.5 0 0 0 .8.8L10 4a1.5 1.5 0 0 0-2.2-2.2L1.7 8.2a2.5 2.5 0 0 0 3.6 3.6l5.1-5.2z"
        fill="currentColor"
      />
    </svg>
  )
}
