import { useFlashState } from '@st/react-util/use-flash-state'
import { Button, CopyIcon, Wizard } from '@st/theme'

import { clientMagicLink } from '@features/routing'
import { Organization } from '@st/sdk'
import { CURRENT_TAX_YEAR, RETURN_TYPES, buildSchemaId } from '@st/tax-folder'

type Props = {
  organization: Organization
  onClose: () => void
  onBack: () => void
}

export function STQuestionnaireMagicLinksPage({ organization, onClose, onBack }: Props) {
  return (
    <Wizard.Scaffold
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onBack}>
            Back
          </Button>
        </Wizard.BottomNav>
      }
      onClose={onClose}
    >
      <Wizard.Main
        title="Copy your magic link below"
        subtitle="Share these links with new clients for a smooth onboarding experience. If a folder for a given return type with your client's email does not exist, it will be created automatically."
      >
        <div className="flex w-[600px] flex-col items-stretch gap-4">
          {RETURN_TYPES.map((returnType, index) => (
            <div className="flex flex-col gap-1" key={index}>
              <label className="text-base">{returnType}</label>
              <CopyLinkInput
                link={clientMagicLink({
                  organizationSlug: organization.slug,
                  schemaId: buildSchemaId(CURRENT_TAX_YEAR, returnType)
                })}
              />
            </div>
          ))}
        </div>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function CopyLinkInput({ link, onCopy }: { link: string; onCopy?: () => void }) {
  const [copied, setCopied] = useFlashState(false, 2000)

  function handleCopy() {
    navigator.clipboard.writeText(link)
    setCopied(true)

    onCopy?.()
  }

  return (
    <div className="flex flex-row gap-2">
      <input
        type="text"
        className="flex-1 rounded-md border-0 py-1.5 pl-3 pr-10 text-base text-gray-700 ring-1 ring-inset ring-gray-300"
        value={link}
      />
      <Button
        className="w-32"
        leadingIcon={<CopyIcon className="h-4 w-4" />}
        variant="primary"
        onClick={handleCopy}
      >
        {copied ? 'Copied!' : 'Copy link'}
      </Button>
    </div>
  )
}
