import { Bookmark, Renderer } from './interface'

export const bookmarkRenderer: Renderer<Bookmark> = {
  getSize() {
    return { width: 0, height: 0 }
  },
  render(props) {
    return {
      type: 'add_bookmark',
      path: props.path,
      title: props.title,
      fontWeight: props.fontWeight ?? 'normal'
    }
  }
}
