import { defineModule, Ref } from '@st/redux'
import { SDKCommand, SDKOperation, STSDK } from '@st/sdk'
import { delay } from '@st/util/delay'

type Timer = any
type IdToTimerMap = Record<string, Timer>

type SDKState = {
  sdk: Ref<STSDK>
  idToTimerMap: Ref<IdToTimerMap>
}

type SDKInit = {
  sdk: STSDK
}

export type SDKMessage = SDKRequest | SDKResponse | SDKScheduleIn | SDKScheduledRun

export type SDKRequest = {
  type: 'request'
  requestId?: string
  delay?: number
  request: SDKCommand
}
export type SDKResponse = {
  type: 'response'
  requestId?: string
  operation: SDKOperation
}
export type SDKRequestUnauthorized = {
  type: 'requestUnauthorized'
  requestId?: string
  request: SDKCommand
}
export type SDKScheduleIn<T = any> = {
  type: 'scheduleIn'
  scheduleId: string
  delay: number
  data: T
}
export type SDKScheduledRun<T = any> = { type: 'scheduledRun'; timerId: string; data: T }

export const sdkModule = defineModule<SDKState, SDKMessage, SDKInit, { sdk: SDKMessage }>({
  name: 'sdk',
  init: ({ sdk }, { createRef }) => {
    return {
      sdk: createRef(sdk, () => null),
      idToTimerMap: createRef({}, () => null)
    }
  },
  handle: (state, message, context) => {
    const sdk = context.resolveRef(state.sdk)
    const idToTimerMap = context.resolveRef(state.idToTimerMap)

    switch (message.type) {
      case 'request':
        sdk.fetch(message.request).then(async (httpResponse) => {
          const response = await httpResponse.json()
          if (httpResponse.status == 401) {
            context.send(context.from!, {
              type: 'requestUnauthorized',
              requestId: message.requestId,
              request: message.request
            })
          } else {
            if (message.delay) {
              await delay(message.delay)
            }

            const operation = {
              type: message.request.type,
              request: message.request,
              response: response
            }
            context.send(context.from!, {
              type: 'response',
              requestId: message.requestId,
              operation: operation
            })
          }
        })
        break
      case 'scheduleIn':
        const prevTimerId = idToTimerMap[message.scheduleId]
        if (prevTimerId) {
          clearInterval(prevTimerId)
          console.log(`cancelled timer ${message.scheduleId}`)
        }
        const timer = setTimeout(() => {
          context.send(context.from!, {
            type: 'scheduledRun',
            timerId: message.scheduleId,
            data: message.data
          })
        }, message.delay)

        idToTimerMap[message.scheduleId] = timer

        break
    }
    return state
  }
})
