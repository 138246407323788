import { useAppDeps } from '@features/app-deps-provider'
import {
  TemplateCommentEditor,
  TemplatePlaceholderLegend
} from '@features/st-organizations/st-organization-message-templates-page'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { useDerivedState } from '@st/react-util/use-derived-state'
import { useProcessState } from '@st/redux'
import { Button, Wizard } from '@st/theme'

export function STEditTemplateInlineWizardPage({
  title,
  subtitle,
  onClose,
  onClickBack,
  onClickContinue,
  templateType
}: {
  title: string
  subtitle: string
  templateType: string
  onClose: () => void
  onClickBack: () => void
  onClickContinue: () => void
}) {
  const { sdk } = useAppDeps()
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)
  const template = useProcessState(stOrganizationModule, (s) => {
    return s.state!.messageTemplates.find(
      (t) => t.tags.includes('1040') && t.tags.includes(templateType)
    )!
  })
  const [comment, setComment] = useDerivedState(template.comment)

  async function handleClickContinue() {
    if (comment != template.comment) {
      await sdk.send({
        type: 'organizations/editMessageTemplate',
        organizationId: organizationId,
        messageTemplateId: template.id,
        messageTemplateComment: comment
      })
    }

    onClickContinue()
  }

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onClickBack}>
            Back
          </Button>
          <Button variant="primary" onClick={handleClickContinue}>
            Continue
          </Button>
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main variant="wide" title={title} subtitle={subtitle}>
        <div className="mt-4 flex items-start gap-5">
          {/* This is used to balance out the page so the textarea is centered */}
          <div className="w-[320px]" />

          <div className="flex w-[600px] flex-col gap-6">
            <TemplateCommentEditor template={template} value={comment} onChange={setComment} />
          </div>

          <TemplatePlaceholderLegend template={template} />
        </div>
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}
