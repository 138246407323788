import { defineModule, defineTask } from '@st/redux'
import { monkeyPatchBrowserLocationEvents } from '@st/util/browser-location'
import { routes } from './routing'
import { deepEqual } from '@st/util/json-value'

export type RouteState = {
  location: RouteLocation
}

type RouteLocation = {
  origin: string
  pathname: string
}

export type RouteContext = {}

export type RouteMessage =
  | { type: 'navigate'; pathname: string; replace?: boolean }
  | { type: 'browserLocationChanged'; pathname: string }

export const routeModule = defineModule({
  name: 'route',
  init: (_, { send }) => {
    const initialLocation = getCurrentLocation()

    let currentLocation = getCurrentLocation()
    function locationChangeHandler() {
      const newLocation = getCurrentLocation()

      if (deepEqual(currentLocation, newLocation)) {
        return
      }

      currentLocation = newLocation
      send('route', {
        type: 'browserLocationChanged',
        pathname: newLocation.pathname
      })
    }
    if (typeof window !== 'undefined') {
      window.addEventListener('locationchange', locationChangeHandler)
    }

    return {
      location: initialLocation
    }
  },
  handle: handle
})

export function getCurrentLocation(): RouteLocation {
  if (typeof window === 'undefined') return { origin: 'http://localhost', pathname: '/' }
  return {
    origin: window.location.origin,
    pathname: window.location.pathname + window.location.search
  }
}

function handle(state: RouteState, event: RouteMessage): RouteState {
  switch (event.type) {
    case 'navigate':
      if (event.replace) {
        window.history.replaceState(null, '', event.pathname)
      } else {
        window.history.pushState(null, '', event.pathname)
      }

      // Force scroll to top this is what browsers normally do when
      // navigating by clicking a link.
      // Without this, scroll stays wherever it was which can be quite odd.
      document.body.scrollTop = 0
      return {
        ...state,
        location: { ...state.location, pathname: event.pathname }
      }
    case 'browserLocationChanged':
      return {
        ...state,
        location: { ...state.location, pathname: event.pathname }
      }
    default:
      return state
  }
}

export function selRoute(state: RouteState) {
  const match = routes.match(state.location.pathname)
  if (!match) {
    console.warn(`No route match for ${location.pathname}`)
  }
  return match!
}

export function selRouteOrganizationSlug(state: RouteState) {
  const route = selRoute(state)
  if ('organizationSlug' in route) {
    return route.organizationSlug
  }
}

type Navigate = {
  pathname: string
  replace?: boolean
}
export const navigate = defineTask(
  routeModule,
  ({ send, getState }, { pathname, replace }: Navigate) => {
    send({ type: 'navigate', pathname, replace })
  }
)

if (typeof window !== 'undefined') {
  monkeyPatchBrowserLocationEvents()
}
