import { useAppDeps } from '@features/app-deps-provider'
import { useDerivedState } from '@st/react-util/use-derived-state'
import { useFlashState } from '@st/react-util/use-flash-state'
import { useProcess, useProcessState } from '@st/redux'
import { DynamicEmailTemplate } from '@st/sdk'
import {
  Button,
  HighlightableTextArea,
  HighlightChip,
  HighlightMap,
  Tab,
  TabGroup
} from '@st/theme'
import { useMemo, useState } from 'react'
import { stOrganizationModule } from './st-organization-module'
import { OrganizationSettingsScaffold } from './st-organization-settings-scaffold'

export function STOrganizationMessageTemplatesPage() {
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)
  const templates = useProcessState(stOrganizationModule, (s) =>
    s.state!.messageTemplates.filter((t) => t.tags.includes('1040'))
  )
  const [selectedTemplate, setSelectedTemplate] = useState(templates[0])

  return (
    <OrganizationSettingsScaffold title="Settings">
      <div className="flex w-[600px] flex-col gap-4">
        <h2 className="mb-5 text-xl text-gray-800">Templates</h2>

        <TabGroup>
          {templates.map((template) => (
            <Tab
              key={template.id}
              selected={template.id === selectedTemplate.id}
              onClick={() => setSelectedTemplate(template)}
            >
              {template.label}
            </Tab>
          ))}
        </TabGroup>
        <MessageTemplateSettings organizationId={organizationId} template={selectedTemplate} />
      </div>
    </OrganizationSettingsScaffold>
  )
}

function MessageTemplateSettings({
  organizationId,
  template
}: {
  organizationId: string
  template: DynamicEmailTemplate
}) {
  const { sdk } = useAppDeps()
  const stOrganization = useProcess(stOrganizationModule)

  const [comment, setComment] = useDerivedState(template.comment)
  const [saved, setSaved] = useFlashState(false, 2000)

  return (
    <div className="flex flex-col items-start gap-2">
      <div className="mt-4 flex items-start gap-5">
        <div className="flex w-[600px] flex-col gap-6">
          <TemplateCommentEditor template={template} value={comment} onChange={setComment} />
          <Button
            className="self-end"
            variant="primary"
            onClick={async () => {
              setSaved(true)

              await sdk.send({
                type: 'organizations/editMessageTemplate',
                organizationId: organizationId,
                messageTemplateId: template.id,
                messageTemplateComment: comment
              })
              stOrganization.send({ type: 'reload' })
            }}
          >
            {saved ? 'Saved template' : 'Save template'}
          </Button>
        </div>

        <TemplatePlaceholderLegend template={template} />
      </div>
    </div>
  )
}

export function TemplateCommentEditor({
  template,
  value,
  onChange
}: {
  template: DynamicEmailTemplate
  value: string
  onChange: (value: string) => void
}) {
  const higlights: HighlightMap = useMemo(() => {
    return Object.fromEntries(template.placeholders.map((p) => ['$' + p.name, 'bg-yellow-200']))
  }, [template])

  return (
    <HighlightableTextArea
      placeholder="Send a message"
      value={value}
      onChange={onChange}
      highlights={higlights}
    />
  )
}

export function TemplatePlaceholderLegend({ template }: { template: DynamicEmailTemplate }) {
  return (
    <div className="flex w-[320px] flex-col gap-6 rounded-md p-5">
      {template.placeholders.map((placeholder) => (
        <TemplateVarInfo
          key={placeholder.name}
          name={'$' + placeholder.name}
          description={placeholder.description}
        />
      ))}
    </div>
  )
}

function TemplateVarInfo({ name, description }: { name: string; description: string }) {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex w-24 flex-row">
        <HighlightChip className="bg-yellow-200">{name}</HighlightChip>
      </div>
      <div className="flex-1 text-base text-gray-700">{description}</div>
    </div>
  )
}
