import { useResizeObserver } from '@st/react-util/use-resize-observer'
import { SortDirection, SortState } from '@st/util/sort'

import { SortTransitions } from '@st/util/sort'
import { useVirtualizer } from '@tanstack/react-virtual'
import { CSSProperties, ReactNode, useRef } from 'react'

export type ColumnDef<ColumnType extends string> = {
  key: ColumnType
  header: string
  width?: number
}

export type SortOptions<ColumnType extends string> = {
  transitions: SortTransitions<ColumnType>
  defaultSort: SortState<ColumnType>
}

type ColumnState = {
  sortDirection: SortDirection | undefined
  isSortable: boolean
}

type RowState = {
  style: CSSProperties
}

type Props<Row, ColumnType extends string, SortColumn extends string> = {
  columns: ColumnDef<ColumnType>[]
  rows: Row[]
  sortOptions: SortOptions<SortColumn>
  headerHeight: number
  rowHeight: number
  sortState: SortState<SortColumn>
  renderHeaderCell: (column: ColumnDef<ColumnType>, sortState: ColumnState) => ReactNode
  renderRow: (row: Row, rowState: RowState, columns: Array<ColumnDef<ColumnType>>) => ReactNode
}
export function BigTable<Row, ColumnType extends string, SortColumn extends string>({
  columns,
  rows,
  sortOptions,
  headerHeight,
  rowHeight,
  sortState,
  renderHeaderCell,
  renderRow
}: Props<Row, ColumnType, SortColumn>) {
  const containerRef = useRef<HTMLDivElement>(null)
  const parentRef = useRef<HTMLDivElement>(null)

  const gridTemplateColumns = columns
    .map((col) => {
      return col.width ? `${col.width}px` : 'minmax(200px, 1fr)'
    })
    .join(' ')

  const size = useResizeObserver(containerRef)
  const scrollableSize = size ? size.height - headerHeight : 0

  const virtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => rowHeight,
    overscan: 30
  })

  return (
    <div ref={containerRef} style={{ width: '100%' }}>
      <div
        style={{
          scrollbarGutter: 'stable',
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'grid',
          gridTemplateColumns,
          height: headerHeight
        }}
      >
        {columns.map((column) => {
          const colState: ColumnState = {
            sortDirection:
              (sortState.column as any) === column.key ? sortState.direction : undefined,
            isSortable: column.key in sortOptions.transitions
          }
          return renderHeaderCell(column, colState)
        })}
      </div>
      <div
        ref={parentRef}
        className="overflow-auto"
        style={{ scrollbarGutter: 'stable', height: scrollableSize }}
      >
        <div style={{ position: 'relative', height: `${virtualizer.getTotalSize()}px` }}>
          {virtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index]
            const style: CSSProperties = {
              top: 0,
              left: 0,
              position: 'absolute',
              display: 'grid',
              width: '100%',
              gridTemplateColumns,
              height: `${virtualRow.size}px`,
              transform: `translateY(${virtualRow.start}px)`
            }
            return renderRow(row, { style }, columns)
          })}
        </div>
      </div>
    </div>
  )
}
