import { SDKMessage } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { Organization } from '@st/sdk'

export type OrganizationTeamInviteState = {
  status: 'loading' | 'sendSetPasswordEmail' | 'sendingSetPasswordEmail' | 'sentSetPasswordEmail'
  organizationSlug: string
  email: string
  organization: Organization | undefined
}

export type OrganizationTeamInviteMessage =
  | { type: 'sendSetPasswordEmail'; continueUrl: string }
  | SDKMessage

type OrganizationTeamInviteInit = {
  organizationSlug: string
  email: string
}

export const organizationTeamInviteModule = defineModule<
  OrganizationTeamInviteState,
  OrganizationTeamInviteMessage,
  OrganizationTeamInviteInit,
  {
    sdk: SDKMessage
  }
>({
  name: 'organizationTeamInvite',
  init: ({ organizationSlug, email }) => {
    return [
      {
        status: 'loading',
        organizationSlug,
        email,
        organization: undefined
      },
      {
        sdk: {
          type: 'request',
          request: { type: 'organizations/getOrganizationBySlug', organizationSlug }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'response':
        if (message.operation.type == 'organizations/getOrganizationBySlug') {
          return {
            ...state,
            status: 'sendSetPasswordEmail',
            organization: message.operation.response
          }
        } else if (message.operation.type == 'accounts/sendPasswordResetEmail') {
          return { ...state, status: 'sentSetPasswordEmail' }
        } else {
          return state
        }
      case 'sendSetPasswordEmail':
        return [
          {
            ...state,
            status: 'sendingSetPasswordEmail',
            error: undefined
          },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'accounts/sendPasswordResetEmail',
                email: state.email,
                mode: 'set_password',
                continueUrl: message.continueUrl
              }
            }
          }
        ]
      default:
        return state
    }
  }
})
