export function UploadDocumentIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34 8.5C33.1716 8.5 32.5 9.17157 32.5 10C32.5 10.8284 33.1716 11.5 34 11.5V8.5ZM11.5 34C11.5 33.1716 10.8284 32.5 10 32.5C9.17157 32.5 8.5 33.1716 8.5 34H11.5ZM64.5 18V58H67.5V18H64.5ZM58 64.5H18V67.5H58V64.5ZM34 11.5H58V8.5H34V11.5ZM11.5 58V34H8.5V58H11.5ZM18 64.5C14.4101 64.5 11.5 61.5899 11.5 58H8.5C8.5 63.2467 12.7533 67.5 18 67.5V64.5ZM64.5 58C64.5 61.5899 61.5899 64.5 58 64.5V67.5C63.2467 67.5 67.5 63.2467 67.5 58H64.5ZM67.5 18C67.5 12.7533 63.2467 8.5 58 8.5V11.5C61.5899 11.5 64.5 14.4101 64.5 18H67.5Z"
        fill="#C5C5C5"
      />
      <path
        d="M13 64L35.8375 40.249C37.0178 39.0214 38.9821 39.0214 40.1625 40.249L63 64"
        stroke="#C5C5C5"
        strokeWidth="3"
      />
      <circle cx="50" cy="26" r="5" stroke="#C5C5C5" strokeWidth="3" />
      <circle cx="15" cy="15" r="15" fill="#C5C5C5" />
      <path
        d="M16.5 7C16.5 6.17157 15.8284 5.5 15 5.5C14.1716 5.5 13.5 6.17157 13.5 7L16.5 7ZM13.5 23C13.5 23.8284 14.1716 24.5 15 24.5C15.8284 24.5 16.5 23.8284 16.5 23L13.5 23ZM13.5 7L13.5 23L16.5 23L16.5 7L13.5 7Z"
        fill="currentColor"
      />
      <path
        d="M23 16.5C23.8284 16.5 24.5 15.8284 24.5 15C24.5 14.1716 23.8284 13.5 23 13.5L23 16.5ZM7 13.5C6.17157 13.5 5.5 14.1716 5.5 15C5.5 15.8284 6.17157 16.5 7 16.5L7 13.5ZM23 13.5L7 13.5L7 16.5L23 16.5L23 13.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
