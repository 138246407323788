export type Comparable = string | number

export type CompareFn<E> = (a: E, b: E) => number

export function sort<E>(array: E[], ...compareFns: CompareFn<E>[]): E[] {
  const compareFn = levels(...compareFns)
  return array.toSorted ? array.toSorted(compareFn) : [...array].sort(compareFn)
}

type PropSelector<T> = keyof T | ((v: T) => Comparable)

function property<T>(prop: PropSelector<T>): (v: T) => Comparable {
  return typeof prop === 'function' ? prop : (obj: T) => obj[prop] as Comparable
}

export type SortDirection = 'asc' | 'desc'

export function direction<T>(dir: SortDirection, fn: PropSelector<T>): CompareFn<T> {
  return dir == 'asc' ? asc(fn) : desc(fn)
}

export function asc<T>(prop: PropSelector<T>): CompareFn<T> {
  const fn = property(prop)
  return function compareFn(a: T, b: T): number {
    const posA = fn(a)
    const posB = fn(b)

    // both are  undefined, so we consider them equal
    if (isNullish(posA) && isNullish(posB)) return 0
    // we want undefineds to be at the bottom so the sorting can take over
    // so if posA has is undefined but posB isn't, we give posA a higher value so it shows up later on
    else if (isNullish(posA)) return 1
    // the reverse is true if posB is undefined but posA is not
    else if (isNullish(posB)) return -1

    if (posA > posB) return 1
    else if (posA < posB) return -1
    else return 0
  }
}

function isNullish(v: unknown): boolean {
  return v === undefined || v === null || v === ''
}

export function desc<T>(prop: PropSelector<T>): CompareFn<T> {
  const fn = property(prop)
  return function compareFn(a: T, b: T): number {
    const posA = fn(a)
    const posB = fn(b)

    // both are  undefined, so we consider them equal
    if (isNullish(posA) && isNullish(posB)) return 0
    // we want undefineds to be at the bottom so the sorting can take over
    // so if posA has is undefined but posB isn't, we give posA a higher value so it shows up later on
    else if (isNullish(posA)) return 1
    // the reverse is true if posB is undefined but posA is not
    else if (isNullish(posB)) return -1

    // same as asc but we flip it around
    if (posA > posB) return -1
    else if (posA < posB) return 1
    else return 0
  }
}

export function rank<T, K extends keyof T>(
  prop: K,
  order: T[K][],
  direction: SortDirection = 'asc'
): CompareFn<T> {
  return (a: T, b: T): number => {
    const indexA = order.indexOf(a[prop])
    const indexB = order.indexOf(b[prop])

    if (indexA === -1 && indexB === -1)
      return 0 // Both values are not in the order array, consider them equal
    else if (indexA === -1) return 1 // a[prop] is not in the order array, it goes last
    else if (indexB === -1) return -1 // b[prop] is not in the order array, it goes last

    return direction === 'asc' ? indexA - indexB : indexB - indexA
  }
}

export function levels<T>(...compareFns: CompareFn<T>[]): CompareFn<T> {
  // no need to compose if just 1
  if (compareFns.length == 1) return compareFns[0]

  return function multiLevelCompareFn(a: T, b: T) {
    for (const fn of compareFns) {
      const comparison = fn(a, b)
      if (comparison != 0) return comparison
    }
    return 0
  }
}

export function minBy<E, T extends Comparable>(
  iterable: Iterable<E>,
  fn: (v: E) => T
): E | undefined {
  let minValue: E | undefined
  let minKey: T | undefined

  for (const value of iterable) {
    const key = fn(value)
    if (minKey === undefined || key < minKey) {
      minValue = value
      minKey = key
    }
  }

  return minValue
}

export function maxBy<E, T extends Comparable>(
  iterable: Iterable<E>,
  fn: (v: E) => T
): E | undefined {
  let maxValue: E | undefined
  let maxKey: T | undefined

  for (const value of iterable) {
    const key = fn(value)
    if (maxKey === undefined || key > maxKey) {
      maxValue = value
      maxKey = key
    }
  }

  return maxValue
}

/**
 * Represents the current sort state of a table
 *
 * There are 3 possibilities:
 *  1. No sort is set (undefined)
 *  2. An ascending sort is set for a specific column
 *  3. A descending sort is set for a specific column
 */
export type SortState<T extends string> = {
  column: T
  direction: SortDirection
}

/**
 * A map of sort transitions for each column
 *
 * The transitions are a linear list of possible states for the sort
 *
 * For example, with asc -> desc -> undefined
 * If you are at asc, we would transition to desc, then to undefined (unset)
 * With a final click, we would "loop around" and go back to asc
 *
 * Different combinations can be used to determine how the sorting behaves
 * For example:
 *  - `['asc', 'desc', undefined]`:  means you start asc, then desc, then unset on the final click
 *  - `['desc', 'asc']`: starts desc, then flips back to asc, then flips back to desc, forever
 */
export type SortTransitions<T extends string> = Record<T, Array<SortDirection | undefined>>

/**
 * Updates sort state when a column header is clicked.
 *
 * The sorting behavior is determined by the sortTransitions that are passed in.
 * It's commonly:
 *  - asc->desc->nothing->...
 *  - desc->asc->desc->asc->...
 *
 * Clicking on a different column starts from the beginning of the transition list for that column.
 *
 * @param currentSort - The current sort state
 * @param column - The clicked column
 * @param initialSortDirections - The initial sort direction for each column
 * @returns The new sort state or undefined if sort removed
 */
export function toggleSort<T extends string>(
  currentSort: SortState<T> | undefined,
  column: T,
  sortTransitions: SortTransitions<T>
): SortState<T> | undefined {
  if (!currentSort) {
    // No sorting is set, so we start off with the initial sort
    return { column, direction: sortTransitions[column][0]! }
  } else if (currentSort.column !== column) {
    // Changing columns, so we start with the initial sort for the new column
    return { column, direction: sortTransitions[column][0]! }
  } else {
    // The sort transitions are a linear list of possible states for the sort
    // For example with asc -> desc -> undefined
    // If you are at asc, we would transition to desc, then to undefined (unset)
    const transitions = sortTransitions[column]

    // To get the next sort state, we need to find where it is in the transition array
    // and get the next one
    const currentTransitionIndex = transitions.indexOf(currentSort?.direction)
    // The next state is the next item in the sort transition array
    // It resets to the beginning if it reaches the end of the transitions
    const nextSort = transitions[(currentTransitionIndex + 1) % transitions.length]

    return nextSort === undefined ? undefined : { column, direction: nextSort }
  }
}
