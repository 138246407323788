import { Button } from '@st/theme'
import { OrganizationSettingsScaffold } from './st-organization-settings-scaffold'

export function OrganizationIntegrationsPage() {
  function onClick() {
    window.location.href =
      'https://zapier.com/developer/public-invite/216697/1ea5deeb0cfce016594b26d588647d70/'
  }

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Integrations</h2>

      <Button variant="default" onClick={onClick}>
        Connect Zapier
      </Button>
    </OrganizationSettingsScaffold>
  )
}
