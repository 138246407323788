/**
 * This code relates makes it easier to manage json-related config
 * In our app, we use it for FormConfig and FolderConfig.
 */

import { CachedRepo, InMemoryCache } from '@st/util/cache'

type GetPreset<T> = (id: string) => T | undefined
type FetchConfig<T> = (id: string) => Promise<T | undefined>

/**
 * A repo that handles all the details of fetching configuration.
 * It will cache things in memory, fetch from in-memory hardcoded presets,
 * or fetch from servers.
 */
export class JsonConfigRepo<T> {
  constructor({
    fetchPreset,
    fetchConfig
  }: {
    fetchPreset: GetPreset<T>
    fetchConfig: FetchConfig<T>
  }) {
    this.getPreset = fetchPreset
    this.fetchConfig = fetchConfig

    this.cachedRepo = new CachedRepo<string, T | undefined>({
      fetch: (id) => this.doFetch(id),
      cache: new InMemoryCache('JsonConfigRepo'),
      cacheKey: (id) => id
    })
  }

  fetch = (id: string) => this.cachedRepo.get(id)

  private doFetch: FetchConfig<T> = (id: string) => {
    const preset = this.getPreset(id)
    if (preset) return Promise.resolve(preset)

    return this.fetchConfig(id)
  }

  private cachedRepo: CachedRepo<string, T | undefined>
  private getPreset: GetPreset<T>
  private fetchConfig: FetchConfig<T>
}
