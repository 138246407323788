export function SendIcon({ className }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 16 16">
      <path
        fill="currentColor"
        d="M14.985 8.501a1 1 0 0 0-.512-.872l-.006-.007L3.41 1.713A1 1 0 0 0 1.983 2.92l1.925 5.581-1.925 5.576a.991.991 0 0 0 .298 1.1.992.992 0 0 0 1.128.11l11.054-5.91.007-.007a1 1 0 0 0 .515-.869ZM2.935 14.41l-.008.004.003-.003 1.867-5.408h4.242a.5.5 0 0 0 0-1H4.797L2.929 2.595l-.004-.003.007.006L13.985 8.5 2.935 14.41Z"
      />
    </svg>
  )
}
