import { MouseEvent } from 'react'
import { useRoute, useSetRoute } from './app-hooks'
import { SetAppRoute, routes } from './routing/routes'

type Props = {
  href: SetAppRoute | string
  target?: string
  onClick?: () => void
}

export function useLink({ href, target, onClick }: Props) {
  const route = useRoute()
  const setRoute = useSetRoute()

  function handleOnClick(e: MouseEvent<HTMLAnchorElement>) {
    if (typeof href !== 'string' && target !== '_blank') {
      e.preventDefault()
      setRoute(href)
    }

    onClick?.()
  }

  return {
    href: typeof href === 'string' ? href : routes.toPath(href, route),
    onClick: handleOnClick,
    isActive: typeof href !== 'string' && routes.isActive(href, route!)
  }
}
