import { useAuthClient, useSetRoute } from '@features/app-hooks'
import { SetAppRoute } from '@features/routing'
import { useLink } from '@features/use-link'
import { Button, HeaderNav, HeadNavLink } from '@st/theme'
import { ReactNode } from 'react'

export function STAppHeaderNav() {
  const authClient = useAuthClient()
  const setRoute = useSetRoute()

  return (
    <HeaderNav
      onClickLogo={() => {
        setRoute({ name: 'folder_list' })
      }}
      trailingMenu={
        <Button variant="link" className="text-white" onClick={() => authClient.logout()}>
          Logout
        </Button>
      }
    >
      <AppHeaderNavLink href={{ name: 'folder_list' }}>Workpapers</AppHeaderNavLink>
      <AppHeaderNavLink href={{ name: 'team' }}>Settings</AppHeaderNavLink>
      <AppHeaderNavLink href={{ name: 'logged_in_pricing' }}>Pricing</AppHeaderNavLink>

      <HeadNavLink href="https://calendly.com/stanford-tax/intro?month=2024-11" target="_blank">
        Book Consultation
      </HeadNavLink>
    </HeaderNav>
  )
}

function AppHeaderNavLink(props: {
  href: SetAppRoute | string
  children: ReactNode
  onClick?: () => void
}) {
  const { href, onClick, isActive } = useLink({ href: props.href })

  return (
    <HeadNavLink href={href} onClick={onClick} active={isActive}>
      {props.children}
    </HeadNavLink>
  )
}
