import { envVar } from '@env'
import { Script } from '@st/react-util/script'
import { AnalyticsEvent } from '../analytics-events'
import { AnalyticsProvider, serviceCall } from '../core'

export const BingAnalyticsService: AnalyticsProvider = {
  Script: () => {
    const analyticsId = envVar('NEXT_PUBLIC_BING_ANALYTICS_ID')
    if (!analyticsId) return <></>
    return <BingAnalyticsScript analyticsId={analyticsId} />
  },
  track(event: AnalyticsEvent) {
    switch (event) {
      case 'signed_up':
        serviceCall('btag', 'event', 'signup', {})
        break
      case 'purchase':
        serviceCall('btag', 'event', 'subscribe', { currency: 'USD' })
        break
    }
  }
}

function BingAnalyticsScript({ analyticsId }: { analyticsId: string }) {
  return (
    <Script
      id="bing-analytics"
      src="https://bat.bing.com/bat.js"
      async={true}
      onLoad={function () {
        var win = window as any
        var UET = win.UET as any

        win.uetq = win.uetq || []
        var uetqConfig = { ti: analyticsId, q: win.uetq }
        win.uetq = new UET(uetqConfig)
        win.uetq.push('pageLoad')

        win.btag = function btag(event: string, action: string, data: any) {
          win.uetq.push(event, action, data)
        }
      }}
    />
  )
}
