import { HtmlElement, parseSimpleHtmlMarkdown } from './parse-simple-html-markdown'
import { useMemo } from 'react'

interface SimpleHtmlMarkdownProps {
  content: string
}
/**
 * A component that renders a tiny subset of html.
 * Used for dynamic that comes from the server.
 * Look at the tests for {@link parseSimpleHtmlMarkdown} for examples of valid markdown.
 *
 * Limitations:
 * - Nested tags not allowed
 * - Only a subset of tags allowed
 *
 * @param
 * @returns
 */
export function SimpleHtmlMarkdown({ content }: SimpleHtmlMarkdownProps) {
  const elements = useMemo(() => parseSimpleHtmlMarkdown(content), [content])
  return (
    <>
      {elements.map((t, idx) => (
        <HtmlMarkdownElement key={idx} token={t} />
      ))}
    </>
  )
}

function HtmlMarkdownElement({ token }: { token: HtmlElement }) {
  switch (token.tag) {
    case 'span':
      return <span>{token.content}</span>
    case 'b':
      return <b>{token.content}</b>
    case 'em':
      return <em>{token.content}</em>
    case 'a':
      return (
        <a
          href={token.attrs['href']}
          target="_blank"
          rel="noreferrer"
          className="cursor-pointer text-blue-600 underline"
        >
          {token.content}
        </a>
      )
  }
}
