import { useEffect } from 'react'

type Props = {
  /** URL of the script to load */
  src?: string
  /** Unique identifier for the script element */
  id: string
  /** JavaScript code to execute as the script content */
  children?: string
  /** Whether to load the script asynchronously */
  async?: boolean
  /** Callback function executed when script loads successfully */
  onLoad?: () => void
  /** Callback function executed if script fails to load */
  onError?: () => void
}

/**
 * A React component that dynamically injects a script tag into the document.
 *
 * The script can either load an external resource via the src prop,
 * or execute inline JavaScript code via the children prop.
 *
 * The script is only injected once - subsequent renders will not create duplicate scripts.
 * When a script with the same ID already exists, only the onLoad callback is executed.
 *
 * @example
 * ```tsx
 * // Load external script
 * <Script
 *   id="google-analytics"
 *   src="https://www.googletagmanager.com/gtag/js"
 *   async={true}
 *   onLoad={() => console.log('GA loaded')}
 * />
 *
 * // Execute inline script
 * <Script id="inline-js">
 *   {`console.log('Hello world');`}
 * </Script>
 * ```
 */
export function Script({ src, children, id, async = false, onLoad, onError }: Props) {
  useEffect(() => {
    if (document.querySelector(`script[id="${id}"]`)) {
      onLoad?.()
      return
    }

    const script = document.createElement('script')
    script.id = id

    if (src) {
      script.src = src
    }

    if (children) {
      script.textContent = children
    }

    script.async = async
    script.onload = onLoad ?? (() => {})
    script.onerror = onError ?? (() => {})
    document.body.appendChild(script)
  }, [src, id, async, onLoad, onError])

  return null
}
