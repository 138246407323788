import { Checkbox, Renderer } from './interface'

const CHECKBOX_SIZE = 10

export const checkboxRenderer: Renderer<Checkbox> = {
  getSize(props, bounds, context) {
    return { width: CHECKBOX_SIZE, height: CHECKBOX_SIZE }
  },
  render(props, bounds, context) {
    return {
      type: 'checkbox',
      name: props.name,
      x: bounds.x,
      y: bounds.y,
      height: CHECKBOX_SIZE,
      width: CHECKBOX_SIZE,
      checked: props.value,
      backgroundColor: props.backgroundColor ?? '#ffffff'
    }
  }
}
