import { RefObject, useEffect, useState } from 'react'

type Timeout = ReturnType<typeof setTimeout>

type Size = {
  width: number
  height: number
}

export function useResizeObserver<T extends Element>(
  ref: RefObject<T>,
  delay = 250
): Size | undefined {
  const [size, setSize] = useState<Size | undefined>(undefined)

  useEffect(() => {
    if (!ref.current) return

    let timeoutId: Timeout | null = null

    const observer = new ResizeObserver((entries) => {
      const entry = entries[0]

      // Clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId)
      }

      // Set new timeout
      timeoutId = setTimeout(() => {
        const newSize = {
          width: entry.contentRect.width,
          height: entry.contentRect.height
        }

        // If the size hasn't changed, don't update the state
        if (size && newSize.width === size.width && newSize.height === size.height) {
          return
        }

        setSize(newSize)
      }, delay)
    })

    observer.observe(ref.current)

    return () => {
      observer.disconnect()
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [ref, delay])

  return size
}
