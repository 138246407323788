const BLOCK_SIZE = 4
const ID_BASE = 36
const DISCRETE_VALUES = Math.pow(ID_BASE, BLOCK_SIZE)

export function cuid(): string {
  const letter = 'c'
  const timestamp = new Date().getTime().toString(ID_BASE)
  const counter = pad(safeCounter().toString(ID_BASE), BLOCK_SIZE)
  const random = randomBlock() + randomBlock() + randomBlock()

  return letter + timestamp + counter + random
}

function pad(num: string, size: number): string {
  const s = '000000000' + num
  return s.slice(-size)
}

let counter = 0
function safeCounter(): number {
  counter = counter < DISCRETE_VALUES ? counter : 0
  counter++
  return counter - 1
}

function getRandomValue(): number {
  return crypto.getRandomValues(new Uint32Array(1))[0] / (Math.pow(2, 32) - 1)
}

function randomBlock(): string {
  return pad(
    ((getRandomValue() * DISCRETE_VALUES) << 0).toString(ID_BASE),
    BLOCK_SIZE
  )
}
