import { Size } from '@st/util/geom'
export type { Point, Size } from '@st/util/geom'

/**
 * Constraints inspired by Flutter's constraints for layouts
 * A parent passes constraints down to each child when asking for its size.
 */
export type Constraints = {
  /**
   * The max width a child can occupy
   */
  width: number

  /**
   * The max height a child can occupy
   */
  height: number
}

export function validateSize(
  el: any,
  constraints: Constraints,
  size: Size
): Size {
  if (size.width == Infinity || size.height == Infinity) {
    console.error(
      'Invalid size',
      size,
      ' for ',
      el,
      ' constraints ',
      constraints
    )
    throw 'Invalid size'
  }
  return size
}

export type Rect = {
  x: number
  y: number
  width: number
  height: number
}

export type Pad = {
  top: number
  bottom: number
  left: number
  right: number
}

export const Pad = {
  zero: { top: 0, left: 0, right: 0, bottom: 0 } as Pad,
  all: (v: number) => {
    return { top: v, bottom: v, left: v, right: v }
  },
  horizontal: (v: number) => {
    return { top: 0, bottom: 0, left: v, right: v }
  },
  only: (pad: Partial<Pad>) => {
    return { ...Pad.zero, ...pad }
  }
}

export function rectSize(rect: Rect): Size {
  return { width: rect.width, height: rect.height }
}

export function unionRects(rects: Rect[]): Rect {
  let left = Number.MAX_SAFE_INTEGER
  let top = Number.MAX_SAFE_INTEGER
  let right = Number.MIN_SAFE_INTEGER
  let bottom = Number.MIN_SAFE_INTEGER

  for (const rect of rects) {
    left = Math.min(left, rect.x)
    top = Math.min(top, rect.y)
    right = Math.max(right, rect.x + rect.width)
    bottom = Math.max(bottom, rect.y + rect.height)
  }

  return {
    x: left,
    y: top,
    width: right - left,
    height: bottom - top
  }
}

export function addPadding(rect: Rect, padding: Pad): Rect {
  return {
    x: rect.x + padding.left,
    y: rect.y + padding.top,
    width: rect.width - padding.left - padding.right,
    height: rect.height - padding.top - padding.bottom
  }
}

export type Align = 'start' | 'center' | 'end'

export function vAlignRect(rect: Rect, inParent: Rect, align: Align): Rect {
  switch (align) {
    case 'start':
      return rect
    case 'center':
      return {
        x: rect.x,
        y: inParent.y + inParent.height / 2 - rect.height / 2,
        width: rect.width,
        height: rect.height
      }
    case 'end':
      return {
        x: rect.x,
        y: inParent.y + inParent.height - rect.height,
        width: rect.width,
        height: rect.height
      }
  }
}

export function hAlignRect(rect: Rect, inParent: Rect, align: Align): Rect {
  switch (align) {
    case 'start':
      return rect
    case 'center':
      return {
        x: inParent.x + inParent.width / 2 - rect.width / 2,
        y: rect.y,
        width: rect.width,
        height: rect.height
      }
    case 'end':
      return {
        x: inParent.x + inParent.width - rect.width,
        y: rect.y,
        width: rect.width,
        height: rect.height
      }
  }
}

export function getAlignOffset(
  parentSize: number,
  childSize: number,
  align: Align
): number {
  switch (align) {
    case 'start':
      return 0
    case 'center':
      return (parentSize - childSize) * 0.5
    case 'end':
      return parentSize - childSize
  }
}

export type AxisDirection = 'row' | 'column'

export function constrainSize(size: Size, constraints: Constraints): Size {
  return {
    width: Math.min(size.width, constraints.width),
    height: Math.min(size.height, constraints.height)
  }
}

export function getMainDimension(direction: AxisDirection, size: Size): number {
  switch (direction) {
    case 'column':
      return size.height
    case 'row':
      return size.width
  }
}
