import type { DependencyList } from 'react'
import { useEffect, useRef } from 'react'

export function useCreation<T>(factory: () => T, deps: DependencyList) {
  const { current } = useRef({
    deps,
    obj: undefined as undefined | T,
    initialized: false
  })

  useEffect(() => {
    return () => {
      // component is unmounting so we need to dispose the instance
      // if it's disposable
      return maybeDispose(current.obj)
    }
  }, [])

  if (current.initialized === false || !depsAreSame(current.deps, deps)) {
    current.deps = deps

    // we are replacing the current instance because
    // one of the deps changed
    // so we may need to dispose the previous instance if one exists
    maybeDispose(current.obj)

    current.obj = factory()

    current.initialized = true
  }
  return current.obj as T
}

function depsAreSame(oldDeps: DependencyList, deps: DependencyList): boolean {
  if (oldDeps === deps) return true
  for (let i = 0; i < oldDeps.length; i++) {
    if (!Object.is(oldDeps[i], deps[i])) return false
  }
  return true
}

function isDisposable<T>(obj: T): obj is T & Disposable {
  return typeof obj === 'object' && obj !== null && Symbol.dispose in obj
}

function maybeDispose<T>(obj: T | undefined) {
  if (isDisposable(obj)) {
    obj[Symbol.dispose]()
  }
}
