import clsx from 'clsx'
import { useId } from 'react'

type Props = {
  label?: string
  className?: string
  placeholder?: string
  value?: string
  onChange?: (v: string) => void
}
export function TextArea({ className, label, placeholder, value, onChange }: Props) {
  const id = useId()
  return (
    <div className="flex flex-col gap-2">
      {label && (
        <label htmlFor={id} className="block text-gray-900">
          {label}
        </label>
      )}
      <textarea
        id={id}
        rows={4}
        placeholder={placeholder}
        className={clsx(
          'block resize-none rounded-md border-0 px-2 py-3 leading-5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600',
          className
        )}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
      />
    </div>
  )
}
