import { useAuthClient, useAuthUserChanged, usePath } from '@features/app-hooks'
import { getAppType } from '@features/get-app-type'
import { ReactNode, useEffect } from 'react'
import { AnalyticsEvent } from './analytics-events'
import { AnalyticsClient, AnalyticsProvider, AuthContext, EventData, TrackOptions } from './core'
import { BingAnalyticsService } from './providers/bing'
import { GoogleAnalyticsService } from './providers/google'
import { IntercomAnalyticsService } from './providers/intercom'
import { PosthogAnalyticsService } from './providers/posthog'
import { SentryAnalyticsService } from './providers/sentry'
import { Organization } from '@st/sdk'
import { User } from '@features/auth/auth-client-adapter'

export const AppAnalytics: AnalyticsClient = {
  Script() {
    return (
      <CombinedAnalyticsScript>
        {getAnalyticsProviders().map((provider, key) => (
          <provider.Script key={key} />
        ))}
      </CombinedAnalyticsScript>
    )
  },
  track(event: AnalyticsEvent, data: EventData = {}, options: TrackOptions = {}) {
    for (var p of getAnalyticsProviders()) {
      if (p.track) p.track(event, data, options)
    }
  },
  trackLocationChanged(location: string) {
    for (var p of getAnalyticsProviders()) {
      if (p.trackLocationChanged) p.trackLocationChanged(location)
    }
  },
  trackUserIdentified(user: User, authContext: AuthContext) {
    for (var p of getAnalyticsProviders()) {
      if (p.trackUserIdentified) p.trackUserIdentified(user, authContext)
    }
  },
  trackUserAnonymous() {
    for (var p of getAnalyticsProviders()) {
      if (p.trackUserAnonymous) p.trackUserAnonymous()
    }
  },
  setUserProperties(properties: Record<string, string>) {
    for (var p of getAnalyticsProviders()) {
      if (p.setUserProperties) p.setUserProperties(properties)
    }
  },
  setOrganization(organization: Organization) {
    for (var p of getAnalyticsProviders()) {
      if (p.setOrganization) p.setOrganization(organization)
    }
  },
  captureException(exception) {
    console.error(exception)

    for (var p of getAnalyticsProviders()) {
      p.captureException?.(exception)
    }
  },
  setGroup(groupName, groupId, properties) {
    for (var p of getAnalyticsProviders()) {
      if (p.setGroup) p.setGroup(groupName, groupId, properties)
    }
  }
}

export function useTrackEvent(event: AnalyticsEvent, data?: EventData, options: TrackOptions = {}) {
  useEffect(() => {
    AppAnalytics.track(event, data, options)
  }, [])
}

export function useAnalytics(): AnalyticsClient {
  // we make this look like a hook so it's easier to encapsulate it later
  return AppAnalytics
}

export function useIntercomChatVisible(visible: boolean) {
  useEffect(() => {
    IntercomAnalyticsService.setChatVisible(visible)

    return () => {
      // rest it to what it was before
      IntercomAnalyticsService.setChatVisible('auto')
    }
  }, [])
}

function CombinedAnalyticsScript({ children }: { children: ReactNode[] }) {
  const authClient = useAuthClient()

  useLocationPathChange((location) => {
    AppAnalytics.trackLocationChanged(location)
  })

  useAuthUserChanged((user) => {
    if (user) {
      AppAnalytics.trackUserIdentified(user, { getToken: authClient.getToken })
    } else {
      AppAnalytics.trackUserAnonymous()
    }
  })

  return <>{children}</>
}

function getAnalyticsProviders(): AnalyticsProvider[] {
  switch (getAppType()) {
    case 'organization':
      return [
        SentryAnalyticsService,
        GoogleAnalyticsService,
        BingAnalyticsService,
        IntercomAnalyticsService,
        PosthogAnalyticsService
      ]
    case 'client':
      return [SentryAnalyticsService, PosthogAnalyticsService]
    default:
      return []
  }
}

function log(msg: string, arg?: any) {
  if (arg) {
    console.log(msg, arg)
  } else {
    console.log(msg)
  }
}

function useLocationPathChange(handler: (path: string) => void) {
  const location = usePath()

  useEffect(() => {
    handler(window.location.pathname + window.location.search)
  }, [location])
  /* eslint-enable react-hooks/rules-of-hooks */
}
