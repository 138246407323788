import { clsx } from 'clsx'
import { ReactNode, useState } from 'react'

type Props = {
  children: ReactNode
  className?: string
}
export function FAQList({ className, children }: Props) {
  return (
    <div className={clsx('mx-auto divide-y divide-stone-200 border-y border-stone-200', className)}>
      {children}
    </div>
  )
}

export function FAQ({ title, children }: { title: string; children: ReactNode }) {
  const [expanded, setExpanded] = useState(false)

  return (
    <div className="py-6">
      <div
        className="flex w-full cursor-pointer items-center justify-between text-gray-900"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="items-center text-lg font-semibold">{title}</div>
        {expanded ? <MinusSmallIcon className="size-6" /> : <PlusSmallIcon className="size-6" />}
      </div>
      {expanded && (
        <div className="mt-2 pr-12">
          <p className="text-base text-gray-600">{children}</p>
        </div>
      )}
    </div>
  )
}

function MinusSmallIcon({ className }: { className: string }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M5 12h14" />
    </svg>
  )
}

function PlusSmallIcon({ className }: { className: string }) {
  return (
    <svg
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
    </svg>
  )
}
