import { Link } from '@features/link'
import { SetAppRoute } from '@features/routing'
import { BrandLogo } from '@st/theme'
import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  backHref: SetAppRoute | string
}
export function HeaderNavigation({ children, backHref }: Props) {
  return (
    <div className="sticky flex h-16 w-full flex-row items-center border-b border-gray-300 bg-white">
      <div className="navigation__inner">
        <Link href={backHref}>
          <BrandLogo />
        </Link>

        <div className="grow" />
        <nav className="flex flex-row items-stretch gap-5 text-base">{children}</nav>
      </div>
    </div>
  )
}
