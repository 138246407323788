import { useDerivedState } from '@st/react-util/use-derived-state'
import { useProcess, useProcessState } from '@st/redux'
import { Button, Dialog, DialogButtons, TextArea } from '@st/theme'
import { match } from 'ts-pattern'
import { formatFolderEntityName } from './st-folder-module'
import {
  saveFolder,
  sendQuestionnaireEmail,
  STSendQuestionnaireMessage,
  stSendQuestionnaireModule,
  STSendQuestionnaireState
} from './st-send-questionnaire-module'

export function STRequestItemsDialog({
  folderId,
  onClose
}: {
  folderId: string
  onClose: () => void
}) {
  const stSendQuestionnaire = useProcess(stSendQuestionnaireModule, {
    folderId: folderId,
    templateType: 'document_request'
  })
  const state = useProcessState(stSendQuestionnaireModule)

  if (!state.sendState) {
    return null
  }

  return (
    <STRequestItemsDialogView
      state={state}
      send={stSendQuestionnaire.send}
      onClickSend={async (message) => {
        // deleteDraft true means we delete the draft after sending the email
        // this is to avoid leaving a stale draft
        // if we're talking about requesting missing items, this can go stale quickly
        await stSendQuestionnaire.send(
          sendQuestionnaireEmail({ message: message, deleteDraft: true })
        )
        onClose()
      }}
      onSaveFolder={(message) => {
        stSendQuestionnaire.send(saveFolder({ message: message }))
      }}
      onClose={onClose}
    />
  )
}

function STRequestItemsDialogView({
  state,
  onClickSend,
  onSaveFolder,
  onClose
}: {
  state: STSendQuestionnaireState
  send: (message: STSendQuestionnaireMessage) => void
  onClickSend: (message: string) => void
  onSaveFolder: (message: string) => void
  onClose: () => void
}) {
  const [message, setMessage] = useDerivedState(state.sendState?.message ?? '')

  const sendState = state.sendState!

  if (sendState.folder.questionnaireStatus != 'submitted') {
    return (
      <Dialog
        title="Request documents"
        subtitle={`This feature will get turned on once ${formatFolderEntityName(
          state.sendState?.folder.entities
        )}'s questionnaire has been submitted, to make it easier for you to request documents which are still missing.`}
        className="w-full max-w-2xl"
        buttons={
          <DialogButtons>
            <Button onClick={onClose}>Close</Button>
          </DialogButtons>
        }
      />
    )
  } else if (sendState.vars['missingdocumentscount'] == 0) {
    return (
      <Dialog
        title="Request documents"
        subtitle={`According to ${formatFolderEntityName(
          sendState.folder.entities
        )}'s checklist, there aren't any documents which are still waiting to be received.`}
        buttons={
          <DialogButtons>
            <Button onClick={onClose}>Close</Button>
          </DialogButtons>
        }
      />
    )
  }

  return (
    <Dialog
      title="Request documents"
      subtitle={`${formatFolderEntityName(
        state.sendState?.folder.entities
      )} will receive the email below, with a button to upload missing documents. You can edit the email before sending it.`}
      className="w-full max-w-2xl"
      buttons={
        <DialogButtons>
          <Button
            variant="subtle"
            onClick={() => {
              // we don't want to save drafts here
              onClose()
            }}
          >
            Close
          </Button>

          <Button
            variant="primary"
            disabled={state.status == 'sending'}
            onClick={() => onClickSend(message)}
          >
            {match(state.status)
              .with('sending', () => 'Sending')
              .with('sent', () => 'Sent')
              .otherwise(() => 'Send')}
          </Button>
        </DialogButtons>
      }
    >
      <div className="">
        <label className="font-semibold">To:</label>
        <div className="flex flex-row gap-1">
          {state.emails.map((email, index) => {
            return <div key={email}>{email + (index == state.emails.length - 1 ? '' : ',')}</div>
          })}
        </div>
      </div>
      <TextArea
        className="min-h-96"
        placeholder="Send a message"
        value={message}
        onChange={(value) => setMessage(value)}
      />
    </Dialog>
  )
}
