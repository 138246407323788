import { PDFViewerMessage } from '@features/st-pdf-viewer/module'
import { MessageHandler, defineModule } from '@st/redux'
import { maybe } from '@st/util/array'
import { Rect, Size } from '@st/util/geom'
import { create } from 'mutative'
import { Dispatch, FC } from 'react'
import { match } from 'ts-pattern'

type AnnotationInit = {
  tools: Array<DrawTool<any, any>>
  annots?: Annot[]
}

type AnnotationSend = { self: AnnotationMessage; pdfViewer: PDFViewerMessage }

export type Page = { index: number; size: Size }

export const annotationLayerModule = defineModule<
  AnnotationState,
  AnnotationMessage,
  AnnotationInit,
  AnnotationSend
>({
  name: 'annotationLayer',
  init: ({ tools, annots }) => {
    return {
      activeToolName: tools[0].name,
      activeAnnotId: undefined,
      tools: tools,
      toolStates: Object.fromEntries(tools.map((t) => [t.name, t.init()])),
      annots: Object.fromEntries((annots ?? []).map((a) => [a.id, a])),
      annotDrafts: {},
      sidebarType: undefined,
      pages: []
    }
  },
  handle: (prevState: AnnotationState, event: AnnotationMessage, context) => {
    const resp = annotationLayerHandle(prevState, event, context, {})

    var [nextState, nextSend] = Array.isArray(resp) ? resp : [resp, {}]

    if (prevState.activeAnnotId && prevState.activeAnnotId != nextState.activeAnnotId) {
      const deselectedAnnot = selAnnot(prevState, prevState.activeAnnotId)
      const MatchingTool = prevState.tools.find((t) => t.matches(deselectedAnnot))!
      if (MatchingTool.handle) {
        var toolState = nextState.toolStates[MatchingTool.name]
        var [newToolState, toolSend] = MatchingTool.handle(toolState, {
          type: 'deselect',
          annot: deselectedAnnot
        })

        nextState = create(nextState, (s) => {
          s.toolStates[MatchingTool.name] = newToolState
        })
        nextSend = create(nextSend, (s) => {
          s.self = [...toArray(s.self), ...toArray(toolSend.annotationLayer)]
        })
      }
    }

    if (prevState.activeToolName && prevState.activeToolName != nextState.activeToolName) {
      const MatchingTool = prevState.tools.find((t) => t.name == prevState.activeToolName)!
      if (MatchingTool.handle) {
        var toolState = nextState.toolStates[MatchingTool.name]
        var [newToolState, toolSend] = MatchingTool.handle(toolState, {
          type: 'deactivate'
        })

        nextState = create(nextState, (s) => {
          s.toolStates[MatchingTool.name] = newToolState
        })
        nextSend = create(nextSend, (s) => {
          s.self = [...toArray(s.self), ...toArray(toolSend.annotationLayer)]
        })
      }
    }

    // const [newState, send]: AnnotationSend
    return [nextState, nextSend]
  }
})

function toArray<T>(value: T | T[] | undefined): T[] {
  if (value === undefined) return []
  else if (Array.isArray(value)) return value
  else return [value]
}

const annotationLayerHandle: MessageHandler<
  AnnotationState,
  AnnotationMessage,
  AnnotationSend,
  {}
> = (state, message) => {
  switch (message.type) {
    case 'load':
      return {
        activeToolName: state.tools[0].name,
        activeAnnotId: undefined,
        tools: state.tools,
        toolStates: Object.fromEntries(state.tools.map((t) => [t.name, t.init()])),
        annots: Object.fromEntries(message.annots.map((a) => [a.id, a])),
        annotDrafts: {},
        sidebarType: undefined,
        pages: []
      }
    case 'selectAnnot':
      const selectedAnnot = selAnnot(state, message.annotId)
      const matchingTool = state.tools.find((t) => t.matches(selectedAnnot))

      const sidebarType = match<string, SidebarType | undefined>(selectedAnnot.type)
        .with('comment', () => 'comment')
        .with('field', () => 'field')
        .otherwise(() => state.sidebarType)

      return [
        {
          ...state,
          activeAnnotId: message.annotId,
          activeToolName: matchingTool?.name,
          sidebarType: sidebarType
        },
        {
          pdfViewer: message.scrollIntoView
            ? [
                {
                  type: 'scrollTo',
                  page: selectedAnnot.page,
                  rect: selectedAnnot.bounds
                }
              ]
            : []
        }
      ]
    case 'deselectAnnot':
      return { ...state, activeAnnotId: undefined }
    case 'selectTool':
      return create(state, (s) => {
        s.activeAnnotId = undefined
        s.activeToolName = message.toolName
        if (message.toolName == 'comment') {
          s.sidebarType = 'comment'
        }
      })
    case 'updateTool':
      return create(state, (s) => {
        s.toolStates[message.tool.name] = message.tool
      })
    case 'addAnnotDraft':
      var newState = create(state, (s) => {
        s.annotDrafts[message.annot.id] = message.annot
        s.activeAnnotId = message.annot.id
      })
      return [
        newState,
        {
          self: [
            ...maybe(
              message.save
                ? {
                    type: 'annotSave',
                    annot: newState.annotDrafts[message.annot.id]
                  }
                : null
            )
          ] as AnnotationMessage[]
        }
      ]
    case 'annotDraftEdited':
      var newState = create(state, (s) => {
        s.annotDrafts[message.annot.id] = message.annot
      })
      return message.save
        ? [
            newState,
            {
              self: {
                type: 'annotSave',
                annot: newState.annotDrafts[message.annot.id]
              }
            }
          ]
        : newState
    case 'annotDraftCancelled':
      return create(state, (d) => {
        d.activeAnnotId = undefined
        delete d.annotDrafts[message.annotId]
      })
    case 'annotDraftMove':
    case 'annotDraftResize':
      var newState = create(state, (s) => {
        if (!s.annotDrafts[message.annotId]) {
          s.annotDrafts[message.annotId] = s.annots[message.annotId]
        }
        s.annotDrafts[message.annotId].bounds = message.bounds
      })
      return message.phase == 'commit'
        ? [
            newState,
            {
              self: {
                type: 'annotSave',
                annot: newState.annotDrafts[message.annotId]
              }
            }
          ]
        : newState
    case 'annotSave':
      return state
    case 'annotSaveCompleted':
      // const savedAnnot = message.annot
      // // we clear out the draft
      // // if saved annot and draft annot are matching it  means
      // // we succesfully saved and the draft is redundant
      // // hack - we only do this for comments for the time being
      // if (savedAnnot.type == 'comment') {
      //   return create(state, (s) => {
      //     delete s.annotDrafts[message.annot.id]
      //   })
      // }
      return state
    case 'annotDelete':
      return create(state, (s) => {
        // we want to deselect if we're deleting the selected
        // annotation
        if (s.activeAnnotId == message.annotId) {
          s.activeAnnotId = undefined
        }
        if (message.annotId in s.annotDrafts) {
          delete s.annotDrafts[message.annotId]
        }
      })
    case 'toggleSidebar':
      if (state.sidebarType == message.sidebarType) {
        return { ...state, sidebarType: undefined }
      } else {
        return { ...state, sidebarType: message.sidebarType }
      }
  }
}

type BackgroundElementProps<A, T> = {
  draft: A | undefined
  tool: T
  send: Dispatch<AnnotationMessage>
}

type ElementProps<A, T> = {
  annot: A
  tool: T
  selected: boolean
  send: Dispatch<AnnotationMessage>
}

type ToolMessage<A extends Annot> =
  | {
      type: 'deselect'
      annot: A
    }
  | { type: 'deactivate' }

type ToolSend = { annotationLayer: Array<AnnotDelete | AnnotSave> }

export type DrawTool<A extends Annot, T extends Tool> = {
  name: string
  matches: (annot: Annot) => boolean

  init: () => T

  /**
   * When a particular tool is selected, the corresponding background element will be activated.
   *
   * For example
   * - the RectBackgroundElement will provide a background where you can click and drag to draw rects.
   * - the RectBackgroundElement will provide a background where you can click and drag to draw rects.
   */
  CanvasBackgroundElement: FC<BackgroundElementProps<A, T>>

  /**
   * The element of the actual annotation positioned in the x-y coordinate space.
   * There is one per each annotation.
   */
  Element?: FC<ElementProps<A, T>>

  handle?: (state: T, message: ToolMessage<A>) => [T, ToolSend]

  // onDeactivate?: (toolState: T, annot: Annot) => AnnotationMessage[]
}

export type Font = { family: string; weight: 'bold' | 'normal'; size: number }

export type Border = {
  width: number
  color: string
}

export type Tool = { name: string } & Record<string, any>
type ToolName = string

export type Annot = {
  type: string
  id: string
  bounds: Rect
  page: number
} & Record<string, any>

export type AnnotationState = {
  annots: Record<string, Annot>
  pages: Page[]
  /**
   * This will be a copy of the currently selected annotation
   */
  annotDrafts: Record<string, Annot>

  tools: Array<DrawTool<any, any>>
  toolStates: Record<string, Tool>

  activeToolName: string | undefined
  activeAnnotId: string | undefined

  sidebarType: SidebarType | undefined
}

type SidebarType = 'field' | 'comment'

type Load = { type: 'load'; annots: Annot[] }
type AnnotSelected = { type: 'selectAnnot'; annotId: string; scrollIntoView?: boolean }
type DeselectAnnot = { type: 'deselectAnnot' }
type SelectTool = { type: 'selectTool'; toolName: ToolName }
export type UpdateTool = { type: 'updateTool'; tool: Tool }
type AnnotDraftAdded = { type: 'addAnnotDraft'; annot: Annot; save?: boolean }
type AnnotDraftEdited = { type: 'annotDraftEdited'; annot: Annot; save?: boolean }
type AnnotSave = { type: 'annotSave'; annot: Annot }
type AnnotSaveCompleted = { type: 'annotSaveCompleted'; annot: Annot }
type AnnotDelete = { type: 'annotDelete'; annotId: string }
type AnnotDraftMove = {
  type: 'annotDraftMove'
  annotId: string
  bounds: Rect
  phase: 'start' | 'continue' | 'commit'
}
type AnnotDraftResize = {
  type: 'annotDraftResize'
  annotId: string
  bounds: Rect
  phase: 'start' | 'continue' | 'commit'
}
type AnnotDraftCancelled = { type: 'annotDraftCancelled'; annotId: string }
type SidebarSet = { type: 'toggleSidebar'; sidebarType: SidebarType }

export type AnnotationMessage =
  | Load
  | AnnotSelected
  | DeselectAnnot
  | SelectTool
  | UpdateTool
  | AnnotDraftAdded
  | AnnotDraftEdited
  | AnnotSave
  | AnnotSaveCompleted
  | AnnotDelete
  | AnnotDraftMove
  | AnnotDraftResize
  | AnnotDraftCancelled
  | SidebarSet

export function selAnnot(state: AnnotationState, annotId: string) {
  return state.annotDrafts[annotId] ?? state.annots[annotId]
}

export function selSelectedAnnot(state: AnnotationState) {
  if (!state.activeAnnotId) {
    return undefined
  }
  return state.annotDrafts[state.activeAnnotId] ?? state.annots[state.activeAnnotId]
}

export function selDrawingAnnots(state: AnnotationState) {
  const annots = { ...state.annots, ...state.annotDrafts }
  return Object.values(annots)
}
