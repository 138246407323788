import { RouteMessage } from '@features/route-module'
import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { LookupOrCreateFolderByEmailResult } from '@st/sdk'
import { match } from 'ts-pattern'

export type STQuestionnaireLookupState =
  | {
      status: 'idle'
      organizationId: string
      schemaId: string
    }
  | {
      status: 'running'
      organizationId: string
      schemaId: string
      email: string
    }
  | {
      status: 'completed'
      organizationId: string
      schemaId: string
      email: string
      result: LookupOrCreateFolderByEmailResult
    }

export type STQuestionnaireLookupMessage = { type: 'lookup'; email: string } | SDKResponse

type STQuestionnaireLookupInit = { organizationId: string; schemaId: string }

export const stQuestionnaireLookupModule = defineModule<
  STQuestionnaireLookupState,
  STQuestionnaireLookupMessage,
  STQuestionnaireLookupInit,
  { sdk: SDKMessage; route: RouteMessage }
>({
  name: 'stQuestionnaireLookup',
  init: ({ organizationId, schemaId }) => {
    return {
      status: 'idle',
      organizationId: organizationId,
      schemaId: schemaId
    }
  },
  handle: (state, message) => {
    return match({ state, message })
      .with({ state: { status: 'idle' }, message: { type: 'lookup' } }, ({ state, message }) => {
        return [
          {
            status: 'running',
            organizationId: state.organizationId,
            schemaId: state.schemaId,
            email: message.email
          },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/lookupOrCreateFolderByEmail',
                organizationId: state.organizationId,
                schemaId: state.schemaId,
                email: message.email
              }
            }
          }
        ] satisfies [STQuestionnaireLookupState, { sdk: SDKMessage }]
      })
      .with(
        {
          state: { status: 'running' },
          message: { type: 'response', operation: { type: 'folders/lookupOrCreateFolderByEmail' } }
        },
        ({ state, message }) => {
          return {
            status: 'completed',
            organizationId: state.organizationId,
            schemaId: state.schemaId,
            email: state.email,
            result: message.operation.response
          } satisfies STQuestionnaireLookupState
        }
      )
      .otherwise(({ state }) => {
        return state
      })
  }
})
