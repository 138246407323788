import { maxBy, minBy } from '../sort'

export type Rect = {
  x: number
  y: number
  width: number
  height: number
}

export type NormalizedRect = Rect
export type NormalizedPoint = { x: number; y: number }

export type Point = { x: number; y: number }
export type Offset = { dx: number; dy: number }
export type Size = { width: number; height: number }

export { LinearTransform } from './transform'

export function move<T extends Point>(point: T, offset: Partial<Offset>): T {
  return {
    ...point,
    x: point.x + (offset.dx ?? 0),
    y: point.y + (offset.dy ?? 0)
  }
}

export enum Anchor {
  topLeft = 'topLeft',
  topCenter = 'topCenter',
  topRight = 'topRight',
  centerLeft = 'centerLeft',
  center = 'center',
  centerRight = 'centerRight',
  bottomLeft = 'bottomLeft',
  bottomCenter = 'bottomCenter',
  bottomRight = 'bottomRight'
}

export function otherSide(anchor: Anchor): Anchor {
  switch (anchor) {
    case Anchor.topLeft:
      return Anchor.bottomRight
    case Anchor.topCenter:
      return Anchor.bottomCenter
    case Anchor.topRight:
      return Anchor.bottomLeft
    case Anchor.centerLeft:
      return Anchor.centerRight
    case Anchor.center:
      return Anchor.center
    case Anchor.centerRight:
      return Anchor.centerLeft
    case Anchor.bottomLeft:
      return Anchor.topRight
    case Anchor.bottomCenter:
      return Anchor.topCenter
    case Anchor.bottomRight:
      return Anchor.topLeft
  }
}
type AnchorOptions = {
  follower: Rect | Size
  followerAnchor: Anchor
  target: Rect
  targetAnchor: Anchor
  followerOffset?: Partial<Offset>
}

export function anchored({
  follower,
  followerAnchor,
  target,
  targetAnchor,
  followerOffset = { dx: 0, dy: 0 }
}: AnchorOptions): Rect {
  const followerAnchorPoint = atAnchor(
    { x: 0, y: 0, width: follower.width, height: follower.height },
    followerAnchor
  )

  const targetAnchorPoint = atAnchor(target, targetAnchor)

  return move(
    {
      x: targetAnchorPoint.x - followerAnchorPoint.x,
      y: targetAnchorPoint.y - followerAnchorPoint.y,
      width: follower.width,
      height: follower.height
    },
    followerOffset
  )
}

const ANCHOR_OFFSETS: Record<Anchor, Offset> = {
  [Anchor.topLeft]: { dx: 0, dy: 0 },
  [Anchor.topCenter]: { dx: 0.5, dy: 0 },
  [Anchor.topRight]: { dx: 1, dy: 0 },
  [Anchor.centerLeft]: { dx: 0, dy: 0.5 },
  [Anchor.center]: { dx: 0.5, dy: 0.5 },
  [Anchor.centerRight]: { dx: 1, dy: 0.5 },
  [Anchor.bottomLeft]: { dx: 0, dy: 1 },
  [Anchor.bottomCenter]: { dx: 0.5, dy: 1 },
  [Anchor.bottomRight]: { dx: 1, dy: 1 }
}
export function atAnchor(rect: Rect, anchor: Anchor): Point {
  const { dx, dy } = ANCHOR_OFFSETS[anchor]
  return { x: rect.x + rect.width * dx, y: rect.y + rect.height * dy }
}

type RectPoints = {
  topLeft: Point
  bottomLeft: Point
  topRight: Point
  bottomRight: Point
}
export function getRectPoints(rect: Rect): RectPoints {
  return {
    topLeft: { x: rect.x, y: rect.y },
    bottomLeft: { x: rect.x, y: rect.y + rect.height },
    topRight: { x: rect.x + rect.width, y: rect.y },
    bottomRight: { x: rect.x + rect.width, y: rect.y + rect.height }
  }
}

export function boundingRectForPolygon(points: Point[]): Rect {
  return {
    x: minBy(points, (p) => p.x)!.x,
    y: minBy(points, (p) => p.y)!.y,
    width: maxBy(points, (p) => p.x)!.x - minBy(points, (p) => p.x)!.x,
    height: maxBy(points, (p) => p.y)!.y - minBy(points, (p) => p.y)!.y
  }
}

/**
 * Given the points of 2 opposite corners in a rectangle, create a rect
 *
 * @param a
 * @param b
 * @returns
 */
export function rectFromPoints(a: Point, b: Point): Rect {
  const topLeft: Point = { x: Math.min(a.x, b.x), y: Math.min(a.y, b.y) }
  const bottomRight: Point = { x: Math.max(a.x, b.x), y: Math.max(a.y, b.y) }
  return {
    x: topLeft.x,
    y: topLeft.y,
    width: bottomRight.x - topLeft.x,
    height: bottomRight.y - topLeft.y
  }
}
