export function DownloadIcon(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16" fill="none">
      <path
        d="M3.14625 7.35375C3.05243 7.25993 2.99972 7.13268 2.99972 7C2.99972 6.86732 3.05243 6.74007 3.14625 6.64625C3.24007 6.55243 3.36732 6.49972 3.5 6.49972C3.63268 6.49972 3.75993 6.55243 3.85375 6.64625L7.5 10.2931V2C7.5 1.86739 7.55268 1.74021 7.64645 1.64645C7.74021 1.55268 7.86739 1.5 8 1.5C8.13261 1.5 8.25979 1.55268 8.35355 1.64645C8.44732 1.74021 8.5 1.86739 8.5 2V10.2931L12.1462 6.64625C12.1927 6.59979 12.2479 6.56294 12.3086 6.5378C12.3692 6.51266 12.4343 6.49972 12.5 6.49972C12.5657 6.49972 12.6308 6.51266 12.6914 6.5378C12.7521 6.56294 12.8073 6.59979 12.8538 6.64625C12.9002 6.6927 12.9371 6.74786 12.9622 6.80855C12.9873 6.86925 13.0003 6.9343 13.0003 7C13.0003 7.0657 12.9873 7.13075 12.9622 7.19145C12.9371 7.25214 12.9002 7.3073 12.8538 7.35375L8.35375 11.8538C8.30731 11.9002 8.25217 11.9371 8.19147 11.9623C8.13077 11.9874 8.06571 12.0004 8 12.0004C7.93429 12.0004 7.86923 11.9874 7.80853 11.9623C7.74783 11.9371 7.69269 11.9002 7.64625 11.8538L3.14625 7.35375ZM13.5 13H2.5C2.36739 13 2.24021 13.0527 2.14645 13.1464C2.05268 13.2402 2 13.3674 2 13.5C2 13.6326 2.05268 13.7598 2.14645 13.8536C2.24021 13.9473 2.36739 14 2.5 14H13.5C13.6326 14 13.7598 13.9473 13.8536 13.8536C13.9473 13.7598 14 13.6326 14 13.5C14 13.3674 13.9473 13.2402 13.8536 13.1464C13.7598 13.0527 13.6326 13 13.5 13Z"
        fill="currentColor"
      />
    </svg>
  )
}
