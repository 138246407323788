import { clsx } from 'clsx'
import { ReactNode } from 'react'

export function CTACard({
  icon,
  message,
  button,
  className
}: {
  entityName?: string
  icon?: ReactNode
  message?: string
  button?: ReactNode
  className?: string
}) {
  return (
    <div
      className={clsx(
        'flex flex-col items-center rounded-md bg-stone-100 p-6 font-sans',
        className
      )}
    >
      {icon && icon}
      <p className="mt-6 font-sans text-base text-gray-700">{message}</p>
      {button}
    </div>
  )
}
