import { platformModule } from '@features/st-pdf-viewer/platform-module'
import { useDerivedState } from '@st/react-util/use-derived-state'
import { useProcess, useProcessState } from '@st/redux'
import { Button, Dialog, DialogButtons, EmailsChipInput, TextArea } from '@st/theme'
import { formatFolderEntityName } from './st-folder-module'
import {
  saveFolder,
  STSendQuestionnaireMessage,
  stSendQuestionnaireModule,
  STSendQuestionnaireState
} from './st-send-questionnaire-module'

/**
 * There's a screen which is table of folders in which an accountant
 * can send out questionnaires to all of them in bulk.
 *
 * In some cases they want to edit the message for a single client or
 * edit the emails which determine who gets sent the questionnaire.
 *
 * This dialog provides that editing functionality.
 */
export function STBulkSendEditQuestionnaireDialog({
  folderId,
  onClose
}: {
  folderId: string
  onClose: () => void
}) {
  const platform = useProcess(platformModule)
  const stSendQuestionnaire = useProcess(stSendQuestionnaireModule, {
    folderId: folderId,
    templateType: 'folder_invite'
  })
  const state = useProcessState(stSendQuestionnaireModule)

  if (!state.sendState) {
    return null
  }

  return (
    <STBulkSendEditQuestionnaireDialogView
      state={state}
      send={stSendQuestionnaire.send}
      onClose={onClose}
      onSave={async (message) => {
        await stSendQuestionnaire.send(saveFolder({ message: message }))
        platform.send({ type: 'showSnackbar', message: 'Saved' })
        onClose()
      }}
    />
  )
}

function STBulkSendEditQuestionnaireDialogView({
  state,
  onSave,
  onClose,
  send
}: {
  state: STSendQuestionnaireState
  send: (message: STSendQuestionnaireMessage) => void
  onSave: (message: string) => void
  onClose: () => void
}) {
  const [message, setMessage] = useDerivedState(state.sendState?.message ?? '')

  return (
    <Dialog
      title={`Email to ${formatFolderEntityName(state.sendState?.folder?.entities)}`}
      subtitle={`You can edit the email for ${formatFolderEntityName(
        state.sendState?.folder?.entities
      )} below.`}
      className="w-full max-w-2xl"
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>

          <Button
            variant="primary"
            disabled={state.status == 'sending'}
            onClick={() => onSave(message)}
          >
            {state.savingFolder ? 'Saving...' : 'Save'}
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="">
          <label className="font-semibold">To:</label>
          <EmailsChipInput
            className="w-full"
            emails={state.emails}
            inputValue={state.inputValue}
            onInputChange={(v) => send({ type: 'typeEmail', email: v })}
            selectedEmail={state.selectedEmail}
            onSelectedEmailChange={(v) => send({ type: 'selectEmail', email: v })}
            onAdd={(email) => send({ type: 'addEmail', email })}
            onDelete={(email) => send({ type: 'removeEmail', email })}
          />
        </div>
        <TextArea
          className="min-h-96"
          placeholder="Send a message"
          value={message}
          onChange={(value) => setMessage(value)}
        />
      </div>
    </Dialog>
  )
}
