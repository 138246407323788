import { ReactNode } from 'react'

type Props = {
  children: ReactNode
  translateX?: number
}
export function NoteTip({ children, translateX }: Props) {
  return (
    <div className="note-tip">
      <div className="note-tip__container" style={{ left: translateX }}>
        <div className="note-tip__arrow" />
        <div className="note-tip__content text-black">{children}</div>
      </div>
    </div>
  )
}
