import { BlobURLHandler } from './handlers/blob-url-handler'
import { ContentURLHandler } from './handlers/content-url-handler'
import { DataURLHandler } from './handlers/data-url-handler'
import { URLProtocol } from './types'
export * from './types'

export async function createURL(
  protocol: URLProtocol,
  blob: Blob
): Promise<string> {
  switch (protocol) {
    case 'data':
      return DataURLHandler.createURL(blob)
    case 'blob':
      return BlobURLHandler.createURL(blob)
    case 'content':
      return ContentURLHandler.createURL(blob)
  }
}

export function fetchURL(url: string): Promise<Response> {
  switch (getURLProtocol(url)) {
    case 'data':
      return DataURLHandler.fetch(url)
    case 'blob':
      return BlobURLHandler.fetch(url)
    case 'content':
      return ContentURLHandler.fetch(url)
    default:
      return fetch(url)
  }
}

export async function revokeURL(url: string) {
  switch (getURLProtocol(url)) {
    case 'data':
      return DataURLHandler.revokeURL(url)
    case 'blob':
      return BlobURLHandler.revokeURL(url)
    case 'content':
      return ContentURLHandler.revokeURL(url)
    default:
    // do nothing
  }
}

export function getURLProtocol(url: string) {
  // take substring to avoid searching a long string for a colon
  // 15 is arbitrary but is long enough for protocols
  const urlPart = url.substring(0, 15)
  const colonIndex = urlPart.indexOf(':')
  if (colonIndex == -1) {
    return undefined
  }
  return urlPart.substring(0, colonIndex)
}
