import { ComponentConfig } from '@st/folder/ui-config'
import { ADDRESS_KEYS, HUMAN_NAME_KEYS } from '@st/tax-folder'
import { JsonMap, isEmpty } from '@st/util/json-value'

type ComponentValidationError = {
  type: 'missing'
  keys: string[]
  label?: string
}

export type QuestionnairePageValidationResult =
  | { ok: true }
  | { ok: false; errors: ComponentValidationError[] }

export function validateQuestionnairePage(
  components: ComponentConfig[],
  inputs: JsonMap
): QuestionnairePageValidationResult {
  const errors: ComponentValidationError[] = []

  for (const component of components) {
    const err = validateComponent(component, inputs)
    if (err) errors.push(err)
  }

  if (errors.length > 0) {
    console.error('validation failed', errors)
  }

  return errors.length == 0 ? { ok: true } : { ok: false, errors }
}

export function formatValidationErrors(errors: ComponentValidationError[]): string {
  return `Please complete the required ${errors.length > 1 ? 'questions' : 'question'} to continue`
}

function validateComponent(
  c: ComponentConfig,
  inputs: JsonMap
): ComponentValidationError | undefined {
  if (!('isUserInputRequired' in c)) return
  if (!c.isUserInputRequired) return

  switch (c.__typename) {
    case 'TextInputConfig':
    case 'MoneyInputConfig':
    case 'DateInputConfig':
    case 'DropdownConfig':
    case 'RadioSelectConfig':
      if (isEmpty(inputs[c.userInputKey])) {
        return { type: 'missing', keys: [c.userInputKey], label: c.label ?? undefined }
      }
      break
    case 'MultiSelectConfig':
      if (isEmpty(inputs[c.userInputKey])) {
        return { type: 'missing', keys: [c.userInputKey] }
      }
      break
    case 'NumberStepperConfig':
      if (isEmpty(inputs[c.userInputKey]) || inputs[c.userInputKey] == 0) {
        return { type: 'missing', keys: [c.userInputKey] }
      }
      break
    case 'YesNoConfig':
      if (inputs[c.userInputKey] !== true && inputs[c.userInputKey] !== false) {
        return { type: 'missing', keys: [c.userInputKey] }
      }
      break
    case 'NameInputConfig':
      var keys = HUMAN_NAME_KEYS.map((k) => `${c.userInputKey}.${k}`)
      var missingKeys = keys.filter((k) => isEmpty(inputs[k]))
      if (missingKeys.length > 0) {
        return { type: 'missing', keys: missingKeys }
      }
      break
    case 'AddressInputConfig':
      var missingKeys = ADDRESS_KEYS.map((k) => `${c.userInputKey}.${k}`).filter((k) =>
        isEmpty(inputs[k])
      )
      if (missingKeys.length > 0) {
        return { type: 'missing', keys: missingKeys }
      }
      break
  }
}
