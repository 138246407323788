export type Result<T> =
  | {
      success: true
      position: number
      value: T
    }
  | {
      success: false
      position: number
      error: string
    }

export type Parser<T, S> = (target: T, position: number) => Result<S>

export function seq<T, P0, P1>(
  ...parsers: [Parser<T, P0>, Parser<T, P1>]
): Parser<T, [P0, P1]>
export function seq<T, P0, P1, P2>(
  ...parsers: [Parser<T, P0>, Parser<T, P1>, Parser<T, P2>]
): Parser<T, [P0, P1, P2]>
export function seq<T, P0, P1, P2, P3>(
  ...parsers: [Parser<T, P0>, Parser<T, P1>, Parser<T, P2>, Parser<T, P3>]
): Parser<T, [P0, P1, P2, P3]>
export function seq<T, P0, P1, P2, P3, P4>(
  ...parsers: [
    Parser<T, P0>,
    Parser<T, P1>,
    Parser<T, P2>,
    Parser<T, P3>,
    Parser<T, P4>
  ]
): Parser<T, [P0, P1, P2, P3, P4]>
export function seq<T, P0, P1, P2, P3, P4, P5>(
  ...parsers: [
    Parser<T, P0>,
    Parser<T, P1>,
    Parser<T, P2>,
    Parser<T, P3>,
    Parser<T, P4>,
    Parser<T, P5>
  ]
): Parser<T, [P0, P1, P2, P3, P4, P5]>
export function seq<T, P0, P1, P2, P3, P4, P5, P6>(
  ...parsers: [
    Parser<T, P0>,
    Parser<T, P1>,
    Parser<T, P2>,
    Parser<T, P3>,
    Parser<T, P4>,
    Parser<T, P5>,
    Parser<T, P6>
  ]
): Parser<T, [P0, P1, P2, P3, P4, P5, P6]>
export function seq<T, P0>(...parsers: Parser<T, P0>[]): Parser<T, P0[]>
export function seq<T, S>(...parsers: Parser<T, S>[]): Parser<T, S[]> {
  return (target, position) => {
    const result: S[] = []
    for (const parser of parsers) {
      const parsed = parser(target, position)
      if (parsed.success === true) {
        result.push(parsed.value)
        position = parsed.position
      } else {
        return {
          success: false,
          position,
          error: `seq@${position}: ${parsed.error}`
        }
      }
    }
    return {
      success: true,
      value: result,
      position
    }
  }
}

export function or<T, S>(...parsers: Parser<T, S>[]): Parser<T, S>
export function or<T, P0, P1>(
  ...parsers: [Parser<T, P0>, Parser<T, P1>]
): Parser<T, P0 | P1>
export function or<T, P0, P1, P2>(
  ...parsers: [Parser<T, P0>, Parser<T, P1>, Parser<T, P2>]
): Parser<T, P0 | P1 | P2>
export function or<T, P0, P1, P2, P3>(
  ...parsers: [Parser<T, P0>, Parser<T, P1>, Parser<T, P2>, Parser<T, P3>]
): Parser<T, P0 | P1 | P2 | P3>
export function or<T, P0, P1, P2, P3, P4>(
  ...parsers: [
    Parser<T, P0>,
    Parser<T, P1>,
    Parser<T, P2>,
    Parser<T, P3>,
    Parser<T, P4>
  ]
): Parser<T, P0 | P1 | P2 | P3 | P4>
export function or<T, P0, P1, P2, P3, P4, P5>(
  ...parsers: [
    Parser<T, P0>,
    Parser<T, P1>,
    Parser<T, P2>,
    Parser<T, P3>,
    Parser<T, P4>,
    Parser<T, P5>
  ]
): Parser<T, P0 | P1 | P2 | P3 | P4 | P5>
export function or<T, V>(...parsers: Parser<T, V>[]): Parser<T, V> {
  return (target, position) => {
    const errors: string[] = []
    for (const parser of parsers) {
      const parsed = parser(target, position)
      if (parsed.success === true) {
        return parsed
      } else {
        errors.push(parsed.error)
      }
    }
    return {
      success: false,
      position,
      error: `or@${position}: expected ${errors.join(' or ')}`
    }
  }
}

export function lazy<T, S>(generator: () => Parser<T, S>): Parser<T, S> {
  let parser: Parser<T, S>
  return (target, position) => {
    if (parser === undefined) {
      parser = generator()
    }
    return parser(target, position)
  }
}

export function opt<T, S>(
  parser: Parser<T, S>,
  defaultValue?: S
): Parser<T, S | undefined> {
  return (target, position) => {
    const result = parser(target, position)
    if (result.success === true) {
      return result
    }
    return {
      success: true,
      value: defaultValue,
      position: position
    }
  }
}

export function many<T, S>(parser: Parser<T, S>): Parser<T, S[]> {
  return (target, position) => {
    const result = []
    while (true) {
      const parsed = parser(target, position)
      if (parsed.success) {
        result.push(parsed.value)
        position = parsed.position
      } else {
        break
      }
    }
    if (result.length === 0) {
      return {
        success: false,
        position,
        error: `many@${position}: cannot parse`
      }
    }
    return {
      success: true,
      value: result,
      position
    }
  }
}

export function map<T, S, U>(
  parser: Parser<T, S>,
  transform: (value: S) => U
): Parser<T, U> {
  return (target, position) => {
    const result = parser(target, position)
    if (result.success === true) {
      return {
        success: true,
        value: transform(result.value),
        position: result.position
      }
    } else {
      return {
        success: false,
        position,
        error: `map@${position}: ${result.error}`
      }
    }
  }
}

export const transform = <T, S, U>(
  transformParser: Parser<T, S>,
  parser: Parser<S, U>
): Parser<T, U> => {
  return (target, position) => {
    const result = transformParser(target, position)
    if (!result.success) {
      return {
        success: false,
        position,
        error: `transform@${position}: ${result.position}`
      }
    }
    return parser(result.value, 0)
  }
}

export function fail<T>(_: T, position: number) {
  return {
    success: false,
    position,
    error: `fail@${position}`
  }
}

export function pass<T>(target: T[], position: number) {
  return {
    success: true,
    value: target[position],
    position: position + 1
  }
}

export function seqMap<T, S, R>(
  parser: Parser<T, S>,
  next: (value: S) => Parser<T, R>
): Parser<T, R> {
  return (target, position) => {
    const result = parser(target, position)
    if (result.success === false) {
      return {
        success: false,
        position,
        error: result.error
      }
    }
    return next(result.value)(target, result.position)
  }
}

export function vec<T, S>(parser: Parser<T, S>, size: number): Parser<T, S[]> {
  return (target, position) => {
    const result = []
    for (let i = 0; i < size; i++) {
      const parsed = parser(target, position)
      if (parsed.success === true) {
        result.push(parsed.value)
        position = parsed.position
      } else {
        return {
          success: false,
          position,
          error: `vec@${position}: ${parsed.error}`
        }
      }
    }
    return { success: true, value: result, position }
  }
}

// this does not advance the position, but succeeds to parse and returns result
export function terminate<S, T>(result: T): Parser<S, T> {
  return (_, position) => ({ success: true, value: result, position })
}
