import { clsx } from 'clsx'
import { EventHandler, ReactNode, SyntheticEvent } from 'react'

export function SideNavLink({
  children,
  active,
  href,
  onClick
}: {
  children?: ReactNode
  active?: boolean
  href?: string
  onClick?: EventHandler<SyntheticEvent<unknown, Event>>
}) {
  return (
    <a
      href={href}
      onClick={onClick}
      className={clsx(
        'flex h-10 items-center border-l-4 pl-10 pr-5 text-base',
        active && 'border-blue-500 bg-blue-100 font-semibold text-blue-500',
        !active && 'border-transparent'
      )}
    >
      {children}
    </a>
  )
}
