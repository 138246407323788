type Props = {
  className?: string
}
export function CopyIcon({ className }: Props) {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path d="m0 0h24v24h-24z" fill="none" />
      <path
        d="m16 1h-12c-1.1 0-2 .9-2 2v14h2v-14h12zm3 4h-11c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16h-11v-14h11z"
        fill="currentColor"
      />
    </svg>
  )
}
