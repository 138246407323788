import {
  Annot,
  AnnotationMessage,
  Border,
  DrawTool,
  ElementContainer,
  MoveEventHandler,
  SelectedElementContainer,
  useDrawContext,
  useMoveable,
  useUpdateBounds
} from '@features/st-annotation-layer'
import { useDebouncedCallback } from '@st/react-util/use-debounced-callback'
import { cuid } from '@st/util/cuid'
import { Rect, rectFromPoints } from '@st/util/geom'
import { InlineColorPicker } from '@theme'
import { create } from 'mutative'
import { Dispatch } from 'react'
import { P, match } from 'ts-pattern'
import { AnnotationToolbar, DELETE_OPTION } from './annotation-toolbar'

const RECT_COLOR_OPTIONS = ['#FF0000', '#FFCD45', '#59BE8D']

type Rectangle = Annot & {
  type: 'rectangle'
  id: string
  bounds: Rect
}

export const RECT_TOOL: DrawTool<Rectangle, RectTool> = {
  name: 'rect',
  init: () => {
    return {
      name: 'rect',
      border: { color: RECT_COLOR_OPTIONS[0], width: 6 }
    }
  },
  CanvasBackgroundElement: RectCanvasTool,
  Element: RectCanvasElement,
  matches: (annot) => annot.type == 'rectangle'
}

export type RectTool = {
  name: 'rect'
  border: Border
}

export function RectToolbar({
  annot,
  tool,
  send
}: {
  tool: RectTool
  annot: Rectangle | undefined
  send: Dispatch<AnnotationMessage>
}) {
  const border = match({ annot, tool })
    .with({ annot: P.nonNullable }, ({ annot }) => annot.border)
    .otherwise(() => tool.border)

  const saveAnnotDebounced = useDebouncedCallback(
    (annotToSave: Rectangle) => {
      send({ type: 'annotSave', annot: annotToSave })
    },
    2000,
    [send]
  )

  return (
    <div className="flex flex-row gap-4">
      <InlineColorPicker
        options={RECT_COLOR_OPTIONS}
        value={border.color}
        onChange={(c) => {
          if (annot) {
            const newAnnot = create(annot, (d) => {
              d.border.color = c
            })
            send({ type: 'annotDraftEdited', annot: newAnnot, save: true })
          }

          // remember color for next time
          send({
            type: 'updateTool',
            tool: create(tool, (t) => {
              t.border.color = c
            })
          })
        }}
      />
      <input
        type="range"
        min="1"
        max="10"
        value={border.width}
        onChange={(e) => {
          if (annot) {
            const newAnnot = create(annot, (d) => {
              d.border.width = Number(e.target.value)
            })
            send({ type: 'annotDraftEdited', annot: newAnnot })
            // dragging the slider would trigger a lot of save events, so we need to debounce the save
            saveAnnotDebounced(newAnnot)
          }

          // remember border width for the next time
          send({
            type: 'updateTool',
            tool: create(tool, (t) => {
              t.border.width = Number(e.target.value)
            })
          })
        }}
      />
    </div>
  )
}

type RectCanvasElementProps = {
  selected?: boolean
  annot: Rectangle
  send: Dispatch<AnnotationMessage>
}
export function RectCanvasElement({ annot, selected, send }: RectCanvasElementProps) {
  const { onMove, onResize } = useUpdateBounds(annot, send)

  if (selected) {
    return (
      <SelectedElementContainer
        key={annot.id}
        rect={annot.bounds}
        onMove={onMove}
        onResize={onResize}
        resizeHandles={true}
        toolbar={
          <AnnotationToolbar
            options={[DELETE_OPTION]}
            height={24}
            onClickOption={(opt) => {
              if (opt.name == 'delete') {
                send({ type: 'annotDelete', annotId: annot.id })
              }
            }}
          />
        }
      >
        <RectangleView annot={annot} />
      </SelectedElementContainer>
    )
  } else {
    return (
      <ElementContainer
        key={annot.id}
        rect={annot.bounds}
        onClick={() => send({ type: 'selectAnnot', annotId: annot.id })}
      >
        <RectangleView annot={annot} />
      </ElementContainer>
    )
  }
}

type RectCanvasToolProps = {
  draft: Rectangle | undefined
  tool: RectTool
  send: Dispatch<AnnotationMessage>
}
export function RectCanvasTool({ draft, tool, send }: RectCanvasToolProps) {
  const { page } = useDrawContext()

  const onCreateRect: MoveEventHandler = (e) => {
    const startPoint = e.start.point
    const currentPoint = e.type == 'start' ? startPoint : e.current.point

    const bounds = rectFromPoints(startPoint, currentPoint)

    switch (e.type) {
      case 'start':
        send({
          type: 'addAnnotDraft',
          annot: {
            page: page.index,
            type: 'rectangle',
            id: cuid(),
            bounds: bounds,
            border: tool.border
          }
        })
        break
      case 'move':
        send({
          type: 'annotDraftEdited',
          annot: { ...draft!, bounds }
        })
        break
      case 'end':
        if (bounds.width < 10 && bounds.height < 10) {
          // this rectangle is tiny so most likely meant to be a click, we cancel the creation here
          send({ type: 'annotDraftCancelled', annotId: draft!.id })
        } else {
          console.log('endendend', { ...draft!, bounds })
          send({
            type: 'annotDraftEdited',
            save: true,
            annot: { ...draft!, bounds }
          })
        }

        break
    }
  }
  const { ref, onPointerDown, onPointerMove, onPointerUp } = useMoveable({
    canvasClass: 'drawing-canvas',
    onMove: onCreateRect
  })

  return (
    <div
      ref={ref}
      style={{
        position: 'absolute',
        cursor: 'crosshair',
        background: 'rgba(1, 1, 1, 0.0)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
    >
      {draft ? (
        <ElementContainer rect={draft.bounds}>
          <RectangleView annot={draft} />
        </ElementContainer>
      ) : null}
    </div>
  )
}

type RectangleProps = {
  annot: Rectangle
}
function RectangleView({ annot }: RectangleProps) {
  return (
    <div
      style={{
        border: `${annot.border.width}px solid ${annot.border.color}`,
        boxSizing: 'border-box',
        position: 'absolute',
        width: annot.bounds.width,
        height: annot.bounds.height,
        overflow: 'visible'
      }}
    />
  )
}
