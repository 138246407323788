import { useState } from 'react'
import { OrganizationLoggedOutHeader } from '../components/organization-header'
import { setAdd, setContains, setRemove } from '@st/util/array-set'
import { Button } from '@st/theme'
import { useSDK } from '@features/app-deps-provider'
import { useProcessState } from '@st/redux'
import { stOrganizationModule } from '@features/st-organizations/st-organization-module'
import { useSetRoute } from '@features/app-hooks'
import { useAnalytics, useTrackEvent } from '@features/analytics'

const TAX_STACK_SOFTWARES = [
  'PDF Organizer',
  'Karbon',
  'Canopy',
  'TaxDome',
  'Financial Cents',
  'Firm360',
  'Liscio',
  'TaxCaddy',
  'SurePrep',
  'GruntWorx',
  'Other'
]

export function OrganizationSelectStackPage() {
  const analytics = useAnalytics()
  useTrackEvent('viewed_select_stack_page')

  const sdk = useSDK()
  const organizationId = useProcessState(stOrganizationModule, (s) => s.organizationId)
  const [appsInStack, setAppsInStack] = useState<string[]>([])
  const setRoute = useSetRoute()

  function onClickCreateAccount() {
    analytics.track('continued_from_select_stack_page', {
      numAppsSelected: appsInStack.length
    })

    if (appsInStack.length > 0) {
      sdk.send({
        type: 'organizations/updateOrganization',
        organizationId: organizationId,
        appsInStack: appsInStack
      })
    }

    setRoute({ name: 'folder_list' })
  }

  return (
    <div className="h-svh bg-gray-100">
      <OrganizationLoggedOutHeader />
      <div className="mt-12 flex flex-col items-center py-6">
        <h1 className="w-max-500 text-center text-3xl">
          Which of the following are you currently using?
        </h1>

        <div className="text-center text-base">Select all that apply</div>

        <div className="my-8 flex h-[230px] flex-col flex-wrap items-stretch gap-4">
          {TAX_STACK_SOFTWARES.map((app) => {
            return (
              <label key={app} className="row items mx-6 cursor-pointer items-center gap-1.5">
                <input
                  type="checkbox"
                  className="size-4"
                  checked={setContains(appsInStack, app)}
                  onChange={(e) => {
                    setAppsInStack(
                      e.target.checked ? setAdd(appsInStack, app) : setRemove(appsInStack, app)
                    )
                  }}
                />
                {app}
              </label>
            )
          })}
        </div>

        <Button size="l" variant="primary" onClick={onClickCreateAccount}>
          Create Account
        </Button>
      </div>
    </div>
  )
}
