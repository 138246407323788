import { clsx } from 'clsx'
import { ReactNode } from 'react'

function SpreadsheetTableMain({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) {
  return (
    <div
      className={clsx(
        'overflow-scroll rounded-md border border-stone-200 bg-white bg-clip-border',
        className
      )}
    >
      <table className="w-full min-w-max table-auto text-left">{children}</table>
    </div>
  )
}

export const SpreadsheetTable = Object.assign(SpreadsheetTableMain, {
  Head: ({ children }: { children: ReactNode }) => {
    return (
      <thead>
        <tr className="h-7 divide-x divide-stone-200">{children}</tr>
      </thead>
    )
  },
  Th: ({ children }: { children?: ReactNode }) => {
    return (
      <th className="bg-stone-100 px-2 text-xs font-medium uppercase text-gray-600">{children}</th>
    )
  },
  Body: ({ children }: { children: ReactNode }) => {
    return <tbody className="divide-y divide-stone-200">{children}</tbody>
  },
  Row: ({ children }: { children: ReactNode }) => {
    return <tr className="h-7 divide-x divide-stone-200">{children}</tr>
  },
  Cell: ({ children }: { children?: ReactNode }) => {
    return <td className="px-2 text-sm text-gray-900">{children}</td>
  }
})
