import { FolderSummary } from '@st/sdk'

export function getStatusColor(status: FolderSummary['questionnaireStatus']) {
  switch (status) {
    case 'not_seen_yet':
      return 'gray'
    case 'in_progress':
      return 'yellow'
    case 'submitted':
      return 'green'
  }
}

export function getStatusLabel(status: FolderSummary['questionnaireStatus']) {
  switch (status) {
    case 'not_seen_yet':
      return 'Not seen yet'
    case 'in_progress':
      return 'In progress'
    case 'submitted':
      return 'Submitted'
  }
}
