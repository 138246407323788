import { JsonMap, isNotEmpty } from '@st/util/json-value'

import { OptionConfig } from '@st/folder/ui-config'
import { TAG_OPTIONS } from './tag-entities'

export const ADDRESS_KEYS = ['street', 'city', 'state', 'zip']

export const HUMAN_FIRST_NAME_KEY = 'first_name'
export const HUMAN_LAST_NAME_KEY = 'last_name'
export const HUMAN_NAME_KEYS = [HUMAN_FIRST_NAME_KEY, HUMAN_LAST_NAME_KEY]

export const LEGACY_FULL_NAME_KEY = 'basic_info.personal_details.full_name'

export const LEGACY_EMAIL_USER_INPUT_KEY = 'basic_info.personal_details.email_address'

export const CORPORATION_NAME_KEY = 'general_information.corporation_name'

export const SPOUSE_NAME_KEY = 'basic_info.spouse.name'

export const LAST_WIZARD_PAGE_USER_INPUT = 'meta.last_wizard_page_id'
export const TAG_INPUT_KEYS = TAG_OPTIONS.map((opt) => `meta.tags.${opt.key}`)

export const CLIENT_UPDATED_AT_KEY = 'computed.client_updated_at'
export const FOLDER_LIST_META_KEYS = ['meta._source.id', 'meta._source.name']

export type TaxYear = number & { _: 'TaxYear' }

export const CURRENT_TAX_YEAR = 2024 as TaxYear
export const SUPPORTED_TAX_YEARS = [2024, 2023, 2022] as TaxYear[]

export type ReturnType = string & { _: 'ReturnType' }
export const DEFAULT_RETURN_TYPE = '1040' as ReturnType
export const RETURN_TYPES = ['1040', '1120', '1120s', '1065'] as ReturnType[]

export interface Address extends JsonMap {
  country?: string
  street?: string
  city?: string
  state?: string
  zip?: string
}

export interface Name extends JsonMap {
  first_name?: string
  last_name?: string
}

export function buildSchemaId(taxYear: TaxYear, returnType: ReturnType) {
  return `${taxYear}-${returnType}`
}

export type TaxAppId =
  | 'csv'
  | 'stanfordtax'
  | 'drake'
  | 'ultratax'
  | 'lacerte'
  | 'atx'
  | 'proconnect'
  | 'proseries'
  | 'prosystem'
  | 'axcess'
  | 'taxact'
  | 'taxslayer'
  | 'taxwise'
  | 'gosystem_tax_rs'
  | 'turbotax'
  | 'crosslink'

type IntegrationType = 'upload' | 'auto' | 'coming_soon' | undefined

export type TaxApp = {
  id: TaxAppId
  name: string
  integration?: IntegrationType
}

export type ImportInstructions = {
  appId: string
  estimatedTime: string
  steps: ImportInstructionStep[]
}

export type ImportInstructionStep = {
  caption: string
  image?: string // Optional, as some steps might not have an image
}

export const TAX_APPS: TaxApp[] = [
  { id: 'axcess', name: 'CCH Axcess', integration: 'upload' },
  { id: 'prosystem', name: 'CCH Prosystem fx', integration: 'upload' },
  { id: 'crosslink', name: 'CrossLink' },
  { id: 'drake', name: 'Drake', integration: 'upload' },
  { id: 'gosystem_tax_rs', name: 'GoSystem Tax RS' },
  { id: 'lacerte', name: 'Lacerte', integration: 'upload' },
  { id: 'proseries', name: 'ProSeries', integration: 'upload' },
  { id: 'taxact', name: 'TaxAct' },
  { id: 'taxslayer', name: 'TaxSlayer' },
  { id: 'taxwise', name: 'TaxWise' },
  { id: 'turbotax', name: 'TurboTax' },
  { id: 'ultratax', name: 'UltraTax CS', integration: 'upload' },
  { id: 'proconnect', name: 'ProConnect', integration: 'upload' },
  { id: 'atx', name: 'ATX', integration: 'coming_soon' }
]

export const CUSTOM_CSV_IMPORT: TaxApp = {
  id: 'csv',
  name: 'Custom CSV',
  integration: 'upload'
}

export const STANFORDTAX_TAX_APP: TaxApp = {
  id: 'stanfordtax',
  name: 'StanfordTax',
  integration: 'auto'
}

export const TAX_APPS_IMPORT_OPTIONS: TaxApp[] = [
  // CUSTOM_TAX_APP,
  // STANFORDTAX_TAX_APP,
  ...TAX_APPS.filter((app) => isNotEmpty(app.integration))
]

export const TAX_APP_OPTIONS: OptionConfig[] = [
  ...TAX_APPS.map((app) => {
    return {
      key: app.id,
      label: app.name
    }
  }),
  { key: 'other', label: 'Other' }
]
