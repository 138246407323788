import { ArrowRight } from '@components/icons'
import { envVar } from '@env'
import { useAnalytics } from '@features/analytics'
import { loggedOutPricing, loginRoute } from '@features/routing'
import { Button } from '@st/theme'
import { HeaderNavigation } from '@theme'
import { ReactNode } from 'react'

export function OrganizationMarketingHeader() {
  function onClickLogin() {
    window.location.href = loginRoute({})
  }

  return (
    <HeaderNavigation backHref={envVar('NEXT_PUBLIC_HOMEPAGE_URL') ?? '/'}>
      <Button variant="subtle" href={loggedOutPricing({})}>
        Plans
      </Button>

      <Button className="login" variant="subtle" onClick={onClickLogin}>
        Log In
      </Button>

      <GetStartedButton>Try for free</GetStartedButton>
    </HeaderNavigation>
  )
}

function GetStartedButton({ children }: { children: ReactNode }) {
  const analytics = useAnalytics()

  function onClick() {
    analytics.track('clicked_get_started')
    window.location.href = '/sign-up'
  }

  return (
    <Button variant="subtle" onClick={onClick} trailingIcon={<ArrowRight />}>
      {children}
    </Button>
  )
}
