type PromisesRecord = { [key: string]: Promise<any> }
type ResolvedPromises<T> = {
  [P in keyof T]: T[P] extends Promise<infer R> ? R : T[P]
}

export function all<T extends PromisesRecord>(
  promises: T
): Promise<ResolvedPromises<T>> {
  const keys = Object.keys(promises)
  const promisesArray = keys.map((key) => promises[key])

  return Promise.all(promisesArray).then((results) => {
    const resultObj = {} as ResolvedPromises<T>
    results.forEach((result, index) => {
      resultObj[keys[index] as keyof T] = result
    })
    return resultObj
  })
}
