import { FAQ, FAQList } from '@st/theme'

type QA = {
  question: string
  answer: string
}

export function STPricingFAQList() {
  return (
    <FAQList className="w-2/3">
      {PRICING_FAQS.map((qa) => (
        <FAQ key={qa.question} title={qa.question}>
          {qa.answer}
        </FAQ>
      ))}
    </FAQList>
  )
}

const PRICING_FAQS: QA[] = [
  {
    question: 'How much does StanfordTax cost?',
    answer:
      'StanfordTax costs either $12 per client or $18 per client each year, depending on which plan you sign up for (minimum of 50 clients). There are no additional fees, including any implementation or onboarding fees.'
  },
  {
    question: 'Can I try it out for free?',
    answer: 'Yes! Anyone can try StanfordTax for free with 5 clients - no credit card required.'
  },
  {
    question: 'When should I pay?',
    answer:
      "We recommend paying before December 3rd, 2024 since you'll take advantage of our Early Bird pricing. Any clients you pay for before December 3rd, 2024 will be at a 25% discount."
  },
  {
    question: 'What if I want to use StanfordTax with more clients than what I paid for?',
    answer:
      "No problem! You'll be charged for the extras at the end of the year at $12/client (Premium plan) or $18/client (Professional plan)."
  },
  {
    question: 'What if I pay for more than what I end up using?',
    answer: 'Unused credits will roll over to the next year.'
  },
  {
    question: "What if my client enters the questionnaire but doesn't end up filling it out?",
    answer:
      "You only have to pay for clients who go into the questionnaire and upload at least one document. This means you can import as many clients as you'd like from your tax software without having to worry about who will actually use it. Any extras you're left with at the end of the year will roll over to the next year."
  },
  {
    question: 'Are there any implementation or onboarding fees?',
    answer:
      'StanfordTax does not have any implementation or onboarding fees because of how simple it is to set up. You can schedule a free 30-minute onboarding session once you upgrade your account.'
  }
]
