const EnvVars = {
  /**
   * The public facing app url.
   * For example https://app.stanfordtax.com
   */
  NEXT_PUBLIC_APP_URL: process.env.NEXT_PUBLIC_APP_URL,

  /**
   * The marketing page url.
   * For example https://stanfordtax.com
   */
  NEXT_PUBLIC_HOMEPAGE_URL: process.env.NEXT_PUBLIC_HOMEPAGE_URL,

  /**
   * The portal url that clients use to access the folder they are sent
   */
  NEXT_PUBLIC_CLIENTS_APP_URL: process.env.NEXT_PUBLIC_CLIENTS_APP_URL,

  /**
   * The endpoint where to call serverless functions for firebase
   * If left empty, fall back to NEXT_PUBLIC_API_ENDPOINT
   */
  NEXT_PUBLIC_SERVERLESS_ENDPOINT: process.env.NEXT_PUBLIC_SERVERLESS_ENDPOINT,

  /**
   * The sentry DSN
   */
  NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,

  NEXT_PUBLIC_POSTHOG_API_KEY: process.env.NEXT_PUBLIC_POSTHOG_API_KEY,
  NEXT_PUBLIC_POSTHOG_API_HOST: process.env.NEXT_PUBLIC_POSTHOG_API_HOST,

  NEXT_PUBLIC_INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  NEXT_PUBLIC_BING_ANALYTICS_ID: process.env.NEXT_PUBLIC_BING_ANALYTICS_ID,
  NEXT_PUBLIC_GOOGLE_ANALYTICS_ID: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID,
  NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN,
  NEXT_PUBLIC_REDDIT_ADVERTISER_ID: process.env.NEXT_PUBLIC_REDDIT_ADVERTISER_ID,
  NEXT_PUBLIC_LINKED_IN_PARTNER_ID: process.env.NEXT_PUBLIC_LINKED_IN_PARTNER_ID
}

type EnvVarKey = keyof typeof EnvVars

/**
 * Get the value of the environment variable from the process
 * @param key
 * @returns
 */
export function envVar(key: EnvVarKey): string | undefined {
  return EnvVars[key]
}

export const NODE_ENV = process.env.NODE_ENV
