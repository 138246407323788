import { STDocument } from '@st/sdk/src/sdk.types'
import { Button, Dialog, DialogButtons } from '@st/theme'

export function ConfirmDeleteDocumentDialog(props: {
  doc: STDocument
  onConfirm: () => void
  onCancel: () => void
}) {
  return (
    <Dialog
      title="Delete document"
      subtitle={`Please confirm that you would like to delete ${props.doc.name}`}
      buttons={
        <DialogButtons>
          <Button variant="default" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button onClick={props.onConfirm}>Delete</Button>
        </DialogButtons>
      }
    />
  )
}
