import { parsePath } from '../url'
import { Route, RouteMatch, RouteMatchPartial } from './route'

type GetMatchMapType<T> = {
  [K in keyof T]: T[K] extends Route<infer Key, infer Pattern, infer ParamMap>
    ? RouteMatch<Key, Pattern, ParamMap>
    : never
}

type GetSetMatchMapType<T> = {
  [K in keyof T]: T[K] extends Route<infer Key, infer Pattern, infer ParamMap>
    ? RouteMatchPartial<Key, Pattern, ParamMap>
    : never
}

type ValueOf<T> = T[keyof T]
export type GetRouteMatchType<T> = ValueOf<GetMatchMapType<T>>
export type GetSetRouteMatchType<T> = ValueOf<GetSetMatchMapType<T>>

export class RouteMap<
  Map extends Record<string, Route<any, any, any>>,
  RouteType = GetRouteMatchType<Map>,
  SetRouteType = GetSetRouteMatchType<Map>
> {
  constructor(map: Map) {
    this.map = map
  }

  private map: Map

  match = (path: string): RouteType | undefined => {
    const { pathname, searchParams } = parsePath(path)
    for (var route of Object.values(this.map)) {
      var matchedRoute = route.match(pathname, searchParams)

      if (matchedRoute) {
        return matchedRoute as unknown as RouteType
      }
    }

    return undefined
  }

  matches = (path: string): boolean => {
    return this.match(path) != undefined
  }

  isActive = (
    route: RouteType | SetRouteType,
    currentRoute: RouteType
  ): boolean => {
    const routePath = this.toPath(route, currentRoute)

    const currentRoutePath = this.toPath(currentRoute)

    if (!routePath || !currentRoutePath) return false

    // add trailing slash to avoid thinkin a route like
    // /demo/1120 is active when we are on /demo/1120s
    return `${currentRoutePath}/`.startsWith(`${routePath}/`)
  }

  toPath = (
    route: RouteType | SetRouteType,
    copyParamsFrom?: RouteType
  ): string | undefined => {
    const routeFn = this.getRoute((route as any).name)

    if (!routeFn) {
      console.warn('No matching route')
      return undefined
    }

    if (!copyParamsFrom) {
      return routeFn(route as any)
    }

    const paramNames = routeFn.paramNames

    const finalRoute = this.getAppRoute(
      copyParamsFrom,
      route as any,
      paramNames
    )

    return routeFn(finalRoute)
  }

  private getRoute = (name: string): Route<any, any, any> | undefined => {
    const route = Object.values(this.map).find((r) => {
      return r.key == name
    })

    if (!route) {
      console.warn(`ROUTE MAP: No matching route`, name, 'found in', this.map)
    }

    return route
  }

  private getAppRoute<
    S extends Record<string, any>,
    D extends Record<string, any>
  >(from: S, to: D, paramNames: string[]): D {
    var res: D = { ...to }

    for (var paramName of paramNames) {
      if (to[paramName] === undefined && from[paramName] !== undefined) {
        ;(res as any)[paramName] = from[paramName]
      }
    }

    return res
  }
}
