import { Point, Rect, Size, rectFromPoints } from '.'

/**
 * Represents a 2D linear transformation in the form of a 3x3 matrix, where the last row is always [0, 0, 1].
 *
 * ```
 *  | a  b  tx |
 *  | c  d  ty |
 *  | 0  0   1 |
 * ```
 */
export type LinearTransform = {
  a: number
  b: number
  c: number
  d: number
  tx: number
  ty: number
}

function isRect(shape: Point | Rect): shape is Rect {
  return 'width' in shape && 'height' in shape
}

const IDENTITY = Object.freeze({
  a: 1,
  b: 0,
  c: 0,
  d: 1,
  tx: 0,
  ty: 0
}) as Readonly<LinearTransform>

function apply(shape: Rect, transform: LinearTransform): Rect
function apply(shape: Point, transform: LinearTransform): Point
function apply(shape: Point | Rect, transform: LinearTransform): Point | Rect {
  // optimization where if we have the identity transform we can return the original
  // thing unchanged
  if (transform === IDENTITY) {
    return shape
  }

  if (isRect(shape)) {
    const p1 = apply({ x: shape.x, y: shape.y }, transform)
    const p2 = apply(
      { x: shape.x + shape.width, y: shape.y + shape.height },
      transform
    )
    return rectFromPoints(p1, p2)
  } else {
    return {
      x: transform.a * shape.x + transform.b * shape.y + transform.tx,
      y: transform.c * shape.x + transform.d * shape.y + transform.ty
    }
  }
}

export const LinearTransform = {
  apply,

  toCSS(transform: LinearTransform): string {
    return `matrix(${transform.a}, ${transform.b}, ${transform.c}, ${transform.d}, ${transform.tx}, ${transform.ty})`
  },

  identity: IDENTITY,

  scale(factor: number): LinearTransform {
    return { a: factor, b: 0, c: 0, d: factor, tx: 0, ty: 0 }
  },

  invert(transform: LinearTransform): LinearTransform {
    const denom = transform.a * transform.d - transform.b * transform.c

    if (denom === 0) {
      throw new Error('Matrix not invertible')
    }

    return {
      a: transform.d / denom,
      b: transform.b / -denom,
      c: transform.c / -denom,
      d: transform.a / denom,
      tx: (transform.d * transform.tx - transform.c * transform.ty) / -denom,
      ty: (transform.b * transform.tx - transform.a * transform.ty) / denom
    }
  },

  /**
   * Creates a transformation that flips coordinates vertically around the specified y-axis.
   *
   * @param axisY The y-coordinate of the axis to flip around (typically the vertical center of the viewport).
   * @returns A LinearTransform that performs the vertical flip.
   *
   * Common Use Case:
   *   - Converting between coordinate systems where the y-axis origin is at the top (e.g., web browsers)
   *     and those where the origin is at the bottom (e.g., some PDFs or graphics libraries).
   */
  flipVertical(axisY: number): LinearTransform {
    return { a: 1, b: 0, c: 0, d: -1, tx: 0, ty: axisY * 2 }
  },

  /**
   * Creates a transformation that normalizes coordinates from the actual page dimensions to the [0, 1] range.
   *
   * Normalized coordinates
   *
   * @param size The dimensions of the page ({ width: number, height: number })
   * @returns A LinearTransform that normalizes coordinates.
   */
  normalize(size: Size): LinearTransform {
    return {
      a: 1 / size.width,
      b: 0,
      c: 0,
      d: 1 / size.height,
      tx: 0,
      ty: 0
    }
  },

  /**
   * Creates a transformation that denormalizes coordinates from the [0, 1] range to the actual page dimensions.
   *
   * @param size The dimensions of the page ({ width: number, height: number })
   * @returns A LinearTransform that denormalizes coordinates.
   */
  denormalize(size: Size): LinearTransform {
    return {
      a: size.width,
      b: 0,
      c: 0,
      d: size.height,
      tx: 0,
      ty: 0
    }
  }
}
