import {
  calculateWizardState,
  getProgressForRoute,
  getWizardNavigationState,
  getWizardRoutes,
  getWizardSectionRoutes,
  IndexedPage,
  indexedPageById,
  matchesCondition,
  pageById,
  resolveValue,
  selectableCategoriesInGroups,
  WizardNavigationState,
  WizardRoute
} from '@features/formation-viewer/formation-wizard/model'
import { SDKMessage, SDKResponse } from '@features/sdk-module'
import {
  selFolderRole,
  selResolvedInputs,
  stFolderModule,
  STFolderModuleState
} from '@features/st-folder-viewer/st-folder-module'
import { FolderUIRole, getCategoriesForSection } from '@st/folder'
import { WizardConfig } from '@st/folder/ui-config'
import { defineModule, defineTask } from '@st/redux'
import { Organization, STChecklistItem, STDocument } from '@st/sdk'
import { isEmpty, JsonMap } from '@st/util/json-value'
import { Progress } from '@st/util/progress'
import {
  formatValidationErrors,
  QuestionnairePageValidationResult,
  validateQuestionnairePage
} from './st-questionnaire-validation'

export type STQuestionnaireState = {
  wizardConfig: WizardConfig
  organization: Organization
  mode: 'dashboard' | 'questionnaire'
  route: WizardRoute | undefined
  submittingQuestionnaire: boolean
  validationResult: QuestionnairePageValidationResult
  preview: boolean
  documentsToAddToChecklist: string[]
}

export type STQuestionnaireMessage =
  | { type: 'enterQuestionnaire' }
  | { type: 'navigateNext' }
  | { type: 'navigatePrev' }
  | { type: 'navigate'; route: WizardRoute }
  | { type: 'returnToDashboard' }
  | { type: 'submitQuestionnaire' }
  | { type: 'deleteDocument'; docId: string }
  | { type: 'addToChecklistStack'; docId: string }
  | { type: 'removeFromChecklistStack'; docId: string }
  | { type: 'completeChecklistItemsWithDocument'; docId: string; checklistItemIds: string[] }
  | { type: 'rerunValidation' }
  | SDKResponse

type STQuestionnaireInit = {
  wizardConfig: WizardConfig
  organization: Organization
  preview: boolean
}

type STQuestionnaireDeps = { stFolder: typeof stFolderModule }

export type { WizardNavigationState }

export const stQuestionnaireModule = defineModule<
  STQuestionnaireState,
  STQuestionnaireMessage,
  STQuestionnaireInit,
  { sdk: SDKMessage },
  STQuestionnaireDeps
>({
  name: 'stQuestionnaire',
  deps: { stFolder: stFolderModule },
  init: ({ wizardConfig, organization, preview }, _) => {
    return {
      wizardConfig,
      organization,
      mode: 'dashboard',
      submittingQuestionnaire: false,
      route: undefined,
      validationResult: { ok: true },
      preview: preview,
      documentsToAddToChecklist: []
    }
  },
  handle: (state, message, _context, deps) => {
    const folder = deps.stFolder.folderState!.folder
    const folderRole = selFolderRole(deps.stFolder)

    switch (message.type) {
      case 'enterQuestionnaire':
        var inputs = selResolvedInputs(deps.stFolder)
        var savedProgress = selQuestionnaireSavedProgress(state, deps)

        return {
          ...state,
          route: selInitialQuestionnaireRoute({
            lastSavedPageId: savedProgress.lastPageId,
            inputs: inputs,
            folderRole,
            wizardConfig: state.wizardConfig
          })
        }
      case 'navigatePrev':
        var navState = selQuestionnaireNavState(state, deps)
        var newState: STQuestionnaireState = { ...state, route: navState.next! }

        return [
          { ...state, route: navState.prev },
          // we don't want to save the client's questionnaire progress when the accountant is previewing it
          folderRole == 'client'
            ? {
                sdk: {
                  type: 'request',
                  request: {
                    type: 'folders/saveQuestionnaireProgress',
                    pageId: newState.route!.pageId,
                    folderId: folder.id,
                    progress: Progress.toNumber(selQuestionnaireProgress(newState, deps))
                  }
                }
              }
            : {}
        ]
      case 'navigateNext':
        var navState = selQuestionnaireNavState(state, deps)

        var curPage = selQuestionnaireCurrentPage(state, deps)
        var inputs = selResolvedInputs(deps.stFolder)

        var validationResult = validateQuestionnairePage(curPage?.components ?? [], inputs)

        if (!validationResult.ok) {
          return { ...state, validationResult }
        }

        var newState: STQuestionnaireState = { ...state, route: navState.next! }

        return [
          { ...newState, validationResult },
          // we don't want to save the client's questionnaire progress when the accountant is previewing it
          folderRole == 'client'
            ? {
                sdk: {
                  type: 'request',
                  request: {
                    type: 'folders/saveQuestionnaireProgress',
                    pageId: newState.route!.pageId,
                    folderId: folder.id,
                    progress: Progress.toNumber(selQuestionnaireProgress(newState, deps))
                  }
                }
              }
            : {}
        ]
      case 'navigate':
        var newState: STQuestionnaireState = {
          ...state,
          route: message.route,
          validationResult: { ok: true }
        }
        if (message.route && folderRole == 'client') {
          return [
            newState,
            {
              sdk: {
                type: 'request',
                request: {
                  type: 'folders/saveQuestionnaireProgress',
                  pageId: newState.route!.pageId,
                  folderId: folder.id,
                  progress: Progress.toNumber(selQuestionnaireProgress(newState, deps))
                }
              }
            }
          ]
        }
        return { ...state, route: message.route, validationResult: { ok: true } }
      case 'returnToDashboard':
        return { ...state, route: undefined, validationResult: { ok: true } }
      case 'submitQuestionnaire':
        return [
          { ...state, submittingQuestionnaire: true },
          {
            sdk: {
              type: 'request',
              request: { type: 'folders/submitQuestionnaire', folderId: folder.id }
            }
          }
        ]
      case 'deleteDocument':
        return [
          state,
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/deleteDocument',
                folderId: folder.id,
                documentId: message.docId
              }
            }
          }
        ]
      case 'addToChecklistStack':
        return {
          ...state,
          documentsToAddToChecklist: [...state.documentsToAddToChecklist, message.docId]
        }
      case 'removeFromChecklistStack':
        return {
          ...state,
          documentsToAddToChecklist: state.documentsToAddToChecklist.filter(
            (id) => id != message.docId
          )
        }
      case 'completeChecklistItemsWithDocument':
        return [
          {
            ...state,
            // remove the document from the stack
            documentsToAddToChecklist: state.documentsToAddToChecklist.filter(
              (id) => id != message.docId
            )
          },
          {
            sdk: message.checklistItemIds.map((checklistItemId) => ({
              type: 'request',
              request: {
                type: 'folders/completeChecklistItemWithDocument',
                folderId: folder.id,
                documentId: message.docId,
                checklistItemId: checklistItemId
              }
            })) as SDKMessage[]
          }
        ]
      case 'rerunValidation':
        var curPage = selQuestionnaireCurrentPage(state, deps)
        var validationResult = validateQuestionnairePage(
          curPage?.components ?? [],
          selResolvedInputs(deps.stFolder)
        )

        return {
          ...state,
          validationResult: validationResult
        }
      case 'response':
        if (message.operation.type == 'folders/submitQuestionnaire') {
          return { ...state, submittingQuestionnaire: false, route: undefined }
        }
        return state
      default:
        return state
    }
  }
})

export function selQuestionnaireValidationErrorMessage(state: STQuestionnaireState) {
  return state.validationResult.ok
    ? undefined
    : formatValidationErrors(state.validationResult.errors)
}

export function hasValidationError(
  validationResult: QuestionnairePageValidationResult,
  inputKey: string
) {
  return !validationResult.ok && validationResult.errors.some((e) => e.keys.includes(inputKey))
}

function selInitialQuestionnaireRoute(arg: {
  inputs: JsonMap
  folderRole: FolderUIRole
  wizardConfig: WizardConfig
  lastSavedPageId: string | undefined
}): WizardRoute {
  const wizardState = selQuestionnaireState({
    inputs: arg.inputs,
    folderRole: arg.folderRole,
    wizardConfig: arg.wizardConfig
  })

  const lastSavedPage = arg.lastSavedPageId
    ? indexedPageById(wizardState, arg.lastSavedPageId)
    : undefined

  if (lastSavedPage) {
    return routeForIndexedPage(lastSavedPage)
  }

  return routeForIndexedPage(wizardState.filteredPages[0])
}

type QuestionnaireProgress = {
  lastPageId: string | undefined
  progress: number
}

export function selQuestionnaireSavedProgress(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
): QuestionnaireProgress {
  return {
    lastPageId: deps.stFolder.folderState?.folder.questionnaireLastPageId,
    progress: deps.stFolder.folderState?.folder.questionnaireProgress ?? 0
  }
}

export function selQuestionnaireCurrentPage(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
) {
  const inputs = selResolvedInputs(deps.stFolder)
  const folderRole = selFolderRole(deps.stFolder)
  const wizardState = selQuestionnaireState({
    inputs,
    folderRole,
    wizardConfig: questionnaireState.wizardConfig
  })
  return pageById(wizardState, questionnaireState.route!.pageId)
}

export function selQuestionnaireNavState(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
) {
  const inputs = selResolvedInputs(deps.stFolder)
  const folderRole = selFolderRole(deps.stFolder)
  const wizardState = selQuestionnaireState({
    inputs,
    folderRole,
    wizardConfig: questionnaireState.wizardConfig
  })
  const routes = getWizardRoutes(wizardState)

  return getWizardNavigationState(routes, questionnaireState.route!)
}

export function selQuestionnaireProgress(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
) {
  const inputs = selResolvedInputs(deps.stFolder)
  const folderRole = selFolderRole(deps.stFolder)
  const wizardState = selQuestionnaireState({
    inputs,
    folderRole,
    wizardConfig: questionnaireState.wizardConfig
  })
  return getProgressForRoute(wizardState, questionnaireState.route!.pageId)
}

export type WizardSectionTab = { label: string; route: WizardRoute }
export function selQuestionnaireTabs(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
): WizardSectionTab[] {
  const inputs = selResolvedInputs(deps.stFolder)
  const folderRole = selFolderRole(deps.stFolder)
  const wizardState = selQuestionnaireState({
    inputs,
    folderRole,
    wizardConfig: questionnaireState.wizardConfig
  })

  const routes = getWizardRoutes(wizardState)
  return getWizardSectionRoutes(routes).map((route) => {
    const section = questionnaireState.wizardConfig.sections.find((s) => s.id == route.sectionId)
    return {
      label: section ? section.name : '<missing>',
      route: route
    }
  })
}

export function selQuestionnairePersonalizedNote(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
) {
  const pageId = questionnaireState.route?.pageId
  if (!pageId) {
    return undefined
  }
  return deps.stFolder.folderState!.personalizedNotes.find((n) => n.pageId == pageId)
}

export function selQuestionnaireShouldShowSubtitle(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
) {
  const page = selQuestionnaireCurrentPage(questionnaireState, deps)
  const subtitleShowWhen = page?.subtitleShowWhen

  if (isEmpty(subtitleShowWhen)) {
    return true
  }

  const conditionResult = matchesCondition(subtitleShowWhen!, {
    resolveValue: (path) => resolveValue(path, deps.stFolder.folderState!.inputs)
  })

  return conditionResult
}

export function categorySelectorCategoryGroups(
  questionnaireState: STQuestionnaireState,
  sectionId: string
) {
  return selectableCategoriesInGroups(
    getCategoriesForSection(questionnaireState.wizardConfig, sectionId)
  )
}

function routeForIndexedPage(indexedPage: IndexedPage) {
  return {
    pageId: indexedPage.page.id,
    sectionId: indexedPage.section.id,
    categoryId: indexedPage.category?.id
  }
}

function selQuestionnaireState(arg: {
  inputs: JsonMap
  wizardConfig: WizardConfig
  folderRole: FolderUIRole
}) {
  const inputs = arg.inputs
  const context = { role: arg.folderRole }
  return calculateWizardState({ inputs, uiConfig: arg.wizardConfig, context: context })
}

export type DocumentStackItem = {
  doc: STDocument
  checklistItemCandidates: STChecklistItem[]
}

export function selDocumentsChecklistTopOfStack(
  questionnaireState: STQuestionnaireState,
  deps: { stFolder: STFolderModuleState }
): DocumentStackItem | undefined {
  const items: DocumentStackItem[] = questionnaireState.documentsToAddToChecklist
    .map((id) => {
      const candidateChecklistItemIds = deps.stFolder
        .folderState!.checklistItemLinkCandidates.filter((c) => c.documentId == id)
        .map((c) => c.checklistItemId)

      const checklistItems = deps.stFolder.folderState!.checklistItems.filter((c) =>
        candidateChecklistItemIds.includes(c.id)
      )

      return {
        doc: deps.stFolder.folderState!.documents.find((d) => d.id == id)!,
        checklistItemCandidates: checklistItems
      }
    })
    .filter((stackItem) => stackItem.doc && stackItem.checklistItemCandidates.length > 0)

  return items[0]
}
