import { AuthError, User } from './auth-client-adapter'

export type AuthStatus = 'unknown' | 'signingUp' | 'loggingIn' | 'loggedOut' | 'loggedIn' | 'error'

export type AuthState = {
  status: AuthStatus
  user?: User
  email?: string
  error?: AuthError
  loginState: LoginState
}

type LoginState = {
  status: 'idle' | 'sendingLoginVerificationEmail'
  lastDeliveredTo?: string
}

export type AuthEvent =
  /**
   * The user is in the process of registering an account
   * with the given email.
   * The operation hasn't yet been completed.
   */
  | { type: 'auth/signingUp'; email: string }

  /**
   * Creating a new account succeeded
   */
  | { type: 'auth/signUpSucceeded'; user: User }

  /**
   * Creating a new account failed.
   * This could be for many reasons such as a weak password or
   * an email with the account already existing.
   */
  | { type: 'auth/signUpFailed'; error: AuthError }

  /**
   * The user is in the process of logging in with the given email
   * The operation hasn't yet been completed.
   */
  | { type: 'auth/loggingIn/emailPassword'; email: string }

  /**
   * The user is in the process of logging in with the given email
   * The operation hasn't yet been completed.
   */
  | { type: 'auth/loggingIn/verificationLink'; email: string }

  /**
   * The user tried to login and the login failed do to the reason
   * provided in the error
   */
  | { type: 'auth/loginFailed'; error: AuthError }

  /**
   * We have detected that the user is logged in.
   * This can be because user logged in from the UI
   * or they returned later and were still logged in.
   */
  | { type: 'auth/userIsLoggedIn'; user: User }

  /**
   * We have detected that the user is logged out.
   * This can be because the user tapped logout
   * or they come to the website and we see they are logged out.
   */
  | { type: 'auth/userIsLoggedOut' }
  | {
      type: 'auth/loginWithEmail/sendingEmail'
      organizationId: string
      email: string
      destination: string
    }
  | {
      type: 'auth/loginWithEmail/emailSent'
      organizationId: string
      email: string
      destination: string
    }

export function authReducer(state: AuthState, event: AuthEvent): AuthState {
  switch (event.type) {
    case 'auth/loggingIn/emailPassword':
      return { ...state, status: 'loggingIn', email: event.email }
    case 'auth/loggingIn/verificationLink':
      return { ...state, status: 'loggingIn', email: event.email }
    case 'auth/signingUp':
      return { ...state, status: 'signingUp', email: event.email }
    case 'auth/userIsLoggedOut':
      return { status: 'loggedOut', loginState: { status: 'idle' } }
    case 'auth/userIsLoggedIn':
      return {
        ...state,
        status: 'loggedIn',
        user: event.user,
        email: event.user.email!
      }
    case 'auth/loginFailed':
      return { ...state, status: 'error', error: event.error }
    case 'auth/signUpFailed':
      return { ...state, status: 'error', error: event.error }
    case 'auth/loginWithEmail/sendingEmail':
      return {
        ...state,
        loginState: {
          ...state.loginState,
          status: 'sendingLoginVerificationEmail'
        }
      }
    case 'auth/loginWithEmail/emailSent':
      return {
        ...state,
        loginState: {
          ...state.loginState,
          status: 'idle',
          lastDeliveredTo: event.email
        }
      }
    default:
      return state
  }
}

export function formatAuthError(error: AuthError): string {
  return error.message
}
