import { LinearTransform, Size } from '@st/util/geom'
import '@styles/main.scss'
import '@themestyle'
import { Dispatch } from 'react'

import { DrawingCanvasProvider } from './common'
import {
  AnnotationMessage,
  AnnotationState,
  Page,
  selDrawingAnnots,
  selSelectedAnnot
} from './module'

export * from './common'
export * from './module'

type Props = {
  size: Size
  state: AnnotationState
  pageIndex: number
  zoomLevel: number
  send: Dispatch<AnnotationMessage>
}
export function AnnotationLayer({ size, state, pageIndex, zoomLevel, send }: Props) {
  const page: Page = { index: pageIndex, size: size }

  const denorm = LinearTransform.denormalize(page.size)
  const annotations = selDrawingAnnots(state)
    .filter((p) => p.page == pageIndex)
    .map((annot) => {
      return { ...annot, bounds: LinearTransform.apply(annot.bounds, denorm) }
    })

  const ActiveTool = state.tools.find((t) => t.name == state.activeToolName)!
  const activeToolState = state.toolStates[ActiveTool.name]
  const selectedAnnot = selSelectedAnnot(state)

  return (
    <DrawingCanvasProvider value={{ zoom: zoomLevel, page }}>
      <div
        className="drawing-canvas"
        style={{
          width: size.width,
          height: size.height,
          // background: 'rgba(200, 0, 0, 0.1)',
          position: 'relative',
          transform: LinearTransform.toCSS(LinearTransform.scale(zoomLevel)),
          transformOrigin: 'top left'
        }}
      >
        {ActiveTool ? (
          <ActiveTool.CanvasBackgroundElement
            draft={selectedAnnot?.page === pageIndex ? selectedAnnot : undefined} // only pass along the draft if it's relevant to the current page
            tool={activeToolState}
            send={send}
          />
        ) : null}

        {annotations.map((annot) => {
          const MatchingTool = state.tools.find((t) => t.matches(annot))
          if (!MatchingTool?.Element) return null

          return (
            <MatchingTool.Element
              key={annot.id}
              annot={annot}
              tool={state.toolStates[MatchingTool.name]}
              selected={annot.id == state.activeAnnotId}
              send={send}
            />
          )
        })}
      </div>
    </DrawingCanvasProvider>
  )
}
