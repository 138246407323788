import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { PlatformMessage } from '@features/st-pdf-viewer/platform-module'
import { defineModule, defineTask } from '@st/redux'
import {
  QuestionnaireTemplateType,
  ScopedFolderMembership,
  SDKCommand,
  STFolderSendState,
  STSDK
} from '@st/sdk'
import { setRemove } from '@st/util/array-set'
import { deepEqual } from '@st/util/json-value'
import { match } from 'ts-pattern'

type Status = 'loading' | 'loaded' | 'sending' | 'sent'

export type STSendQuestionnaireContext = {
  sdk: STSDK
}

export type STSendQuestionnaireState = {
  status: Status
  folderId: string
  templateType: QuestionnaireTemplateType

  sendState: STFolderSendState | undefined

  /**
   * State for the TO: field
   */

  initialEmails: string[]
  emails: string[]
  inputValue: string
  selectedEmail: string | undefined

  savingFolder: boolean
}

export type STSendQuestionnaireMessage =
  | { type: 'savingFolder' }
  | { type: 'savedFolder' }
  | { type: 'sendingQuestionnaireEmail' }
  | { type: 'sentQuestionnaireEmail' }
  | { type: 'typeEmail'; email: string }
  | { type: 'selectEmail'; email: string | undefined }
  | { type: 'addEmail'; email: string }
  | { type: 'removeEmail'; email: string }
  | { type: 'updatedFolderAccess'; memberships: ScopedFolderMembership[] }
  | { type: 'savedDraft'; message: string }
  | SDKResponse

type STQuestionnaireSendPlatformModule = {
  sdk: SDKMessage
  platform: PlatformMessage
}

type STQuestionSendModuleInit = { folderId: string; templateType: QuestionnaireTemplateType }

export const stSendQuestionnaireModule = defineModule<
  STSendQuestionnaireState,
  STSendQuestionnaireMessage,
  STQuestionSendModuleInit,
  STQuestionnaireSendPlatformModule
>({
  name: 'stSendQuestionnaire',
  init: ({ folderId, templateType }) => {
    return [
      {
        status: 'loading',
        folderId: folderId,
        sendState: undefined,
        initialEmails: [],
        emails: [],
        inputValue: '',
        selectedEmail: undefined,
        templateType: templateType,
        savingFolder: false
      },
      {
        sdk: {
          type: 'request',
          request: {
            type: 'folders/getFolderSendState',
            folderId: folderId,
            templateType: templateType
          }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'typeEmail':
        return { ...state, inputValue: message.email }
      case 'addEmail':
        return {
          ...state,
          emails: [...state.emails, message.email],
          inputValue: '',
          selectedEmail: undefined
        }
      case 'removeEmail':
        return {
          ...state,
          emails: setRemove(state.emails, message.email),
          selectedEmail: undefined,
          inputValue: ''
        }
      case 'selectEmail':
        return { ...state, selectedEmail: message.email }
      case 'sendingQuestionnaireEmail':
        return { ...state, status: 'sending' }
      case 'sentQuestionnaireEmail':
        return [
          { ...state, status: 'sent' },
          { platform: { type: 'showSnackbar', message: 'Sent email' } }
        ]
      case 'updatedFolderAccess':
        const clients = message.memberships.filter((m) => m.scope == 'folder').map((m) => m.user)
        const updatedEmails = clients.map((u) => u.email)

        return {
          ...state,
          sendState: { ...state.sendState!, recipients: clients },
          initialEmails: updatedEmails,
          emails: updatedEmails
        }
      case 'savedDraft':
        return {
          ...state,
          sendState: { ...state.sendState!, message: message.message }
        }
      case 'savingFolder':
        return { ...state, savingFolder: true }
      case 'savedFolder':
        return { ...state, savingFolder: false }
      case 'response':
        const op = message.operation
        switch (op.type) {
          case 'folders/getFolderSendState':
            return {
              ...state,
              status: match<Status, Status>(state.status)
                .with('sent', () => 'sent')
                .with('loading', () => 'loaded')
                .otherwise(() => 'loaded'),
              sendState: op.response,
              initialEmails: op.response.recipients.map((r) => r.email),
              emails: op.response.recipients.map((r) => r.email)
            }
          default:
            return state
        }
      default:
        return state
    }
  }
})

export const sendQuestionnaireEmail = defineTask(
  stSendQuestionnaireModule,
  async (
    { getState, send },
    { message, deleteDraft }: { message: string; deleteDraft?: boolean },
    { sdk }: STSendQuestionnaireContext
  ) => {
    const state = getState()

    send({ type: 'sendingQuestionnaireEmail' })

    if (!deepEqual(state.emails, state.initialEmails)) {
      const resp = await sdk.send({
        type: 'folders/updateFolderAccess',
        emailsWithAccess: state.emails,
        folderId: state.folderId
      })
      send({ type: 'updatedFolderAccess', memberships: resp.memberships })
    }

    if (state.sendState?.message != message) {
      await sdk.send({
        type: 'folders/saveFolderDraftMessage',
        folderId: state.folderId,
        templateType: state.templateType,
        message: message
      })
      send({ type: 'savedDraft', message: message })
    }

    await sdk.send({
      type: 'folders/sendQuestionnaireEmail',
      folderId: state.folderId,
      templateType: state.templateType,
      deleteDraft: deleteDraft
    })

    send({ type: 'sentQuestionnaireEmail' })
  }
)

export function selCanSendQuestionnaire(state: STSendQuestionnaireState) {
  return state.status == 'loaded' && state.emails.length > 0
}

/**
 * Save the folder, specifically the draft message to send
 * and/or the users who have access to the folder (questionnaire)
 */
export const saveFolder = defineTask(
  stSendQuestionnaireModule,
  async (
    { getState, send },
    { message }: { message: string },
    { sdk }: STSendQuestionnaireContext
  ) => {
    const state = getState()

    send({ type: 'savingFolder' })

    if (!deepEqual(state.emails, state.initialEmails)) {
      await sdk.send({
        type: 'folders/updateFolderAccess',
        emailsWithAccess: state.emails,
        folderId: state.folderId
      })
    }

    if (state.sendState?.message != message) {
      await sdk.send({
        type: 'folders/saveFolderDraftMessage',
        folderId: state.folderId,
        templateType: state.templateType,
        message: message
      })
    }

    send({ type: 'savedFolder' })
  }
)
