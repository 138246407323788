export function ArrowLeftIcon(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 16 16">
      <path
        d="M13.9999 8.00003C13.9999 8.13264 13.9472 8.25982 13.8535 8.35359C13.7597 8.44736 13.6325 8.50003 13.4999 8.50003H3.70678L7.35366 12.1463C7.40011 12.1927 7.43696 12.2479 7.4621 12.3086C7.48724 12.3693 7.50018 12.4343 7.50018 12.5C7.50018 12.5657 7.48724 12.6308 7.4621 12.6915C7.43696 12.7522 7.40011 12.8073 7.35366 12.8538C7.3072 12.9002 7.25205 12.9371 7.19135 12.9622C7.13066 12.9874 7.0656 13.0003 6.99991 13.0003C6.93421 13.0003 6.86915 12.9874 6.80846 12.9622C6.74776 12.9371 6.69261 12.9002 6.64615 12.8538L2.14615 8.35378C2.09967 8.30735 2.06279 8.2522 2.03763 8.1915C2.01246 8.13081 1.99951 8.06574 1.99951 8.00003C1.99951 7.93433 2.01246 7.86926 2.03763 7.80856C2.06279 7.74786 2.09967 7.69272 2.14615 7.64628L6.64615 3.14628C6.73997 3.05246 6.86722 2.99976 6.99991 2.99976C7.13259 2.99976 7.25984 3.05246 7.35366 3.14628C7.44748 3.2401 7.50018 3.36735 7.50018 3.50003C7.50018 3.63272 7.44748 3.75996 7.35366 3.85378L3.70678 7.50003H13.4999C13.6325 7.50003 13.7597 7.55271 13.8535 7.64648C13.9472 7.74025 13.9999 7.86743 13.9999 8.00003Z"
        fill="currentColor"
      />
    </svg>
  )
}
