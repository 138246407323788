import { CloudUploadIcon } from './icons/CloudUploadIcon'

export function FullScreenDropZoneBarrier() {
  return (
    <div
      className={
        'pointer-events-none fixed bottom-0 left-0 right-0 top-0 z-50 flex flex-col items-center justify-center bg-blue-500 text-white opacity-90'
      }
    >
      <CloudUploadIcon className="h-12 w-12" />

      <div className="mt-4 text-2xl font-bold">Drop files here</div>
      <div className="mt-2 text-base">Upload your files by dropping them here</div>
    </div>
  )
}
