import clsx from 'clsx'
import { forwardRef, ReactNode, useState } from 'react'

import { CaretDownIcon, Tip } from '@st/theme'

type Props = {
  children: ReactNode
  bottom?: ReactNode
  className?: string
}
export function DocumentTreeView({ children, bottom, className }: Props) {
  return (
    <div className={clsx('flex flex-col gap-4 overflow-hidden pb-4', className)}>
      <div className="grow overflow-y-scroll">{children}</div>
      <div className="px-4">{bottom}</div>
    </div>
  )
}

type DocumentTreeViewSectionProps = {
  title: string
  children?: ReactNode
  hasChildren?: boolean
  isExpanded?: boolean
}
export function DocumentTreeViewSection({
  title,
  children,
  hasChildren = true,
  isExpanded: defaultIsExpanded = true
}: DocumentTreeViewSectionProps) {
  const [isExpanded, setExpanded] = useState(defaultIsExpanded)
  return (
    <div className="flex flex-col">
      <div
        className="flex h-9 cursor-pointer items-center gap-x-2 pl-4 pt-2 uppercase"
        onClick={() => setExpanded(!isExpanded)}
      >
        {hasChildren && (
          <CaretDownIcon
            className={clsx('h-3 w-3', isExpanded ? 'rotate-0' : '-rotate-90', 'cursor-pointer')}
          />
        )}
        <div className="grow text-sm font-semibold text-gray-700">{title}</div>
      </div>

      {children && isExpanded ? <div className="flex flex-col">{children}</div> : null}
    </div>
  )
}

type DocumentTreeViewItemProps = {
  selected?: boolean
  children?: ReactNode
  badges?: ReactNode
  menu?: ReactNode
  tip?: string
  onClick?: () => void
}
export const DocumentTreeViewItem = forwardRef<HTMLDivElement, DocumentTreeViewItemProps>(
  ({ selected, children, badges, menu, tip, onClick }, ref) => {
    return (
      <Tip title={tip} placement="right" active={tip != undefined}>
        <div
          ref={ref}
          className={clsx(
            'group border-l-4 text-sm',
            onClick && 'cursor-pointer hover:bg-stone-100',
            selected ? 'border-blue-500 bg-blue-100' : 'border-transparent'
          )}
          onClick={onClick}
        >
          <div className="flex h-8 grow items-center gap-2 bg-transparent pl-8">
            <div className="grow overflow-hidden text-ellipsis text-nowrap">{children}</div>
            {badges}
            {menu}
          </div>
        </div>
      </Tip>
    )
  }
)
