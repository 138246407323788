import { clsx } from 'clsx'
import { forwardRef, ReactNode } from 'react'
import { TabGroup } from '../TabGroup'

type Props = {
  children?: ReactNode
  leadingIcon?: ReactNode
  trailingIcon?: ReactNode
  className?: string
  disabled?: boolean
  type?: 'button' | 'submit'
  variant?: 'default' | 'link' | 'primary' | 'subtle' | 'icon'
  size?: 'm' | 'l'
  onClick?: () => void
  href?: string
  target?: '_blank'
}

export const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      children,
      className,
      leadingIcon,
      trailingIcon,
      variant = 'primary',
      disabled,
      size = 'm',
      type = 'button',
      href,
      target,
      onClick
    },
    forwardedRef
  ) => {
    const cls = clsx(
      'flex items-center justify-center gap-x-2 rounded text-center text-base font-medium leading-4 outline-none focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
      variant == 'default' && 'bg-stone-200 text-gray-900 hover:bg-stone-300 active:bg-stone-400',
      variant == 'primary' && 'bg-blue-500 text-white hover:bg-blue-600 active:bg-blue-800',
      variant == 'subtle' && 'bg-transparent text-gray-700 hover:bg-stone-100 active:bg-stone-200',
      variant == 'icon' && 'bg-transparent',
      variant == 'link' && 'px-0 py-2 text-blue-500',
      size == 'm' && 'px-2.5 py-2',
      size == 'l' && 'px-4 py-3',
      disabled && 'cursor-default opacity-80',
      className
    )
    if (href) {
      return (
        <a ref={forwardedRef as any} className={cls} href={href} target={target}>
          {leadingIcon}
          {children}
          {trailingIcon}
        </a>
      )
    }

    return (
      <button type={type} ref={forwardedRef} disabled={disabled} className={cls} onClick={onClick}>
        {leadingIcon}
        {children}
        {trailingIcon}
      </button>
    )
  }
)
