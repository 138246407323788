import { asyncMap } from '@st/util/async'
import { PDFDocument, PDFFont, StandardFonts } from 'pdf-lib'

export async function embedFonts(
  doc: PDFDocument,
  fontMap: Record<string, StandardFonts | string>
): Promise<Record<string, PDFFont>> {
  function fetchFont(url: string): Promise<ArrayBuffer> {
    return fetch(url).then((r) => r.arrayBuffer())
  }

  function isCustomFont(source: string) {
    return source.includes('/')
  }

  const hasCustomFonts = Object.values(fontMap).some(isCustomFont)

  if (hasCustomFonts) {
    const fontkit = (await import('@pdf-lib/fontkit')).default
    doc.registerFontkit(fontkit)
  }

  const fontTuples = await asyncMap(
    Object.entries(fontMap),
    async ([fontName, fontSource]) => {
      const embeddedFont = isCustomFont(fontSource)
        ? doc.embedFont(await fetchFont(fontSource))
        : doc.embedFont(fontSource)
      return [fontName, await embeddedFont] as [string, PDFFont]
    }
  )

  return Object.fromEntries(fontTuples)
}
