import { useAppDeps } from '@features/app-deps-provider'
import { selFoldersById, stFolderListModule } from '@features/st-folder-list/st-folder-list-module'
import { STBulkSendEditQuestionnaireDialog } from '@features/st-folder-viewer/st-bulk-send-edit-questionnaire-dialog'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { useProcessState } from '@st/redux'
import { Folder } from '@st/sdk'
import {
  Button,
  CheckIcon,
  Dialog,
  DialogButtons,
  EyeIcon,
  Modal,
  PencilIcon,
  Tip,
  Wizard
} from '@st/theme'
import { inflect } from '@st/util/inflect'
import { clsx } from 'clsx'
import { useState } from 'react'
import { match } from 'ts-pattern'
import { STEditTemplateInlineWizardPage } from './st-edit-template-inline-wizard-page'
import { useIntercomChatVisible } from '@features/analytics/analytics-service'

type SendStatus = 'alreadySent' | 'sent' | 'failed'

type BulkSendStatus = 'editTemplate' | 'previewRecipients' | 'sending' | 'sent'

type SendAction =
  | {
      type: 'edit'
      folder: Folder
    }
  | { type: 'confirmEnrollInReminders' }

export function STQuestionnairesBulkEmailPage({
  folderIds,
  onClose,
  // onClickBack,
  onClickEnrollInReminders
}: {
  folderIds: string[]
  onClose: () => void
  // onClickBack: () => void
  onClickEnrollInReminders: (folderIds: string[]) => void
}) {
  useIntercomChatVisible(false)

  const { sdk } = useAppDeps()

  const organizationId = useProcessState(stFolderListModule, (s) => s.organizationId)
  const selectedFolders = useProcessState(stFolderListModule, (s) => selFoldersById(s, folderIds))
  const questionnaireCount = selectedFolders.length

  const [status, setStatus] = useState<BulkSendStatus>('editTemplate')
  const [action, setAction] = useState<SendAction | undefined>(undefined)

  async function onClickSend() {
    setStatus('sending')
    await sdk.send({ type: 'folders/bulkSendQuestionnaireEmails', organizationId, folderIds })
    setStatus('sent')
  }

  async function onClickDone() {
    setAction({ type: 'confirmEnrollInReminders' })
  }

  if (status == 'editTemplate') {
    return (
      <STEditTemplateInlineWizardPage
        title="Send questionnaire to clients"
        subtitle="Clients will receive the following email with a button to access their questionnaire. The email will come from StanfordTax but it will be branded with your firm name and logo."
        templateType="folder_invite"
        onClose={onClose}
        onClickBack={onClose}
        onClickContinue={() => setStatus('previewRecipients')}
      />
    )
  }

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={() => setStatus('editTemplate')}>
            Back
          </Button>
          <div className="flex flex-row items-center gap-6">
            {match(status)
              .with('previewRecipients', () => (
                <Button variant="primary" onClick={onClickSend}>
                  Send {questionnaireCount} {inflect(questionnaireCount, 'questionnaire')}
                </Button>
              ))
              .with('sending', () => (
                <Button variant="primary" disabled={true}>
                  Sending...
                </Button>
              ))
              .with('sent', () => {
                return (
                  <Button
                    variant="primary"
                    onClick={onClickDone}
                    leadingIcon={<CheckIcon className="h-4 w-4" />}
                  >
                    Done
                  </Button>
                )
              })
              .run()}
          </div>
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main
        variant="wide"
        title={status == 'sent' ? 'Sent questionnaires!' : 'Send questionnaires'}
        subtitle={
          status == 'sent'
            ? 'The questionnaires have successfully been emailed.'
            : `You're about to send questionnaires to ${questionnaireCount} ${inflect(
                questionnaireCount,
                'client'
              )}.`
        }
      >
        <STBulkSendTable
          folders={selectedFolders}
          onClickEdit={(folder) => setAction({ type: 'edit', folder })}
        />
      </Wizard.Main>
      {match(action)
        .with({ type: 'edit' }, (action) => {
          return (
            <Modal isOpen={true}>
              <STBulkSendEditQuestionnaireDialog
                folderId={action.folder.id}
                onClose={() => setAction(undefined)}
              />
            </Modal>
          )
        })
        .with({ type: 'confirmEnrollInReminders' }, () => {
          return (
            <Modal isOpen={true}>
              <Dialog
                title="Enroll clients in reminders?"
                buttons={
                  <DialogButtons>
                    <Button variant="default" onClick={onClose}>
                      No
                    </Button>
                    <Button variant="primary" onClick={() => onClickEnrollInReminders(folderIds)}>
                      Yes
                    </Button>
                  </DialogButtons>
                }
              >
                Would you like to enroll these clients in automated reminders?
              </Dialog>
            </Modal>
          )
        })
        .otherwise(() => null)}
    </Wizard.Scaffold>
  )
}

export type SendEmailJob = {
  entityName: string
  addressedAs: string
  email?: string
  status: SendStatus
  onClickEdit: (folder: Folder) => void
}

function STBulkSendTable({
  folders,
  onClickEdit
}: {
  folders: Folder[]
  onClickEdit: (folder: Folder) => void
}) {
  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      <thead>
        <tr className="h-9">
          <th scope="col" className="pl-8 text-left text-xs uppercase text-gray-600">
            Client
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Email
          </th>
          <th scope="col" className="pr-8 text-left text-xs uppercase text-gray-600"></th>
          <th scope="col" className="pr-8 text-left text-xs uppercase text-gray-600"></th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {folders.map((folder) => (
          <tr
            key={folder.id}
            className={clsx('group h-12', { 'bg-green-200': folder.questionnaireSent })}
          >
            <td className="whitespace-nowrap pl-8">
              <div className="flex flex-row items-center gap-2">
                <div className="text-base font-semibold text-gray-900">
                  {formatFolderEntityName(folder.entities)}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap text-base text-gray-500">
              <div className="flex flex-row items-center">
                {folder.memberships.map((m) => m.user.email).join(', ')}

                {folder.memberships.length > 0 ? (
                  <div className="invisible ml-2 flex items-center group-hover:visible">
                    <Tip title="Edit emails">
                      <button onClick={() => onClickEdit(folder)}>
                        <PencilIcon className="h-4 w-4" />
                      </button>
                    </Tip>
                  </div>
                ) : (
                  <Button variant="link" onClick={() => onClickEdit(folder)}>
                    Add email
                  </Button>
                )}
              </div>
            </td>
            <td className="whitespace-nowrap text-base text-gray-500">
              <div className="flex flex-row items-center">
                {/* <TagChip color={getStatusColor(row.status)}>{titleify(row.status)}</TagChip> */}
              </div>
            </td>
            <td>
              <div className="invisible group-hover:visible">
                <button
                  className="flex flex-row items-center gap-2 text-sm text-gray-600"
                  onClick={() => onClickEdit(folder)}
                >
                  <EyeIcon className="h-4 w-4" />
                  Preview
                </button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
