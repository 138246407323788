import { useFlashState } from '@st/react-util/use-flash-state'
import { clsx } from 'clsx'
import { CSSProperties, useEffect, useState } from 'react'

type Props = {
  className?: string
  style?: CSSProperties
  saveStatus: 'saving' | 'saved' | 'idle'
}
export function SaveIndicator({ className, style, saveStatus }: Props) {
  // To prevent jerkiness of the save indicator, we smooth out the save state
  // by ensuring something is shown as saving for at least 1 second
  // (when set tot rue, isPretendSaving flips from true back to false after 1 second)
  const [isPretendSaving, setIsPretendSaving] = useFlashState(false, 1000)
  const [lastSavedAt, setLastSavedAt] = useState<Date | null>(null)

  const isActuallySaving = saveStatus == 'saving'
  useEffect(() => {
    if (isActuallySaving) {
      setIsPretendSaving(true)
      setLastSavedAt(new Date())
    }
  }, [isActuallySaving])

  const reportAsSaving = isActuallySaving || isPretendSaving

  return (
    <div
      style={style}
      className={clsx('flex flex-row items-center text-base text-gray-700', className)}
    >
      {lastSavedAt && !reportAsSaving && <CheckIcon className="mr-2 h-4 w-4 text-green-600" />}
      {reportAsSaving ? 'Saving...' : lastSavedAt ? `Saved at ${formatTime(lastSavedAt)}` : null}
    </div>
  )
}

function CheckIcon({ className }: { className: string }) {
  return (
    <svg fill="none" viewBox="0 0 16 16" className={className}>
      <path
        fill="currentColor"
        d="m14.354 4.854-8 8a.5.5 0 0 1-.707 0l-3.5-3.5a.5.5 0 1 1 .707-.708L6 11.793l7.647-7.647a.5.5 0 0 1 .707.708"
      />
    </svg>
  )
}

function formatTime(time: Date): string {
  return time.toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}
