import { PageNotFoundIcon } from '@components/icons'
import { Link } from '@features/link'
import { FullScreenNoticePage } from '@theme'

export function PageNotFound() {
  return (
    <FullScreenNoticePage title="Page not found" icon={<PageNotFoundIcon />}>
      <Link className="link bold" href={{ name: '/' }}>
        Click here to go home
      </Link>
    </FullScreenNoticePage>
  )
}
