import clsx from 'clsx'
import { useFlashState } from './hooks/useFlashState'

type Props = {
  title: string
  body: string | number
  selected?: boolean
  onClick?: () => void
}
export function DocumentFieldCard({ title, body, selected = false, onClick }: Props) {
  return (
    <div
      className={clsx('cursor-pointer px-3 py-2', selected ? 'bg-yellow-100' : 'bg-white')}
      onClick={onClick}
    >
      <div className="text-base text-gray-700">{title}</div>
      <div className="mt-1 flex flex-row items-end justify-between gap-2 font-semibold text-gray-900">
        <div className="whitespace-pre-wrap">{body}</div>

        <CopyButton text={body} />
      </div>
    </div>
  )
}

function CopyButton({ text }: { text: string | number }) {
  const [copied, setCopied] = useFlashState(false, 2000)

  return copied ? (
    <CopiedIndicator />
  ) : (
    <button>
      <CopyIcon
        className="mb-1 h-4 w-4"
        onClick={() => {
          navigator.clipboard.writeText(typeof text == 'number' ? text.toString() : text)
          setCopied(true)
        }}
      />
    </button>
  )
}

function CopiedIndicator() {
  return (
    <div className="rounded bg-gray-700 px-1 py-0.5 text-center text-xs font-normal text-white">
      Copied
    </div>
  )
}

function CopyIcon(props: { className: string; onClick: () => void }) {
  return (
    <svg
      className={clsx('cursor-pointer', props.className)}
      onClick={props.onClick}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.5 4H2.5C2.36739 4 2.24021 4.05268 2.14645 4.14645C2.05268 4.24021 2 4.36739 2 4.5V13.5C2 13.6326 2.05268 13.7598 2.14645 13.8536C2.24021 13.9473 2.36739 14 2.5 14H11.5C11.6326 14 11.7598 13.9473 11.8536 13.8536C11.9473 13.7598 12 13.6326 12 13.5V4.5C12 4.36739 11.9473 4.24021 11.8536 4.14645C11.7598 4.05268 11.6326 4 11.5 4ZM11 13H3V5H11V13ZM14 2.5V11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12C13.3674 12 13.2402 11.9473 13.1464 11.8536C13.0527 11.7598 13 11.6326 13 11.5V3H4.5C4.36739 3 4.24021 2.94732 4.14645 2.85355C4.05268 2.75979 4 2.63261 4 2.5C4 2.36739 4.05268 2.24021 4.14645 2.14645C4.24021 2.05268 4.36739 2 4.5 2H13.5C13.6326 2 13.7598 2.05268 13.8536 2.14645C13.9473 2.24021 14 2.36739 14 2.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

function CheckIcon(props: { className: string }) {
  return (
    <svg className={clsx('check-icon', props.className)} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"
      />
    </svg>
  )
}
