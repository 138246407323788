import { cn } from '@util/cn'
import { ReactNode } from 'react'

type Props = {
  className?: string
  icon?: ReactNode
  title?: ReactNode
  children: ReactNode
}
export function FullScreenNoticePage({ className, children, title, icon }: Props) {
  return (
    <div
      className={cn(
        'absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center',
        className
      )}
    >
      {icon}
      {title ? (
        <h1 className="page-subtitle text-center" style={{ margin: '16px 0' }}>
          {title}
        </h1>
      ) : null}
      {children}
    </div>
  )
}
