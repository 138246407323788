import { DocCategory } from './src/entities'
import { ComponentConfig, OptionConfig } from './src/ui-config'

export * from './src/ui-config'

export type SectionID = string

export type ComponentConfigType = ComponentConfig['__typename'] & string

export const NONE_OPTION: OptionConfig = {
  key: 'none',
  label: 'None of the above'
}

export const UNCATEGORIZED_DOCUMENT_TYPE: DocCategory = {
  id: 'uncategorized',
  name: 'Uncategorized'
}

export const TASKS_DOCUMENT_TYPE: DocCategory = {
  id: 'tasks',
  name: 'Tasks',
  type: 'tasks'
}

export const INVALID_CATEGORY_DOCUMENT_TYPE: DocCategory = {
  id: 'invalid_category',
  name: 'Invalid Category'
}
