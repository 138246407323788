export function GearIcon({ className }: { className?: string }) {
  return (
    <svg fill="none" className={className}>
      <path
        fill="#fff"
        d="M8 5a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm0 5a2 2 0 1 1-.001-4 2 2 0 0 1 0 4Zm5.5-1.865a4.86 4.86 0 0 0 0-.27l.932-1.165a.502.502 0 0 0 .092-.441 6.704 6.704 0 0 0-.68-1.64.5.5 0 0 0-.375-.246l-1.482-.165a5.023 5.023 0 0 0-.188-.188l-.175-1.486a.5.5 0 0 0-.246-.375 6.731 6.731 0 0 0-1.64-.68.5.5 0 0 0-.442.094L8.134 2.5h-.27l-1.165-.93a.5.5 0 0 0-.441-.093 6.726 6.726 0 0 0-1.64.68.5.5 0 0 0-.246.375l-.165 1.485a5.695 5.695 0 0 0-.188.187l-1.486.171a.5.5 0 0 0-.375.246 6.732 6.732 0 0 0-.68 1.64.5.5 0 0 0 .094.442l.927 1.162v.27L1.569 9.3a.5.5 0 0 0-.093.441 6.7 6.7 0 0 0 .68 1.64.5.5 0 0 0 .375.246l1.482.165c.063.066.125.128.188.188l.173 1.486a.5.5 0 0 0 .246.375 6.735 6.735 0 0 0 1.64.68.501.501 0 0 0 .442-.094l1.162-.927c.09.002.18.002.27 0l1.165.932a.5.5 0 0 0 .441.093 6.697 6.697 0 0 0 1.64-.68.499.499 0 0 0 .247-.375l.165-1.482c.065-.062.127-.125.187-.188l1.486-.175a.5.5 0 0 0 .375-.246 6.732 6.732 0 0 0 .68-1.64.5.5 0 0 0-.093-.442l-.928-1.162Zm-1.007-.406c.01.18.01.362 0 .542a.5.5 0 0 0 .109.343l.886 1.108a5.716 5.716 0 0 1-.389.937l-1.412.16a.5.5 0 0 0-.32.165c-.12.136-.248.264-.383.384a.5.5 0 0 0-.165.319l-.157 1.411c-.3.157-.614.288-.937.39l-1.11-.887a.5.5 0 0 0-.312-.11h-.03c-.18.01-.361.01-.542 0a.5.5 0 0 0-.343.109l-1.11.888a5.717 5.717 0 0 1-.938-.39l-.16-1.41a.5.5 0 0 0-.165-.32 4.631 4.631 0 0 1-.384-.383.5.5 0 0 0-.319-.165l-1.411-.158a5.706 5.706 0 0 1-.39-.937l.887-1.109a.5.5 0 0 0 .11-.342c-.011-.18-.011-.362 0-.543a.5.5 0 0 0-.11-.342l-.886-1.11c.101-.324.232-.638.389-.938l1.41-.16a.5.5 0 0 0 .32-.165c.12-.136.248-.264.383-.384a.5.5 0 0 0 .166-.32l.157-1.41c.3-.157.614-.288.937-.39l1.109.887a.5.5 0 0 0 .342.11c.181-.011.362-.011.543 0a.5.5 0 0 0 .342-.11l1.11-.886c.324.101.638.232.938.389l.16 1.41a.5.5 0 0 0 .165.32c.136.12.264.248.384.383a.5.5 0 0 0 .319.165l1.411.157c.158.3.288.614.39.937l-.887 1.11a.5.5 0 0 0-.11.345h.003Z"
      />
    </svg>
  )
}
