import { useSDK } from '@features/app-deps-provider'
import { useOrganization } from '@features/organizations'
import { usePromise } from '@util/promise'
import { OrganizationSettingsScaffold } from './st-organization-settings-scaffold'
import { match, P } from 'ts-pattern'
import { Subscription } from '@st/sdk'
import { formatMoney } from '@st/util/format'

export function STOrganizationPlanSettingsPage() {
  const organization = useOrganization()
  const sdk = useSDK()

  const planResp = usePromise(() => {
    return sdk.send({ type: 'billing/getBillingState', organizationId: organization.id })
  }, [sdk, organization.id])

  return (
    <OrganizationSettingsScaffold title="Settings">
      <h2 className="mb-5 text-xl text-gray-800">Your plan</h2>

      {match(planResp)
        .with({ status: 'pending' }, () => null)
        .with({ status: 'fulfilled' }, ({ value }) => {
          if (!value.activeSubscription) {
            return <div>You are currently on the free plan which includes 5 free returns.</div>
          }

          return <SubscriptionCard subscription={value.activeSubscription} />
        })
        .run()}
    </OrganizationSettingsScaffold>
  )
}

function SubscriptionCard({ subscription }: { subscription: Subscription }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-col">
        <label className="text-base font-semibold">Annual price</label>
        {formatMoney(subscription.basePrice)}/year, {subscription.baseUnitsIncluded} returns
        included
      </div>
      <div className="flex flex-col">
        <label className="text-base font-semibold">Price for each additional return</label>
        {formatMoney(subscription.additionalUnitPrice)}/return
      </div>
    </div>
  )
}
