import { DrawOp } from '../draw'
import { addDebugInfo } from './common'
import { Radio, Renderer } from './interface'

const RADIO_SIZE = 10

export const radioRenderer: Renderer<Radio> = {
  getSize() {
    return { width: RADIO_SIZE, height: RADIO_SIZE }
  },
  render(props, origin, constraints, context) {
    const size = this.getSize(props, constraints, context)

    const op: DrawOp = {
      type: 'radio',
      name: props.name,
      value: props.value,
      x: origin.x,
      y: origin.y + 1,
      width: size.width,
      height: size.height,
      selected: props.selected,
      backgroundColor: props.backgroundColor ?? '#ffffff'
    }

    return addDebugInfo(
      op,
      { rect: { ...origin, ...size } },
      context.debug?.text
    )
  }
}
