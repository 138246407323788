import { DataType } from '@st/util/cast'

type InputFormat = 'PhoneNumber' | 'SocialSecurityNumber'

export function formatInput(format: InputFormat, value: string): string {
  switch (format) {
    case 'PhoneNumber':
      return PhoneNumberType.cast(value)
    case 'SocialSecurityNumber':
      return SocialSecurityNumberType.cast(value)
  }
}

export function isValidInput(format: InputFormat, value: any): boolean {
  switch (format) {
    case 'PhoneNumber':
      return PhoneNumberType.valid(value)
    case 'SocialSecurityNumber':
      return SocialSecurityNumberType.valid(value)
  }
}

const NON_DIGIT_REGEX = /\D/g

export const SocialSecurityNumberType: DataType<string> = {
  valid: function (value: any): boolean {
    if (typeof value !== 'string') {
      return false
    }
    const digits = value.replace(NON_DIGIT_REGEX, '')
    return digits.length == 9
  },
  cast: function (value: any): string {
    if (typeof value !== 'string') {
      return ''
    }
    return formatSocialSecurityNumber(value)
  }
}

type FormatSSNOpts = { censor?: boolean }
export function formatSocialSecurityNumber(ssn: string, opts?: FormatSSNOpts): string {
  const digits = ssn.replace(NON_DIGIT_REGEX, '')
  const a = digits.substring(0, 3)
  const b = digits.substring(3, 5)
  const c = digits.substring(5, 9)
  return [censor(a, opts?.censor === true), censor(b, opts?.censor === true), c]
    .filter((el) => el.length > 0)
    .join('-')
}

const CHAR_REGEX = /./g
function censor(value: string, shouldSensor: boolean) {
  return shouldSensor ? value.replace(CHAR_REGEX, 'x') : value
}

export const PhoneNumberType: DataType<string> = {
  valid: function (value: any): boolean {
    const digits = value.replace(NON_DIGIT_REGEX, '')
    // Allow numbers between 8 and 15 digits (common international range)
    return digits.length >= 8 && digits.length <= 15
  },
  cast: function (value: unknown): string {
    if (typeof value !== 'string') {
      return ''
    }
    return formatPhoneNumber(value)
  }
}

const US_PHONE_NUMBER_LENGTH = 11
export function formatPhoneNumber(phoneNumber: string) {
  var digits = phoneNumber.replace(NON_DIGIT_REGEX, '')

  // US numbers without the country code
  if (digits.length == 10) {
    digits = '1' + digits
  }

  if (digits.length == US_PHONE_NUMBER_LENGTH) {
    const countryCode = digits.substring(0, 1)
    const areaCode = digits.substring(1, 4)
    const a = digits.substring(4, 7)
    const b = digits.substring(7, 11)

    return [
      countryCode.length > 0 ? `+${countryCode}` : '',
      areaCode.length > 0 ? ` (${areaCode})` : '',
      a.length > 0 ? ` ${a}` : '',
      b.length > 0 ? `-${b}` : ''
    ]
      .filter((el) => el.length > 0)
      .join('')
  } else {
    return phoneNumber
  }
}
