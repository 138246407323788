import { Section } from '../section'
import asyncLimit from './limit'

type Opts = {
  concurrency: number
}
export function asyncMap<T, U>(
  items: T[],
  callbackFn: (item: T, index: number, array: T[]) => Promise<U>,
  opts?: Opts
): Promise<U[]> {
  if (items.length == 0) return Promise.resolve([])

  const concurrency = opts?.concurrency ?? 1000
  const limit = asyncLimit(concurrency)

  const limitedCallbacks = items.map((item, index) => limit(() => callbackFn(item, index, items)))

  return Promise.all(limitedCallbacks)
}

export async function asyncFilter<T, U>(
  items: T[],
  filterFn: (item: T, index: number, array: T[]) => Promise<boolean>,
  opts?: Opts
): Promise<T[]> {
  function mapFn(item: T, index: number, array: T[]): Promise<[T, boolean]> {
    return filterFn(item, index, array).then((shouldInclude) => {
      return [item, shouldInclude] as [T, boolean]
    })
  }

  const filterResults = await asyncMap(items, mapFn, opts)

  return filterResults
    .filter(([_item, shouldInclude]) => shouldInclude)
    .map(([item, _shouldFilter]) => item)
}

export async function asyncFlatMap<T, U>(
  items: T[],
  callbackFn: (item: T, index: number, array: T[]) => Promise<U[]>,
  opts?: Opts
): Promise<U[]> {
  if (items.length == 0) return []
  const arrays = await asyncMap(items, callbackFn, opts)
  return arrays.reduce((flat, array) => flat.concat(array), [])
}

export async function asyncSectionMap<S, T, U>(
  sections: Section<S, T>[],
  callbackFn: (item: T, index: number, heading: S) => Promise<U>,
  opts?: Opts
): Promise<Section<S, U>[]> {
  const concurrency = opts?.concurrency ?? 1000
  const limit = asyncLimit(concurrency)

  // First we collect an intermediate array of sections where each item holds a pending
  const pendingSections: Section<S, Promise<U>>[] = sections.map((section) => {
    return {
      heading: section.heading,
      items: section.items.map((item, index) =>
        limit(() => callbackFn(item, index, section.heading))
      )
    }
  })

  // We wait for all of the items to get resolved
  const resolvedSections: Section<S, U>[] = []
  for (const s of pendingSections) {
    resolvedSections.push({
      ...s,
      items: await Promise.all(s.items)
    })
  }

  return resolvedSections
}
