import { cn } from '@util/cn'
import { useFileDrop } from '@st/theme'
import { ChangeEvent, CSSProperties, ReactNode, useRef } from 'react'

type Props = {
  children?: ReactNode
  className?: string
  style?: CSSProperties
  accept?: string
  onDrop: (dt: DataTransfer) => void
  disabled?: boolean
}

export function FileDropZone({
  children,
  className,
  style,
  accept,
  onDrop,
  disabled = false,
  ...otherProps
}: Props) {
  const { isDragging, dropHandlers } = useFileDrop(onDrop)
  const fileInputRef = useRef<HTMLInputElement>(null)

  function onFilesSelect(e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files
    if (!files || files.length == 0) {
      return
    }

    const dt = new DataTransfer()
    for (const f of files) dt.items.add(f)
    onDrop(dt)
  }

  return (
    <div
      className={cn(
        'flex cursor-pointer flex-col items-center justify-center rounded-md border border-gray-200',
        className,
        isDragging ? 'border-black' : null
      )}
      style={style}
      {...dropHandlers}
      onClick={() => fileInputRef.current!.click()}
      {...otherProps}
    >
      <input
        type="file"
        disabled={disabled}
        accept={accept}
        hidden
        multiple
        onChange={onFilesSelect}
        ref={fileInputRef}
      />
      {children}
    </div>
  )
}
