import { ReactNode } from 'react'
import { clsx } from 'clsx'

type Props = {
  children?: ReactNode
  className?: string
}

export function Toolbar({ children, className }: Props) {
  return (
    <div
      className={clsx(
        'flex justify-between border-b border-l border-neutral-200 bg-neutral-100 px-5 py-1.5',
        className
      )}
    >
      {children}
    </div>
  )
}

export function ToolbarSection({ children, className }: Props) {
  return <div className={clsx('flex items-center gap-2', className)}>{children}</div>
}
