export function ArrowRight() {
  return (
    <svg className="size-4" viewBox="0 0 19 17" fill="none">
      <path
        d="M10.7045 1.28406C10.3111 0.896434 9.67795 0.901123 9.29033 1.29453C8.90271 1.68793 8.9074 2.32108 9.3008 2.7087L14.6711 8.00004L1 8.00004C0.447714 8.00004 -3.73713e-07 8.44776 -3.49572e-07 9.00004C-3.25431e-07 9.55233 0.447715 10 1 10L14.6646 10L9.3008 15.285C8.9074 15.6726 8.90271 16.3058 9.29033 16.6992C9.67795 17.0926 10.3111 17.0973 10.7045 16.7096L17.6287 9.88725C18.1256 9.39764 18.1256 8.59605 17.6287 8.10644L10.7045 1.28406Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function ArrowLeft() {
  return (
    <svg className="size-4" viewBox="0 0 19 17" fill="none">
      <path
        d="M8.29549 1.28406C8.6889 0.896434 9.32205 0.901123 9.70967 1.29453C10.0973 1.68793 10.0926 2.32108 9.6992 2.7087L4.3289 8.00004L18 8.00004C18.5523 8.00004 19 8.44776 19 9.00004C19 9.55233 18.5523 10 18 10L4.33539 10L9.6992 15.285C10.0926 15.6726 10.0973 16.3058 9.70967 16.6992C9.32205 17.0926 8.6889 17.0973 8.29549 16.7096L1.3713 9.88725C0.87439 9.39764 0.87439 8.59605 1.3713 8.10644L8.29549 1.28406Z"
        fill="currentColor"
      />
    </svg>
  )
}
