export class BlobStore {
  private blobMap: Record<string, Blob> = {}
  private refCounts: Record<string, number> = {}

  get = (url: string): Blob | undefined => {
    return this.blobMap[url]
  }

  set = (url: string, blob: Blob): void => {
    this.blobMap[url] = blob

    const curRefCount = this.refCounts[url]
    this.refCounts[url] = (curRefCount ?? 0) + 1
  }

  delete = (url: string) => {
    const curRefCount = this.refCounts[url] ?? 0
    if (curRefCount === undefined) {
      // do nothing
    } else if (curRefCount === 1) {
      delete this.refCounts[url]
      delete this.blobMap[url]
    } else {
      this.refCounts[url] = curRefCount - 1
    }
  }
}
