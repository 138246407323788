import { useSDK } from '@features/app-deps-provider'
import { useAuthState } from '@features/app-hooks'
import { DumpJSON } from '@st/react-util/dump-json'
import { Button } from '@st/theme'
import { throwTestException } from '@util/test-exception'

export function DebugPage() {
  const authState = useAuthState()
  const sdk = useSDK()

  async function callServerException() {
    await sdk.send({ type: 'accounts/triggerTestException', message: 'error from client' })
  }

  async function callInvalidArguments() {
    await sdk.send({ type: 'accounts/triggerTestException' } as any)
  }

  return (
    <div className="flex h-full w-full flex-col gap-4 p-20">
      <Button
        variant="primary"
        onClick={() => {
          throwTestException()
        }}
      >
        Test Browser Exception
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          callServerException()
        }}
      >
        Test Server Exception
      </Button>

      <Button
        variant="primary"
        onClick={() => {
          callInvalidArguments()
        }}
      >
        Test Invalid Arguments
      </Button>

      <h1 className="mt-4 text-2xl font-bold">Auth state</h1>
      <DumpJSON value={authState} />
    </div>
  )
}
