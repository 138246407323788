export function monkeyPatchBrowserLocationEvents() {
  if (typeof window === 'undefined') return

  let oldPushState = window.history.pushState

  history.pushState = function newPushState(...args) {
    // there's a but either with wouter of google analytics
    // one of these libs is already monkeypatching history which
    // causes some conflicts and results in a "//"
    const url = args[2]
    if (typeof url == 'string' && url.startsWith('//')) {
      args[2] = url.substring(1)
    }

    let ret = oldPushState.apply(this, args)
    window.dispatchEvent(new Event('pushstate'))
    window.dispatchEvent(new Event('locationchange'))
    return ret
  }

  let oldReplaceState = window.history.replaceState
  history.replaceState = function replaceState(...args) {
    let ret = oldReplaceState.apply(this, args)
    window.dispatchEvent(new Event('replacestate'))
    window.dispatchEvent(new Event('locationchange'))
    return ret
  }

  window.addEventListener('popstate', () => {
    window.dispatchEvent(new Event('locationchange'))
  })
}
