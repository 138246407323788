import { CheckboxConfig } from '@st/folder/ui-config'
import { QuestionTip } from '@st/theme'
import { cn } from '@util/cn'
import { ConfigInputProps } from '@util/input-component'

type Props = ConfigInputProps<CheckboxConfig, boolean> & {
  className?: string
  disabled?: boolean
}
export function ToggleButton({ value, label, className, onChange, tip, disabled = false }: Props) {
  function handleCheckboxClick() {
    if (disabled) {
      return
    }

    onChange(!value)
  }

  return (
    <div
      className={`${className === undefined ? 'toggle-button__container' : className} `}
      onClick={handleCheckboxClick}
    >
      {label}
      <div style={{ width: '20px' }} />
      <ToggleButtonView value={value} />
      {tip ? (
        <>
          &nbsp;
          <QuestionTip title={tip.title} body={tip.body} />
        </>
      ) : null}
    </div>
  )
}

const CloseIcon = () => (
  <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 1.41L8.59 0L5 3.59L1.41 0L0 1.41L3.59 5L0 8.59L1.41 10L5 6.41L8.59 10L10 8.59L6.41 5L10 1.41Z"
      fill="#828282"
    />
  </svg>
)

const DoneIcon = () => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.98965 3.29302L5.9394 7.24277L4.52519 8.65698L0.575439 4.70723L1.98965 3.29302Z"
      fill="white"
    />
    <path
      d="M4.52518 5.82854L10.0105 0.343262L11.4247 1.75748L5.9394 7.24277L4.52518 5.82854Z"
      fill="white"
    />
  </svg>
)

const ToggleButtonView = ({ value }: { value: boolean }) => (
  <div className={cn('toggle-button', value ? `toggle-button--${value ? 'active' : ''}` : null)}>
    <div className="toggle-button__state-slider">{value ? <DoneIcon /> : <CloseIcon />}</div>
  </div>
)
