/**
 * Search a list of {@link records} by a {@link query}
 * The query is matched against the value of {@link toString} for each record
 * Records are returned in order.
 *
 * Inspired by https://codereview.stackexchange.com/a/23905
 */
export function search<T>(
  records: T[],
  toString: (el: T) => string,
  query: string
) {
  query = query.trim()

  if (query.length == 0) {
    return records
  }
  const patterns = query.split(' ').map((word) => {
    // word boundary to ensure it starts at the beginning
    return new RegExp('\\b' + escapeRegExp(word), 'i')
  })
  return records.filter((record) => {
    const recordText = toString(record)
    return patterns.every((p) => recordText.match(p))
  })
}

function escapeRegExp(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
} /* $& means the whole matched string*/ // $& means the whole matched string
