import { FormConfig } from '@st/folder/ui-config'
import { JsonConfigRepo } from '@st/util/json-config'

const formConfigRepo = new JsonConfigRepo<FormConfig>({
  fetchPreset(id) {
    // no longer supported, we pull from the API now
    return undefined
  },
  fetchConfig(id) {
    if (!id) throw `FormConfigRepo.fetchConfig missing id ${id}`
    const endpoint = `${new URL(process.env.NEXT_PUBLIC_API_V2_ENDPOINT!).origin}/config/form`
    return fetch(`${endpoint}/${id}`, {
      method: 'GET',
      headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' }
    }).then((resp) => resp.json() as any)
  }
})

export function getFormConfig(id: string): Promise<FormConfig | undefined> {
  return formConfigRepo.fetch(id)
}
