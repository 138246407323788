import { useAnalytics, useTrackEvent } from '@features/analytics'
import { useAuthState } from '@features/app-hooks'
import { Link } from '@features/link'
import { OrganizationLoggedOutHeader } from '@features/organizations'
import {
  ORGANIZATION_NUM_CLIENTS_OPTIONS,
  OrganizationSignUpInput,
  organizationSignUpModule,
  signUpOrganization
} from '@features/organizations/organization-sign-up-module'
import { routes } from '@features/routing'
import { useProcess, useProcessState } from '@st/redux'
import { TAX_APP_OPTIONS } from '@st/tax-folder'
import { Button, Select, TextInput } from '@st/theme'
import { useState } from 'react'

const INITIAL_FORM: OrganizationSignUpInput = {
  userName: '',
  userEmail: '',
  organizationName: '',
  organizationPhoneNumber: '',
  password: '',
  passwordConfirm: '',
  tosPrivacyAgreement: false,
  taxApp: undefined
}

export function OrganizationSignUpPage() {
  const analytics = useAnalytics()
  useTrackEvent('viewed_sign_up_page')

  const { user } = useAuthState()
  const [input, setInput] = useState<OrganizationSignUpInput>(INITIAL_FORM)

  const organizationSignUp = useProcess(organizationSignUpModule)
  const signUpState = useProcessState(organizationSignUpModule)

  const error = 'error' in signUpState ? signUpState.error : undefined
  const validationErrors = error?.type == 'validation' ? error.errors : {}
  const signingUp = signUpState.status != 'idle'

  async function onClickSignUp() {
    analytics.track('clicked_sign_up')

    const state = await organizationSignUp.send(signUpOrganization(input))

    if (state.status == 'succeeded') {
      window.location.href = routes.toPath({
        name: 'organization_select_stack',
        organizationSlug: state.organization.slug
      })!
    }
  }

  return (
    <div className="h-full">
      <OrganizationLoggedOutHeader />
      <div className="mt-12 flex h-full flex-col items-center">
        <h1 className="w-max-500 text-center text-3xl">{`You're`} almost in!</h1>

        <div className="text-center text-base">
          {'Already have an account?'}{' '}
          <Link className="text-blue-500" href={{ name: 'login' }}>
            Click here to login
          </Link>
        </div>

        <form className="w-max-[600px] mt-4">
          <div className="flex flex-col items-stretch gap-4">
            <InputField
              label="Your name"
              value={input.userName}
              error={validationErrors.userName}
              onChange={(value) => setInput({ ...input, userName: value })}
            />

            <InputField
              label="Company name"
              value={input.organizationName}
              error={validationErrors.organizationName}
              onChange={(value) => setInput({ ...input, organizationName: value })}
            />

            <InputField
              label="Email"
              type="email"
              value={input.userEmail}
              error={validationErrors.userEmail}
              onChange={(value) => setInput({ ...input, userEmail: value })}
            />

            {user ? null : (
              <InputField
                label="Password"
                type="password"
                value={input.password}
                error={validationErrors.password}
                onChange={(value) => setInput({ ...input, password: value })}
              />
            )}

            {user ? null : (
              <InputField
                label="Confirm password"
                type="password"
                value={input.passwordConfirm}
                error={validationErrors.passwordConfirm}
                onChange={(value) => setInput({ ...input, passwordConfirm: value })}
              />
            )}

            <div className="flex flex-col items-stretch gap-1.5">
              <Select
                options={TAX_APP_OPTIONS}
                placeholder="Select your tax software"
                value={input.taxApp}
                onChange={(value) => setInput({ ...input, taxApp: value })}
                buildValue={(o) => o.key}
                buildLabel={(o) => o.label}
              />
              {validationErrors.taxApp ? (
                <div className="error-message">{validationErrors.taxApp}</div>
              ) : null}
            </div>

            <div className="flex flex-col items-stretch gap-1.5">
              <Select
                options={ORGANIZATION_NUM_CLIENTS_OPTIONS}
                placeholder="Number of clients"
                value={input.numberOfClients}
                onChange={(value) => setInput({ ...input, numberOfClients: value })}
                buildValue={(o) => o}
                buildLabel={(o) => o}
              />
              {validationErrors.numberOfClients ? (
                <div className="error-message">{validationErrors.numberOfClients}</div>
              ) : null}
            </div>

            {error?.type == 'general' && <div className="error-message">{error.message}</div>}

            <Button size="l" variant="primary" disabled={signingUp} onClick={onClickSignUp}>
              {signingUp ? 'Please wait...' : 'Continue'}
            </Button>

            <div className="flex flex-col gap-1.5">
              <div className="flex flex-row items-center gap-1.5">
                <input
                  type="checkbox"
                  style={{ width: 20, height: 20 }}
                  onChange={(e) => setInput({ ...input, tosPrivacyAgreement: e.target.checked })}
                />
                <span>
                  {`I agree to StanfordTax's `}
                  <a
                    className="text-blue-500"
                    target="_blank"
                    href="https://stanfordtax.com/terms-of-service"
                  >
                    Terms of Service
                  </a>
                  {' and '}
                  <a
                    className="text-blue-500"
                    target="_blank"
                    href="https://stanfordtax.com/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                </span>
              </div>

              {validationErrors.tosPrivacyAgreement ? (
                <div className="error-message">{validationErrors.tosPrivacyAgreement}</div>
              ) : null}
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

type InputFieldProps = {
  type?: 'text' | 'email' | 'password'
  label: string
  value?: string
  error?: string
  onChange: (value: string) => void
}
function InputField({ type = 'text', label, value, error, onChange }: InputFieldProps) {
  return (
    <div className="flex flex-col items-stretch gap-1.5">
      <TextInput
        placeholder={label}
        type={type}
        className="condensed"
        autoComplete="false"
        value={value}
        onChange={onChange}
      />
      {error ? <div className="error-message">{error}</div> : null}
    </div>
  )
}
