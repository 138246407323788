import { TagOption } from '@st/folder'

/**
 * Colored Trello style label tags
 * The label is empty because the labels can be edited from within the organization settings
 */
export const UI_TAG_OPTIONS: TagOption[] = [
  {
    key: 'green',
    label: '',
    background: '#5abe8e',
    color: '#e0fbee'
  },
  {
    key: 'orange',
    label: '',
    background: '#e6750d',
    color: '#ffe9d5'
  },
  {
    key: 'blue',
    label: '',
    background: '#536cf1',
    color: '#dde9ff'
  },
  { key: 'red', label: '', background: '#d0474b', color: '#fbe0e0' },
  { key: 'yellow', label: '', background: '#daa00b', color: '#fff6d2' },
  { key: 'violet', label: '', background: '#a665ce', color: '#f3e0ff' }
]

export const SENT_TAG = 'sent'
export const REMINDED_TAG = 'reminded'

/**
 * Special tags that we use to keep track of thing
 * Currently not editable from within the UI
 */
export const SYSTEM_TAG_OPTIONS: TagOption[] = [
  {
    key: SENT_TAG,
    label: 'Sent',
    background: '#5abe8e',
    color: '#e0fbee',
    readOnly: true
  },
  {
    key: REMINDED_TAG,
    label: 'Reminded',
    background: '#5abe8e',
    color: '#e0fbee',
    readOnly: true
  }
]

export const TAG_OPTIONS: TagOption[] = [
  ...UI_TAG_OPTIONS,
  ...SYSTEM_TAG_OPTIONS
]
