import { range } from '@st/util/array'
import { ReactNode } from 'react'
import { Size } from './geom'
import { Box, Expand, Flex } from './primitive-elements'
import * as r from './renderer'

export * from './primitive-elements'

type Props<T> = Omit<T, 'type' | 'children'> & {
  children: ReactNode
}

type RowProps = Omit<Props<r.Flex>, 'direction'> & {
  gap?: number
}
export function Row({ children, align, gap = 0, mainAxisSize, tag }: RowProps) {
  const finalChildren = addGapElements(children, {
    width: gap,
    height: 0
  })
  return (
    <Flex direction="row" align={align} mainAxisSize={mainAxisSize} tag={tag}>
      {finalChildren}
    </Flex>
  )
}

type ColumnProps = Omit<Props<r.Flex>, 'direction'> & {
  gap?: number
}
export function Column({
  children,
  align,
  gap = 0,
  mainAxisSize,
  tag
}: ColumnProps) {
  const finalChildren = addGapElements(children, {
    width: 0,
    height: gap
  })
  return (
    <Flex
      direction="column"
      align={align}
      mainAxisSize={mainAxisSize}
      tag={tag}
    >
      {finalChildren}
    </Flex>
  )
}

export function Space({ w = 0, h = 0 }: { w?: number; h?: number }) {
  return <Box width={w} height={h} />
}

type TableProps = {
  rowCount: number
  colCount: number
  colFlex: (colIndex: number) => number
  rowHeight: (rowIndex: number) => number
  children: (rowIndex: number, colIndex: number) => ReactNode
}
export function Table({
  colCount,
  rowCount,
  rowHeight,
  colFlex,
  children
}: TableProps) {
  return (
    <Row tag="yeet">
      {range(0, colCount - 1).map((colIndex) => (
        <Expand key={colIndex} flex={colFlex(colIndex)}>
          <Column mainAxisSize="min">
            {range(0, rowCount - 1).map((rowIndex) => {
              return (
                <Box
                  key={rowIndex}
                  height={rowHeight(rowIndex)}
                  width={Infinity}
                >
                  {children(rowIndex, colIndex)}
                </Box>
              )
            })}
          </Column>
        </Expand>
      ))}
    </Row>
  )
}

function addGapElements(children: ReactNode, size: Size) {
  if (size.width == 0 && size.height == 0) return children
  if (!Array.isArray(children)) return children

  const newChildren: ReactNode[] = []
  for (var i = 0; i < children.length; i++) {
    newChildren.push(children[i])
    if (i < children.length - 1)
      newChildren.push(
        <Space key={`space-${i}`} w={size.width} h={size.height} />
      )
  }

  return newChildren
}
