import { DrawOp } from '../draw'
import { Rect } from '../geom'

type DebugInfo = {
  rect: Rect
}

export function addDebugInfo(
  op: DrawOp,
  info: DebugInfo,
  enabled?: boolean
): DrawOp {
  if (!enabled) {
    return op
  }

  const drawRectOp: DrawOp = {
    type: 'rect',
    rect: info.rect,
    borderColor: '#ff0000',
    borderWidth: 1
  }
  return {
    type: 'group',
    ops: op.type == 'group' ? [...op.ops, drawRectOp] : [op, drawRectOp]
  }
}
