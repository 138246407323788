import { useAnalytics } from '@features/analytics'
import { PosthogAnalyticsService } from '@features/analytics/providers/posthog'
import { AppDepsProvider, useAppDeps } from '@features/app-deps-provider'
import { useAppDepsCreate, useRoute } from '@features/app-hooks'
import { appStore } from '@features/app-store'
import { removeLoginQueryParams } from '@features/auth'
import { authModule, loginWithMagicLink } from '@features/auth/auth-module'
import { ErrorReporting } from '@features/error-reporting'
import { organizationModule } from '@features/organizations/organization-module'
import { PageNotFound } from '@features/organizations/pages/page-not-found'
import { routeModule } from '@features/route-module'
import { sdkModule } from '@features/sdk-module'
import { STQuestionnaire } from '@features/st-questionnaire'
import {
  STQuestionnaireLoginPage,
  STQuestionnaireLookupPage
} from '@features/st-questionnaire-login'
import { StoreDepsProvider, StoreProvider, useProcess, useProcessState } from '@st/redux'
import { getQueryParams } from '@st/util/url'
import { ModalSurface } from '@util/use-modal'
import { ReactNode, useEffect } from 'react'
import { CollectionHomePage } from './pages/collection-home-page'
import { Title } from '@st/react-util/title'

/**
 * The app that the clients of accountants use to fill out a questionnaire.
 * It lives on its own domain and is whitelabeled, meaning it uses the branding of the accounants.
 *
 * They may send a link like organizer.tax/cpa-esq/taxes2021 to their clients.
 * When their clients, click the link, they'll have to login and then fill out what is essentially a survey.
 */
export function CollectionApp() {
  const appDeps = useAppDepsCreate()

  return (
    <StoreDepsProvider deps={appDeps}>
      <StoreProvider store={appStore}>
        <AppDepsProvider deps={appDeps}>
          <PosthogAnalyticsService.Script />

          <CollectionAppPlugins />
          <ErrorReporting />
          <ModalSurface>
            <OrganizationCollectionApp />
          </ModalSurface>
        </AppDepsProvider>
      </StoreProvider>
    </StoreDepsProvider>
  )
}

function OrganizationCollectionApp() {
  const route = useRoute()

  switch (route.name) {
    case '/':
      return <CollectionHomePage />
    case 'client_folder':
      return (
        <STQuestionnaireLoginPage folderId={route.folderId}>
          <LoadOrganization organizationSlug={route.organizationSlug}>
            <STQuestionnaire folderId={route.folderId} role="client" />
          </LoadOrganization>
        </STQuestionnaireLoginPage>
      )
    case 'find_or_create_folder':
      return (
        <LoadOrganization organizationSlug={route.organizationSlug}>
          <STQuestionnaireLookupPage schemaId={route.schemaId} />
        </LoadOrganization>
      )
    default:
      return <PageNotFound />
  }
}

function LoadOrganization({
  organizationSlug,
  children
}: {
  organizationSlug: string
  children: ReactNode
}) {
  const analytics = useAnalytics()

  useProcess(organizationModule, { organizationSlug })
  const organization = useProcessState(organizationModule, (s) => s.organization)

  useEffect(() => {
    if (organization) analytics.setOrganization(organization)
  }, [analytics, organization])

  if (!organization) {
    return null
  }

  return <>{children}</>
}

export function CollectionAppPlugins() {
  const { authClient, sdk } = useAppDeps()

  const auth = useProcess(authModule)
  const analytics = useAnalytics()

  useProcess(sdkModule, { sdk })
  const route = useProcess(routeModule)

  const magicLinkToken = useProcessState(routeModule, (s) => {
    const searchParams = getQueryParams(s.location.pathname)
    return searchParams['magic-link-token'] ?? undefined
  })

  useEffect(() => {
    return authClient.subscribeToAuthStatus((event) => {
      switch (event.type) {
        case 'loggedIn':
          auth.send({ type: 'userIsLoggedIn', user: event.user })
          analytics.trackUserIdentified(event.user, {
            getToken: authClient.getToken
          })
          break
        case 'loggedOut':
          auth.send({ type: 'userIsLoggedOut' })
          analytics.trackUserAnonymous()
          break
      }
    })
  }, [authClient])

  useEffect(() => {
    if (magicLinkToken) {
      const { location } = route.getState()
      route.send({
        type: 'navigate',
        pathname: removeLoginQueryParams(location.pathname)
      })

      auth.send(loginWithMagicLink({ magicLinkToken }))
    }
  }, [auth, magicLinkToken])

  return <></>
}
