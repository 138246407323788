import posthog from 'posthog-js'
import { useEffect, useState } from 'react'

type FeatureFlag = 'document-ai-fields'

export function useFeatureEnabled(flag: FeatureFlag): boolean | undefined {
  const [featureEnabled, setFeatureEnabled] = useState<boolean | undefined>()

  useEffect(() => {
    if (typeof posthog === 'undefined') return () => null

    return posthog.onFeatureFlags(() => {
      setFeatureEnabled(posthog.isFeatureEnabled(flag))
    })
  }, [flag])

  return featureEnabled
}

export function isFeatureEnabled(name: string) {
  return posthog.isFeatureEnabled(name)
}
