import { useSDK } from '@features/app-deps-provider'
import { RequireLogin } from '@features/auth/require-login'
import { OrganizationLoginPage } from '@features/organizations'
import { BrandLogo, Button, FullPageCard } from '@st/theme'

type Props = {
  clientId: string
  state: string
  redirectUri: string
}
export function AuthorizeZapierPage({ clientId, state, redirectUri }: Props) {
  return (
    <RequireLogin
      loginScreen={<OrganizationLoginPage header={<ZapierLoginHeader />} />}
      loadingScreen={<></>}
    >
      <LoggedInAuthorizeZapier clientId={clientId} state={state} redirectUri={redirectUri} />
    </RequireLogin>
  )
}

function LoggedInAuthorizeZapier({ clientId, state, redirectUri }: Props) {
  const sdk = useSDK()

  async function onClickAuthorize() {
    const response = await sdk.send({
      type: 'accounts/authorizeZapier',
      clientId,
      state,
      redirectUri
    })
    window.location.href = response.redirectTo
  }

  return (
    <FullPageCard>
      <div className="flex flex-col items-center gap-3">
        <BrandLogo className="text-3xl" />
        <Button variant="primary" onClick={onClickAuthorize}>
          Authorize Zapier
        </Button>
      </div>
    </FullPageCard>
  )
}

export function ZapierLoginHeader() {
  return (
    <div className="flex flex-col items-center gap-3">
      <BrandLogo className="text-3xl" />
      <p className="text-center">Login to authorize Zapier to access your StanfordTax data</p>
    </div>
  )
}
