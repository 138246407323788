import { SDKOperation } from './sdk.types'

export type STSDK = SDK<SDKOperation>

type SDKOpts = {
  baseUrl: string
  getToken: () => Promise<string | undefined>
  onVersionEvent?: (event: VersionEvent) => void
  onRequestFailed?: (trace: RequestTrace) => void
}
export function createSTClientSDK(opts: SDKOpts) {
  return new SDK<SDKOperation>(opts)
}

export type Operation<Req = any, Res = any> = {
  request: Req
  response: Res
}

type SDKReq = {
  type: string
  [key: string]: any
}

type RequestOpts = {
  delay?: number
}

class SDK<T extends Operation<SDKReq, any>> {
  private baseUrl: string
  private getToken: () => Promise<string | undefined>
  private onRequestFailed: (trace: RequestTrace) => void
  private onVersionEvent: (event: VersionEvent) => void
  private lastApiVersion?: string

  constructor({ baseUrl, getToken, onRequestFailed, onVersionEvent }: SDKOpts) {
    this.baseUrl = baseUrl
    this.getToken = getToken
    this.onRequestFailed = onRequestFailed ?? (() => {})
    this.onVersionEvent = onVersionEvent ?? (() => {})
  }

  async fetch<K extends T['request']['type']>(
    sdkRequest: Extract<T['request'], { type: K }>
  ): Promise<Response> {
    const token = await this.getToken()
    return fetch(this.constructRequest(sdkRequest, token))
  }

  async send<K extends T['request']['type']>(
    req: Extract<T['request'], { type: K }>,
    opts?: RequestOpts
  ): Promise<Extract<T, { request: { type: K } }>['response']> {
    const token = await this.getToken()

    const request = this.constructRequest(req, token)
    const response = await fetch(request)

    const apiVersion = response.headers.get('x-api-version')

    // if they are the same nothing to do
    if (apiVersion && this.lastApiVersion !== apiVersion) {
      // initializing apiVersion - nothing was there before
      if (!this.lastApiVersion) {
        this.onVersionEvent({ type: 'versionInit', version: apiVersion })
        this.lastApiVersion = apiVersion
      } else {
        this.onVersionEvent({
          type: 'versionChange',
          prevVersion: this.lastApiVersion,
          nextVersion: apiVersion
        })
        this.lastApiVersion = apiVersion
      }
    }

    if (response.status != 200) {
      const responseContentType = response.headers.get('content-type')
      const responseBody =
        responseContentType == 'application/json' ? await response.json() : await response.text()

      const trace: RequestTrace = {
        request: {
          method: request.method,
          url: request.url,
          headers: Object.fromEntries(request.headers.entries()),
          body: req
        },
        response: {
          status: response.status,
          headers: Object.fromEntries(response.headers.entries()),
          body: responseBody
        }
      }

      this.onRequestFailed(trace)

      return responseBody
    }

    if (opts?.delay) {
      await delay(opts.delay)
    }

    return response.json()
  }

  private constructRequest<K extends T['request']['type']>(
    request: Extract<T['request'], { type: K }>,
    token: string | undefined
  ): Request {
    const headers: HeadersInit = { 'Content-Type': 'application/json' }
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }

    return new Request(this.baseUrl + '/' + request.type, {
      method: 'POST',
      headers,
      body: JSON.stringify(request)
    })
  }
}

function delay(ms: number): Promise<void> {
  return new Promise<void>((resolve) => setTimeout(() => resolve(), ms))
}

type Version = string

type VersionEvent =
  | { type: 'versionInit'; version: Version }
  | { type: 'versionChange'; prevVersion: Version; nextVersion: Version }

export type RequestTrace = {
  request: {
    url: string
    method: string
    headers: Record<string, string>
    body: Record<string, any>
  }
  response: {
    status: number
    headers: Record<string, string>
    body: string | Record<string, any>
  }
}

export function redactRequestTrace(trace: RequestTrace, regex: RegExp): RequestTrace {
  function redactHeaders(headers: Record<string, string>): Record<string, string> {
    const redacted = { ...headers }
    for (const key in redacted) {
      if (regex.test(key)) {
        redacted[key] = '[REDACTED]'
      }
    }
    return redacted
  }

  return {
    request: {
      method: trace.request.method,
      url: trace.request.url,
      headers: redactHeaders(trace.request.headers),
      body: trace.request.body
    },
    response: {
      status: trace.response.status,
      headers: redactHeaders(trace.response.headers),
      body: trace.response.body
    }
  }
}

export class SDKRequestError extends Error {
  constructor(
    public request: RequestTrace['request'],
    public response: RequestTrace['response']
  ) {
    super(`API request failed: ${request.url} with status ${response.status}`)
    this.name = 'APIRequestError'
    Object.setPrototypeOf(this, SDKRequestError.prototype)
  }
}
