import { useSetRoute } from '@features/app-hooks'
import { OrganizationLogo, useOrganization } from '@features/organizations'
import { useProcess, useProcessState } from '@st/redux'
import { Button, FullPageCard, TextInput } from '@st/theme'
import { isEmpty } from '@st/util/json-value'
import { useEffect, useState } from 'react'
import { match, P } from 'ts-pattern'
import { stQuestionnaireLookupModule } from './st-questionnaire-lookup-module'
import { Link } from '@features/link'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'

type Props = {
  schemaId: string
}
export function STQuestionnaireLookupPage({ schemaId }: Props) {
  const organization = useOrganization()

  const stQuestionnaireLookup = useProcess(stQuestionnaireLookupModule, {
    organizationId: organization.id,
    schemaId: schemaId
  })
  const state = useProcessState(stQuestionnaireLookupModule)

  const [email, setEmail] = useState('')

  return (
    <FullPageCard
      footer={
        <p className="mt-10 text-center text-sm text-gray-500">
          Powered by StanfordTax, Inc.
          <br /> 222 Broadway, New York, 10032
        </p>
      }
    >
      <div className="flex flex-col items-center gap-1">
        {organization.logo && <OrganizationLogo src={organization.logo} size={80} />}

        <h3 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-900">
          {organization.name ?? ' '}
        </h3>
      </div>

      {match(state)
        .with({ status: P.union('idle', 'running') }, () => {
          return (
            <div className="flex flex-col items-center gap-4">
              <p className="my-4 text-center text-base">Please enter your email address</p>

              <TextInput
                type="email"
                placeholder="Enter your email"
                className="w-3/4"
                value={email}
                onChange={setEmail}
              />

              <Button
                variant="primary"
                className="m-auto"
                disabled={state.status === 'running' || isEmpty(email)}
                onClick={() => {
                  stQuestionnaireLookup.send({ type: 'lookup', email })
                }}
              >
                {state.status === 'running' ? 'Looking up...' : 'Lookup questionnaire'}
              </Button>
            </div>
          )
        })
        .with({ status: 'completed' }, (state) => {
          const folders = state.result.folders
          if (folders.length == 1) {
            // non-ambiguous match we redirect
            return (
              <RedirectToQuestionnaire
                folderId={folders[0].id}
                organizationSlug={organization.slug}
              />
            )
          }
          return (
            <div className="flex flex-col items-center gap-4">
              <p className="my-4 text-center text-base">
                We found multiple questionnaires for your email address. Please select the one you
                want to use.
              </p>
              <div className="flex w-full flex-col items-stretch gap-4">
                {folders.map((f) => (
                  <Link
                    className="flex gap-x-2 rounded bg-stone-200 px-4 py-3 text-center text-base font-medium leading-4 text-gray-900 outline-none hover:bg-stone-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 active:bg-stone-400"
                    key={f.id}
                    href={{ name: 'client_folder', folderId: f.id }}
                  >
                    {formatFolderEntityName(f.entities)} {f.type}
                  </Link>
                ))}
              </div>
            </div>
          )
        })
        .otherwise(() => null)}
    </FullPageCard>
  )
}

function RedirectToQuestionnaire({
  organizationSlug,
  folderId
}: {
  organizationSlug: string
  folderId: string
}) {
  const setRoute = useSetRoute()

  useEffect(() => {
    setRoute({
      name: 'client_folder',
      organizationSlug: organizationSlug,
      folderId: folderId
    })
  }, [organizationSlug, folderId])

  return <></>
}
