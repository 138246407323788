import { URLHandler } from '../types'

export const DataURLHandler: URLHandler = {
  protocol: 'data',
  fetch: async (url) => {
    if (!url.startsWith('data:')) {
      throw new Error('data url must start with data:')
    }

    return fetch(url)
  },
  createURL: (blob: Blob) => {
    if (typeof FileReader === 'undefined') return toDataUrlNode(blob)

    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onloadend = () => {
        return resolve(reader.result as string)
      }
      reader.onerror = reject
      reader.readAsDataURL(blob)
    })

    async function toDataUrlNode(blob: Blob): Promise<string> {
      const arrayBuffer = await blob.arrayBuffer()
      const buffer = Buffer.from(arrayBuffer)
      return 'data:' + blob.type + ';base64,' + buffer.toString('base64')
    }
  },
  revokeURL: () => Promise.resolve()
}
