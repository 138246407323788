import { FC, createContext } from 'react'
import { PDFTronController } from './pdftron-controller'
import { Page } from './module'

export type PDFViewerContext = {
  controller: undefined | PDFTronController

  /**
   * Allow a react component to be returned as an overlay a page.
   *
   * @param page
   * @param zoomLevel
   * @returns
   */
  PageOverlay: PageOverlayComponent
}

export type PageOverlayComponent = FC<{ page: Page; zoomLevel: number }>

export const PDFViewerControllerContext = createContext<PDFViewerContext>({
  controller: undefined,
  PageOverlay: () => null
})
