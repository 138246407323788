import { useAppDeps } from '@features/app-deps-provider'
import { documentUploadsModule, Upload, uploadDocument } from '@features/document-uploads'
import { useProcess, useProcessState } from '@st/redux'
import { STChecklistItem, STDocument } from '@st/sdk'
import {
  Button,
  Dialog,
  DialogButtons,
  FileUploadItem,
  GroupedSelect,
  MiniDocumentDropZone,
  PaperClipIcon,
  Tip
} from '@st/theme'
import { TrashIcon } from '@st/theme/src/icons/12x12'
import { cuid } from '@st/util/cuid'
import { Progress } from '@st/util/progress'
import { useCallback, useState } from 'react'
import { match, P } from 'ts-pattern'
import { formatChecklistItem, getBookmarkSections, stFolderModule } from './st-folder-module'

type Props = {
  folderId: string
  checklistItem: STChecklistItem
  onClose: () => void
}

export function ManageChecklistItemCompletion(props: Props) {
  switch (props.checklistItem.constraint.type) {
    case 'document_type':
      return <ManageDocumentChecklistItemCompletion {...props} />
    case 'category':
      return <ManageOrganizerChecklistItemCompletion {...props} />
  }
}

function ManageOrganizerChecklistItemCompletion({ checklistItem, onClose }: Props) {
  return (
    <Dialog
      title={checklistItem.status == 'complete' ? 'Item completed' : 'Mark as completed'}
      className="w-96"
      buttons={
        <DialogButtons>
          <Button variant="primary" onClick={onClose}>
            OK
          </Button>
        </DialogButtons>
      }
    >
      {match(checklistItem)
        .with({ status: 'complete' }, () => {
          return (
            <div className="flex flex-col gap-2">
              This checklist item has been marked completed because the relevant information was
              filled out by your client in the questionnaire or by you in the organizer.
            </div>
          )
        })
        .otherwise(() => (
          <div className="flex flex-col gap-2">
            To mark this checklist item as completed, either fill out the relevant information in
            the organizer or ask your client to fill it out in the questionnaire.
          </div>
        ))}
    </Dialog>
  )
}

function ManageDocumentChecklistItemCompletion({ folderId, checklistItem, onClose }: Props) {
  const { sdk } = useAppDeps()
  const [uploadId] = useUUID()
  const [selectedDocumentId, setSelectedDocumentId] = useState<string | undefined>(
    checklistItem.reference?.documentId
  )

  const sections = useProcessState(stFolderModule, (s) =>
    getBookmarkSections({
      documents: s.folderState!.documents!,
      documentTypes: s.folderState!.documentTypes
    }).filter((s) => s.heading.id != 'stanfordtax')
  )

  const documentUploads = useProcess(documentUploadsModule)
  const upload = useProcessState(documentUploadsModule, (s) => s.uploads[uploadId])

  const documents = sections.flatMap((doc) => doc.items)
  const checklistItemDocument = documents.find(
    (doc) => doc.id == checklistItem.reference?.documentId
  )

  const context: {
    currentDocument: STDocument | undefined
    selectedDocument: STDocument | undefined
    upload: Upload | undefined
  } = {
    currentDocument: checklistItemDocument,
    selectedDocument: documents.find((doc) => doc.id == selectedDocumentId),
    upload
  }

  async function onClickSave() {
    if (context.selectedDocument?.id == context.currentDocument?.id) {
      onClose() // nothing changed
    } else if (context.selectedDocument) {
      await sdk.send({
        type: 'folders/completeChecklistItemWithDocument',
        folderId: folderId,
        checklistItemId: checklistItem.id,
        documentId: context.selectedDocument.id
      })
      onClose()
    }
    // selected document will be removed
    else if (!context.selectedDocument) {
      await sdk.send({
        type: 'folders/markChecklistItemWithDocumentIncomplete',
        folderId: folderId,
        checklistItemId: checklistItem.id
      })
      onClose()
    }
  }

  return (
    <Dialog
      title="Mark as completed"
      className="w-96"
      buttons={
        <DialogButtons>
          <Button variant="subtle" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onClickSave}>
            Save
          </Button>
        </DialogButtons>
      }
    >
      <div className="flex flex-col gap-2">
        <div className="text-base text-gray-900">
          To mark this item ({formatChecklistItem(checklistItem)}) as completed, please provide the
          relevant document.
        </div>

        {match(context)
          .with({ upload: { status: 'running' } }, ({ upload }) => {
            return (
              <FileUploadItem status="uploading" value={Progress.toNumber(upload.progress)}>
                {upload.filename}
              </FileUploadItem>
            )
          })
          .with({ selectedDocument: P.nonNullable }, ({ selectedDocument }) => {
            return (
              <div className="flex flex-row items-center gap-2">
                <PaperClipIcon className="h-4 w-4" />
                <div className="flex-grow text-base font-semibold text-gray-900">
                  {selectedDocument.name}
                </div>
                <Tip title={`Remove link between checklist and ${selectedDocument.name}`}>
                  <button onClick={() => setSelectedDocumentId(undefined)}>
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </Tip>
              </div>
            )
          })
          .otherwise(() => {
            return (
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-1">
                  <label className="text-base font-medium text-gray-900">
                    Select uploaded document
                  </label>
                  <GroupedSelect
                    placeholder="Select uploaded document"
                    sections={sections}
                    buildHeader={(section) => section.heading.name}
                    buildValue={(document) => document.id}
                    buildLabel={(doc) => doc.name}
                    onChange={(docId) => setSelectedDocumentId(docId)}
                  />
                </div>

                <div className="flex flex-col gap-1">
                  <label className="text-base font-medium text-gray-900">
                    Or upload new document
                  </label>
                  <MiniDocumentDropZone
                    onDrop={async (dt) => {
                      const documentId = await documentUploads.send(
                        uploadDocument({
                          uploadId: uploadId,
                          folderId,
                          documentTypeId: checklistItem.constraint.id,
                          file: dt.files[0]
                        })
                      )
                      setSelectedDocumentId(documentId)
                    }}
                  />
                </div>
              </div>
            )
          })}
      </div>
    </Dialog>
  )
}

function useUUID(): [string, () => void] {
  const [uuid, setUUID] = useState(() => cuid())

  const nextId = useCallback(() => {
    setUUID(cuid())
  }, [setUUID])

  return [uuid, nextId]
}
