import { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import { useFlashState } from './hooks/useFlashState'

type SetMessage = (message: string) => void

const SnackbarContext = createContext<SetMessage>(() => null)

export function SnackbarContainer({ children }: { children: ReactNode }) {
  const [message, setMessage] = useState<string | undefined>(undefined)
  const [visible, setVisible] = useFlashState(false, 5000)

  const setMessageFlash = useCallback(
    (message: string) => {
      setMessage(message)
      setVisible(message != null)
    },
    [setMessage, setVisible]
  )

  return (
    <SnackbarContext.Provider value={setMessageFlash}>
      {visible ? <Snackbar visible={visible}>{message}</Snackbar> : null}
      {children}
    </SnackbarContext.Provider>
  )
}

export function useShowSnackbar(): SetMessage {
  return useContext(SnackbarContext)
}

function Snackbar({ children }: { visible: boolean; children: ReactNode }) {
  return (
    <div className="pointer-events-auto fixed bottom-6 left-6 z-50 flex touch-auto select-none flex-row items-center justify-start rounded bg-gray-800 p-3 px-3 py-1.5 text-base text-white">
      {children}
    </div>
  )
}
