import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { IncompleteReasonOption, STChecklistItem } from '@st/sdk'
import { create } from 'mutative'
import { STQuestionnaireMessage } from './st-questionnaire-module'

type STReviewState = {
  folderId: string
  incompleteChecklistItems: STChecklistItem[]
  editedIncompleteReasons: Record<string, string>
  status: 'editing' | 'validationFailed' | 'savingIncompleteReasons' | 'submitting' | 'submitted'
  validationError: ReviewValidationError | undefined
}

type STReviewMessage =
  | {
      type: 'setIncompleteReason'
      checklistItemId: string
      incompleteReason: string | undefined
    }
  | { type: 'submit' }
  | SDKResponse

type STReviewInit = {
  folderId: string
  incompleteChecklistItems: STChecklistItem[]
}

export const stReviewModule = defineModule<
  STReviewState,
  STReviewMessage,
  STReviewInit,
  { sdk: SDKMessage; stQuestionnaire: STQuestionnaireMessage }
>({
  name: 'stReviewQuestionnaire',
  init: ({ folderId, incompleteChecklistItems }) => {
    return {
      folderId,
      status: 'editing',
      incompleteChecklistItems,
      editedIncompleteReasons: {},
      validationError: undefined
    }
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'setIncompleteReason':
        return create(state, (s) => {
          const checklistItem = s.incompleteChecklistItems.find(
            (el) => el.id == message.checklistItemId
          )
          s.editedIncompleteReasons[message.checklistItemId] = message.incompleteReason!

          if (
            !message.incompleteReason ||
            message.incompleteReason == checklistItem!.incompleteReason
          ) {
            delete s.editedIncompleteReasons[message.checklistItemId]
          }
        })
      case 'submit':
        const validation = reviewValidation(state)

        if (!validation.ok) {
          return { ...state, status: 'validationFailed', validationError: validation.error }
        }

        const options: IncompleteReasonOption[] = Object.entries(state.editedIncompleteReasons).map(
          ([checklistItemId, incompleteReason]) => {
            return { checklistItemId, incompleteReason }
          }
        )

        return [
          { ...state, status: 'savingIncompleteReasons', validationError: undefined },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'folders/setChecklistItemIncompleteReasons',
                folderId: state.folderId,
                options: options
              }
            }
          }
        ]
      case 'response':
        if (message.operation.type == 'folders/setChecklistItemIncompleteReasons') {
          return [
            { ...state, status: 'submitting' },
            {
              sdk: {
                type: 'request',
                request: { type: 'folders/submitQuestionnaire', folderId: state.folderId }
              }
            }
          ]
        } else if (message.operation.type == 'folders/submitQuestionnaire') {
          return [
            { ...state, status: 'submitted' },
            { stQuestionnaire: { type: 'returnToDashboard' } }
          ]
        }
        return state
    }
  }
})

export function selIsSubmitting(state: STReviewState) {
  return state.status == 'savingIncompleteReasons' || state.status == 'submitting'
}

export function selIncompleteReasons(state: STReviewState): Record<string, string> {
  const reasons: Record<string, string> = {}
  for (const item of state.incompleteChecklistItems) {
    reasons[item.id] = state.editedIncompleteReasons[item.id] ?? item.incompleteReason
  }

  return { ...reasons, ...state.editedIncompleteReasons }
}

export type ReviewValidationError = { type: 'incompleteItems'; incompleteItemIds: string[] }
function reviewValidation(
  state: STReviewState
): { ok: true } | { ok: false; error: ReviewValidationError } {
  const incompleteItemIds: string[] = []
  for (const item of state.incompleteChecklistItems) {
    const status = state.editedIncompleteReasons[item.id] ?? item.incompleteReason
    if (!status) {
      incompleteItemIds.push(item.id)
    }
  }
  if (incompleteItemIds.length == 0) {
    return { ok: true }
  } else {
    return { ok: false, error: { type: 'incompleteItems', incompleteItemIds: incompleteItemIds } }
  }
}
export function formatValidationMessage(error: ReviewValidationError) {
  switch (error.type) {
    case 'incompleteItems':
      return `Please select reasons for the remaining incomplete items`
    default:
      return ''
  }
}
