type RGB = { r: number; g: number; b: number }
export function hexToRgb(hex: string): RGB | undefined {
  hex = hex.replace('#', '').toLowerCase()

  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  if (isNaN(r) || isNaN(g) || isNaN(b)) throw `Invalid hex color ${hex}`

  return { r, g, b }
}

export function rgbToHex(rgb: RGB) {
  return '#' + toHex(rgb.r) + toHex(rgb.g) + toHex(rgb.b)

  function toHex(n: number) {
    const hex = Math.round(n).toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }
}

export function blendHexColor(
  fromColor: string,
  toColor: string,
  blendAmount: number
): string | null {
  function linearBlend(v0: number, v1: number, blendAmount: number): number {
    return v0 * (1 - blendAmount) + v1 * blendAmount
  }

  function blend(rgb1: RGB, rgb2: RGB, blendAmount: number): RGB {
    let blendFunc = linearBlend
    return {
      r: blendFunc(rgb1.r, rgb2.r, blendAmount),
      g: blendFunc(rgb1.g, rgb2.g, blendAmount),
      b: blendFunc(rgb1.b, rgb2.b, blendAmount)
    }
  }

  if (blendAmount < -1 || blendAmount > 1) {
    throw 'Blend amount must be between -1 and 1'
  }

  let rgb1 = hexToRgb(fromColor)
  let rgb2 = hexToRgb(toColor)

  if (!rgb1) {
    throw `Invalid from color ${rgb1}`
  }
  if (!rgb2) {
    throw `Invalid to color ${rgb2}`
  }

  let blendedRgb = blend(rgb1, rgb2, Math.abs(blendAmount))
  return rgbToHex(blendedRgb)
}

export function darkenColor(hexColor: string, percent: number) {
  // Convert hex to RGB
  let r = parseInt(hexColor.substring(1, 3), 16)
  let g = parseInt(hexColor.substring(3, 5), 16)
  let b = parseInt(hexColor.substring(5, 7), 16)

  // Calculate the adjustment value
  let adjustment = 1 - percent

  // Adjust each color component
  r = Math.floor(r * adjustment)
  g = Math.floor(g * adjustment)
  b = Math.floor(b * adjustment)

  // Convert back to hex and return
  return (
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')
  )
}
