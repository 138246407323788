import { FileEntry } from './unpack-upload'

type ZipEntry = { name: string; input: Blob; lastModified?: Date }

export async function zipEntries(entries: FileEntry[], filename: string): Promise<File> {
  const { downloadZip } = await import('client-zip')

  const zipEntries: ZipEntry[] = entries.map((entry) => ({
    name: entry.path,
    input: entry.file
  }))

  return downloadZip(zipEntries)
    .blob()
    .then((blob) => new File([blob], filename, { type: 'application/zip' }))
}
