import { registerShortcuts } from '@st/react-util/shortcuts'
import { ProcessName, Ref, defineModule } from '@st/redux'
import { removeKey } from '@st/util/object'

type ShowSnackbar = (message: string) => void

type PlatformState = {
  shortcutSubscriptions: Record<ProcessName, ShortcutSubscription[]>
  showSnackbarRef: Ref<ShowSnackbar>
}

type ShortcutSubscription = {
  shortcuts: string[]
  unsubscribeRef: Ref<() => void>
}

export type ShortcutTriggered = { type: 'shortcutTriggered'; shortcut: string }

export type PlatformMessage =
  | { type: 'copyToClipboard'; text: string }
  | { type: 'subscribeToShortcuts'; shortcuts: string[] }
  | { type: 'showSnackbar'; message: string }
  | { type: 'unsubscribeFromShortcuts' }

type PlatformInit = {
  showSnackbar?: ShowSnackbar
}

export const platformModule = defineModule<PlatformState, PlatformMessage, PlatformInit>({
  name: 'platform',
  init: ({ showSnackbar = () => null }, { createRef }) => {
    return {
      shortcutSubscriptions: {},
      showSnackbarRef: createRef(showSnackbar, () => null)
    }
  },
  handle: (state, message, { from, send, createRef, resolveRef }) => {
    switch (message.type) {
      case 'copyToClipboard':
        navigator.clipboard.writeText(message.text)
        return state
      case 'subscribeToShortcuts':
        const unsubscribe = registerShortcuts(
          window,
          Object.fromEntries(
            message.shortcuts.map((shortcut) => {
              return [shortcut, () => send(from!, { type: 'shortcutTriggered', shortcut })]
            })
          )
        )

        const unsubscribeRef = createRef(unsubscribe, (unsub) => unsub())

        return {
          ...state,
          shortcutSubscriptions: {
            [from!]: [
              ...(state.shortcutSubscriptions[from!] ?? []),
              {
                shortcuts: message.shortcuts,
                unsubscribe: unsubscribeRef
              }
            ]
          }
        }
      case 'unsubscribeFromShortcuts':
        const subscriptions = state.shortcutSubscriptions[from!]
        const unsubHandlers = subscriptions.map((sub) => resolveRef(sub.unsubscribeRef))

        // unsubscribe
        for (const fn of unsubHandlers) fn()

        return {
          ...state,
          shortcutSubscriptions: removeKey(state.shortcutSubscriptions, from!)
        }
      case 'showSnackbar':
        const showSnackbar = resolveRef(state.showSnackbarRef)
        showSnackbar(message.message)
        return state
    }
  }
})
