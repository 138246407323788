import {
  AddressInputConfig,
  ComponentConfig,
  GridTableConfig,
  LabeledGridTableConfig,
  NoteWidgetConfig,
  StandardTableConfig
} from '@st/folder/ui-config'
import {
  Box,
  CheckboxInput,
  Expand,
  FormNameInput,
  FormTable,
  FormTextInput,
  Label,
  LabelPosition,
  MultiSelectInput,
  MultiSelectOption,
  Note,
  RadioOption,
  RadioSelectInput,
  SectionRow,
  Space,
  YesNoRadio,
  useReadWriteContext
} from '@st/pdf'
import { toMaybeStr } from '@st/util/cast'

export function FormationComponent({ config }: { config: ComponentConfig }) {
  switch (config.__typename) {
    case 'TextInputConfig':
    case 'MoneyInputConfig':
      return (
        <FormTextInput
          label={config.label}
          inputKey={config.userInputKey}
          shouldShowHint={config.shouldShowPrevYearHint ?? false}
          mask={
            config.__typename == 'TextInputConfig' && config.inputFormat == 'SocialSecurityNumber'
          }
          multiline={(config.__typename == 'TextInputConfig' && config.isMultiline) ?? false}
        />
      )
    case 'DateInputConfig':
      return <FormTextInput label={config.label} inputKey={config.userInputKey} />
    case 'NameInputConfig':
      return <FormNameInput userInputKey={config.userInputKey} />
    case 'YesNoConfig':
      return (
        <YesNoRadio
          label={config.label ?? ''}
          labelPosition={(config.labelPosition ?? 'left') as LabelPosition}
          userInputKey={config.userInputKey}
        />
      )
    case 'FormSelectCategoryConfig':
    case 'CheckboxConfig':
      return <CheckboxInput label={config.label ?? ''} userInputKey={config.userInputKey} />
    case 'TextConfig':
      return <Label bold={config.isBold ?? false}>{config.content}</Label>
    case 'FilesInputConfig':
      return <Space />
    case 'RadioSelectConfig':
      const radioOptions: RadioOption[] = config.options.map((opt) => {
        return { label: opt.label, value: opt.key }
      })
      return (
        <RadioSelectInput
          label={config.label ?? ''}
          userInputKey={config.userInputKey}
          options={radioOptions}
        />
      )
    case 'MultiSelectConfig':
      const multiSelectOptions: MultiSelectOption[] = config.options.map((opt) => {
        return { label: opt.label, value: opt.key }
      })
      return (
        <MultiSelectInput
          label={config.label ?? undefined}
          userInputKey={config.userInputKey}
          options={multiSelectOptions}
        />
      )
    case 'AddressInputConfig':
      return <AddressInput {...config} />
    case 'StandardTableConfig':
      return <StandardTable {...config} />
    case 'GridTableConfig':
      return <GridTableInput {...config} />
    case 'LabeledGridTableConfig':
      return <LabeledGridTableInput {...config} />
    case 'NoteWidgetConfig':
      return <FormationNote {...config} />
    default:
      return <Label>{config.__typename ?? ''}</Label>
  }
}

function AddressInput({ userInputKey: key }: AddressInputConfig) {
  return (
    <SectionRow>
      <Expand flex={16}>
        <FormTextInput fontSize={9} label="Addr." inputKey={`${key}.street`} />
      </Expand>
      <Expand flex={8}>
        <FormTextInput fontSize={9} label="City" inputKey={`${key}.city`} />
      </Expand>
      <Expand flex={8}>
        <FormTextInput fontSize={9} label="State" inputKey={`${key}.state`} />
      </Expand>
      <Expand flex={6}>
        <FormTextInput fontSize={9} label="Country" inputKey={`${key}.country`} />
      </Expand>
      <Expand flex={5}>
        <FormTextInput fontSize={9} label="Zip" inputKey={`${key}.zip`} />
      </Expand>
    </SectionRow>
  )
}

function StandardTable({ columns }: StandardTableConfig) {
  return (
    <FormTable
      colCount={columns.length}
      rowCount={columns[0].components.length}
      colHeader={(colIndex) => columns[colIndex].title}
    >
      {(rowIndex, colIndex) => {
        const config = columns[colIndex].components[rowIndex]
        return <FormationComponent config={config} />
      }}
    </FormTable>
  )
}

/**
 * A grid-style input where there the first row is labels
 * Then there is one or more entries for those labels
 *
 * For example a users table might look like:
 * +---------------------------+
 * | Name | Age | Address      |
 * +---------------------------+
 * | Bob  | 23  | 123 Blu Ln   |
 * | Doe  | 99  | 77 Severn    |
 * | ...  | ..  | ...          |
 * +---------------------------+
 *
 * The key structure might look like
 * registered_users.0.name
 * registered_users.1.age
 *
 * The key is constructed like $userInputKey.$rowIndex.$fieldName
 *
 * @param
 * @returns
 */
function GridTableInput({ userInputKey, columns, rowCount }: GridTableConfig) {
  return (
    <FormTable
      colCount={columns.length}
      rowCount={rowCount}
      colHeader={(colIndex) => columns[colIndex].title}
      colFlex={(colIndex) => columns[colIndex].width ?? 1}
    >
      {(rowIndex, colIndex) => (
        <FormTextInput inputKey={`${userInputKey}.${rowIndex}.${columns[colIndex].key}`} />
      )}
    </FormTable>
  )
}

function LabeledGridTableInput({ userInputKey, columns, rows }: LabeledGridTableConfig) {
  return (
    <FormTable
      rowCount={rows.length}
      colCount={columns.length}
      colHeader={(colIndex) => columns[colIndex].title}
      rowHeader={(rowIndex) => rows[rowIndex].title}
    >
      {(rowIndex, colIndex) => {
        const column = columns[colIndex]
        const row = rows[rowIndex]
        return <FormTextInput inputKey={`${userInputKey}.${row.key}.${column.key}`} />
      }}
    </FormTable>
  )
}

function FormationNote({ userInputKey }: NoteWidgetConfig) {
  const { useReadFieldComment } = useReadWriteContext()
  const comment = useReadFieldComment(userInputKey)

  return comment ? <Note author={comment.author} time={comment.time} body={comment.body} /> : null
}
