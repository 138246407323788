import { useProcessState } from '@st/redux'
import { stFolderModule } from './st-folder-module'
import { DumpJSON } from '@st/react-util/dump-json'
import { Button } from '@st/theme'

export function StFolderViewerDebug() {
  const state = useProcessState(stFolderModule, (s) => s.folderState)

  if (!state) {
    return null
  }

  return (
    <div className="flex h-full w-full flex-col">
      <DumpJSON
        value={{
          folderId: state.folderId,
          inputs: state.inputs,
          prevInputs: state.prevInputs,
          checklistItems: state.checklistItems
        }}
      />
    </div>
  )
}
