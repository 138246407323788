import {
  AnnotationMessage,
  DrawTool,
  ElementContainer,
  SelectedElementContainer,
  toDragPointerEvent,
  useDrawContext,
  useUpdateBounds
} from '@features/st-annotation-layer'
import { DocCommentAnnotation } from '@st/folder'
import { cuid } from '@st/util/cuid'
import { Dispatch } from 'react'
import { AnnotationToolbar, DELETE_OPTION } from './annotation-toolbar'

export const COMMENT_TOOL: DrawTool<DocCommentAnnotation, CommentTool> = {
  name: 'comment',
  matches: (annot) => annot.type == 'comment',
  init: () => {
    return { name: 'comment', editing: false }
  },
  CanvasBackgroundElement: CommentCanvasTool,
  Element: CommentElement,
  handle: (state, message) => {
    switch (message.type) {
      case 'deselect':
        if (message.annot.body == '') {
          return [state, { annotationLayer: [{ type: 'annotDelete', annotId: message.annot.id }] }]
        }
        return [state, { annotationLayer: [] }]
      default:
        return [state, { annotationLayer: [] }]
    }
  }
  // onDeactivate: (toolState, annot) => {
  //   if (annot.body == '') {
  //     return [{ type: 'annotDelete', annotId: annot.id }]
  //   }
  //   return []
  // }
}

export type CommentTool = {
  name: 'comment'
  editing: boolean
}

type CommentCanvasToolProps = {
  draft: DocCommentAnnotation | undefined
  tool: CommentTool
  send: Dispatch<AnnotationMessage>
}
export function CommentCanvasTool({ send }: CommentCanvasToolProps) {
  const { page, zoom } = useDrawContext()

  return (
    <div
      style={{
        position: 'absolute',
        cursor: 'crosshair',
        background: 'rgba(1, 1, 1, 0.0)',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      }}
      onPointerDown={(e) => {
        e.preventDefault()
        e.stopPropagation()

        const event = toDragPointerEvent(e, zoom, 'drawing-canvas')
        const point = event.point

        const annot: DocCommentAnnotation = {
          type: 'comment',
          id: cuid(),
          time: new Date().toISOString(),
          page: page.index,
          bounds: {
            ...point,
            width: 24,
            height: 24
          },
          body: ''
        }

        send({ type: 'addAnnotDraft', annot: annot, save: false })
      }}
    />
  )
}

type CommentElementProps = {
  annot: DocCommentAnnotation
  selected?: boolean
  send: Dispatch<AnnotationMessage>
}
export function CommentElement({ annot, selected, send }: CommentElementProps) {
  const { onMove } = useUpdateBounds(annot, send)

  if (selected) {
    return (
      <SelectedElementContainer
        key={annot.id}
        rect={annot.bounds}
        onMove={onMove}
        resizeHandles={false}
        toolbar={
          <AnnotationToolbar
            options={[DELETE_OPTION]}
            height={24}
            onClickOption={(opt) => {
              if (opt.name == 'delete') {
                send({ type: 'annotDelete', annotId: annot.id })
              }
            }}
          />
        }
      >
        <CommentIcon size={24} />
      </SelectedElementContainer>
    )
  } else {
    return (
      <ElementContainer
        key={annot.id}
        rect={annot.bounds}
        onClick={() => {
          send({ type: 'selectAnnot', annotId: annot.id })
        }}
      >
        <CommentIcon size={24} />
      </ElementContainer>
    )
  }
}

function CommentIcon({ size = 24 }: { size?: number }) {
  return (
    <svg
      style={{ color: '#FFD000' }}
      width={size}
      height={size}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="38" height="38" fill="currentColor" />
      <path d="M31 7H7V26H14.5V32L21 26H31V7Z" fill="white" />
      <rect x="10" y="14" width="17" height="1.5" fill="currentColor" />
      <rect x="10" y="18" width="14" height="1.5" fill="currentColor" />
    </svg>
  )
}
