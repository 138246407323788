import { useEffect, useMemo } from 'react'
import { Command, DepsMap, DepsState, Process, ProcessMessage, ProcessModule } from './process'
import { ReduxStore } from './redux-store'
import { useStoreContext } from './store-deps-provider'
import { useStore } from './store-provider'
import { useCreation } from './use-creation'

export function useProcessState<ModuleState, Event extends ProcessMessage>(
  module: ProcessModule<ModuleState, Event, any, any>
): ModuleState

export function useProcessState<
  ProcessState,
  Event extends ProcessMessage,
  Deps extends DepsMap,
  SelectedState
>(
  module: ProcessModule<ProcessState, Event, any, any, Deps>,
  selector: Selector<ProcessState, DepsState<Deps>, SelectedState>
): SelectedState

// Function implementation
export function useProcessState<
  ProcessState,
  Message extends ProcessMessage,
  SelectedState = ProcessState,
  Deps extends DepsMap = {}
>(
  module: ProcessModule<ProcessState, Message, any, any, Deps>,
  selector?: Selector<ProcessState, DepsState<Deps>, SelectedState>
): SelectedState | ProcessState {
  const store = useStore()
  // Assuming useRequireModule is defined elsewhere
  useRequireProcess(store, module, undefined)
  return store.useSelect((state: any) => {
    const processState: ProcessState = state[module.name]
    const depsState: any = {}
    for (const [k, _] of Object.entries(module.deps)) {
      depsState[k] = state[k]
    }
    return selector ? selector(processState, depsState) : processState
  })
}

export function useProcessAlive(processName: string) {
  const store = useStore()
  return store.useSelect((state: any) => {
    return state[processName] !== undefined
  })
}

type Selector<State, DepsState, SelectedState> = (
  processState: State,
  depsState: DepsState
) => SelectedState

export function useProcessMessages<ModuleState, Message extends ProcessMessage>(
  module: ProcessModule<ModuleState, Message, any, any>,
  handler: (e: Message) => void
) {
  const store = useStore()
  useRequireProcess(store, module, undefined)

  useEffect(() => {
    const unsubscribe = store.subscribe((message: ProcessMessage) => {
      if (message.type.startsWith(`${module.name}/`)) {
        handler(localizeEvent(message, module.name) as any)
      }
    })
    return unsubscribe
  }, [store])
}

function localizeEvent(e: ProcessMessage, processName: string) {
  if (!e.type.startsWith(`${processName}/`)) {
    throw new Error(`Event ${e.type} should start with ${processName}`)
  }
  return { ...e, type: e.type.substring(processName.length + 1) }
}

function getDebugStack() {
  const stack = new Error().stack ?? ''
  const lines = stack.split('\n')
  const filteredLines = lines.filter((line) => {
    if (line.includes('/node_modules/') || line.includes('/redux/')) {
      return false
    }
    return true
  })

  if (filteredLines.length == 1) {
    return lines.join('\n')
  } else {
    return filteredLines.join('\n')
  }
}

function useRequireProcess<State, Message extends ProcessMessage, InitArg, Deps extends DepsMap>(
  store: ReduxStore,
  module: ProcessModule<State, Message, InitArg, any, Deps>,
  initArg: InitArg
) {
  useCreation(() => {
    store.requireProcess(module.name, module, initArg)
    return {
      label: `module: ${module.name}`,
      [Symbol.dispose]: () => {
        store.releaseProcess(module.name)
      }
    }
  }, [store, module])
}

export function useProcess<State, Message extends ProcessMessage, InitArg, Deps extends DepsMap>(
  handler: ProcessModule<State, Message, InitArg, any, Deps>,
  initArg?: InitArg
): Process<State, Message> {
  const store = useStore()
  const context = useStoreContext()

  useRequireProcess(store, handler, initArg as any)

  const process: Process<State, Message> = useMemo(() => {
    return {
      send: (messages: Message | Message[] | Command<any, Message, any, any>) => {
        return store.send(handler.name, messages as any, {
          context: context,
          send: store.send
        })
      },
      getState: () => {
        const storeState = store.getState()
        return (storeState as any)[handler.name]
      }
    }
  }, [store, handler.name, context])

  return process
}
