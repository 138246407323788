import clsx from 'clsx'

type Props = { onClick?: () => void; className?: string; href?: string; variant?: 'light' | 'dark' }
export function BrandLogo({ onClick, href, variant = 'light', className }: Props) {
  return (
    <a
      href={href}
      style={{ fontFamily: 'Gilroy', color: variant == 'light' ? '#484F75' : 'white' }}
      className={clsx(
        'cursor-pointer text-2xl',
        variant == 'light' ? 'font-black' : 'font-bold',
        className
      )}
      onClick={onClick}
    >
      StanfordTa<span style={{ color: '#536CF1' }}>x</span>
    </a>
  )
}
