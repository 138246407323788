import { NumberStepperConfig } from '@st/folder/ui-config'
import { ConfigInputProps } from '@util/input-component'

type NumberStepperProps = ConfigInputProps<NumberStepperConfig, number>

export function NumberStepper({ onChange, value = 0, maxNumber }: NumberStepperProps) {
  return (
    <div className="flex flex-row items-center justify-center gap-8 text-4xl">
      <button
        className="flex size-16 flex-col items-center justify-center rounded-md bg-blue-500 text-white"
        disabled={value <= 0}
        onClick={() => onChange(value - 1)}
      >
        <MinusIcon />
      </button>
      <p className="min-w-[60px] text-center">{value}</p>
      <button
        className="flex size-16 flex-col items-center justify-center rounded-md bg-blue-500 text-white"
        disabled={maxNumber != undefined && value >= maxNumber}
        onClick={() => onChange(value + 1)}
      >
        <PlusIcon />
      </button>
    </div>
  )
}

function PlusIcon() {
  return (
    <svg width="19" height="18" viewBox="0 0 19 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9941 8V0.992993C11.0046 -0.343545 8.99414 -0.32053 8.99414 0.99948V8H1.98713C0.650595 7.98956 0.67361 10 1.99362 10H8.99414V16.9997C8.99414 17.5521 9.44186 18 9.99414 18C10.5464 18 10.9941 17.5521 10.9941 16.9997V10H17.9938C18.5463 10 18.9941 9.55229 18.9941 9C18.9941 8.44772 18.5463 8 17.9938 8H10.9941Z"
        fill="currentColor"
      />
    </svg>
  )
}

function MinusIcon() {
  return (
    <svg width="19" height="2" viewBox="0 0 19 2" fill="none">
      <path
        d="M1.98714 -1.93549e-06L17.9938 -1.23582e-06C18.5463 -1.21167e-06 18.9941 0.447716 18.9941 1C18.9941 1.55229 18.5463 2 17.9938 2L1.99362 2C0.673611 2 0.650597 -0.010435 1.98714 -1.93549e-06Z"
        fill="currentColor"
      />
    </svg>
  )
}
