import { map, regexp, seq, token } from './tspc'
import { matches } from './parse'

const usernameToken = regexp(
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")/i
)

const domainToken = regexp(
  /(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
)

type ParsedEmail = { username: string; domain: string }

const emailToken = map(
  seq(usernameToken, token('@'), domainToken),
  (r): ParsedEmail => ({ username: r[0], domain: r[2] })
)

export function isValidEmail(maybeEmail: string): boolean {
  return matches(emailToken, maybeEmail)
}
