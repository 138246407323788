import { useCreation } from '@st/react-util/use-creation'
import { useProcess, useProcessState } from '@st/redux'
import { useCallback, useEffect } from 'react'
import { appDepsCreate } from './app-deps-create'
import { useAppDeps } from './app-deps-provider'
import { User } from './auth/auth-client-adapter'
import { authModule } from './auth/auth-module'
import { routeModule, selRoute } from './route-module'
import { AppRoute, SetAppRoute, routes } from './routing'

export function useAppDepsCreate() {
  return useCreation(() => appDepsCreate(), [])
}

export function usePath(): string {
  return useProcessState(routeModule, (s) => s.location.pathname)
}

export function useSetPath(): (path: string) => void {
  const { send } = useProcess(routeModule)
  return useCallback((path: string) => send({ type: 'navigate', pathname: path }), [send])
}

export function useRoute() {
  return useProcessState(routeModule, selRoute)
}

export function useSetRoute() {
  const route = useProcess(routeModule)
  return useCallback(
    (action: SetAppRoute) => {
      const currentPath = route.getState().location.pathname
      const currentRoute = routes.match(currentPath)
      const path = routes.toPath(action, currentRoute as AppRoute)!
      route.send({ type: 'navigate', pathname: path })
    },
    [route]
  )
}

export function useAuthClient() {
  return useAppDeps().authClient
}

export function useAuthState() {
  return useProcessState(authModule, (s) => s)
}

export function useAuthUserChanged(callback: (user?: User) => void) {
  const { user } = useAuthState()
  const userId = user?.id

  useEffect(() => {
    callback(user)
  }, [userId])
}

export function assetURL(filepath: string) {
  return `https://assets.stanfordtax.com/${filepath}`
}
