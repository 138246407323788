import {
  formatChecklistItem,
  selIncompleteChecklistItems,
  selMissingReasonOptionsMap,
  stFolderModule
} from '@features/st-folder-viewer/st-folder-module'
import { useProcess, useProcessState } from '@st/redux'
import {
  ArrowLeftIcon,
  Button,
  CircleXIcon,
  CloseIcon,
  QuestionnaireBanner,
  QuestionnaireFooter,
  QuestionnaireHeader,
  QuestionnairePageContainer,
  QuestionnaireProgressBar,
  QuestionnaireSectionTab,
  Select,
  SendIcon
} from '@st/theme'
import { Progress } from '@st/util/progress'
import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { match } from 'ts-pattern'
import {
  selQuestionnaireNavState,
  selQuestionnaireProgress,
  selQuestionnaireTabs,
  stQuestionnaireModule
} from './st-questionnaire-module'
import {
  formatValidationMessage,
  selIncompleteReasons,
  selIsSubmitting,
  stReviewModule
} from './st-review-questionnaire-module'

export type Props = {
  onClose?: () => void
}
export function STReviewQuestionnairePage(props: Props) {
  const stQuestionnaire = useProcess(stQuestionnaireModule)
  const navState = useProcessState(stQuestionnaireModule, selQuestionnaireNavState)
  const progress = useProcessState(stQuestionnaireModule, selQuestionnaireProgress)
  const tabs = useProcessState(stQuestionnaireModule, selQuestionnaireTabs)

  const folderId = useProcessState(stFolderModule, (s) => s.folderState!.folderId)
  const incompleteChecklistItems = useProcessState(stFolderModule, selIncompleteChecklistItems)
  const missingReasonsById = useProcessState(stFolderModule, (s) =>
    selMissingReasonOptionsMap(s.folderState!)
  )

  const stReview = useProcess(stReviewModule, { folderId, incompleteChecklistItems })
  const incompleteReasons = useProcessState(stReviewModule, selIncompleteReasons)
  const validationError = useProcessState(stReviewModule, (s) => s.validationError)
  const isSubmitting = useProcessState(stReviewModule, selIsSubmitting)

  return (
    <QuestionnairePageContainer
      banner={
        validationError && (
          <QuestionnaireBanner variant="error">
            {formatValidationMessage(validationError)}
          </QuestionnaireBanner>
        )
      }
      header={
        <QuestionnaireHeader
          trailing={
            <Button
              variant="icon"
              trailingIcon={<CloseIcon className="h-6 w-6" />}
              onClick={() => stQuestionnaire.send({ type: 'returnToDashboard' })}
            />
          }
        >
          {tabs.map((tab) => {
            return (
              <QuestionnaireSectionTab
                key={tab.route.sectionId}
                icon={tab.route.sectionId}
                caption={tab.label}
                selected={tab.route.sectionId == navState.cur.sectionId}
                onClick={() => stQuestionnaire.send({ type: 'navigate', route: tab.route })}
              />
            )
          })}
        </QuestionnaireHeader>
      }
      footer={
        <QuestionnaireFooter>
          <Button
            size="l"
            variant="primary"
            leadingIcon={<ArrowLeftIcon className="h-4 w-4" />}
            disabled={navState.prev == undefined}
            onClick={() => stQuestionnaire.send({ type: 'navigate', route: navState.prev! })}
          >
            Back
          </Button>
          <QuestionnaireProgressBar value={Progress.toNumber(progress)} />
          <Button
            size="l"
            variant="primary"
            leadingIcon={<SendIcon className="h-4 w-4" />}
            disabled={isSubmitting}
            onClick={() => stReview.send({ type: 'submit' })}
          >
            {isSubmitting ? 'Submitting' : 'Submit'}
          </Button>
        </QuestionnaireFooter>
      }
    >
      {match(incompleteChecklistItems)
        .with([], () => (
          <div className="flex h-full grow flex-col items-center justify-center">
            <h1 className="text-center text-3xl font-semibold text-gray-800">
              You are almost done with the questionaire!
            </h1>

            <div className="mt-5">
              Please click the submit button below so we will be notified and start working on your
              return.
            </div>
          </div>
        ))
        .otherwise(() => {
          return (
            <div className="flex flex-col">
              <h1 className="text-center text-3xl font-semibold text-gray-800">{`You're almost done with the questionnaire, but you're still missing some items!`}</h1>

              <div className="mt-5">
                <div className="text-center text-base text-gray-900">Please tell us why below</div>

                <div className="divide-y divide-gray-200">
                  {incompleteChecklistItems.map((checklistItem) => {
                    let missingReasons = missingReasonsById[checklistItem.constraint.id] ?? []
                    // temp workaround
                    if (missingReasons.length == 0) {
                      missingReasons = missingReasonsById['w2']
                    }

                    const missing =
                      validationError?.type == 'incompleteItems' &&
                      validationError.incompleteItemIds.includes(checklistItem.id)

                    return (
                      <IncompleteChecklistItem
                        key={checklistItem.id}
                        title={formatChecklistItem(checklistItem)}
                        select={
                          <Select
                            className={clsx('w-48', { 'ring-red-700': missing })}
                            placeholder="Select reason"
                            options={missingReasons}
                            buildValue={(reason) => reason.key}
                            buildLabel={(reason) => reason.name}
                            value={incompleteReasons[checklistItem.id]}
                            onChange={(v) =>
                              stReview.send({
                                type: 'setIncompleteReason',
                                checklistItemId: checklistItem.id,
                                incompleteReason: v
                              })
                            }
                          />
                        }
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
    </QuestionnairePageContainer>
  )
}

function IncompleteChecklistItem({ title, select }: { title: string; select?: ReactNode }) {
  return (
    <div className="flex items-center py-2">
      <CircleXIcon className="mx-3 h-4 w-4 text-gray-300" />
      <div className="grow text-base font-semibold text-gray-900">{title}</div>
      <div>{select}</div>
    </div>
  )
}
