import { clsx } from 'clsx'
import { ReactNode, useEffect, useRef } from 'react'

export type HighlightMap = {
  [key: string]: string
}

type Props = {
  className?: string
  placeholder?: string
  value?: string
  onChange?: (v: string) => void
  highlights: HighlightMap
}
export function HighlightableTextArea({
  className,
  value = '',
  onChange,
  placeholder,
  highlights = {}
}: Props): JSX.Element {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const backdropRef = useRef<HTMLDivElement>(null)

  function handleScroll(): void {
    if (backdropRef.current && textareaRef.current) {
      backdropRef.current.scrollTop = textareaRef.current.scrollTop
      backdropRef.current.scrollLeft = textareaRef.current.scrollLeft
    }
  }

  useEffect(() => {
    handleScroll()
  }, [value])

  return (
    <div className="relative mx-auto h-[500px] w-full max-w-2xl">
      <div
        ref={backdropRef}
        className="ginr pointer-events-none absolute inset-0 overflow-auto rounded-md border-0 border-transparent bg-white ring-gray-300"
      >
        <div
          className="whitespace-pre-wrap break-words p-4 text-lg leading-relaxed"
          dangerouslySetInnerHTML={{ __html: applyHighlights(value, highlights) }}
        />
      </div>
      <textarea
        ref={textareaRef}
        placeholder={placeholder}
        className={clsx(
          'absolute inset-0 resize-none overflow-auto rounded-md border-0 bg-transparent p-4 text-lg leading-relaxed text-gray-700 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400',
          className
        )}
        value={value}
        onChange={(e) => onChange?.(e.target.value)}
        onScroll={handleScroll}
      />
    </div>
  )
}

export function HighlightChip({ className, children }: { className: string; children: ReactNode }) {
  return (
    <mark className={clsx('px-0 py-0 text-lg leading-relaxed text-gray-700', className)}>
      {children}
    </mark>
  )
}

function applyHighlights(input: string, highlightConfig: HighlightMap): string {
  let highlightedText = input

  Object.entries(highlightConfig).forEach(([pattern, className]) => {
    const regex = new RegExp(pattern.replace('$', '\\$') + '\\w*', 'g')
    highlightedText = highlightedText.replace(
      regex,
      (match) => `<mark class="${className} text-transparent rounded">${match}</mark>`
    )
  })

  return highlightedText.replace(/\n$/g, '\n\n')
}
