export type ISO8601Time = string
export type Timestamp = number

export function formatISO8601Date(time: ISO8601Time): ISO8601Time {
  return parseISO8601Time(time).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

export function formatISO8601Time(time: ISO8601Time): ISO8601Time {
  return parseISO8601Time(time).toLocaleString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

export function formatISO8601DateTime(time: ISO8601Time): ISO8601Time {
  return parseISO8601Time(time).toLocaleString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  })
}

/**
 * Parse an ISO8601 timestamp that's cross-browser compatible.
 */
export function parseISO8601Time(iso8601string: string): Date {
  // Safari doesn't accept ISO8601 strings if the date and time are separated by a space
  // It must contain a T
  // See more: https://stackoverflow.com/a/60391306
  return new Date(iso8601string.replace(/\s+/g, 'T'))
}

export function timestampToDate(timestamp: Timestamp) {
  return new Date(timestamp * 1000)
}

export function timestampToISO8601(timestamp: Timestamp): ISO8601Time {
  return new Date(timestamp * 1000).toISOString()
}

export function iso8601ToTimestamp(iso8601string: ISO8601Time): Timestamp {
  const date = parseISO8601Time(iso8601string)
  return Math.floor(date.getTime() / 1000)
}
