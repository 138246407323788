import { ComponentConfig, WizardCategoryConfig, WizardConfig } from '../ui-config'

export type ComponentType = Exclude<ComponentConfig['__typename'], undefined>

export function getCategoriesForSection(
  uiConfig: WizardConfig,
  sectionId: string
): WizardCategoryConfig[] {
  const section = uiConfig.sections.find((el) => el.id == sectionId)
  if (section == null) {
    return []
  }
  return section.categories
}
