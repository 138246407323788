import {
  DependencyList,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState
} from 'react'

/**
 * Same behavior as {@link useState}, except useState will get reset to {@link initialState}
 * whenever it changes.
 *
 * Used in some parts of a UI to reset values. For example, if the id of the currently editing item changes,
 * we would want to reset the value so values from the previously editing item don't stick arounds.
 *
 * If {@link deps} are passed in, these will also trigger a reset to the current {@link initailState}
 *
 * @param initialState
 * @param deps
 * @returns A pair like [V, setV] just as useState does.
 *
 * See this comment and the discussion above for more context on the problem in React:
 * https://github.com/facebook/react/issues/14738#issuecomment-770836948
 */
export function useDerivedState<S>(
  initialState: S,
  deps?: DependencyList
): [S, Dispatch<SetStateAction<S>>] {
  const [stateValue, setStateValue] = useState<S>(initialState)
  const [, forceUpdate] = useReducer((x) => x + 1, 0)
  const isValueLatest = useRef(false)
  const depsToInvalidate = deps ? [initialState, ...deps] : [initialState]

  // useMemo run synchronously when deps changed
  useMemo(() => {
    isValueLatest.current = false
  }, depsToInvalidate)

  // ensure setVersion forces a rerender
  useEffect(forceUpdate, depsToInvalidate)

  const setValueCallback: Dispatch<SetStateAction<S>> = useCallback(
    (action: SetStateAction<S>) => {
      isValueLatest.current = true
      setStateValue(action)
      forceUpdate()
    },
    [setStateValue, forceUpdate]
  )

  const currentValue = isValueLatest.current ? stateValue : initialState

  return [currentValue, setValueCallback]
}
