import { cn } from '@util/cn'

type Props = {
  className?: string
  size?: number
}
export function DeleteIcon({ className, size = 24 }: Props) {
  return (
    <svg
      className={cn('delete-icon', className)}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 5C13.6569 5 15 6.34315 15 8H18.25C18.6642 8 19 8.33579 19 8.75C19 9.1297 18.7178 9.44349 18.3518 9.49315L18.25 9.5H17.454L16.1739 16.5192C16.0412 17.8683 14.9484 18.91 13.6126 18.9945L13.4371 19H10.5629C9.20734 19 8.06365 18.0145 7.84883 16.6934L7.82614 16.5192L6.545 9.5H5.75C5.3703 9.5 5.05651 9.21785 5.00685 8.85177L5 8.75C5 8.3703 5.28215 8.05651 5.64823 8.00685L5.75 8H9C9 6.34315 10.3431 5 12 5ZM10.5 8H13.5C13.5 7.17157 12.8284 6.5 12 6.5C11.1716 6.5 10.5 7.17157 10.5 8ZM8.052 9.5H15.947L14.6811 16.3724L14.6623 16.4982C14.5459 17.0751 14.0372 17.5 13.4371 17.5H10.5629L10.4358 17.4936C9.85033 17.4343 9.37768 16.9696 9.31893 16.3724L8.052 9.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
