import { useAuthUserChanged } from '@features/app-hooks'
import * as Sentry from '@sentry/browser'

export function ErrorReporting() {
  useAuthUserChanged((user) => {
    const sentryUser: Sentry.User | null = user
      ? {
          id: user.id,
          email: user.email
        }
      : null

    Sentry.getCurrentScope().setUser(sentryUser)
  })
  return <></>
}
