import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react'

/**
 * Behaves like useState, but any changes to state will revert to the {@link defaultState}
 * after a period of {@link duration}.
 *
 * Usually used for effects like flash messages where the UI will reset after a set period of time.
 *
 * @returns
 */
export function useFlashState<S>(
  defaultState: S,
  duration: number
): [S, Dispatch<SetStateAction<S>>] {
  const [state, setState] = useState<S>(defaultState)

  const setStateCallback: Dispatch<SetStateAction<S>> = useCallback(
    (action: SetStateAction<S>) => {
      setState(action)
    },
    [setState]
  )

  const timerRef = useRef<number | null>(null)

  useEffect(() => {
    if (state != defaultState) {
      if (timerRef.current) {
        clearTimeout(timerRef.current!)
      }
      timerRef.current = window.setTimeout(() => {
        setState(defaultState)
      }, duration)
    }
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current!)
      }
    }
  }, [state, setState, defaultState, duration])

  return [state, setStateCallback]
}
