import { move } from '@st/util/geom'
import { DrawOp, Rect } from '../draw'
import { Page, Renderer } from './interface'

export const pageRenderer: Renderer<Page> = {
  getSize(opts, constraints, context) {
    return constraints
  },
  render(props, origin, constraints, context) {
    const ops: DrawOp[] = []
    var currentPoint = origin

    if (context.debug?.grid)
      ops.push(drawGrid({ ...origin, ...constraints }, 20))

    for (const el of props.children) {
      const renderer = context.rendererFor(el.type)

      const size = renderer.getSize(el, constraints, context)
      ops.push(renderer.render(el, currentPoint, constraints, context))

      currentPoint = move(currentPoint, { dy: size.height })
    }

    return { type: 'group', ops }
  }
}

function drawGrid(bounds: Rect, distance: number): DrawOp {
  const ops: DrawOp[] = []

  for (let y = bounds.y; y <= bounds.height; y += distance) {
    ops.push({
      type: 'line',
      start: { x: bounds.x, y: y },
      end: { x: bounds.x + bounds.width, y: y },
      color: '#E6E6E6',
      width: 1
    })
  }

  for (let x = bounds.x; x <= bounds.width; x += distance) {
    ops.push({
      type: 'line',
      start: { x: x, y: bounds.y },
      end: { x: x, y: bounds.y + bounds.height },
      color: '#E6E6E6',
      width: 1
    })
  }

  return { type: 'group', ops }
}
