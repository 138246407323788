function ReturningClientsIcon({ className }: { className: string }) {
  return (
    <svg className={className}>
      <path
        opacity="0.2"
        d="M35.5 24C41.5751 24 46.5 28.9249 46.5 35C46.5 41.0751 41.5751 46 35.5 46C29.4249 46 24.5 41.0751 24.5 35C24.5 28.9249 29.4249 24 35.5 24ZM8.5 25.998L26.1188 26.0004C23.8775 28.3361 22.5 31.5072 22.5 35C22.5 37.1747 23.034 39.2247 23.978 41.026C21.8774 41.7259 19.5611 42 17.5 42C12.0557 42 4.82939 40.0879 4.51091 33.4593L4.5 33V29.998C4.5 27.79 6.292 25.998 8.5 25.998ZM35.5 28.0032L35.3202 28.0193C34.9121 28.0934 34.5902 28.4152 34.5161 28.8234L34.5 29.0032V33.9992L29.5 34L29.3202 34.0161C28.9121 34.0902 28.5902 34.4121 28.5161 34.8202L28.5 35L28.5161 35.1798C28.5902 35.5879 28.9121 35.9098 29.3202 35.9839L29.5 36L34.5 35.9992V41L34.5161 41.1798C34.5902 41.5879 34.9121 41.9098 35.3202 41.9839L35.5 42L35.6798 41.9839C36.0879 41.9098 36.4098 41.5879 36.4839 41.1798L36.5 41V35.9992L41.5 36L41.6798 35.9839C42.0879 35.9098 42.4098 35.5879 42.4839 35.1798L42.5 35L42.4839 34.8202C42.4098 34.4121 42.0879 34.0902 41.6798 34.0161L41.5 34L36.5 33.9992V29.0032L36.4839 28.8234C36.4098 28.4152 36.0879 28.0934 35.6798 28.0193L35.5 28.0032ZM17.5 4C22.47 4 26.5 8.03 26.5 13C26.5 17.97 22.47 22 17.5 22C12.53 22 8.5 17.97 8.5 13C8.5 8.03 12.53 4 17.5 4ZM35.5 8C39.366 8 42.5 11.134 42.5 15C42.5 18.866 39.366 22 35.5 22C31.634 22 28.5 18.866 28.5 15C28.5 11.134 31.634 8 35.5 8Z"
        fill="url(#paint0_linear_2683_18219)"
      />
      <path
        d="M35.5 24C41.5751 24 46.5 28.9249 46.5 35C46.5 41.0751 41.5751 46 35.5 46C29.4249 46 24.5 41.0751 24.5 35C24.5 28.9249 29.4249 24 35.5 24Z"
        fill="url(#paint1_linear_2683_18219)"
      />
      <path
        d="M40.25 32.5147C40.5059 32.5147 40.7318 32.6429 40.8672 32.8385L40.9076 32.9038C41.2833 33.5136 41.5 34.2314 41.5 34.9997C41.5 37.1419 39.816 38.8908 37.6996 38.9949L37.5 38.9997L35.055 38.999L35.7774 39.7211C36.0437 39.9874 36.0679 40.404 35.85 40.6977L35.7774 40.7818C35.5112 41.048 35.0945 41.0723 34.8009 40.8544L34.7168 40.7818L32.7151 38.7801C32.4488 38.5138 32.4246 38.0971 32.6424 37.8035L32.7151 37.7194L34.7168 35.7177C35.0097 35.4248 35.4845 35.4248 35.7774 35.7177C36.0437 35.984 36.0679 36.4006 35.85 36.6943L35.7774 36.7784L35.056 37.499L37.5 37.4997C38.8255 37.4997 39.91 36.4682 39.9947 35.1641L40 34.9997C40 34.5052 39.8564 34.0442 39.6086 33.6561C39.5404 33.5427 39.5 33.4084 39.5 33.2647C39.5 32.8505 39.8358 32.5147 40.25 32.5147ZM35.2197 29.2177C35.4859 28.9515 35.9026 28.9272 36.1962 29.1451L36.2803 29.2177L38.282 31.2194L38.3546 31.3035C38.5483 31.5645 38.5507 31.9227 38.3618 32.1861L38.282 32.2801L36.2803 34.2818L36.1962 34.3544C35.9352 34.548 35.577 34.5504 35.3136 34.3616L35.2197 34.2818L35.1471 34.1977C34.9534 33.9367 34.951 33.5785 35.1399 33.3151L35.2197 33.2211L35.941 32.499L33.5 32.4997C32.1745 32.4997 31.09 33.5313 31.0053 34.8354L31 34.9997C31 35.4212 31.1043 35.8182 31.2884 36.1666L31.3976 36.3512C31.4627 36.4623 31.5 36.5917 31.5 36.7298C31.5 37.144 31.1642 37.4798 30.75 37.4798C30.4821 37.4798 30.2469 37.3392 30.1143 37.1279C29.7253 36.5142 29.5 35.7833 29.5 34.9997C29.5 32.8576 31.184 31.1087 33.3004 31.0046L33.5 30.9997L35.94 30.999L35.2197 30.2784L35.1471 30.1943C34.9292 29.9006 34.9534 29.484 35.2197 29.2177Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2683_18219"
          x1="16.47"
          y1="36.725"
          x2="39.0527"
          y2="1.91143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2683_18219"
          x1="30.77"
          y1="41.1417"
          x2="42.599"
          y2="22.906"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function NewClientsIcon({ className }: { className: string }) {
  return (
    <svg className={className} width="49" height="48" fill="none">
      <path
        fill="url(#a)"
        d="M26.652 24.18c2.762 0 5 2.26 5 5.045l-.003 2.517C31.992 37.92 27.277 41 18.535 41 9.818 41 5 37.96 5 31.83v-2.605c0-2.786 2.239-5.045 5-5.045h16.652Zm13.335 0c2.762 0 5 2.26 5 5.045l-.002 1.771c.301 5.542-3.865 8.32-11.48 8.32-1.034 0-2.006-.051-2.913-.153 1.789-1.667 2.746-3.929 2.742-6.787l-.02-.728.005-2.423c0-2.01-.874-3.813-2.259-5.046l8.927.001ZM18.333 4c4.603 0 8.335 3.765 8.335 8.41s-3.732 8.41-8.335 8.41C13.73 20.82 10 17.055 10 12.41S13.73 4 18.333 4ZM35 7.363c3.682 0 6.667 3.012 6.667 6.727 0 3.715-2.985 6.727-6.667 6.727-3.681 0-6.666-3.012-6.666-6.727 0-3.715 2.985-6.727 6.666-6.727Z"
        opacity=".2"
      />
      <defs>
        <linearGradient
          id="a"
          x1="16.4"
          x2="35.624"
          y1="32.829"
          y2=".79"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function InBulkCSVIcon({ className }: { className: string }) {
  return (
    <svg className={className} fill="none">
      <path
        fill="url(#a)"
        d="M19.776 6.927C23.09 2.866 28.213 0 35.266 0c4.282 0 9.274 1.419 13.245 4.676 3.579 2.938 6.227 7.295 6.793 13.153 3.47.417 6.835 1.726 9.524 3.808 3.342 2.588 5.703 6.44 5.703 11.205 0 4.43-1.47 8.346-4.646 11.113C62.76 46.678 58.313 48 52.898 48H17.633c-6.82 0-11.374-2.072-14.176-5.284C.715 39.574 0 35.736 0 32.842c0-5.95 4.864-13.97 15.257-15.039.433-3.677 1.863-7.62 4.52-10.876Z"
        opacity=".2"
      />
      <defs>
        <linearGradient
          id="a"
          x1="20.101"
          x2="40.986"
          y1="37.4"
          y2="-9.909"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function OneByOneIcon({ className }: { className: string }) {
  return (
    <svg className={className} fill="none">
      <path
        fill="url(#a)"
        d="M11.294 20.5c1.128 0 2.042.914 2.042 2.042v7.874a6.418 6.418 0 0 1-12.836 0v-7.874c0-1.128.914-2.042 2.042-2.042h8.752Zm-4.38-9.333a4.083 4.083 0 1 1 0 8.167 4.083 4.083 0 0 1 0-8.167Z"
        opacity=".2"
      />
      <path
        fill="url(#b)"
        d="M20.132 24a.75.75 0 0 1 .75-.75h5.94l-1.97-1.97a.75.75 0 1 1 1.06-1.06l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 1 1-1.06-1.06l1.97-1.97h-5.94a.75.75 0 0 1-.75-.75Z"
      />
      <path
        fill="url(#c)"
        d="M46.426 20.5c1.128 0 2.042.914 2.042 2.042v7.874a6.418 6.418 0 0 1-12.836 0v-7.874c0-1.128.914-2.042 2.042-2.042h8.752Zm-4.38-9.333a4.083 4.083 0 1 1 0 8.167 4.083 4.083 0 0 1 0-8.167Z"
        opacity=".2"
      />
      <path
        fill="url(#d)"
        d="M55.264 24a.75.75 0 0 1 .75-.75h5.94l-1.97-1.97a.75.75 0 1 1 1.06-1.06l3.25 3.25a.75.75 0 0 1 0 1.06l-3.25 3.25a.75.75 0 0 1-1.06-1.06l1.97-1.97h-5.94a.75.75 0 0 1-.75-.75Z"
      />
      <path
        fill="url(#e)"
        d="M81.558 20.5c1.128 0 2.042.914 2.042 2.042v7.874a6.418 6.418 0 0 1-12.836 0v-7.874c0-1.128.915-2.042 2.042-2.042h8.752Zm-4.38-9.333a4.083 4.083 0 1 1 0 8.167 4.083 4.083 0 0 1 0-8.167Z"
        opacity=".2"
      />
      <defs>
        <linearGradient
          id="a"
          x1="4.243"
          x2="18.94"
          y1="31.166"
          y2="19.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="27.34"
          x2="20.368"
          y1="25.72"
          y2="20.491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="39.375"
          x2="54.073"
          y1="31.166"
          y2="19.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="62.472"
          x2="55.5"
          y1="25.72"
          y2="20.491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
        <linearGradient
          id="e"
          x1="74.507"
          x2="89.205"
          y1="31.166"
          y2="19.573"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

function MagicLinkIcon({ className }: { className?: string }) {
  return (
    <svg fill="none" className={className}>
      <path
        fill="url(#a)"
        d="M33.25 13c5.841 0 10.594 4.659 10.746 10.464l.004.286v.5c0 5.841-4.659 10.594-10.464 10.746L33.25 35h-4.5a1.75 1.75 0 0 1-.143-3.494l.143-.006h4.5a7.25 7.25 0 0 0 7.246-7.01l.004-.24v-.5a7.25 7.25 0 0 0-7.01-7.246l-.24-.004h-4.5a1.75 1.75 0 0 1-.143-3.494L28.75 13h4.5Zm-14 0a1.75 1.75 0 0 1 .143 3.494l-.143.006h-4.5a7.25 7.25 0 0 0-7.246 7.01l-.004.24v.5a7.25 7.25 0 0 0 7.01 7.246l.24.004h4.5a1.75 1.75 0 0 1 .143 3.494L19.25 35h-4.5C8.909 35 4.156 30.341 4.004 24.536L4 24.25v-.5c0-5.841 4.659-10.595 10.464-10.746L14.75 13h4.5ZM14 22.25h20a1.75 1.75 0 0 1 .144 3.494L34 25.75H14a1.75 1.75 0 0 1-.143-3.494L14 22.25h20-20Z"
      />
      <defs>
        <linearGradient
          id="a"
          x1="15.4"
          x2="23.6"
          y1="30.142"
          y2="7.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#526BF0" />
          <stop offset="1" stopColor="#8572FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const WizardIcons = {
  ReturningClientsIcon,
  NewClientsIcon,
  OneByOneIcon,
  InBulkCSVIcon,
  MagicLinkIcon
}
