import { FileStorageClient } from '@st/folder'
import { ReactNode, createContext, useContext } from 'react'

type Props = {
  children: ReactNode
  adapter: FileStorageClient
}
export function FileStorageProvider({ children, adapter }: Props) {
  return (
    <FileStorageContext.Provider value={adapter}>
      {children}
    </FileStorageContext.Provider>
  )
}

export function useFileStorageAdapter(): FileStorageClient {
  return useContext(FileStorageContext)!
}

const FileStorageContext = createContext<FileStorageClient | undefined>(
  undefined
)
