import type { FieldComment, FieldDecoration } from '@st/pdf'
import { useProcess, useProcessState } from '@st/redux'
import { JsonMap, JSONValue } from '@st/util/json-value'
import { useCallback } from 'react'
import {
  selFieldComment,
  selFieldDecoration,
  selReadInput,
  stFolderModule
} from './st-folder-module'

export function useRead(key: string): JSONValue {
  const value = useProcessState(stFolderModule, (s) => selReadInput(s, key))
  return value
}

export function useReadInputs<T>(sel: (inputs: JsonMap) => T): T {
  return useProcessState(stFolderModule, (s) => sel(s.folderState?.inputs ?? {}))
}

export function useInputsWrite() {
  const process = useProcess(stFolderModule)
  return useCallback(
    (inputs: JsonMap) => {
      process.send({
        type: 'request',
        request: {
          type: 'folders/setFolderInputs',
          folderId: process.getState().folderState!.folderId,
          inputs: inputs
        }
      })
    },
    [process]
  )
}

export function useFieldDecoration(key: string): FieldDecoration | undefined {
  const fieldDecoration = useProcessState(stFolderModule, (s) => {
    if (!s.folderState) return undefined
    return selFieldDecoration(s.folderState, key)
  })
  return fieldDecoration
}

export function useReadFieldComment(key: string): FieldComment | undefined {
  const comment: FieldComment | undefined = useProcessState(stFolderModule, (s) => {
    if (!s.folderState) return undefined
    return selFieldComment(s.folderState, key)
  })
  return comment
}
