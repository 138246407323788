import { Annot, AnnotationMessage, DrawTool, useDrawContext } from '@features/st-annotation-layer'
import { pdfViewerModule, selPageSelection } from '@features/st-pdf-viewer/module'
import { useProcess, useProcessState } from '@st/redux'
import { LinearTransform, Rect } from '@st/util/geom'
import { CopyIcon } from '@theme/icons'
import { Dispatch, useEffect } from 'react'
import { AnnotationToolbar, AnnotationToolbarOption } from './annotation-toolbar'

export const SELECT_TOOL: DrawTool<Annot, SelectTool> = {
  name: 'select',
  init: () => {
    return { name: 'select' }
  },
  CanvasBackgroundElement: SelectCanvasTool,
  matches: () => false
}

export type SelectTool = {
  name: 'select'
}

type SelectCanvasToolProps = {
  draft: Annot | undefined
  tool: SelectTool
  send: Dispatch<AnnotationMessage>
}
export function SelectCanvasTool({}: SelectCanvasToolProps) {
  const { page } = useDrawContext()

  const pdfViewer = useProcess(pdfViewerModule)
  const textSelection = useProcessState(pdfViewerModule, (s) => selPageSelection(s, page.index))

  useEffect(() => {
    pdfViewer.send({ type: 'setTool', tool: 'select' })
    return () => {
      pdfViewer.send({ type: 'setTool', tool: 'none' })
    }
  }, [])

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
        // background: 'rgba(1, 1, 1, 0.5)'
      }}
    >
      {textSelection ? (
        <SelectedTextOverlay
          height={20}
          rect={LinearTransform.apply(textSelection.rect, LinearTransform.denormalize(page.size))}
          options={[{ name: 'copy', icon: <CopyIcon size={14} />, tooltip: 'Copy Text' }]}
          onClickOption={() => {
            pdfViewer.send({ type: 'copySelectedText' })
          }}
        />
      ) : null}
    </div>
  )
}

type SelectedTextOverlayProps = {
  options: AnnotationToolbarOption[]
  onClickOption: (opt: AnnotationToolbarOption) => void
  height: number
  rect: Rect
}
function SelectedTextOverlay({ rect, options, onClickOption }: SelectedTextOverlayProps) {
  return (
    <div
      style={{
        position: 'absolute',
        boxSizing: 'border-box',
        top: rect.y,
        left: rect.x,
        width: rect.width,
        height: rect.height,
        cursor: 'move'
        // outline: '1px solid #3182EC'
      }}
    >
      <AnnotationToolbar
        style={{
          position: 'absolute',
          left: '50%',
          bottom: 20
        }}
        options={options}
        height={20}
        onClickOption={onClickOption}
      />
    </div>
  )
}
