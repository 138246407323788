import { Base } from '@st/util/base'
import { URLHandler } from '../types'
import { BlobStore } from './blob-store'

const blobStore = new BlobStore()

export const ContentURLHandler: URLHandler = {
  protocol: 'content',
  fetch: (url) => {
    const blob = blobStore.get(url)

    if (!blob) {
      return Promise.resolve(new Response('', { status: 404 }))
    }

    return Promise.resolve(
      new Response(blob, {
        headers: {
          'Content-Type': blob.type,
          'Content-Length': blob.size.toString()
        }
      })
    )
  },
  createURL: async (blob) => {
    const data = await blob.arrayBuffer()
    const hash = await computeFileHash(data)

    const contentUrl = `content:${hash}`

    blobStore.set(contentUrl, blob)

    return contentUrl
  },
  revokeURL: (url) => {
    blobStore.delete(url)
    return Promise.resolve()
  }
}

function computeFileHash(data: ArrayBuffer) {
  // seems like a bug in nodejs
  const finalData = typeof Buffer !== 'undefined' ? Buffer.from(data) : data

  return crypto.subtle
    .digest('SHA-1', finalData)
    .then((buf) => Base.bytesToString(buf, 'hex'))
}
