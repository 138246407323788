import { validateSize } from '../geom'
import { Renderer, TextInput } from './interface'

export const textInputRenderer: Renderer<TextInput> = {
  getSize(props, constraints, context) {
    const size = constraints
    return validateSize(props, constraints, size)
  },
  render(props, origin, constraints, context) {
    return {
      type: 'text_input',
      rect: { ...origin, ...constraints },
      name: props.name,
      value: props.value,
      fontFamily: props.fontFamily,
      fontSize: props.fontSize,
      multiline: props.multiline,
      mask: props.mask,

      backgroundColor: props.backgroundColor,
      borderColor: props.borderColor,
      borderWidth: props.borderWidth
    }
  }
}
