import { clsx } from 'clsx'

type Props = {
  placeholder?: string
  type?: 'text' | 'email' | 'password'
  className?: string
  value?: string
  autoFocus?: boolean
  autoComplete?: string
  onChange?: (value: string) => void
}
export function TextInput({
  className,
  autoFocus,
  autoComplete,
  placeholder,
  value,
  onChange,
  type = 'text'
}: Props) {
  return (
    <input
      type={type}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      placeholder={placeholder}
      className={clsx(
        'rounded-md border-0 py-1.5 text-base text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:leading-6',
        className
      )}
      value={value}
      onChange={(e) => onChange?.(e.target.value)}
    />
  )
}
