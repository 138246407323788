import * as Sentry from '@sentry/browser'
import { User } from '@features/auth/auth-client-adapter'
import { AnalyticsProvider } from '../core'

export const SentryAnalyticsService: AnalyticsProvider = {
  Script: () => {
    // script is already part of the build
    return <></>
  },
  trackUserIdentified(user: User) {
    Sentry.setUser({ id: user.id, email: user.email })
  },
  trackUserAnonymous() {
    Sentry.setUser(null)
  },

  captureException(exception) {
    Sentry.captureException(exception)
  }
}
