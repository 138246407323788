import { Note, Renderer } from './interface'

export const noteRenderer: Renderer<Note> = {
  getSize() {
    return { width: 0, height: 0 }
  },
  render(props, offset) {
    return {
      type: 'render_annot',
      annot: {
        type: 'comment',
        author: props.author,
        body: props.body,
        color: props.color,
        time: props.time,
        rect: {
          x: offset.x,
          y: offset.y,
          width: 25,
          height: 25
        }
      }
    }
  }
}
