import { clsx } from 'clsx'
import { ReactNode } from 'react'

export function TabGroup({ children }: { children: ReactNode }) {
  return (
    <nav className="flex flex-row justify-start divide-x divide-gray-300 rounded-md border border-gray-300">
      {children}
    </nav>
  )
}

export function Tab({
  selected,
  onClick,
  children
}: {
  selected?: boolean
  onClick?: () => void
  children: React.ReactNode
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        selected ? 'border bg-blue-100 text-blue-500' : 'bg-white text-gray-700',
        'w-40 min-w-0 flex-1 px-2 py-2 text-center text-base',
        'first:rounded-l-md last:rounded-r-md'
      )}
    >
      {children}
    </button>
  )
}
