import { ReactNode, createContext, useContext } from 'react'

import { FieldDecoration } from '@st/pdf'
import { JsonMap } from '@st/util/json-value'
import { GetWizardPageNote } from './formation-wizard/types'

export type FormationViewerBranding = {
  companyName?: string
  logo?: string
}

type FormationViewerContext = {
  getWizardPageNote?: GetWizardPageNote
  branding?: FormationViewerBranding

  /**
   * The organizer and the wizard have an optional hint that can be rendered
   *
   * @param inputs
   * @param inputKey
   * @returns
   */
  getFieldDecoration?: (inputs: JsonMap, inputKey: string) => FieldDecoration | undefined
}

const FormationViewerContext = createContext<FormationViewerContext | undefined>(undefined)

type Props = {
  children: ReactNode
} & FormationViewerContext
export function FormationViewerProvider({
  getFieldDecoration,
  getWizardPageNote,
  branding,
  children
}: Props) {
  return (
    <FormationViewerContext.Provider
      value={{
        getFieldDecoration,
        getWizardPageNote,
        branding
      }}
    >
      {children}
    </FormationViewerContext.Provider>
  )
}

export function useFormationViewerContext(): FormationViewerContext {
  return useContext(FormationViewerContext)!
}
