import { ImageConfig } from '@st/folder/ui-config'

export function IconImage({ imageUrl }: ImageConfig) {
  return (
    <div className="flex flex-col items-center">
      <img
        style={{ height: '200px', margin: '120px 0px 40px 0px' }}
        className="category-icon"
        alt="category icon"
        src={`/category-icons/${imageUrl}`}
      />
    </div>
  )
}
