export function PageNotFoundIcon() {
  return (
    <svg
      height="100px"
      version="1.1"
      viewBox="0 0 100 100"
      width="100px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="#333333"
        d="M95.86 28.31c-0.04,0.97 -0.73,1.67 -1.71,1.67l-86.68 0 0 49.16c0,4.57 3.76,8.33 8.33,8.33l68.4 0c4.58,0 8.33,-3.75 8.33,-8.33l0 -33.9c0,-0.92 0.74,-1.66 1.66,-1.66 0.92,0 1.67,0.74 1.67,1.66l0 33.9c0,6.42 -5.25,11.67 -11.66,11.67l-68.4 0c-6.41,0 -11.66,-5.26 -11.66,-11.67 0,-19.43 0,-38.85 0,-58.28 0,-6.41 5.26,-11.66 11.66,-11.66l68.4 0c6.41,0 11.66,5.25 11.66,11.66l0 7.45zm-20.36 29.39c0,-0.93 -0.75,-1.67 -1.67,-1.67 -0.92,0 -1.67,0.74 -1.67,1.67l0 2.78 -6.89 0 4.81 -12.69c0.33,-0.86 -0.11,-1.82 -0.96,-2.14 -0.86,-0.33 -1.82,0.11 -2.15,0.96l-5.67 14.95c-0.41,1.11 0.4,2.22 1.56,2.25l9.3 0 0 6.44c0,0.92 0.75,1.67 1.67,1.67 0.92,0 1.67,-0.75 1.67,-1.67l0 -12.55zm-36.69 0c0,-0.93 -0.75,-1.67 -1.67,-1.67 -0.92,0 -1.67,0.74 -1.67,1.67l0 2.78 -6.89 0 4.81 -12.69c0.32,-0.86 -0.11,-1.82 -0.97,-2.14 -0.85,-0.33 -1.82,0.11 -2.14,0.96l-5.67 14.95c-0.41,1.11 0.4,2.22 1.56,2.25l9.3 0 0 6.44c0,0.92 0.75,1.67 1.67,1.67 0.92,0 1.67,-0.75 1.67,-1.67l0 -12.55zm11.51 -12.17c4.14,0 7.53,3.4 7.53,7.53l0 11.33c0,4.14 -3.38,7.53 -7.52,7.53 -4.15,0 -7.53,-3.38 -7.53,-7.53l0 -11.33c0,-4.13 3.39,-7.53 7.52,-7.53zm0.01 3.34c-2.31,0 -4.2,1.88 -4.2,4.19l0 11.33c0,2.3 1.89,4.19 4.19,4.19 2.31,0 4.2,-1.88 4.2,-4.19l0 -11.33c0,-2.31 -1.88,-4.19 -4.19,-4.19zm42.2 -22.23l0 -5.78c0,-4.59 -3.75,-8.33 -8.33,-8.33l-68.4 0c-4.58,0 -8.33,3.75 -8.33,8.33l0 5.78 85.06 0zm-76.67 -10.39c1.84,0 3.33,1.5 3.33,3.34 0,1.84 -1.49,3.33 -3.33,3.33 -1.84,0 -3.34,-1.49 -3.34,-3.33 0,-1.84 1.5,-3.34 3.34,-3.34z"
      />
    </svg>
  )
}
