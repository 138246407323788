import { Parser } from "./core";

export function token<T extends string>(word: T): Parser<string, T> {
  return (target, position) =>
    target.substring(position, position + word.length) === word
      ? { success: true, value: word, position: position + word.length }
      : {
          success: false,
          position,
          error: `token@${position}: ${target.substr(
            position,
            word.length
          )} is not ${word}`,
        };
}

export function regexp(reg: RegExp): Parser<string, string> {
  return (target, position) => {
    reg.lastIndex = 0;
    const result = reg.exec(target.slice(position));
    return result
      ? {
          success: true,
          value: result[0],
          position: position + result[0].length,
        }
      : {
          success: false,
          position,
          error: `regexp@${position}: ${target.slice(
            position
          )} does not match ${reg.source}`,
        };
  };
}
