import { at } from '@st/util/array'
import { deepEqual } from '@st/util/json-value'

import { indexedPageById, WizardState } from './wizard-state'

export type Progress = { value: number; max: number }

export type WizardNavigationState = {
  cur: WizardRoute
  prev?: WizardRoute
  next?: WizardRoute
}

export type WizardRoute = {
  sectionId: string
  pageId: string
  categoryId: string | undefined
}

export function getWizardNavigationState(
  routes: WizardRoute[],
  currentRoute: WizardRoute
): WizardNavigationState {
  const routeIndex = routes.findIndex((el) => deepEqual(el, currentRoute))
  return {
    cur: currentRoute,
    prev: at(routes, routeIndex - 1),
    next: at(routes, routeIndex + 1)
  }
}

export function getWizardRoutes({ filteredPages }: WizardState): WizardRoute[] {
  return filteredPages.map<WizardRoute>((p) => {
    return {
      pageId: p.page.id,
      categoryId: p.category?.id,
      sectionId: p.section.id
    }
  })
}

export function getWizardSectionRoutes(routes: WizardRoute[]): WizardRoute[] {
  const sectionRoutes: WizardRoute[] = []
  const set = new Set()
  for (const r of routes) {
    if (set.has(r.sectionId)) continue

    set.add(r.sectionId)
    sectionRoutes.push(r)
  }
  return sectionRoutes
}

export function getProgressForRoute(state: WizardState, pageId: string): Progress {
  const indexedPage = indexedPageById(state, pageId)

  if (!indexedPage) {
    return { value: 0, max: 0 }
  }

  const sProgress = sectionProgress(state, indexedPage.section.id)
  const pProgress = progressForPage(state, pageId)
  return {
    value: sProgress.value + pProgress.value / pProgress.max,
    max: sProgress.max
  }
}

function sectionProgress(state: WizardState, sectionId: string): Progress {
  const sectionsBefore = new Set()
  const totalSections = new Set()
  for (const p of state.allIndexedPages) {
    if (p.section.id == sectionId) break
    sectionsBefore.add(p.section.id)
  }
  for (const p of state.allIndexedPages) {
    totalSections.add(p.section.id)
  }
  return { value: sectionsBefore.size, max: totalSections.size }
}

/**
 * Calculate the progress *within the current section* to indicate to the user
 * filling out the questionnaire how far along they are.
 *
 * The current formula is essentially: indexOfCurrentPage / numberOfPagesInSection
 *
 * This progress accounts for filters such as selected categories and conditional filters like showWhen
 */
function progressForPage(state: WizardState, pageId: string): Progress {
  const indexedPage = indexedPageById(state, pageId)

  if (!indexedPage) {
    return { value: 0, max: 0 }
  }

  const filteredPagesInSectionWithinCategoriesSelected = state.allIndexedPages.filter(
    (el) => el.section.id == indexedPage.section.id && el.matchesFilter
  )

  const unfilteredPagesInSectionWithinCategoriesSelected = state.allIndexedPages.filter(
    (el) => el.section.id == indexedPage.section.id
  )

  const indexOfPage = filteredPagesInSectionWithinCategoriesSelected.findIndex(
    (el) => el.page.id == pageId
  )

  const unfilteredIndexOfPage = unfilteredPagesInSectionWithinCategoriesSelected.findIndex(
    (el) => el.page.id == pageId
  )

  return {
    value: indexOfPage,
    max:
      indexOfPage + unfilteredPagesInSectionWithinCategoriesSelected.length - unfilteredIndexOfPage
  }
}
