import { DeleteIcon } from '@components/icons'
import { useDrawContext } from '@features/st-annotation-layer'
import { CSSProperties, ReactNode } from 'react'

export type AnnotationToolbarOption = {
  name: string
  icon: ReactNode
  tooltip: string
}

type AnnotationToolbarProps = {
  style?: CSSProperties
  options: AnnotationToolbarOption[]
  onClickOption: (opt: AnnotationToolbarOption) => void
  height: number
}

export const DELETE_OPTION: AnnotationToolbarOption = {
  icon: <DeleteIcon size={16} />,
  name: 'delete',
  tooltip: 'Delete item'
}

export function AnnotationToolbar({
  style = {},
  options,
  onClickOption,
  height
}: AnnotationToolbarProps) {
  const { zoom } = useDrawContext()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: options.length * height,
        height: height,
        borderRadius: 4,
        boxShadow: '0 0 3px 0 #868e96',
        background: '#fff',
        position: 'absolute',
        ...style,
        bottom: -height * 1.5,
        left: '50%',
        // todo fix this hack so we can avoid having to revert css transforms
        marginLeft: -(options.length * height) / zoom / 2
      }}
      onMouseDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      {options.map((opt, index) => (
        <AnnotationToolbarButton
          size={height}
          key={index}
          icon={opt.icon}
          onClick={() => onClickOption(opt)}
        />
      ))}
    </div>
  )
}

type AnnotationToolbarButtonProps = {
  size: number
  onClick: () => void
  icon: ReactNode
}

function AnnotationToolbarButton({ size, icon, onClick }: AnnotationToolbarButtonProps) {
  return (
    <button
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        background: 'white',
        width: size,
        height: size,
        cursor: 'pointer',
        borderRadius: 4
      }}
      onPointerDown={(e) => {
        e.preventDefault()
        e.stopPropagation()
        onClick()
      }}
    >
      {icon}
    </button>
  )
}
