type MimeType = {
  type: string
  subtype: string
  parameters: Record<string, string | boolean>
}

export function matchesMimeType(mimeType: string | undefined, match: string) {
  if (!mimeType) return false
  const [mime, _meta] = mimeType.split(';')
  return mime == match
}

export function parseMimeType(mimeType?: string): MimeType | undefined {
  if (!mimeType) return undefined

  let [type, subtype] = mimeType.split('/')
  if (!type || !subtype) return undefined

  if (subtype.indexOf(';') != -1) {
    const [realSubtype, ...paramList] = subtype.split(';')
    const parameters = paramList.map((el) => {
      const [k, v] = el.split('=')
      return [k.trim(), v ? v.trim() : true]
    })
    return {
      type,
      subtype: realSubtype.trim(),
      parameters: Object.fromEntries(parameters)
    }
  }

  return { type, subtype, parameters: {} }
}

const IMG_EXTENSIONS = ['jpeg', 'png', 'gif', 'bmp', 'tif', 'tiff']

export function inferMimeType(ext: string) {
  if (ext == 'pdf') return 'application/pdf'
  if (ext == 'jpg') return 'image/jpeg'

  if (IMG_EXTENSIONS.includes(ext)) return `image/${ext}`

  return 'application/octet-stream'
}
