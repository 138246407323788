import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { DotLottieReact } from '@lottiefiles/dotlottie-react'
import { useProcess, useProcessState } from '@st/redux'
import { CreateFolderJob, CreateFoldersFromImportJobResult, CreateFolderResult } from '@st/sdk'
import { Banner, Button, CheckIcon, TagChip, Tip, Wizard } from '@st/theme'
import { inflect, titleify } from '@st/util/inflect'
import { clsx } from 'clsx'
import { match, P } from 'ts-pattern'
import {
  authorizeCreateFolder,
  CreateFolderAuthorization,
  selAllJobsSelected,
  selSelectedJobIds,
  STRunFolderImportMessage,
  stRunFolderImportModule,
  STRunFolderImportState
} from './st-run-folder-import-module'
import { keyBy } from '@st/util/array'

type Props = {
  importId: string
  onClose: () => void
}
export function STRunFolderImportPage({ importId, onClose }: Props) {
  const stFolderImport = useProcess(stRunFolderImportModule, { importId: importId })
  const state = useProcessState(stRunFolderImportModule, (s) => s)

  return (
    <div className="min-w-[1200px] bg-red-100">
      {state.importState ? (
        <STRunFolderImportContent
          state={state}
          selectedJobIds={selSelectedJobIds(state)}
          allJobIdsSelected={selAllJobsSelected(state)}
          send={stFolderImport.send}
          onClose={onClose}
        />
      ) : (
        <ProcessingPage onClose={onClose} />
      )}
    </div>
  )
}

function ProcessingPage({ onClose }: { onClose: () => void }) {
  return (
    <Wizard.Scaffold breadcrumb="Add clients" onClose={onClose}>
      <div className="flex h-full w-full flex-col items-center justify-center gap-6">
        <div role="status">
          <svg
            aria-hidden="true"
            className="h-16 w-16 animate-spin fill-blue-600 text-gray-200 dark:text-gray-600"
            viewBox="0 0 100 101"
            fill="none"
          >
            <path
              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
              fill="currentColor"
            />
            <path
              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
              fill="currentFill"
            />
          </svg>
        </div>
        <div className="flex flex-col items-center gap-2">
          <div className="text-center text-gray-700">Processing...</div>
          <div className="text-center text-sm text-gray-700">
            This may take 2-3 minutes to complete.
          </div>
        </div>
      </div>
    </Wizard.Scaffold>
  )
}

export default function STRunFolderImportContent({
  state,
  selectedJobIds,
  allJobIdsSelected,
  send,
  onClose
}: {
  state: STRunFolderImportState
  selectedJobIds: Set<string>
  allJobIdsSelected: boolean
  send: (message: STRunFolderImportMessage) => void
  onClose: () => void
}) {
  const jobs = state.importState?.createFolderJobs ?? []
  const pipelineFailureCount = state.importState?.failedRecordsCount ?? 0

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <div></div>
          {match(state)
            .with({ step: 'selectFolders' }, () => (
              <Button
                variant="primary"
                disabled={selectedJobIds.size == 0}
                onClick={() => send({ type: 'runImport' })}
              >
                Import {selectedJobIds.size} {inflect(selectedJobIds.size, 'client')}
              </Button>
            ))
            .with({ step: 'running' }, () => (
              <Button variant="primary" disabled={true}>
                Importing...
              </Button>
            ))
            .with({ step: 'completed' }, () => (
              <Button
                variant="primary"
                onClick={onClose}
                leadingIcon={<CheckIcon className="h-4 w-4" />}
              >
                Done
              </Button>
            ))
            .otherwise(() => (
              <Button variant="primary" disabled={true}>
                Import
              </Button>
            ))}
        </Wizard.BottomNav>
      }
    >
      {match(state)
        .with({ step: 'selectFolders', importState: { createFolderJobs: [] } }, ({ importId }) => {
          return (
            <Wizard.Main variant="wide">
              <div className="flex h-full flex-col items-center justify-center gap-4 text-center">
                <div className="text-lg font-semibold text-gray-900">
                  No clients were found in the upload.
                  <br />
                  {`Please contact us at support@stanfordtax.com with the following reference: ${importId}`}
                </div>
              </div>
            </Wizard.Main>
          )
        })
        .with({ step: 'selectFolders' }, () => {
          return (
            <Wizard.Main
              variant="wide"
              title="Add clients"
              subtitle="Below are the clients that were in the backup file. Click the import button to generate their questionnaires. The questionnaires will not be sent until you choose to send them."
            >
              <STImportFoldersTable
                jobs={jobs}
                step={state.step}
                selectedJobIds={selectedJobIds}
                allJobIdsSelected={allJobIdsSelected}
                send={send}
              />
            </Wizard.Main>
          )
        })
        .with({ step: 'running' }, () => {
          return (
            <Wizard.Main variant="wide">
              <GeneratingChecklistsProcessing />
            </Wizard.Main>
          )
        })
        .with({ step: 'completed' }, (state) => {
          const successfulImportCount = state.createFoldersResult.results.filter(
            (s) => s.status == 'success'
          ).length

          return (
            <Wizard.Main
              variant="wide"
              title={`You successfully imported ${successfulImportCount} ${inflect(
                selectedJobIds.size,
                'client'
              )}`}
              subtitle="You can preview the questionnaires for each of the clients on the main page"
            >
              <STImportFoldersTable
                jobs={state.importState?.createFolderJobs ?? []}
                step={state.step}
                selectedJobIds={selectedJobIds}
                allJobIdsSelected={allJobIdsSelected}
                createFoldersResult={state.createFoldersResult}
                send={send}
              />
            </Wizard.Main>
          )
        })
        .run()}
    </Wizard.Scaffold>
  )
}

function STImportFoldersTable({
  step,
  jobs,
  selectedJobIds,
  allJobIdsSelected,
  createFoldersResult,
  send
}: {
  jobs: CreateFolderJob[]
  selectedJobIds: Set<string>
  createFoldersResult?: CreateFoldersFromImportJobResult
  allJobIdsSelected: boolean
  send: (message: STRunFolderImportMessage) => void
  step: STRunFolderImportState['step']
}) {
  const resultsByJobId = keyBy(createFoldersResult?.results ?? [], (res) => {
    return res.jobId!
  })

  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      <thead className="sticky top-0 z-10 bg-white">
        <tr className="h-9">
          <th scope="col" className="pl-8 text-left text-xs uppercase text-gray-600">
            <input
              type="checkbox"
              className={clsx('h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500', {
                invisible: step == 'completed'
              })}
              checked={allJobIdsSelected}
              onChange={() => send({ type: 'toggleSelectAll' })}
            />
          </th>
          <th scope="col" className="pl-8 text-left text-xs uppercase text-gray-600">
            Name
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Email
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Spouse Email
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Return Type
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Tax Year
          </th>
          <th scope="col" className="pr-8 text-left text-xs uppercase text-gray-600">
            Status
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {jobs.map((job) => {
          return (
            <CreateFolderJobRow
              key={job.id}
              job={job}
              step={step}
              selected={selectedJobIds.has(job.id)}
              authorization={authorizeCreateFolder(job)}
              createFolderResult={resultsByJobId[job.id]}
              onToggleSelected={() => send({ type: 'toggleJobSelected', jobId: job.id })}
            />
          )
        })}
      </tbody>
    </table>
  )
}

function CreateFolderJobRow({
  job,
  selected,
  step,
  authorization,
  onToggleSelected,
  createFolderResult
}: {
  job: CreateFolderJob
  selected: boolean
  step: STRunFolderImportState['step']
  authorization: CreateFolderAuthorization
  onToggleSelected: () => void
  createFolderResult?: CreateFolderResult
}) {
  return (
    <tr key={job.folderSnapshot.year} className={clsx('h-12', {})}>
      <td className="whitespace-nowrap pl-8">
        <Tip placement="right" title={authorization.ok == false ? authorization.error : undefined}>
          <input
            type="checkbox"
            className={clsx('h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500', {
              invisible: step == 'completed'
            })}
            disabled={authorization.ok == false || step != 'selectFolders'}
            checked={selected}
            onChange={onToggleSelected}
          />
        </Tip>
      </td>
      <td className="whitespace-nowrap pl-8">
        <div className="flex flex-row items-center gap-2">
          <div className={`text-base font-semibold`}>
            {formatFolderEntityName(job.folderSnapshot.entities)}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap text-base text-gray-500">
        {job.folderSnapshot.entities.taxpayer?.email}
      </td>
      <td className="whitespace-nowrap text-base text-gray-500">
        {job.folderSnapshot.entities.spouse?.email}
      </td>
      <td className="whitespace-nowrap text-base text-gray-500">{job.folderSnapshot.type}</td>
      <td className="whitespace-nowrap text-base text-gray-500">{job.folderSnapshot.year}</td>
      <td className="whitespace-nowrap text-base text-gray-500">
        <div className="flex flex-row items-center">
          {match({ createFolderResult, job })
            .with({ createFolderResult: P.nonNullable }, ({ createFolderResult }) => {
              return <TagChip color="green">{titleify(createFolderResult.status!)}</TagChip>
            })
            .with({ job: { existingFolder: P.nonNullable } }, () => {
              return <TagChip color="gray">Already imported</TagChip>
            })
            .otherwise(() => {
              return null
            })}
        </div>
      </td>
    </tr>
  )
}

function GeneratingChecklistsProcessing() {
  return (
    <div className="flex w-[600px] flex-1 flex-col items-center justify-center gap-2">
      <DotLottieReact
        src="/lotties/checklist.lottie"
        style={{ width: 70, height: 70 }}
        loop={true}
        autoplay={true}
      />

      <h3 className="text-center text-2xl font-semibold">
        StanfordTax is generating checklists and questionnaires for your clients
      </h3>
    </div>
  )
}
