export function LinkIcon(props: { className?: string }) {
  return (
    <svg className={props.className} viewBox="0 0 17 16" fill="none">
      <path
        d="M15.5 5.5q0 1.4-1 2.3L12.3 10a3 3 0 0 1-2.4 1 3.4 3.4 0 0 1-3.4-3.5.5.5 0 1 1 1 0 2.4 2.4 0 0 0 4.1 1.8L13.8 7a2.4 2.4 0 0 0-3.4-3.4l-.7.7a.5.5 0 0 1-.7-.7l.7-.7a3.4 3.4 0 0 1 5.8 2.5m-8.2 6.1-.7.7a2.4 2.4 0 0 1-3 .3 2.4 2.4 0 0 1-.4-3.7l2.2-2.2a2.4 2.4 0 0 1 4.1 1.8.5.5 0 0 0 1 0A3.4 3.4 0 0 0 7.1 5Q5.6 5 4.7 6L2.5 8.2A3.4 3.4 0 0 0 7.3 13l.7-.7a.5.5 0 0 0-.7-.7"
        fill="currentColor"
      />
    </svg>
  )
}
