import { clsx } from 'clsx'
import { CSSProperties, useMemo } from 'react'

const UNSELECTED = '$$UNSELECTED$$'

type PrimitiveValue = string | number

export type SelectProps<T, V extends PrimitiveValue> = {
  value?: V
  className?: string
  style?: CSSProperties
  placeholder?: string
  disabled?: boolean
  onChange?: (value: V | undefined) => void
  options: T[]
  buildValue: (opt: T) => V
  buildLabel: (opt: T) => string | number
}

export function Select<T, V extends PrimitiveValue>({
  value,
  className,
  style,
  options,
  placeholder,
  onChange,
  buildValue,
  buildLabel,
  disabled
}: SelectProps<T, V>) {
  const optionsComponents = useMemo(() => {
    return [
      ...(placeholder ? [<option key={''} value={UNSELECTED} label={placeholder} />] : []),
      ...options.map((opt, index) => {
        return (
          <option key={index} value={buildValue(opt)}>
            {buildLabel(opt)}
          </option>
        )
      })
    ]
  }, [options, placeholder, buildValue, buildLabel])

  const valueSelected = options.some((opt) => buildValue(opt) === value)

  return (
    <select
      className={clsx(
        'block rounded-md border-0 py-2 pl-3 pr-10 text-base text-gray-900 ring-1 ring-inset ring-gray-300',
        {
          'cursor-not-allowed opacity-50': disabled,
          'text-gray-500': !valueSelected
        },
        className
      )}
      style={style}
      disabled={disabled}
      value={value === undefined ? UNSELECTED : value}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onChange?.(e.target.value === UNSELECTED ? undefined : (e.target.value as V))
      }
    >
      {optionsComponents}
    </select>
  )
}
