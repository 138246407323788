import { clsx } from 'clsx'
import { ReactNode } from 'react'
import { useId } from '@floating-ui/react'

interface DialogProps {
  children?: ReactNode
  title?: ReactNode
  subtitle?: ReactNode
  buttons?: ReactNode
  className?: string
}

export function Dialog({ children, title, subtitle, buttons, className, ...props }: DialogProps) {
  const labelId = useId()
  const descriptionId = useId()

  return (
    <div
      role="dialog"
      aria-labelledby={title ? labelId : undefined}
      aria-describedby={subtitle ? descriptionId : undefined}
      className={clsx(
        'flex h-svh w-svw flex-col items-stretch gap-2 overflow-hidden bg-white px-6 py-6 outline-none sm:h-auto sm:rounded-md sm:shadow-md [:where(&)]:sm:max-w-xl',
        className
      )}
      {...props}
    >
      {title && (
        <div className="mb-6 flex flex-col gap-3">
          <h1 id={labelId} className="text-2xl text-gray-800">
            {title}
          </h1>
          {subtitle && (
            <h2 id={descriptionId} className="text-base text-gray-700">
              {subtitle}
            </h2>
          )}
        </div>
      )}
      <div className="min-h-0 flex-grow overflow-y-auto">{children}</div>
      {buttons}
    </div>
  )
}

export function DialogButtons({
  children,
  leftButton
}: {
  children?: ReactNode
  leftButton?: ReactNode
}) {
  return (
    <div className="mt-4 flex flex-col-reverse items-stretch justify-end gap-2 sm:flex-row">
      {leftButton}
      {leftButton && <div className="grow" />}
      {children}
    </div>
  )
}
