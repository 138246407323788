type BaseEncoding = 'hex' | 'base64' | 'base64url' | 'binary'

export const Base = {
  convert(source: string, from: BaseEncoding, to: BaseEncoding): string {
    return Base.bytesToString(Base.stringToBytes(source, from), to)
  },
  stringToBytes(str: string, encoding: BaseEncoding): Uint8Array {
    switch (encoding) {
      case 'hex':
        return hexToBytes(str)
      case 'base64':
        return base64ToBytes(str)
      case 'base64url':
        return base64URLToBytes(str)
      case 'binary':
        return binaryToBytes(str)
      default:
        throw new Error(`Unsupported source encoding ${encoding}`)
    }
  },
  bytesToString(
    data: Uint8Array | ArrayBuffer,
    encoding: BaseEncoding
  ): string {
    const bytes = data instanceof ArrayBuffer ? new Uint8Array(data) : data

    // We can lean on the native Buffer implementation
    if (typeof Buffer !== 'undefined') {
      return Buffer.from(bytes).toString(encoding)
    }

    switch (encoding) {
      case 'hex':
        return bytesToHex(bytes)
      case 'base64':
        return bytesToBase64(bytes)
      case 'base64url':
        return bytesToBase64URL(bytes)
      case 'binary':
        return bytesToBinary(bytes)
      default:
        throw new Error(`Unsupported target encoding ${encoding}`)
    }
  }
}

export const Base64 = {
  encode: (str: string) => Base.convert(str, 'binary', 'base64'),
  encodeURL: (str: string) => Base.convert(str, 'binary', 'base64url'),
  decode: (str: string) => Base.convert(str, 'base64', 'binary'),
  decodeURL: (str: string) => Base.convert(str, 'base64url', 'binary')
}

function hexToBytes(hex: string): Uint8Array {
  const length = hex.length
  const bytes = new Uint8Array(length / 2)
  for (let i = 0, j = 0; i < length; i += 2, j++) {
    bytes[j] = parseInt(hex.substring(i, i + 2), 16)
  }
  return bytes
}

function bytesToHex(bytes: Uint8Array): string {
  const hex = []
  for (const byte of bytes) {
    hex.push(byte.toString(16).padStart(2, '0'))
  }
  return hex.join('')
}

function base64ToBytes(base64: string): Uint8Array {
  const binaryString = atob(base64)
  const length = binaryString.length
  const bytes = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes
}

function base64URLToBytes(base64url: string): Uint8Array {
  const base64 = base64url.replace(/[-_]/g, (match) => {
    switch (match) {
      case '-':
        return '+'
      case '_':
        return '/'
      default:
        return match
    }
  })
  const paddedLength = Math.ceil(base64.length / 4) * 4
  return base64ToBytes(base64.padEnd(paddedLength, '='))
}
function bytesToBase64URL(bytes: Uint8Array): string {
  return bytesToBase64(bytes).replace(/[+/=]/g, (match) => {
    switch (match) {
      case '+':
        return '-'
      case '/':
        return '_'
      case '=':
        return ''
      default:
        return match
    }
  })
}

function bytesToBase64(bytes: Uint8Array): string {
  let binary = ''
  const len = bytes.byteLength
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return btoa(binary)
}

function binaryToBytes(binary: string): Uint8Array {
  const length = binary.length
  const array = new Uint8Array(length)
  for (let i = 0; i < length; i++) {
    array[i] = binary.charCodeAt(i) & 0xff // & 0xFF ensures we're only getting the byte value
  }
  return array
}

function bytesToBinary(bytes: Uint8Array): string {
  let binary = ''
  for (let i = 0; i < bytes.length; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return binary
}
