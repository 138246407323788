import { AnnotationMessage, DrawTool, ElementContainer } from '@features/st-annotation-layer'
import { DocFieldAnnotation } from '@st/folder'
import { Dispatch } from 'react'

export const FIELD_TOOL: DrawTool<DocFieldAnnotation, FieldTool> = {
  name: 'field',
  init: () => {
    return { name: 'field' }
  },
  CanvasBackgroundElement: FieldCanvasTool,
  Element: FieldCanvasElement,
  matches: (annot) => annot.type == 'field'
}

export type FieldTool = {
  name: 'field'
}

type FieldCanvasElementProps = {
  selected?: boolean
  annot: DocFieldAnnotation
  send: Dispatch<AnnotationMessage>
}
export function FieldCanvasElement({ annot, selected, send }: FieldCanvasElementProps) {
  return (
    <ElementContainer
      key={annot.id}
      rect={annot.bounds}
      onClick={() => send({ type: 'selectAnnot', annotId: annot.id })}
    >
      <div
        style={{
          border: selected ? '1px solid rgba(255, 235, 184, 1)' : '1px solid rgb(26, 115, 232)',
          backgroundColor: selected ? 'rgba(255, 235, 184, 0.4)' : 'rgba(26, 115, 232, 0.15)',
          mixBlendMode: 'multiply',
          cursor: 'pointer',
          boxSizing: 'border-box',
          position: 'absolute',
          width: annot.bounds.width,
          height: annot.bounds.height,
          overflow: 'visible'
        }}
      />
    </ElementContainer>
  )
}

type FieldCanvasToolProps = {
  draft: DocFieldAnnotation | undefined
  tool: FieldTool
  send: Dispatch<AnnotationMessage>
}
export function FieldCanvasTool({ draft, tool, send }: FieldCanvasToolProps) {
  return null
}
