type HtmlTag = 'a' | 'b' | 'em' | 'span'

export interface HtmlElement {
  tag: HtmlTag
  content: string
  attrs: HtmlAttributes
}

type HtmlAttributes = { [key: string]: string }

/**
 * Parse simple html markdown into an array of tokens.
 * Used to render a really stripped down set of html content.
 * Restrictions:
 * - Tags can't be nested
 * - Only a subset of tags are allowed {@link HtmlTag}
 *
 * @param text
 * @returns An array of tokens
 */
export function parseSimpleHtmlMarkdown(text: string): HtmlElement[] {
  function tokenText(token: HtmlToken): string {
    return token.type == 'text' ? token.content : ''
  }

  const tokens = parseHtmlTokens(text)
  const elements: HtmlElement[] = []

  var index = 0
  while (index < tokens.length) {
    const token = tokens[index]
    switch (token.type) {
      case 'open':
        // collect text content until we hit a closing tag
        var content = ''
        while (tokens[index].type != 'close' && index < tokens.length) {
          content = tokenText(tokens[index])
          index++
        }
        elements.push({
          tag: token.tag == 'text' ? 'span' : (token.tag as HtmlTag),
          attrs: token.attrs,
          content
        })
        break
      case 'text':
        elements.push({ tag: 'span', content: tokenText(token), attrs: {} })
        break
    }
    index++
  }

  return elements
}

type HtmlToken =
  | { type: 'open'; tag: string; attrs: HtmlAttributes }
  | { type: 'close'; tag: string }
  | { type: 'text'; content: string }

const HTML_TOKEN_REGEXP =
  /<(?<open>\w+)(?<attrs>[^>]+)?>|<\/(?<close>\w+)>|(?<text>[^><]+)/g
function parseHtmlTokens(html: string): HtmlToken[] {
  const tokens: HtmlToken[] = []
  var match: RegExpExecArray | null
  while ((match = HTML_TOKEN_REGEXP.exec(html))) {
    const groups = match.groups!
    if (groups['open']) {
      tokens.push({
        type: 'open',
        tag: match.groups!['open']!,
        attrs: parseHtmlAttributes(groups['attrs'] || '')
      })
    } else if (groups['close']) {
      tokens.push({ type: 'close', tag: groups['close']! })
    } else if (groups['text']) {
      tokens.push({ type: 'text', content: groups['text'] })
    }
  }
  return tokens
}

const HTML_ATTRS_REGEXP = /(?<name>\w+)="(?<value>[^"]+)"/g
function parseHtmlAttributes(attrsString: string): HtmlAttributes {
  const attrs: HtmlAttributes = {}
  var match: RegExpExecArray | null
  while ((match = HTML_ATTRS_REGEXP.exec(attrsString))) {
    attrs[match.groups!['name']] = match.groups!['value']
  }
  return attrs
}
