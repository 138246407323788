import { clsx } from 'clsx'

type Props<T> = {
  options: T[]
  value: T | undefined
  onChange: (opt: T) => void
  buildClass: (opt: T) => string
}

export function SwatchColorPicker<T>({ options, value, onChange, buildClass }: Props<T>) {
  return (
    <div className="flex flex-row gap-3 p-2">
      {options.map((opt) => {
        return (
          <ColorPickerOption
            color={buildClass(opt)}
            selected={opt == value}
            onClick={() => onChange(opt)}
          />
        )
      })}
    </div>
  )
}

function ColorPickerOption({
  selected,
  color,
  onClick
}: {
  selected?: boolean
  color: string
  onClick?: () => void
}) {
  return (
    <div
      className={clsx(
        'h-6 w-6 cursor-pointer rounded-full border-2 border-white',
        selected ? 'shadow-[0_0_0_2px_black]' : 'shadow-none',
        color
      )}
      onClick={onClick}
    />
  )
}
