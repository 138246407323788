import { CSSProperties, useMemo } from 'react'
import { Section } from '@st/util/section'
import { clsx } from 'clsx'

const UNSELECTED = '$$UNSELECTED$$'

type Props<S, T> = {
  value?: string
  className?: string
  style?: CSSProperties
  placeholder?: string
  disabled?: boolean
  onChange?: (value: string | undefined) => void
  sections: Section<S, T>[]
  buildHeader: (section: Section<S, T>) => string
  buildValue: (opt: T) => string
  buildLabel: (opt: T) => string
}

export function GroupedSelect<S, T>({
  value,
  className,
  style,
  sections,
  placeholder,
  onChange,
  buildHeader,
  buildValue,
  buildLabel,
  disabled
}: Props<S, T>) {
  const optionsComponents = useMemo(() => {
    return [
      ...(placeholder ? [<option key={''} value={UNSELECTED} label={placeholder} />] : []),
      ...sections.map((section, sectionIndex) => (
        <optgroup label={buildHeader(section)} key={sectionIndex}>
          {section.items.map((item, itemIndex) => (
            <option key={itemIndex} value={buildValue(item)}>
              {buildLabel(item)}
            </option>
          ))}
        </optgroup>
      ))
    ]
  }, [sections, placeholder, buildHeader, buildValue, buildLabel])

  const valueSelected = sections.some((section) =>
    section.items.some((item) => buildValue(item) === value)
  )

  return (
    <select
      className={clsx(
        'block rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
        {
          'cursor-not-allowed opacity-50': disabled,
          'text-gray-500': !valueSelected
        },
        className
      )}
      style={style}
      disabled={disabled}
      value={value === undefined ? UNSELECTED : value}
      onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
        onChange?.(e.target.value === UNSELECTED ? undefined : e.target.value)
      }
    >
      {optionsComponents}
    </select>
  )
}
