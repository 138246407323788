export type ProcessMessage = { type: string }

export type ProcessName = string

export interface ProcessModule<
  State,
  Message extends ProcessMessage,
  InitArg,
  Send extends SendMap,
  Deps extends DepsMap = {}
> {
  name: string
  deps: Deps
  init: InitHandler<State, Message, Send, InitArg, Deps>
  dispose?: DisposeHandler<State, Message, Send, Deps>
  handle: MessageHandler<State, Message, Send, Deps>
}

export interface ProcessModuleSpec<
  State,
  Message extends ProcessMessage,
  InitArg,
  Send extends SendMap,
  Deps extends DepsMap = {}
> {
  name: string
  deps?: Deps
  init: InitHandler<State, Message, Send, InitArg, Deps>
  dispose?: DisposeHandler<State, Message, Send, Deps>
  handle: MessageHandler<State, Message, Send, Deps>
}

export type SendOpts = {
  context?: StoreContext
  from?: ProcessName
  send?: Send
}

export type Send = {
  <Message extends ProcessMessage>(
    processName: string,
    message: Message | Message[],
    opts?: SendOpts
  ): void
  <Ret>(processName: string, command: Command<any, any, any, Ret>, opts?: SendOpts): Ret
}

export type RefObject = any
export type Ref<T> = string & { __brand: T }

export interface Process<State, Message extends ProcessMessage> {
  getState: () => State
  send: ProcessSend<Message>
}

type SendSignature<SelfMessage extends ProcessMessage, Send extends SendMap> = SendProtocol<
  Send & { self: SelfMessage }
>

export type MessageContext = {
  from: ProcessName | undefined
  send: Send
  createRef: <T>(obj: T, dispose: (obj: T) => void) => Ref<T>
  resolveRef: <T>(ref: Ref<T>) => T
}

export type InitHandler<
  State,
  Message extends ProcessMessage,
  Send extends SendMap,
  InitArg,
  Deps
> = (
  arg: InitArg,
  context: MessageContext,
  deps: Deps
) => State | [State, SendSignature<Message, Send>]

export type DisposeHandler<State, Message extends ProcessMessage, Send extends SendMap, Deps> = (
  state: State,
  context: MessageContext,
  deps: Deps
) => State | [State, SendSignature<Message, Send>]

export type MessageHandler<
  State,
  Message extends ProcessMessage,
  Send extends SendMap = {},
  Deps extends DepsMap = {}
> = (
  state: State,
  event: Message,
  context: MessageContext,
  deps: DepsState<Deps>
) => State | [State, SendProtocol<Send>]

export type StoreContext = Record<string, any>

type SendMap = Record<string, ProcessMessage | ProcessMessage[]>

export type DepsMap = Record<string, ProcessModule<any, any, any, any>>

export type SendProtocol<Send extends SendMap> = Partial<{
  [K in keyof Send]: Send[K] | Send[K][]
}>

export type DepsState<Deps extends DepsMap> = {
  [K in keyof Deps]: Deps[K] extends ProcessModule<infer State, any, any, any> ? State : never
}

export function defineModule<
  State,
  Message extends ProcessMessage,
  InitArg = undefined,
  Send extends SendMap = {},
  Deps extends DepsMap = {}
>(
  spec: ProcessModuleSpec<State, Message, InitArg, Send, Deps>
): ProcessModule<State, Message, InitArg, Send, Deps> {
  return {
    ...spec,
    deps: spec.deps || ({} as Deps)
  }
}

export type ProcessSend<Message extends ProcessMessage> = {
  (message: Message | Message[]): void
  <Ret>(command: Command<any, any, Ret, any>): Ret
}

/**
 * A function that, when called with getState, dispatch, and context (deps)
 * will actually run the command
 */
export type Command<State, Message extends ProcessMessage, Ret, Context> = ((
  process: Process<State, Message>,
  deps: Context
) => Ret) & { moduleName: string }

type CommandHandler<State, Message extends ProcessMessage, Arg, ReturnType, Context> = (
  process: Process<State, Message>,
  arg: Arg,
  deps: Context
) => ReturnType

export function defineTask<
  State,
  Message extends ProcessMessage,
  TaskArg = undefined,
  TaskReturn = undefined,
  Context = undefined,
  Send extends SendMap = {}
>(
  module: ProcessModule<State, Message, any, Send>,
  handler: CommandHandler<State, Message, TaskArg, TaskReturn, Context>
): (arg: TaskArg) => Command<State, Message, TaskReturn, Context> {
  return function commandCreator(arg: TaskArg) {
    function command(process: Process<State, Message>, context: Context) {
      return handler(process, arg, context)
    }

    command.moduleName = module.name

    return command
  }
}
