import { useAppDeps } from '@features/app-deps-provider'
import { selFoldersById, stFolderListModule } from '@features/st-folder-list/st-folder-list-module'
import { formatFolderEntityName } from '@features/st-folder-viewer/st-folder-module'
import { STEditTemplateInlineWizardPage } from '@features/st-questionnaire-send/st-edit-template-inline-wizard-page'
import { useProcessState } from '@st/redux'
import { Folder } from '@st/sdk'
import { Button, CheckIcon, TagChip, Wizard } from '@st/theme'
import { inflect } from '@st/util/inflect'
import { clsx } from 'clsx'
import { useState } from 'react'
import { match } from 'ts-pattern'

type BulkEnrollStatus = 'editTemplate' | 'previewRecipients' | 'sending' | 'sent'

export function STEnrollInRemindersPage({
  folderIds,
  onClose
}: {
  folderIds: string[]
  onClose: () => void
}) {
  const { sdk } = useAppDeps()

  const organizationId = useProcessState(stFolderListModule, (s) => s.organizationId)
  const selectedFolders = useProcessState(stFolderListModule, (s) => selFoldersById(s, folderIds))
  const questionnaireCount = selectedFolders.length

  const [status, setStatus] = useState<BulkEnrollStatus>('editTemplate')

  async function onClickSend() {
    setStatus('sending')
    await sdk.send({
      type: 'folders/bulkEnrollQuestionnairesInReminders',
      organizationId,
      folderIds
    })
    setStatus('sent')
  }

  if (status == 'editTemplate') {
    return (
      <STEditTemplateInlineWizardPage
        title="Enroll Clients in Automated Reminders"
        subtitle="If you enroll a client for automated reminders, StanfordTax will send them the following reminder every Monday morning until they submit their questionnaire."
        templateType="folder_remind"
        onClose={onClose}
        onClickBack={onClose}
        onClickContinue={() => setStatus('previewRecipients')}
      />
    )
  }

  return (
    <Wizard.Scaffold
      onClose={onClose}
      bottomNav={
        <Wizard.BottomNav>
          <Button variant="default" onClick={onClose}>
            Back
          </Button>
          {match(status)
            .with('previewRecipients', () => (
              <Button variant="primary" onClick={onClickSend}>
                Enroll {questionnaireCount} {inflect(questionnaireCount, 'client')}
              </Button>
            ))
            .with('sending', () => (
              <Button variant="primary" disabled={true}>
                Enrolling...
              </Button>
            ))
            .with('sent', () => {
              return (
                <Button
                  variant="primary"
                  onClick={onClose}
                  leadingIcon={<CheckIcon className="h-4 w-4" />}
                >
                  Done
                </Button>
              )
            })
            .run()}
        </Wizard.BottomNav>
      }
    >
      <Wizard.Main
        variant="wide"
        title={
          status == 'sent'
            ? 'Successfully enrolled clients in reminders!'
            : 'Enroll clients in automated reminders'
        }
        subtitle={
          status == 'sent'
            ? 'You successfully enrolled the clients in reminders'
            : `You're about to enroll ${questionnaireCount} ${inflect(
                questionnaireCount,
                'client'
              )} in automated reminders.`
        }
      >
        <STBulkEnrollTable folders={selectedFolders} />
      </Wizard.Main>
    </Wizard.Scaffold>
  )
}

function STBulkEnrollTable({ folders }: { folders: Folder[] }) {
  return (
    <table className="min-w-full table-fixed divide-y divide-gray-300">
      <thead>
        <tr className="h-9">
          <th scope="col" className="pl-8 text-left text-xs uppercase text-gray-600">
            Client
          </th>
          <th scope="col" className="text-left text-xs uppercase text-gray-600">
            Email
          </th>
          <th scope="col" className="pr-8 text-left text-xs uppercase text-gray-600">
            {/* Send Status */}
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {folders.map((folder, index) => (
          <tr key={folder.id} className={clsx('h-12')}>
            <td className="whitespace-nowrap pl-8">
              <div className="flex flex-row items-center gap-2">
                <div className="text-base font-semibold text-gray-900">
                  {formatFolderEntityName(folder.entities)}
                </div>
              </div>
            </td>
            <td className="whitespace-nowrap text-base text-gray-500">
              {folder.memberships.map((u) => u.user.email).join(', ')}
            </td>
            <td className="whitespace-nowrap text-base text-gray-500">
              <div className="flex flex-row items-center">
                {folder.questionnaireEnrolledInReminders && (
                  <TagChip color="green">Enrolled</TagChip>
                )}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}
