import { Rect, Anchor, anchored, Point, move, Offset } from './index'

export type ViewportGeometry = {
  viewportRect: Rect
  childRect: Rect
  scrollAreaRect: Rect
}

type GetScrollTransform = {
  geom: ViewportGeometry
  viewportAnchor: Anchor
  childAnchor: Anchor
}

/**
 * When you have a viewport (for example a PDF viewer) within a scrollable area
 * and you want to bring a child element into view, there's a certain transform (dx, dy)
 * that needs to happen.
 *
 * For example, you might want to center element of the PDF within the viewport.
 *
 * Given the rectangles of the viewport, the entire scroll area, and the element,
 * we can calculate the translation necessary to scroll there.
 *
 * In the browser, we deal with scrollTop and scrollLeft, so {@link getTargetScrollPosition}
 * may a more convenient way to access this.
 *
 * @returns
 *  An offset (dx, dy) of how much we need to translate the viewport by in order to achieve
 *  the desired anchoring.
 */
export function getViewportTransform({
  geom,
  viewportAnchor,
  childAnchor
}: GetScrollTransform): Offset {
  // The desired viewport to achieve the anchoring necessary
  // for example we may want the center the child to be in the center
  // of the viewport
  const targetViewportRect = anchored({
    follower: geom.viewportRect,
    followerAnchor: viewportAnchor,
    target: geom.childRect,
    targetAnchor: childAnchor
  })

  // Based on the target viewport and the current viewport,
  // we can easily calculate the translatio required to go from the current
  // viewport to the desired viewport
  return {
    dx: targetViewportRect.x - geom.viewportRect.x,
    dy: targetViewportRect.y - geom.viewportRect.y
  }
}

type GetScrollPosition = {
  geom: ViewportGeometry
  viewportAnchor: Anchor
  childAnchor: Anchor
}
export function getTargetScrollPosition({
  geom,
  viewportAnchor,
  childAnchor
}: GetScrollPosition): Point {
  const scrollPosition: Point = {
    x: geom.viewportRect.x - geom.scrollAreaRect.x,
    y: geom.viewportRect.y - geom.scrollAreaRect.y
  }

  return move(scrollPosition, getViewportTransform({ geom, viewportAnchor, childAnchor }))
}
