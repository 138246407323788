import { TipConfig } from '@st/folder/ui-config'
import { Tooltip } from '@theme'
import { Callout, TooltipIcon } from '@theme'

type Props = {
  tip?: TipConfig | null
  children?: string
}
export function WizardTip({ tip, children = '?' }: Props) {
  if (!tip) {
    return null
  }
  return (
    <Tooltip tip={<Callout title={tip.title}>{tip.body}</Callout>}>
      <TooltipIcon>{children}</TooltipIcon>
    </Tooltip>
  )
}
