const PDF_ESCAPE_CHARS = ['(', ')']

export function sanitizePDFText(text: string, charSet: number[]) {
  const characters = [...text]
  return characters
    .map((char) => {
      if (char == '\n') {
        // all good for new line is a valid character
        return char
      } else if (PDF_ESCAPE_CHARS.includes(char) || char == '\\') {
        // an escapable char or the escape (\) itself shoudl be escaped
        return '\\' + char
      }

      const code = char.codePointAt(0)
      if (!code) return ''
      if (charSet.includes(code) == false) return ''

      return char
    })
    .join('')
}
