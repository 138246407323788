import {
  Anchor,
  LinearTransform,
  Point,
  Rect,
  atAnchor,
  move,
  otherSide,
  rectFromPoints
} from '@st/util/geom'
import {
  Dispatch,
  PointerEvent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useRef
} from 'react'
import { Annot, AnnotationMessage, Page } from './module'

type DragPointerEvent = {
  point: Point
  originalEvent: PointerEvent
}

type DragState = { status: 'idle' } | { status: 'dragging'; start: DragPointerEvent }

type ResizeEvent = MoveEvent & { anchor: Anchor }

export type MoveEventHandler = (e: MoveEvent) => void
export type ResizeEventHandler = (e: ResizeEvent) => void

type DrawingCanvasContext = { zoom: number; page: Page }
const DrawingCanvasContext = createContext<DrawingCanvasContext>({
  page: { index: 0, size: { width: 0, height: 0 } },
  zoom: 1
})

export const DrawingCanvasProvider = DrawingCanvasContext.Provider

export function useDrawContext() {
  return useContext(DrawingCanvasContext)
}

type MoveEvent =
  | {
      type: 'start'
      start: DragPointerEvent
    }
  | {
      type: 'move'
      start: DragPointerEvent
      current: DragPointerEvent
    }
  | {
      type: 'end'
      start: DragPointerEvent
      current: DragPointerEvent
    }

type MoveableOpts = {
  onMove: (e: MoveEvent) => void
  canvasClass: string
}
export function useMoveable({ onMove, canvasClass }: MoveableOpts) {
  const ref = useRef<HTMLDivElement>(null)
  const isDraggingRef = useRef<DragState>({ status: 'idle' })
  const { zoom } = useDrawContext()

  const onPointerDown = useCallback(
    (e: PointerEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const event = toDragPointerEvent(e, zoom, canvasClass)

      isDraggingRef.current = { status: 'dragging', start: event }
      ref.current?.setPointerCapture(e.pointerId)
      onMove({ type: 'start', start: event })
    },
    [onMove, zoom]
  )

  const onPointerMove = useCallback(
    (e: PointerEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const state = isDraggingRef.current

      if (state.status != 'dragging') {
        return
      }

      onMove({
        type: 'move',
        start: state.start,
        current: toDragPointerEvent(e, zoom, canvasClass)
      })
    },
    [onMove, zoom]
  )

  const onPointerUp = useCallback(
    (e: PointerEvent) => {
      e.preventDefault()
      e.stopPropagation()

      const state = isDraggingRef.current

      isDraggingRef.current = { status: 'idle' }
      ref.current?.releasePointerCapture(e.pointerId)

      if (state.status != 'dragging') {
        return
      }

      onMove({
        type: 'end',
        start: state.start,
        current: toDragPointerEvent(e, zoom, canvasClass)
      })
    },
    [onMove]
  )

  return { ref, onPointerDown, onPointerMove, onPointerUp }
}

type ElementContainerProps = {
  rect: Rect
  onClick?: () => void
  onDoubleClick?: () => void
  selectionBorder?: boolean
  children: ReactNode
}
export function ElementContainer({
  rect,
  children,
  onClick,
  onDoubleClick,
  selectionBorder
}: ElementContainerProps) {
  return (
    <div
      onClick={
        onClick
          ? (e) => {
              e.stopPropagation()
              e.preventDefault()

              onClick()
            }
          : undefined
      }
      onDoubleClick={
        onDoubleClick
          ? (e) => {
              e.stopPropagation()
              e.preventDefault()

              onDoubleClick()
            }
          : undefined
      }
      style={{
        position: 'absolute',
        boxSizing: 'border-box',
        top: rect.y,
        left: rect.x,
        width: rect.width,
        height: rect.height,
        cursor: 'pointer',
        outline: selectionBorder ? '1px solid #3182EC' : undefined
      }}
    >
      {children}
    </div>
  )
}

/**
 * For editable elements expose onMove and onResize handlers.
 * Passing these into
 *
 * @param annot
 * @param send
 * @returns
 */
export function useUpdateBounds(annot: Annot, send: Dispatch<AnnotationMessage>) {
  const onMoveStartBounds = useRef<Rect | null>(null)
  const onMove: MoveEventHandler = (e) => {
    console.log('onMove', e)

    switch (e.type) {
      case 'start':
        onMoveStartBounds.current = annot.bounds
        send({
          type: 'annotDraftResize',
          annotId: annot.id,
          phase: 'start',
          bounds: annot.bounds
        })
        break
      case 'move':
      case 'end':
        const baseRect = onMoveStartBounds.current!
        send({
          type: 'annotDraftMove',
          phase: e.type == 'move' ? 'continue' : 'commit',
          annotId: annot.id,
          bounds: move(baseRect, {
            dx: e.current.point.x - e.start.point.x,
            dy: e.current.point.y - e.start.point.y
          })
        })
        break
    }
  }

  const onResizeStartBounds = useRef<Rect | null>(null)
  const onResize: ResizeEventHandler = useCallback(
    (e) => {
      switch (e.type) {
        case 'start':
          onResizeStartBounds.current = annot.bounds
          send({
            type: 'annotDraftResize',
            phase: 'start',
            annotId: annot.id,
            bounds: annot.bounds
          })
          break
        case 'move':
        case 'end':
          const baseRect = onResizeStartBounds.current!
          const startPoint = atAnchor(baseRect, e.anchor)
          const desiredPoint = move(startPoint, {
            dx: e.current.point.x - e.start.point.x,
            dy: e.current.point.y - e.start.point.y
          })
          send({
            type: 'annotDraftResize',
            phase: e.type == 'move' ? 'continue' : 'commit',
            annotId: annot.id,
            bounds: moveControlPoint(baseRect, e.anchor, desiredPoint)
          })
          break
      }
    },
    [annot.bounds]
  )

  return { onMove, onResize }
}

/**
 * Given a rectangle, move a control point represented by an anchor to a new point keeping
 * the opposite points in place while maintaining the constraints of the rectangle.
 *
 * For example, if you take take {@link Anchor.topLeft} point and move it to a differnet place,
 * the x, y, width, and height would update with the bottom-right corner remains in place.
 *
 * @param rect
 * @param anchor
 * @param newPoint
 * @returns
 */
function moveControlPoint(rect: Rect, anchor: Anchor, newPoint: Point): Rect {
  return rectFromPoints(atAnchor(rect, otherSide(anchor)), newPoint)
}

type SelectedElementContainerProps = {
  rect: Rect
  children: ReactNode
  onMove: MoveEventHandler
  onResize?: ResizeEventHandler
  onDoubleClick?: () => void
  selectionBorder?: boolean
  resizeHandles?: boolean
  toolbar?: ReactNode
}
export function SelectedElementContainer({
  rect,
  children,
  onMove,
  onResize = () => null,
  onDoubleClick,
  selectionBorder = true,
  resizeHandles = false,
  toolbar
}: SelectedElementContainerProps) {
  const { ref, onPointerDown, onPointerMove, onPointerUp } = useMoveable({
    canvasClass: 'drawing-canvas',
    onMove
  })

  return (
    <div
      ref={ref}
      onDoubleClick={onDoubleClick}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      style={{
        position: 'absolute',
        boxSizing: 'border-box',
        top: rect.y,
        left: rect.x,
        width: rect.width,
        height: rect.height,
        cursor: 'move',
        outline: selectionBorder ? '1px solid #3182EC' : undefined
      }}
    >
      {resizeHandles ? <ResizeHandle anchor={Anchor.topLeft} onResize={onResize} /> : null}
      {resizeHandles ? <ResizeHandle anchor={Anchor.topRight} onResize={onResize} /> : null}
      {resizeHandles ? <ResizeHandle anchor={Anchor.bottomLeft} onResize={onResize} /> : null}
      {resizeHandles ? <ResizeHandle anchor={Anchor.bottomRight} onResize={onResize} /> : null}

      {toolbar}

      {children}
    </div>
  )
}

type ResizeHandleProps = {
  anchor: Anchor
  onResize: ResizeEventHandler
}
function ResizeHandle({ anchor, onResize }: ResizeHandleProps) {
  const onMove: MoveEventHandler = useCallback(
    (e) => onResize({ ...e, anchor }),
    [onResize, anchor]
  )

  const { ref, onPointerDown, onPointerMove, onPointerUp } = useMoveable({
    canvasClass: 'drawing-canvas',
    onMove
  })

  const point = atAnchor({ x: 0, y: 0, width: 100, height: 100 }, anchor)
  const size = 8
  const offset = 2

  return (
    <div
      ref={ref}
      onPointerDown={onPointerDown}
      onPointerMove={onPointerMove}
      onPointerUp={onPointerUp}
      style={{
        color: '#3182EC',
        border: '1px solid #3182EC',
        boxSizing: 'border-box',
        background: 'white',
        width: size,
        height: size,
        position: 'absolute',
        left: point.x == 0 ? `${-offset}px` : `calc(${point.x}% - ${size - offset}px)`,
        top: point.y == 0 ? `${-offset}px` : `calc(${point.y}% - ${size - offset}px)`,
        zIndex: 100,
        cursor: resolveResizeCursor(anchor)
      }}
    />
  )
}

function resolveResizeCursor(anchor: Anchor) {
  if (anchor == Anchor.topLeft || anchor == Anchor.bottomRight) {
    return 'nwse-resize'
  } else if (anchor == Anchor.topRight || anchor == Anchor.bottomLeft) {
    return 'nesw-resize'
  }
  return undefined
}

/**
 * Convert a raw PointerEvent to a special event which contains a
 * point transformed based on the zoom level.
 *
 * The element will get scaled by an amount but will not
 *
 * @param e
 * @param zoom
 * @param canvasClass
 * @returns
 */
export function toDragPointerEvent(
  e: PointerEvent,
  zoom: number,
  canvasClass: string
): DragPointerEvent {
  const target = e.target as HTMLElement

  // We want this coordinate system to be relative to the canvas element itself
  // If you are clicking on some empty space in the canvas, this will be e.target
  // If you are clicking on a nested element, for example a resize handle, you want to get the
  // closest ancestor
  const parent = target.classList.contains(canvasClass) ? target : target.closest(`.${canvasClass}`)
  const rect = parent!.getBoundingClientRect()

  const rawPointOnCanvas: Point = {
    x: e.clientX - rect.left,
    y: e.clientY - rect.top
  }

  // The drawing canvas may have some transformations applied
  // This is implemented using the css transform(...) property (which are linear transformations)
  // For example it could might include scaling (zoom) and rotation
  //
  // However, mouse/pointer events are not transformed when applying the css transform
  // For example, if you are zoomed into a document at 200% and you click at (x: 50, y: 100)
  // you really want to know that this was (x: 25, y: 50) in the original document.
  //
  // To work around this, we can take the raw (x, y) coordinate and apply the inverse transform
  // to get the logical coordinate from the original canvas
  const canvasTransform = LinearTransform.scale(zoom)
  const transform = LinearTransform.invert(canvasTransform)
  const point = LinearTransform.apply(rawPointOnCanvas, transform)

  return { point, originalEvent: e }
}
