type WithoutValues<T, V> = {
  [P in keyof T]: Exclude<T[P], V>
}

/**
 * Removes all properties from an object that have a specific value.
 * @param obj - The object to remove properties from.
 * @param value - The value to remove properties with.
 * @returns A new object with all properties that have the specified value removed.
 *
 * @example
 * const obj = { a: 1, b: 2, c: 1 };
 * const newObj = removeValues(obj, 1);
 * // newObj is { b: 2 }
 */
export function removeValues<T extends Record<string, any>, V>(
  obj: T,
  value: V
): WithoutValues<T, V> {
  const newObj: WithoutValues<T, V> = { ...obj }
  for (var k of Object.keys(newObj)) {
    if (newObj[k] === value) {
      delete newObj[k]
    }
  }
  return newObj
}

export function removeKey<K extends keyof T, T>(obj: T, key: K): Omit<T, K> {
  let copy = { ...obj }
  delete copy[key]
  return copy
}

/**
 * Takes an object and an array of key patterns, and returns a new object containing only the keys that match the given patterns.
 *
 * Patterns can include wildcards represented by an asterisk (*) to match any sequence of characters.
 * For example, the pattern "dependents.0.*" will match any keys that start with "dependents.0.".
 *
 * @param obj The object from which to take the keys.
 * @param patterns An array of string patterns, possibly including wildcards, to match against the keys of the object.
 * @returns A new object containing only the keys that match the given patterns.
 *
 * @example
 * const obj = {
 *   'dependents.0.name': 'John',
 *   'dependents.0.age': 30,
 *   'dependents.1.name': 'Jane',
 * };
 * const result = take(obj, ['dependents.0.*']);
 * console.log(result); // { 'dependents.0.name': 'John', 'dependents.0.age': 30 }
 */
export function take<T extends Record<string, any>>(
  obj: T,
  patterns: string[]
): Record<string, any> {
  const newObj: Record<string, any> = {}

  for (const pattern of patterns) {
    for (const key of expandKeys(obj, pattern)) {
      newObj[key] = obj[key]
    }
  }

  return newObj

  function escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  function expandKeys(obj: T, pattern: string) {
    if (!pattern.includes('*') && obj.hasOwnProperty(pattern)) {
      return [pattern]
    }

    const matches = toMatcher(pattern)
    return Object.keys(obj).filter(matches)
  }

  function toMatcher(pattern: string) {
    const escapedPattern = escapeRegExp(pattern).replace(/\\\*/g, '.*')
    const regex = new RegExp('^' + escapedPattern + '$')
    return (key: string) => regex.test(key)
  }
}

export function transform<T, U>(
  obj: T,
  mapper: (key: keyof T, value: T[keyof T]) => U
): { [K in keyof T]: U } {
  const result: Partial<{ [K in keyof T]: U }> = {}

  for (const key in obj) {
    if (obj!.hasOwnProperty(key)) {
      result[key as keyof T] = mapper(key as keyof T, obj[key])
    }
  }

  return result as { [K in keyof T]: U }
}

export function toConstMap<K extends string | number | symbol, V>(
  keys: K[],
  value: V
): Record<K, V> {
  return Object.fromEntries(
    keys.map((id) => {
      return [id, value] as [K, V]
    })
  ) as Record<K, V>
}
