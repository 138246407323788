import { SDKMessage, SDKResponse } from '@features/sdk-module'
import { defineModule } from '@st/redux'
import { Organization } from '@st/sdk'

type OrganizationState = {
  status: 'loading' | 'loaded'
  organizationSlug: string
  organization: Organization | undefined
}

export type LoadOrganization = { type: 'reloadOrganization' }

export type OrganizationMessage = LoadOrganization | SDKResponse

type OrganizationSend = {
  sdk: SDKMessage
}

type OrganizationInit = {
  organizationSlug: string
}

export const organizationModule = defineModule<
  OrganizationState,
  OrganizationMessage,
  OrganizationInit,
  OrganizationSend
>({
  name: 'organization',
  init: ({ organizationSlug }) => {
    return [
      {
        status: 'loading',
        organizationSlug,
        organization: undefined
      },
      {
        sdk: {
          type: 'request',
          request: {
            type: 'organizations/getOrganizationBySlug',
            organizationSlug
          }
        }
      }
    ]
  },
  handle: (state, message) => {
    switch (message.type) {
      case 'reloadOrganization':
        return [
          {
            ...state,
            status: 'loading',
            organization: undefined
          },
          {
            sdk: {
              type: 'request',
              request: {
                type: 'organizations/getOrganizationBySlug',
                organizationSlug: state.organizationSlug
              }
            }
          }
        ]
      case 'response':
        if (message.operation.type === 'organizations/getOrganizationBySlug') {
          return {
            ...state,
            status: 'loaded',
            organization: message.operation.response
          }
        }
      default:
        return state
    }
  }
})
