import { clsx } from 'clsx'
import { ReactNode } from 'react'

type Props = {
  className?: string
  variant: 'success' | 'warning' | 'error'
  children: ReactNode
}
export function QuestionnaireBanner({ className, variant, children }: Props) {
  return (
    <div className="relative h-0 overflow-visible">
      <div
        className={clsx(
          'absolute left-0 right-0 top-0 z-20 flex h-12 items-center justify-center gap-x-6 px-6 text-center',
          variant == 'success' && 'bg-green-300 text-green-800',
          variant == 'warning' && 'bg-yellow-300 text-yellow-900',
          variant == 'error' && 'bg-red-400 text-white',
          className
        )}
      >
        {children}
      </div>
    </div>
  )
}
