/**
 * Enum for PDF Page Modes.
 * Defines how a PDF document should be displayed when opened.
 */
export enum PDFPageMode {
  /**
   * No panel is displayed automatically when the document is opened.
   */
  UseNone = 'UseNone',

  /**
   * The document's outlines (bookmarks) are displayed in a panel on the side.
   */
  UseOutlines = 'UseOutlines',

  /**
   * The page thumbnails are displayed in a panel on the side.
   */
  UseThumbs = 'UseThumbs',

  /**
   * The document is opened in full-screen mode, usually with navigation controls hidden.
   * This mode is intended for presentations.
   */
  FullScreen = 'FullScreen',

  /**
   * The Optional Content Group (OCG) panel is displayed,
   * used for documents that have layers (optional content) that can be turned on or off.
   */
  UseOC = 'UseOC',

  /**
   * The attachments panel is opened automatically when the document is opened.
   * This is useful for documents that include embedded files.
   */
  UseAttachments = 'UseAttachments'
}
