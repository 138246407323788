import { ComponentType, ReactNode } from 'react'
import { CloseIcon } from './icons'

export function BatchActionToolbar({
  selectionMessage,
  onClearSelection,
  children
}: {
  selectionMessage?: string
  onClearSelection?: () => void
  children?: ReactNode
}) {
  return (
    <div className="flex h-14 w-full bg-gray-900 px-16 text-white">
      <div className="flex min-w-[800px] flex-row items-center">
        <div className="flex flex-row items-center">
          <button className="flex cursor-pointer items-center gap-x-2" onClick={onClearSelection}>
            <CloseIcon className="h-4 w-4" />
            Clear selection
          </button>

          <div className="ml-6 text-gray-300">{selectionMessage}</div>
        </div>

        <div className="ml-16 flex flex-row gap-6">{children}</div>
      </div>
    </div>
  )
}

function Button({
  icon: Icon,
  children,
  onClick
}: {
  icon?: ComponentType<{ className: string }>
  children: ReactNode
  onClick?: () => void
}) {
  return (
    <button
      className="flex items-center gap-x-2 rounded-sm px-2 py-1 hover:ring-1 hover:ring-white"
      onClick={onClick}
    >
      {Icon && <Icon className="h-4 w-4" />}
      {children}
    </button>
  )
}

BatchActionToolbar.Button = Button
