import { Result, err, getOrThrow, ok } from '@st/util/result'
import { keyBy } from '../array'
import { ISO8601Time } from '../time'
import { URLProtocol, createURL, fetchURL, getURLProtocol } from './url'

export type Entry = Directory | XFile

export type Directory = {
  type: 'directory'
  name: string
  entries: Record<string, Entry>
}

/**
 * An cross-platform file representation that abstracts away where the data is fetched from
 */
export type XFile = {
  type: 'file'

  mimeType?: string

  /**
   * The file name
   */
  name: string

  /**
   * The uniform resource identifier of the file. For example:
   *  - Inline data url: data:image/png;base64,iVBORw0KGg...
   *  - In-memory object url: object:...
   *  - File stored in cloud storage: storage://organizations/xyz/folders/234/1099.pdf
   *
   * How we resolve the uri to a Blob will depend on the protocol specified
   * Some are built-in to the browser such as the data url
   * Others such as storage:// are implemented by us
   */
  uri: string

  /**
   * The file size in byte of this file.
   * Will not be present if the file size is unknown
   */
  size?: number

  /**
   * When the file was last modified (optional meta-data)
   */
  lastModified?: ISO8601Time

  /**
   * A git-compatible sha1 hash of the file.
   * Includes the `object ` prefix and is null terminated.
   * See computeFileHash for how it is computed
   */
  sha1?: string
}

export type InlineXFile = XFile & {
  /**
   * As an optimization, we can sometimes store the raw blob of data
   * directly inside of the object.
   */
  data?: Blob | ReadableStream<Uint8Array> | ArrayBuffer
}

export function isDirectory(entry: Entry): entry is Directory {
  return entry.type == 'directory'
}

export function isFile(entry: Entry): entry is XFile {
  return entry.type == 'file'
}

function isDataFile(file: XFile): file is InlineXFile {
  return 'data' in file && file.data !== undefined
}

export type FetchFile = (dataFile: XFile) => Promise<Response>

export function fetchFile(xfile: XFile): Promise<Response> {
  if (isDataFile(xfile)) {
    const headers = new Headers()
    if (xfile.mimeType) {
      headers.set('Content-Type', xfile.mimeType)
    }
    if (xfile.size) {
      headers.set('Content-Length', xfile.size.toString())
    }
    return Promise.resolve(
      new Response(xfile.data!, {
        status: 200,
        headers: headers
      })
    )
  }
  return fetchURL(xfile.uri)
}

type CastOpts = {
  fetch: (file: XFile) => Promise<Response>
}

export type CastProtocolError = { type: 'fetchFailed'; file: XFile }

export async function castFileProtocol(
  file: XFile,
  targetProtocol: URLProtocol,
  opts?: CastOpts
): Promise<Result<XFile, CastProtocolError>> {
  const fetch = opts?.fetch ?? ((file: XFile) => fetchURL(file.uri))

  const currentProtocol = getURLProtocol(file.uri)
  // no casting needed
  if (currentProtocol == targetProtocol) {
    return ok(file)
  }

  const response = await fetch(file)

  if (!response.ok) {
    return err({ type: 'fetchFailed', file })
  }

  const blob = await response.blob()

  const castedUrl = await createURL(targetProtocol, blob)

  const castedFile: XFile = {
    type: 'file',
    mimeType: blob.type,
    uri: castedUrl,
    name: file.name,
    lastModified: file.lastModified,
    size: blob.size
  }

  if (targetProtocol == 'content') {
    // optimization to avoid double work when calculating a hash
    castedFile.sha1 = castedFile.uri.substring('content:'.length)
  }

  castedFile.sha1 = file.sha1

  return ok(castedFile)
}

export async function castEntryProtocol(entry: Entry, protocol: URLProtocol): Promise<Entry> {
  return isFile(entry)
    ? castFileProtocol(entry, protocol).then(getOrThrow)
    : castDirectoryProtocol(entry, protocol)
}

export async function castDirectoryProtocol(entry: Entry, protocol: URLProtocol): Promise<Entry> {
  if (isFile(entry)) {
    return castFileProtocol(entry, protocol).then(getOrThrow)
  } else {
    return mapDirectory(entry, (entry) => {
      if (isFile(entry)) {
        return castFileProtocol(entry, protocol).then(getOrThrow)
      } else {
        return castDirectoryProtocol(entry, protocol)
      }
    })
  }
}

type EntryMapper = (entry: Entry) => Promise<Entry>
export async function mapDirectory(dir: Directory, func: EntryMapper): Promise<Directory> {
  const childEntries = Object.values(dir.entries)
  const mappedChildEntries = await Promise.all(
    childEntries.map(async (entry) => {
      if (isFile(entry)) {
        const mappedFile = await func(entry)

        return mappedFile
      } else {
        return mapDirectory(entry, func)
      }
    })
  )
  const mappedDir: Directory = {
    ...dir,
    entries: keyBy(mappedChildEntries, (e) => e.name)
  }

  return mappedDir
}

export function toUint8Array(response: Response | Blob) {
  return response.arrayBuffer().then((arrayBuffer) => new Uint8Array(arrayBuffer))
}

export function getSubdirectories(directory: Directory): Directory[] {
  const items: Directory[] = []
  for (const v of Object.values(directory.entries)) {
    if (v.type == 'directory') {
      items.push(v)
    }
  }
  return items
}

export type CurrentEntry<T extends Entry = Entry> = { value: T; path: string }

const ROOT = Symbol('ROOT')

type VisitEntryOpts = {
  callback: (cur: CurrentEntry) => void
  traverseChildren: (dir: Directory) => boolean
}
/**
 * Visit all of the entries in the directory
 * @param entry
 * @param opts
 * @param path
 */
export function visitEntries(
  entry: Entry,
  opts: VisitEntryOpts,
  path: string | typeof ROOT = ROOT
) {
  const nextPath = getNextPath(path, entry.name)

  if (isFile(entry)) {
    opts.callback({ path: nextPath, value: entry })
  } else if (isDirectory(entry) && opts.traverseChildren(entry)) {
    // we do not visit the root
    if (path !== ROOT) {
      opts.callback({ path: nextPath, value: entry })
    }

    for (const key in entry.entries) {
      visitEntries(entry.entries[key], opts, nextPath)
    }
  }

  function getNextPath(path: string | typeof ROOT, segment: string): string {
    // if we are at the root, we
    if (path === ROOT) return ''
    return path === '' ? segment : `${path}/${segment}`
  }
}

type IterateFilesOpts = {
  traverseChildren?: (dir: Directory) => boolean
}
export function iterateFiles(entry: Entry, opts?: IterateFilesOpts): CurrentEntry<XFile>[] {
  return iterateEntries(entry, {
    filter: isFile,
    traverseChildren: opts?.traverseChildren ?? (() => true)
  })
}

type IterateEntriesOpts<T extends Entry> = {
  filter: (e: Entry) => e is T
  traverseChildren?: (dir: Directory) => boolean
}
export function iterateEntries<T extends Entry>(
  entry: Entry,
  { filter, traverseChildren = () => true }: IterateEntriesOpts<T>
): CurrentEntry<T>[] {
  const currentEntries: CurrentEntry<T>[] = []
  visitEntries(entry, {
    callback: (cur) => {
      if (filter(cur.value)) {
        currentEntries.push(cur as CurrentEntry<T>)
      }
    },
    traverseChildren
  })
  return currentEntries
}

// todo: deprecate
export function getEntries<T extends Entry>(
  directory: Directory,
  filter: (e: Entry) => e is T
): T[] {
  return iterateEntries(directory, { filter }).map((cur) => cur.value)
}

export function entryAtPath(dir: Directory, path: string): Entry | undefined {
  const segments = path.split('/')
  let cur: Entry = dir
  for (const s of segments) {
    if (!cur) return undefined
    if (!isDirectory(cur)) return undefined
    cur = cur.entries[s]
  }
  return cur
}

export function entriesAtPaths(dir: Directory, paths: string[]): Entry[] {
  return paths.map((p) => {
    const entry = entryAtPath(dir, p)
    if (!entry) throw `Missing entry for ${p}`
    return entry
  })
}
