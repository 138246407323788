import { createSTClientSDK, STSDK } from '@st/sdk'
import { err, ok, Result } from '@st/util/result'
import {
  AuthClientAdapter,
  AuthError,
  AuthSignUpOpts,
  AuthStatusEvent,
  AuthStatusListener,
  User
} from './auth-client-adapter'
import { AuthState } from './auth-state'

const TOKEN_KEY = 'st:token'

// userId = d3MXN3iyA7b7INozl4JYZa16ouh2, email = vendiddy@gmail.com
export class STAuthClientAdapter implements AuthClientAdapter, Disposable {
  private sdk: STSDK

  constructor() {
    this.sdk = createSTClientSDK({
      baseUrl: process.env.NEXT_PUBLIC_API_V2_ENDPOINT!,
      getToken: async (): Promise<string | undefined> => {
        return localStorage.getItem(TOKEN_KEY) ?? undefined
      }
    })
    this._checkLoggedIn()
    window.addEventListener('storage', this.onStorageChange)
  }

  onStorageChange = (e: StorageEvent) => {
    if (e.key === TOKEN_KEY) {
      this._checkLoggedIn()
    }
  }

  private _onAuthStatusChanged: AuthStatusListener[] = []

  private _checkLoggedIn = async () => {
    const token = await this.getToken()
    if (!token) {
      this._loggedOut()
      return
    }

    const response = await this.sdk.fetch({ type: 'accounts/getCurrentUser' })

    if (response.status == 401) {
      localStorage.removeItem(TOKEN_KEY)
      this._loggedOut()
      return
    }

    const user = await response.json()
    this._loggedIn(user)
  }

  signUp = async ({
    email,
    name,
    password,
    passwordConfirm
  }: AuthSignUpOpts): Promise<Result<User, AuthError>> => {
    const res = await this.sdk.send({
      type: 'accounts/registerAccount',
      name,
      email,
      password
    })
    if (res.error) {
      return err(res.error)
    }

    localStorage.setItem(TOKEN_KEY, res.token)
    this._loggedIn(res.user)
    return ok(res.user)
  }

  loginWithEmailPassword = async (
    email: string,
    password: string
  ): Promise<Result<User, AuthError>> => {
    this._loggingIn()
    const res = await this.sdk.send({
      type: 'accounts/loginWithEmailPassword',
      email,
      password
    })
    if (res.error) {
      return err(res.error)
    }

    localStorage.setItem(TOKEN_KEY, res.token!)

    this._loggedIn(res.user)
    return ok(res.user)
  }

  loginWithMagicLink = async (magicLinkToken: string): Promise<Result<User, AuthError>> => {
    this._loggingIn()
    const res = await this.sdk.send({
      type: 'accounts/loginWithMagicLink',
      magicLinkToken: magicLinkToken
    })

    if (res.error) {
      return err(res.error)
    }

    localStorage.setItem(TOKEN_KEY, res.token!)
    this._loggedIn(res.user)
    return ok(res.user)
  }

  resetPassword = async (
    token: string,
    password: string,
    passwordConfirmation: string
  ): Promise<Result<void, AuthError>> => {
    // can handle this client-side - no need to make a network request
    if (password != passwordConfirmation) {
      return err({ type: 'password_confirmation_mismatch', message: 'Passwords do not match' })
    }

    const res = await this.sdk.send({
      type: 'accounts/resetPassword',
      resetPasswordToken: token,
      password,
      passwordConfirmation
    })

    if (res.error) {
      return err(res.error)
    }

    return ok(undefined)
  }

  logout = (): Promise<void> => {
    localStorage.removeItem(TOKEN_KEY)
    this._loggedOut()
    return Promise.resolve()
  }

  /**
   * Get the mock token stored in localStorage of the format mock:$userId:$email
   * stored at dev:token
   *
   * For example:
   * mock:d3MXN3iyA7b7INozl4JYZa16ouh2:vendiddy@gmail.com
   *
   * @returns
   */
  getToken = (): Promise<string | undefined> => {
    return Promise.resolve(localStorage.getItem('st:token') ?? undefined)
  }

  getUser = () => {
    return this._state.user
  }

  subscribeToAuthStatus = (listener: AuthStatusListener) => {
    this._onAuthStatusChanged.push(listener)

    // we trigger this on the first subscription to make sure the listener
    // is notified of the current auth state
    if (this._state.status === 'loggedIn') {
      this._notifyListeners({ type: 'loggedIn', user: this._state.user! })
    } else if (this._state.status === 'loggedOut') {
      this._notifyListeners({ type: 'loggedOut' })
    }

    return () => {
      this._onAuthStatusChanged.splice(this._onAuthStatusChanged.indexOf(listener), 1)
    }
  }

  private _state: AuthState = { status: 'unknown', loginState: { status: 'idle' } }

  _loggingIn = () => {
    this._state = { status: 'loggingIn', loginState: { status: 'idle' } }
  }

  _loggedIn = (user: User) => {
    this._state = { status: 'loggedIn', user, loginState: { status: 'idle' } }
    this._notifyListeners({ type: 'loggedIn', user })
  }

  _loggedOut = () => {
    this._state = { status: 'loggedOut', loginState: { status: 'idle' } }
    this._notifyListeners({ type: 'loggedOut' })
  }

  _notifyListeners = (event: AuthStatusEvent) => {
    for (const l of this._onAuthStatusChanged) l(event)
  };

  [Symbol.dispose](): void {}
}
