type Fn = () => Promise<any>

type Limiter = <T>(limit: () => Promise<T>) => Promise<T>

// ripped from the pLimit library

export default function asyncLimit(concurrency: number): Limiter {
  if (concurrency <= 0) {
    throw new TypeError('Expected `concurrency` to be a number from 1 and up')
  }

  const queue = new Queue<() => void>()
  let activeCount = 0

  const next = () => {
    activeCount--

    if (queue.size > 0) {
      queue.dequeue()!()
    }
  }

  const run = async (fn: Fn, resolve: (value: any) => void) => {
    activeCount++

    const result = (async () => fn())()

    resolve(result)

    try {
      await result
    } catch {}

    next()
  }

  const enqueue = (fn: Fn, resolve: (value: any) => void) => {
    queue.enqueue(run.bind(undefined, fn, resolve))
    ;(async () => {
      await Promise.resolve()

      if (activeCount < concurrency && queue.size > 0) {
        queue.dequeue()!()
      }
    })()
  }

  const generator = (fn: Fn) =>
    new Promise<any>((resolve) => {
      enqueue(fn, resolve)
    })

  Object.defineProperties(generator, {
    activeCount: {
      get: () => activeCount
    },
    pendingCount: {
      get: () => queue.size
    },
    clearQueue: {
      value: () => {
        queue.clear()
      }
    }
  })

  return generator
}

type Node<T> = {
  value: T
  next?: Node<T>
}

class Queue<T> {
  private head: Node<T> | undefined
  private tail: Node<T> | undefined
  private _size: number = 0

  constructor() {
    this.clear()
  }

  enqueue(value: T): void {
    const node: Node<T> = { value }

    if (this.head) {
      this.tail!.next = node
      this.tail = node
    } else {
      this.head = node
      this.tail = node
    }

    this._size++
  }

  dequeue(): T | undefined {
    const current = this.head
    if (!current) {
      return
    }

    this.head = this.head?.next
    this._size--
    return current.value
  }

  clear(): void {
    this.head = undefined
    this.tail = undefined
    this._size = 0
  }

  get size(): number {
    return this._size
  }

  *[Symbol.iterator](): Iterator<T> {
    let current = this.head

    while (current) {
      yield current.value
      current = current.next
    }
  }
}
