import type { SDKMessage } from '@features/sdk-module'
import { defineModule, defineTask } from '@st/redux'
import { FolderImportJob, STSDK } from '@st/sdk'
import { TAX_APP_IMPORT_INSTRUCTIONS, TaxApp, TaxAppId } from '@st/tax-folder'
import { match } from 'ts-pattern'

import { uploadToPresignedUrl } from '@features/file-storage/adapters/s3-storage-client'
import { FileEntry, unpackUpload } from '@st/util/archive/unpack-upload'
import { zipEntries } from '@st/util/archive/zip-entries'
import { computeFileHash } from '@st/util/xfile'

type UploadProgress = {
  bytesTransferred: number
  totalBytes: number
}
export type STFolderImportState =
  | { mode: CreateFolderImportMode; step: 'newOrReturning' }
  | { mode: CreateFolderImportMode; step: 'newClientsImportMethodSelector' }
  | { mode: CreateFolderImportMode; step: 'taxAppSelector' }
  | { mode: CreateFolderImportMode; step: 'copyMagicLink' }
  | { mode: CreateFolderImportMode; step: 'createOneByOne' }
  | STFolderImportTaxAppInstructions
  | STFolderImportUploadFile

export type CreateFolderImportMode = 'preview' | 'normal'

export type STFolderImportTaxAppInstructions = {
  mode: CreateFolderImportMode
  step: 'taxAppInstructions'
  taxApp: TaxApp
  currentPage: number
}

export type STFolderImportUploadFile =
  | { mode: CreateFolderImportMode; step: 'uploadImportFile'; taxApp: TaxApp }
  | { mode: CreateFolderImportMode; step: 'preparingFolderImportFile'; taxApp: TaxApp }
  | {
      mode: CreateFolderImportMode
      step: 'uploadingFolderImportFile'
      taxApp: TaxApp
      progress: UploadProgress
      importId: string
      sha1: string
      filename: string
    }

export type STFolderImportMessage =
  | { type: 'selectNewOrReturning' }
  | {
      type: 'selectReturningClients'
    }
  | { type: 'selectedTaxApp'; taxApp: TaxApp }
  | { type: 'prevInstruction' }
  | { type: 'nextInstruction' }
  | { type: 'selectNewClients' }
  | { type: 'selectBulkCSV' }
  | { type: 'selectOneByOne' }
  | { type: 'selectMagicLink' }
  | { type: 'selectOtherSoftware' }
  | { type: 'preparingFolderImport' }
  | {
      type: 'createdFolderImport'
      importId: string
      filename: string
      size: number
      mimeType: string
      sha1: string
    }
  | { type: 'folderImportUploadProgressed'; progress: UploadProgress }
  | { type: 'folderImportSubmitted'; folderImport: FolderImportJob }

type STFolderImportModuleSend = {
  sdk: SDKMessage
}

type STFolderImportModuleInit = {
  mode: CreateFolderImportMode
  // Define your init parameters here
}

export const stCreateFolderImportModule = defineModule<
  STFolderImportState,
  STFolderImportMessage,
  STFolderImportModuleInit,
  STFolderImportModuleSend
>({
  name: 'stFolderImport',
  init: ({ mode }) => {
    return { mode, step: 'newOrReturning' }
  },
  handle: (state, message) => {
    const mode = state.mode

    return match<
      { state: STFolderImportState; message: STFolderImportMessage },
      STFolderImportState
    >({ state, message })
      .with({ message: { type: 'selectNewOrReturning' } }, () => {
        return { mode: mode, step: 'newOrReturning' }
      })
      .with({ message: { type: 'selectReturningClients' } }, () => {
        return { mode, step: 'taxAppSelector' }
      })
      .with({ message: { type: 'selectNewClients' } }, ({ state }) => {
        return { ...state, step: 'newClientsImportMethodSelector' }
      })
      .with({ message: { type: 'selectMagicLink' } }, () => {
        return { mode, step: 'copyMagicLink' }
      })
      .with(
        { state: { step: 'newClientsImportMethodSelector' }, message: { type: 'selectOneByOne' } },
        () => {
          return { mode, step: 'createOneByOne' }
        }
      )
      .with(
        { state: { step: 'taxAppSelector' }, message: { type: 'selectedTaxApp' } },
        ({ state, message }) => {
          return { mode, step: 'taxAppInstructions', taxApp: message.taxApp, currentPage: 1 }
        }
      )
      .with(
        // we reuse the same flow for selecting another software as new clients
        { state: { step: 'taxAppSelector' }, message: { type: 'selectOtherSoftware' } },
        ({ state, message }) => {
          return { mode, step: 'newClientsImportMethodSelector' }
        }
      )
      .with(
        { state: { step: 'taxAppInstructions' }, message: { type: 'prevInstruction' } },
        ({ state, message }) => {
          if (state.currentPage == 1) {
            // if we're on the first page, we need to go back to the tax app selector
            return { mode, step: 'taxAppSelector' }
          }
          return { ...state, currentPage: state.currentPage - 1 }
        }
      )
      .with(
        { state: { step: 'taxAppInstructions' }, message: { type: 'nextInstruction' } },
        ({ state, message }) => {
          const instructions = TAX_APP_IMPORT_INSTRUCTIONS.find(
            (el) => el.appId == state.taxApp.id
          )!

          const nextPage = state.currentPage + 1
          const lastPage = instructions?.steps.length

          if (nextPage == lastPage) {
            return { ...state, step: 'uploadImportFile' }
          } else {
            return { ...state, currentPage: nextPage }
          }
        }
      )
      .with(
        { state: { step: 'uploadImportFile' }, message: { type: 'prevInstruction' } },
        ({ state, message }) => {
          const instructions = TAX_APP_IMPORT_INSTRUCTIONS.find(
            (el) => el.appId == state.taxApp.id
          )!
          const lastPage = instructions?.steps.length

          return {
            mode,
            step: 'taxAppInstructions',
            taxApp: state.taxApp,
            currentPage: lastPage - 1
          }
        }
      )
      .with(
        { state: { step: 'uploadImportFile' }, message: { type: 'preparingFolderImport' } },
        ({ state, message }) => {
          return { ...state, step: 'preparingFolderImportFile' }
        }
      )
      .with(
        {
          state: { step: 'preparingFolderImportFile' },
          message: { type: 'createdFolderImport' }
        },
        ({ state, message }) => {
          return {
            ...state,
            mode: mode,
            step: 'uploadingFolderImportFile',
            importId: message.importId,
            filename: message.filename,
            mimeType: message.mimeType,
            sha1: message.sha1,
            progress: { bytesTransferred: 0, totalBytes: message.size },
            taxApp: state.taxApp
          }
        }
      )
      .with(
        {
          state: { step: 'uploadingFolderImportFile' },
          message: { type: 'folderImportUploadProgressed' }
        },
        ({ state, message }) => {
          return { ...state, progress: message.progress }
        }
      )
      .otherwise(() => state)
  }
})

export function selInstructions(id: TaxAppId, pageNumber: number) {
  const instructions = TAX_APP_IMPORT_INSTRUCTIONS.find((el) => el.appId == id)
  if (!instructions) return
  return instructions.steps[pageNumber - 1]
}

export type CreateImportFolderContext = {
  sdk: STSDK
}

export const createAndUploadFolderImport = defineTask(
  stCreateFolderImportModule,
  async (
    { send },
    {
      dataTransfer,
      organizationId,
      sourceName
    }: { dataTransfer: DataTransfer; organizationId: string; sourceName: string },
    { sdk }: CreateImportFolderContext
  ) => {
    const fileEntries = await unpackUpload(dataTransfer)

    send({ type: 'preparingFolderImport' })

    const archiveFile = await packToSingleFile(fileEntries)
    const hash = await computeFileHash(await archiveFile.arrayBuffer())

    const response = await sdk.send({
      type: 'organizations/createFolderImportJob',
      organizationId: organizationId,
      sourceName: sourceName,
      filename: archiveFile.name,
      sha1: hash
    })

    send({
      type: 'createdFolderImport',
      importId: response.folderImportJob.id,
      filename: archiveFile.name,
      mimeType: archiveFile.type,
      sha1: hash,
      size: archiveFile.size
    })

    await uploadToPresignedUrl(archiveFile, {
      presignedUrl: response.uploadUrl,
      headers: response.uploadHeaders as Record<string, string>,
      onProgress: (progress) => {
        send({ type: 'folderImportUploadProgressed', progress: progress })
      }
    })

    const submittedImport = await sdk.send({
      type: 'organizations/submitFolderImportJob',
      folderImportJobId: response.folderImportJob.id
    })

    send({ type: 'folderImportSubmitted', folderImport: submittedImport })

    return submittedImport
  }
)

async function packToSingleFile(fileEntries: FileEntry[]): Promise<File> {
  // single archive file, no need to zip it up
  if (
    fileEntries.length == 1 &&
    (fileEntries[0].path.endsWith('.zip') || fileEntries[0].path.endsWith('.7z'))
  ) {
    return fileEntries[0].file
  }
  const timePrefix = new Date().toISOString().replace(/(\..+$|[:-])/g, '')
  return zipEntries(fileEntries, `import-${timePrefix}.zip`)
}
