export function deepEqual(a: any, b: any): boolean {
  if (a === b) {
    return true
  }
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false
    }

    for (var i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false
      }
    }

    return true
  } else if (
    a !== null &&
    typeof a === 'object' &&
    !Array.isArray(a) &&
    b !== null &&
    typeof b == 'object' &&
    !Array.isArray(b)
  ) {
    const aKeys = Object.keys(a)
    const bKeys = Object.keys(b)

    if (aKeys.length !== bKeys.length) {
      return false
    }

    for (var k of aKeys) {
      if (!deepEqual(a[k], b[k])) {
        return false
      }
    }
    return true
  }

  return false
}
