import { unique } from './array'
import { isNotEmpty } from './json-value'

type Entities = {
  taxpayer?: IndividualEntity
  spouse?: IndividualEntity
  corporation?: CorporateEntity
}

type IndividualEntity = {
  firstName?: string
  lastName?: string
  email?: string
}

type CorporateEntity = {
  name?: string
}

type EntitiesFormat = 'internal' | 'client'

export function formatEntities(entities: Entities, format: EntitiesFormat = 'internal') {
  if (!entities) {
    return ''
  }

  if (isNotEmpty(entities.corporation?.name)) {
    return entities.corporation.name
  }

  const taxpayer = entities.taxpayer?.firstName ? entities.taxpayer : undefined
  const spouse = entities.spouse?.firstName ? entities.spouse : undefined

  const lastNames = unique([taxpayer?.lastName, spouse?.lastName].filter(isNotEmpty))
  const firstNames = [taxpayer?.firstName, spouse?.firstName].filter(isNotEmpty)

  switch (format) {
    case 'internal':
      return [lastNames.join(' & '), firstNames.join(' & ')].filter(isNotEmpty).join(', ')
    case 'client':
      if (lastNames.length == 2) {
        // If there are two last names, we want to format the output as "John Smith & Jane Doe"
        return `${taxpayer?.firstName} ${taxpayer?.lastName} & ${spouse?.firstName} ${spouse?.lastName}`
      } else {
        // If there is only one last name, we want to format the output as "John & Jane Smith"
        return [firstNames.join(' & '), lastNames.join(' & ')].filter(isNotEmpty).join(' ')
      }
  }
}
