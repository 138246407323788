import { Result, err, ok, splitResults } from '@st/util/result'
import { Section } from '@st/util/section'
import { XFile, fetchFile, toXFile } from '@st/util/xfile'
import { PDFDocument, PageSizes } from 'pdf-lib'
import { PDFPageMode } from '../document/types'
import { loadPDFTronCore } from '../pdftron'
import { InvalidPDF, PDFError, concatPDFs } from './concat'
import { PDFOutlineNode, getPageInfo, setOutlineWithPDFTron } from './outline'
import { asyncMap } from '@st/util/async'
import type { Core } from '@pdftron/webviewer'

type PDFSection<T> = Section<T, XFile>

type MergeOptions<T> = {
  filename: string
  getHeading: (heading: T) => string | undefined
  pageMode?: PDFPageMode
}

type PartialPDF = {
  /**
   * The PDF file to be included inline
   */
  pdf: XFile

  /**
   * The number of files in the pdf
   */
  pageCount: number

  /**
   * The outline for the PDF file that will get merged into the final PDF
   */
  outline: PDFOutlineNode[]

  /**
   * If there's a corresponding attachment to be included in the final result
   */
  attachment?: XFile
}

type PDFFileSection<T> = Section<T, PartialPDF>

/**
 * Given a directory of .pdf files, return a single .pdf file
 * with all of the files merged together.
 *
 * Expects all of the inputs to be PDFs.
 * Files that are not PDFs will be included as attachments.
 *
 * @param dir
 * @returns
 */
export async function mergePDFs<T>(
  sections: PDFSection<T>[],
  opts: MergeOptions<T>
): Promise<Result<XFile, PDFError>> {
  // const pageMode = opts.pageMode ?? PDFPageMode.UseOutlines

  const core = await loadPDFTronCore()

  const pdfFileSections: PDFFileSection<T>[] = []
  for (const section of sections) {
    const fileSection: PDFFileSection<T> = {
      heading: section.heading,
      items: []
    }
    for (const file of section.items) {
      fileSection.items.push(await toPDFFile(file))
    }
    pdfFileSections.push(fileSection)
  }

  // First we just flatten and combine the pages
  // Outlines (bookmarks) will not be preserved so we need to bring those in
  // after the fact
  const sourcePDFs = pdfFileSections.flatMap((s) => s.items).map((el) => el.pdf)

  const concatResult = await concatPDFs(sourcePDFs)

  if (!concatResult.ok) {
    return concatResult
  }

  // // Acrobat, unlike other PDF readers, only generate appearance streams when it is explicitly told to do so,
  // // by setting / NeedsAppearances to true in the AcroForm dictionary.This is usually preserved when you fill out
  // // a single form, but when you copy forms, the setting is lost.
  // // further reading:
  // // - https://github.com/Hopding/pdf-lib/issues/569#issuecomment-1087328416
  // // - https://github.com/gettalong/hexapdf/issues/86#issuecomment-544110920
  // // - https://stackoverflow.com/questions/38915591/adobe-pdf-forms-text-field-displays-value-only-when-clicked-on-it
  // mergedDocument.getForm().acroForm.dict.set(PDFName.of('NeedAppearances'), PDFBool.True)

  // // Combine the outlines of all PDFs into one master outline
  const mergedOutline = mergeOutlines(pdfFileSections, opts)

  const doc = await core.PDFNet.PDFDoc.createFromBuffer(
    await fetchFile(concatResult.value).then((r) => r.arrayBuffer())
  )

  const pref = await doc.getViewPrefs()
  await pref.setPageMode(core.PDFNet.PDFDocViewPrefs.PageMode.e_UseBookmarks)

  await setOutlineWithPDFTron(doc, mergedOutline)

  const buf = await doc.saveMemoryBuffer(core.PDFNet.SDFDoc.SaveOptions.e_linearized)

  const mergedFile = await toXFile(new File([buf], opts.filename, { type: 'application/pdf' }), {
    protocol: 'blob'
  })

  return ok(mergedFile)
}

function mergeOutlines<T>(sections: PDFFileSection<T>[], opts: MergeOptions<T>): PDFOutlineNode[] {
  let mergedOutline: PDFOutlineNode[] = []
  let pageIndex = 0

  for (const { heading, items } of sections) {
    const title = opts.getHeading(heading)
    const sectionOutline: PDFOutlineNode = {
      title: title ?? '',
      children: [],
      open: true,
      to: pageIndex
    }

    for (const file of items) {
      const fileOutline = file.outline
      sectionOutline.children!.push({
        title: file.pdf.name,
        to: pageIndex,
        open: false,
        children: shiftOutlineIndex(fileOutline, pageIndex)
      })

      pageIndex += file.pageCount
    }

    if (title) {
      mergedOutline.push(sectionOutline)
    } else if (sectionOutline.children) {
      // there is no title so we want to lift the children up and include them as siblings
      // we also want them to be expanded
      const children = sectionOutline.children!.map((node) => {
        return { ...node, open: true }
      })
      mergedOutline = [...mergedOutline, ...children]
    }
  }

  return mergedOutline

  function shiftOutlineIndex(nodes: PDFOutlineNode[], delta: number): PDFOutlineNode[] {
    return nodes.map((node) => {
      const newNode: PDFOutlineNode = { ...node }

      // not all nodes are bookmarked to jump to a page
      // if that's the case, we skip over them
      // need a strict check to distinguish between 0 and undefined
      if (newNode.to !== undefined) {
        // offset destination by the pageIndex
        newNode.to = delta + newNode.to
      }
      if (newNode.children) {
        newNode.children = shiftOutlineIndex(newNode.children, delta)
      }
      return newNode
    })
  }
}

async function toPDFFile(file: XFile): Promise<PartialPDF> {
  switch (file.mimeType) {
    case 'application/pdf':
      try {
        const pageInfo = await getPageInfo(file)
        return {
          pdf: file,
          pageCount: pageInfo.pageCount,
          outline: pageInfo.outline
        }
      } catch (e) {
        console.error(e)
        return attachmentToPDFFile(file)
      }
    default:
      return attachmentToPDFFile(file)
  }
}

async function attachmentToPDFFile(file: XFile): Promise<PartialPDF> {
  const document = await PDFDocument.create()
  const pageWidth = PageSizes.A4[0]
  const pageHeight = 240
  const page = document.addPage([pageWidth, pageHeight])

  page.moveTo(30, 160)
  page.drawText(
    `${file.name} could not be downloaded\n\nTo download this file, select the “Download zip” option within StanfordTax.`,
    { size: 16 }
  )

  const pdfData = await document.save()
  const pdf = await toXFile(
    new File([pdfData], `${file.name} [omitted]`, { type: 'application/pdf' })
  )

  return {
    pdf: pdf,
    pageCount: document.getPageCount(),
    outline: [],
    attachment: file
  }
}
